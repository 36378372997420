import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {SidebarComponent} from '@syncfusion/ej2-angular-navigations';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ProjectSourceAdd, ProjectSourceSetStatusNull, ProjectSourceUpdate} from '../../../../core/store/actions/project-source.actions';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {debounce, EmitType} from '@syncfusion/ej2-base';
import {DropDownListComponent, DropDownTreeComponent, FilteringEventArgs} from '@syncfusion/ej2-angular-dropdowns';
import {Query} from '@syncfusion/ej2-data';
import {CompanyFilter, CompanyLoad, GetSearchedCompany} from '../../../../core/store/actions/company.actions';
import {ContactFilter, ContactLoad, GetAllCompanyContacts, GetSearchedContact} from '../../../../core/store/actions/contact.actions';
import {atLeastOne, websiteValidator} from '../../../../app-validators';
import {CompanyType} from '../../../../core/models/company-types.model';
import {ProjectSource} from '../../../../core/models/project-source.model';
import {ProjectSetUpdatedAtTime} from '../../../../core/store/actions/project.actions';
import * as moment from 'moment';
import * as _ from 'lodash';
import {CompanyService} from '../../../../core/store/services/company.service';
import {ContactService} from '../../../../core/store/services/contact.service';
import { ToolbarService, LinkService, ImageService, HtmlEditorService, RichTextEditorComponent, PasteCleanupSettingsModel, PasteCleanupService } from '@syncfusion/ej2-angular-richtexteditor';

@Component({
  selector: 'app-project-source-form',
  templateUrl: './project-source.component.html',
  styleUrls: ['./project-source.component.scss'],
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService, PasteCleanupService]
})
export class ProjectSourceComponent implements OnInit, OnChanges {


  @Input() set projectSourceInput(mode: ProjectSource | null) {
    this._projectSourceInput$.next(mode);
  }

  get projectSourceInput(): ProjectSource | null {
    return this._projectSourceInput$.getValue();
  }

  constructor(private formBuilder: FormBuilder, private store: Store<AppState>, private router: Router, private activatedRoute: ActivatedRoute,
              private companyService: CompanyService, private contactService: ContactService) {
  }

  @ViewChild('sidebarInstance') public sidebarInstance: SidebarComponent;
  @ViewChild('company') companyDropdown: DropDownListComponent;
  @ViewChild('contact') contactDropdown: DropDownListComponent;
  @ViewChild('sourceType') sourceTypeDropdown: DropDownTreeComponent;
  @ViewChild('fromRTE') private rteEle: RichTextEditorComponent;
  @Input() companyList: any;
  @Input() contactList: any;
  @Input() sourceTypes: any;
  @Input() projectList: any;
  @Input() projectSourceStatus: any;
  @Output() valueChange = new EventEmitter();

  public width = 'auto';
  public position = 'Right';
  projectCode: string;
  selectedCompany = '';
  selectedSourceContact: any;
  // currentDate = moment().format('MM/DD/YYYY');
  currentDate = new Date();
// currentDate = moment().

  public companyFields: object = {value: 'company_code', text: 'company_name'};
  public contactFields: object = {value: 'value', text: 'name'};
  sourceTypeFields: object;
  sourceStatusFields: object = {
    value: 'project_source_status_code',
    text: 'project_source_status_name',
    dataSource: this.projectSourceStatus
  };

  projectSourceForm: FormGroup;
  errorMsg: any;
  success$: Observable<boolean>;
  error$: Observable<any>;
  allowEdit = false;

  public formSent: boolean;
  settingForm = true;
  private selectedCompanyName: string;
  private selectedName: string;
  selectedSourceTypes: any;
  public _projectSourceInput$: BehaviorSubject<ProjectSource | null> = new BehaviorSubject<ProjectSource | null>(null);

  public tools: object = {
    type: 'MultiRow',
      items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
  'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
  'Outdent', 'Indent', '|',
  'CreateLink', '|','ClearFormat', 'Print',
  'FullScreen', '|', 'Undo', 'Redo']
};

public pasteCleanupSettings: PasteCleanupSettingsModel = {deniedTags: ['img', 'script', 'iframe']}

  setDebounce = debounce((e, entity: string) => {
    this.onFilter(e, entity);
  }, 700);


  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.sidebarInstance.isOpen) {
      this.closeSidebar();
    }
  }

  ngOnInit(): void {
    this.projectCode = this.activatedRoute.parent.snapshot.paramMap.get('project_code');
    this.generateProjectSourceForm();

    this.error$ = this.store.pipe(select(store => store.projectSources.error));
    this.error$.subscribe((data: any) => {

      if (data) {
        this.errorMsg = data.error.message;
        this.formSent = false;

      }
    });

    this.success$ = this.store.pipe(select(store => store.projectSources.success));
    this.success$.subscribe((data: any) => {

      if (data) {
        this.store.dispatch(new ProjectSourceSetStatusNull());
        this.store.dispatch(new ProjectSetUpdatedAtTime(moment().format()));
        this.formSent = false;
        this.closeSidebar();
      }
    });
  }

  generateProjectSourceForm() {
    this.projectSourceForm = this.formBuilder.group({
      project_source_type_code: new FormControl([], [Validators.required]),
      contact_code: new FormControl(null, [Validators.required]),
      company_code: new FormControl(null),
      drive_link: new FormControl(null, [Validators.compose([websiteValidator]), Validators.maxLength(500)]), 
      notes: new FormControl(null),
      project_source_status_code: new FormControl(null, [Validators.required]),
      date_received: new FormControl('', [Validators.required])
    });

    if (!this._projectSourceInput$.getValue()) {
      // this.projectSourceForm.controls.date_received.setValue(this.currentDate);
    }
  }

  onAddUpdateProjectSource(form: FormGroup, isAdd: boolean) {
    console.log(form.value);
    
    form = _.cloneDeep(form);
    // console.log(this.contactList.find(item => item.value === form.value.contact_code));
    // console.log(this.contactList);
    if (!form.invalid) {
      let request;
      console.log(this.selectedSourceTypes);
      request = {
        projectCode: this.projectCode,
        project_source_type_code: this.selectedSourceTypes ? [...this.selectedSourceTypes] : null,
        companyCode: this.selectedSourceContact ? this.selectedSourceContact.company_code : null,
        contactCode: this.selectedSourceContact ? this.selectedSourceContact.contact_code : null,
        drive_link: form.value.drive_link,
        notes: form.value.notes,
        project_source_status_code: form.value.project_source_status_code,
        company_contact_code: this.selectedSourceContact ? this.selectedSourceContact.value : null,
        date_received: moment(form.value.date_received).format('YYYY-MM-DD')
      };


      if (this.router.url.indexOf('project/details') > -1) {
        request = {...request, currentScreen: 'project'};
      } else {
        request = {...request, currentScreen: 'source'};
      }
      this.formSent = true;
      if (isAdd) {
        this.store.dispatch(new ProjectSourceAdd(request));
      } else {
        request = {...request, projectSourceCode: this._projectSourceInput$.getValue().project_source_code};
        this.store.dispatch(new ProjectSourceUpdate(request));
      }
    }
  }

  openClick(): void {
    if (!this._projectSourceInput$.getValue()) {
      this.projectSourceForm.reset();
      this.projectSourceForm.controls.date_received.setValue(this.currentDate);
    }
    this.sidebarInstance.show();
  }

  onSelectContact(args) {
    this.selectedSourceContact = args.itemData;
    console.log(this.selectedSourceContact);
  }

  addNewCompanyContact(entity: string) {
    if (entity === 'company') {
      this.valueChange.emit({message: entity});
    } else if (entity === 'contact' || this.selectedCompany) {
      this.valueChange.emit({message: entity, company_code: this.selectedCompany});
    }
  }

  public closeSidebar(): void {
    this.formSent = false;
    this.projectSourceForm.reset();
    // this.projectSourceForm.controls.project_source_type_code.setValue('');
    if (this.sidebarInstance) {
      this.sidebarInstance.hide();
    }
    this.valueChange.emit({message: 'success'});
  }

  public onFiltering = (e, entity: string) => {
    e.preventDefaultAction = true;
    this.setDebounce(e, entity);
  };

  public onFilter: EmitType<any> = (e: FilteringEventArgs, entity: string) => {
    let query = new Query();
    if (entity === 'company') {

      query = (e.text !== '') ? query.where('company_name', 'contains', e.text, true) : query;
      if (e.text && e.text !== '') {
        const filterParams = {company_name: e.text, page_size: 40, page_no: 1};
        this.companyService.getSearchedCompany(filterParams).subscribe((data) => {
          if (data && data.data) {
            if (data.data.result) {
              const result: any = data.data.result;
              e.updateData(result, query);
            }
          }
        });
        // this.store.dispatch(new GetSearchedCompany(filterParams));
      } else {
        const filterParams = {page_size: 40, page_no: 1};
        this.store.dispatch(new CompanyLoad(filterParams));
      }


      // this.store.select(store => store.companies.companies).subscribe((data) => {
      //   if (data) {
      //     this.companyList = data.result;
      //     e.updateData(this.companyList, query);
      //   }
      // });
    } else if (entity === 'contact') {
      query = (e.text !== '') ? query.where('name', 'contains', e.text, true) : query;
      const contactfilterParams = {name: e.text, page_size: 40, page_no: 1};
      this.contactService.getSearchedContact(contactfilterParams).subscribe((data) => {
        if (data && data.data) {
          const companyContactList = [];
          if (data.data.result) {
            for (const contact of data.data.result) {

              if (contact.associated_companies) {
                for (const associatedCompany of contact.associated_companies) {
                  let company = '';
                  let company_code = '';
                  const company_contact_code = associatedCompany.company_contact_code;

                  if (associatedCompany.company) {
                    company = ' - ' + associatedCompany.company.company_name;
                    company_code = associatedCompany.company.company_code;
                  } else {
                    company = ' (' + associatedCompany.email + ')';
                    company_code = '';
                  }

                  const result = {
                    value: company_contact_code,
                    name: (contact.first_name + ' ' + contact.last_name + company),
                    company_code, contact_code: contact.contact_code
                  };
                  companyContactList.push(result);
                }
              }
            }
            // this.contactList = companyContactList;
            e.updateData(companyContactList, query);
          }
        }
      });
      // this.store.dispatch(new GetSearchedContact(contactfilterParams));


      // this.store.select(store => store.contacts.contacts).subscribe((data) => {
      //   if (data) {
      //     const companyContactList = [];
      //     for (const contact of data.result) {
      //
      //       if (contact.associated_companies) {
      //         for (const associatedCompany of contact.associated_companies) {
      //           let company = '';
      //           let company_code = '';
      //           const company_contact_code = associatedCompany.company_contact_code;
      //
      //           if (associatedCompany.company) {
      //             company = ' - ' + associatedCompany.company.company_name;
      //             company_code = associatedCompany.company.company_code;
      //           } else {
      //             company = ' (' + associatedCompany.email + ')';
      //             company_code = '';
      //           }
      //
      //           const result = {
      //             value: contact.contact_code,
      //             name: (contact.first_name + ' ' + contact.last_name + company),
      //             company_code, company_contact_code
      //           };
      //           this.contactList.push(result);
      //         }
      //       }
      //     }
      //     this.contactList = companyContactList;
      //     e.updateData(companyContactList, query);
      //   }
      // });
    }
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sourceTypes) {
      this.sourceTypeFields = {
        value: 'project_source_type_code', text: ('project_source_type_name'), child: 'children',
        dataSource: _.cloneDeep(this.sourceTypes)
      };
    }

    if (this._projectSourceInput$.getValue()) {
      this.searchAndSetCompany();

      const project_type_codes_array = [];
      if (this._projectSourceInput$.getValue().project_source_type) {
        for (const project_source_type of this._projectSourceInput$.getValue().project_source_type) {
          project_type_codes_array.push(project_source_type.project_source_type_code);
        }
      }

      this.projectSourceForm.setValue({
        company_code: this._projectSourceInput$.getValue().company ? this._projectSourceInput$.getValue().company.company_code : null,
        contact_code: this._projectSourceInput$.getValue().contact ? this._projectSourceInput$.getValue().contact.company_contact_code : null,
        drive_link: this._projectSourceInput$.getValue().drive_link ? this._projectSourceInput$.getValue().drive_link : null,
        notes: this._projectSourceInput$.getValue().notes ? this._projectSourceInput$.getValue().notes : null,
        project_source_type_code: project_type_codes_array ? project_type_codes_array : null,
        project_source_status_code: this._projectSourceInput$.getValue().project_source_status ?
          this._projectSourceInput$.getValue().project_source_status.project_source_status_code : null,
        date_received: this._projectSourceInput$.getValue().date_received ? moment(this._projectSourceInput$.getValue().date_received, 'YYYY-MM-DD HH:mm').format('MM/DD/YYYY') : null,
      });
    }
  }

  searchAndSetCompany() {
    this.selectedName = this._projectSourceInput$.getValue().company ? this._projectSourceInput$.getValue().company.company_name : null;
    if (this.selectedName) {
      const isCompanyExistsInList = this.companyList.filter(el => el.company_name === this.selectedName).length > 0;
      if (!isCompanyExistsInList) {
        const companyList = [];
        companyList.push(this._projectSourceInput$.getValue().company);
        this.companyList.forEach(el => companyList.push(el));
        // this.companyDropdown.dataSource = companyList;
        this.projectSourceForm.controls.company_code.setValue(this._projectSourceInput$.getValue().company.company_code);
      }
    }
    this.selectedName = this._projectSourceInput$.getValue().contact ? this._projectSourceInput$.getValue().contact.first_name : null;
    // if (this.selectedName) {
    const isContactExistsInList = this.contactList.filter(el => el.name === this.selectedName).length > 0;
    if (!isContactExistsInList) {
      const contactList = [];
      let company;
      if (this._projectSourceInput$.getValue().company) {
        company = ' - ' + this._projectSourceInput$.getValue().company.company_name;
      } else {
        company = ' ( ' + this._projectSourceInput$.getValue().contact.email + ' ) ';
      }
      const result = {
        value: this._projectSourceInput$.getValue().contact.company_contact_code,
        contact_code: this._projectSourceInput$.getValue().contact.contact_code,
        name: (this._projectSourceInput$.getValue().contact.first_name + ' ' + this._projectSourceInput$.getValue().contact.last_name + company),
        company_code: this._projectSourceInput$.getValue().company ? this._projectSourceInput$.getValue().company.company_code : null
      };
      contactList.push(result);
      this.contactList.push(result);
      // this.contactList.forEach(el => contactList.push(el));
      // this.contactDropdown.dataSource = contactList;
      this.projectSourceForm.controls.contact_code.setValue(this._projectSourceInput$.getValue().contact.company_contact_code);
    } else {
      this.projectSourceForm.controls.contact_code.setValue(this._projectSourceInput$.getValue().contact.company_contact_code);
    }
    if (this._projectSourceInput$.getValue().company) {
      this.settingForm = false;
    }
    // }
  }

  change(args) {
    this.selectedCompanyName = args.itemData ? args.itemData.company_code : '';
    this.selectedCompany = args.itemData ? args.itemData : '';

    if (this.settingForm) {
      this.projectSourceForm.controls.contact_code.setValue(null);
    } else {
      this.settingForm = true;
    }

    if (args.itemData) {
      this.getCompanyContactData(args.itemData.company_code);
    } else {
      this.getCompanyContactData();
    }
  }

  getCompanyContactData(company_code?) {
    if (company_code) {
      // console.log(args.itemData.company_code);
      this.store.dispatch(new GetAllCompanyContacts({company_code}));
      this.store.select(store => store.contacts.companyContacts).subscribe((data) => {
        if (data) {
          const companyContactList = [];
          for (const contact of data.result) {

            let company = '';
            if (contact.company) {
              company = ' - ' + contact.company.company_name;
            } else {
              company = '';
            }
            let result = {
              value: contact.contact_code, category: 'Contacts',
              name: (contact.first_name + ' ' + contact.last_name + company),
            };

            if (contact.company) {
              result = {...result, ...{company_code: contact.company.company_code, company_name: contact.company.company_name}};
            }
            companyContactList.push(result);
          }
          this.contactList = companyContactList;
          console.log('comes gere?????????');
        }
      });
    } else {
      // this.store.dispatch(new ContactLoad());
      // this.store.select(store => store.contacts.contacts).subscribe((data) => {
      //   if (data) {
      //     const companyContactList = [];
      //     for (const contact of data.result) {
      //
      //       let company = '';
      //       if (contact.company) {
      //         company = ' - ' + contact.company.company_name;
      //       } else {
      //         company = '';
      //       }
      //       let result = {
      //         value: contact.contact_code, category: 'Contacts',
      //         name: (contact.first_name + ' ' + contact.last_name + company),
      //       };
      //
      //       if (contact.company) {
      //         result = {...result, ...{company_code: contact.company.company_code, company_name: contact.company.company_name}};
      //       }
      //       companyContactList.push(result);
      //     }
      //     this.contactList = companyContactList;
      //   }
      // });
    }
  }

  focus(args) {
    if (this.selectedCompanyName) {
      this.getCompanyContactData(this.selectedCompanyName);
    } else {
      this.getCompanyContactData();
    }
  }

  setSourceType(args) {
    console.log(args);
    this.projectSourceForm.controls.project_source_type_code.setValue(args.value);
    this.selectedSourceTypes = _.cloneDeep(args.value);
  }

  onSelect(args) {
    this.projectSourceForm.controls.contact_code.setValue(null);
  }

  onChangeSourceType(args) {
    // if (args.itemData.hasChildren) {
    //   // args.itemData.selected = false;
    //   // args.itemData.isChecked = false;
    //   // console.log(this.sourceTypeDropdown);
    // }
    // console.log(this.sourceTypeDropdown.value);
    // console.log(args);
  }

  rteCreated(): void {
    this.rteEle.element.focus();
    this.rteEle.pasteCleanupSettings = this.pasteCleanupSettings;
  }

  actionBegin(args): void {
    if(args.type === 'drop' || args.type === 'dragstart') {
        args.cancel =true;
    }
  }
}
