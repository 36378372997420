import {Action} from '@ngrx/store';
import {CompanyType} from '../../models/company-types.model';

export enum CompanyTypeActionTypes {
  LOAD_COMPANY_TYPE = '[COMPANY TYPE] Load Action',
  LOAD_COMPANY_TYPE_SUCCESS = '[COMPANY TYPE] Load Success Action',
  LOAD_COMPANY_TYPE_FAILURE = '[COMPANY TYPE] Load Failure Action',

  ADD_COMPANY_TYPE = '[COMPANY TYPE] Add Action',
  ADD_COMPANY_TYPE_SUCCESS = '[COMPANY TYPE] Add Success Action',
  ADD_COMPANY_TYPE_FAILURE = '[COMPANY TYPE] Add Failure Action',

  UPDATE_COMPANY_TYPE = '[COMPANY TYPE] Update Action',
  UPDATE_COMPANY_TYPE_SUCCESS = '[COMPANY TYPE] Update Success Action',
  UPDATE_COMPANY_TYPE_FAILURE = '[COMPANY TYPE] Update Failure Action',

  DELETE_COMPANY_TYPE = '[COMPANY TYPE] Delete Action',
  DELETE_COMPANY_TYPE_SUCCESS = '[COMPANY TYPE] Delete Success Action',
  DELETE_COMPANY_TYPE_FAILURE = '[COMPANY TYPE] Delete Failure Action',

  RESET_ERROR = '[COMPANY TYPE] Reset Error Action'
}

export class CompanyTypeLoad implements Action {
  readonly type = CompanyTypeActionTypes.LOAD_COMPANY_TYPE;
  constructor(public payload?: {page_size?: number, page_no?: number}) { }
}

export class CompanyTypeLoadSuccess implements Action {
  readonly type = CompanyTypeActionTypes.LOAD_COMPANY_TYPE_SUCCESS;
  constructor(public payload: { result: Array<CompanyType>, total_count: number, page_size: number, page_no: number }) { }
}

export class CompanyTypeLoadFailure implements Action {
  readonly type = CompanyTypeActionTypes.LOAD_COMPANY_TYPE_FAILURE;
  constructor(public payload: Error) { }
}

export class CompanyTypeAdd implements Action {
  readonly type = CompanyTypeActionTypes.ADD_COMPANY_TYPE;
  constructor(public payload: {company_type_name: string, parent_type_code?: string}) { }
}

export class CompanyTypeAddSuccess implements Action {
  readonly type = CompanyTypeActionTypes.ADD_COMPANY_TYPE_SUCCESS;
  constructor(public payload: { companyType: CompanyType, parentCode: string }) { }
}

export class CompanyTypeAddFailure implements Action {
  readonly type = CompanyTypeActionTypes.ADD_COMPANY_TYPE_FAILURE;
  constructor(public payload: Error) { }
}

export class CompanyTypeUpdate implements Action {
  readonly type = CompanyTypeActionTypes.UPDATE_COMPANY_TYPE;
  constructor(public payload: { company_type_code: string, companyTypeName: string, parent_type_code: string }) { }
}

export class CompanyTypeUpdateSuccess implements Action {
  readonly type = CompanyTypeActionTypes.UPDATE_COMPANY_TYPE_SUCCESS;
  constructor(public payload: { companyType: CompanyType, parent_type_code: string }) { }
}

export class CompanyTypeUpdateFailure implements Action {
  readonly type = CompanyTypeActionTypes.UPDATE_COMPANY_TYPE_FAILURE;
  constructor(public payload: Error) { }
}

export class CompanyTypeDelete implements Action {
  readonly type = CompanyTypeActionTypes.DELETE_COMPANY_TYPE;
  constructor(public payload: { companyTypeCode: string }) { }
}

export class CompanyTypeDeleteSuccess implements Action {
  readonly type = CompanyTypeActionTypes.DELETE_COMPANY_TYPE_SUCCESS;
  constructor(public payload: { companyTypeCode: string }) { }
}

export class CompanyTypeDeleteFailure implements Action {
  readonly type = CompanyTypeActionTypes.DELETE_COMPANY_TYPE_FAILURE;
  constructor(public payload: Error) { }
}

export class CompanyTypeResetError implements Action {
  readonly type = CompanyTypeActionTypes.RESET_ERROR;
}

export type CompanyTypeActions = CompanyTypeLoad | CompanyTypeLoadSuccess | CompanyTypeLoadFailure | CompanyTypeAdd |
  CompanyTypeAddSuccess | CompanyTypeAddFailure | CompanyTypeUpdate | CompanyTypeUpdateSuccess | CompanyTypeUpdateFailure |
  CompanyTypeDelete | CompanyTypeDeleteSuccess | CompanyTypeDeleteFailure | CompanyTypeResetError;
