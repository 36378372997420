import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

import {Injectable} from '@angular/core';
import {ResponseData} from '../ResponseData.interface';
import {environment} from '../../../environments/environment.prod';
import { v4 as uuid } from 'uuid';


const API_URL = environment.API_URL;


@Injectable()
export class CommonService {
  id: string;

  constructor(private http: HttpClient) {
  }

  // Get Company Types
  getCompanyTypes(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'company-type');
  }

  // Get Countries
  getCountries(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'country');
  }

  // Get Project Types
  getProjectTypes(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'project-type');
  }

  // Get Project Status
  getProjectStatus(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'project-status');
  }

  // Get users
  getUsers(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'user');
  }

  // Get Company Roles (Project)
  getCompanyRoles(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'company-role');
  }

  // Get Activity Types
  getActivityTypes(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'activity-type');
  }

  // Get All Tags
  getTags(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'tag');
  }

  // Get user avatar
  getUserAvatar(userCode: string): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'user/generate-avatar/' + userCode);
  }

  // Get project count for status - Dashboard
  getProjectCount(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'project-status/list-project-count');
  }

  getProjectCountByYear(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'project-status/list-project-count-by-year');
  }

  // Get Order Roles
  getOrderRoles(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'order-role');
  }

  // Get Order Roles
  getSourceTypes(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'project-source-type');
  }

  // Get Release Notes
  getReleaseNotes(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'release-notes');
  }

  // Get Categories
  getCategories(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'application-category');
  }

  // Get Email Categories
  getEmailCategories(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'email-category');
  }

  // Format Email
  formatEmail(company_contact_code: string, sender_email: string, email_template_code: string, company_code: string, project_code, type): Observable<ResponseData> {
    const request: any = {
      company_contact_code, sender_email, email_template_code, company_code, project_code
    };

    if (type === 'company') {
      delete request.project_code;
    }
    return this.http.post<ResponseData>(API_URL + 'send-email/format', request);
  }

  // Send Email
  sendEmail(receiver_email: string, sender_email: string, email_subject: string, email_content: string,
            email_template_code: string, company_code: string, project_code, activity: any, type): Observable<ResponseData> {
    let request: any = {company_contact_code: receiver_email, sender_email, email_subject, email_content, email_template_code,
      company_code,  batch_id: uuid()};

    if (type === 'company') {
      request = {...request, company_activity: activity};
    } else {
      request = {...request, project_activity: activity, project_code};

    }

    return this.http.post<ResponseData>(API_URL + 'send-email', request);
  }

  // Get email keywords
  getEmailKeywords(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'email-keyword');
  }

  // Get email keywords
  getDeliverableItemDate(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'deliverable-item-date');
  }

  // Get social media platform
  getSocialMediaPlatform(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'social-media-platform');
  }

  getGoogleMapsCoordinates(address) {
    return this.http.get<ResponseData>(API_URL + 'country/get-map-coordinates', { params: {address}});
  }

  getGoogleMapsAddress(latitude, longitude) {
    const latlng = latitude + ',' + longitude;
    return this.http.get<ResponseData>(API_URL + 'country/get-map-address', { params: {latlng}});
  }

  getDefaultTabs(master_type?: string) {
    return this.http.get<ResponseData>(API_URL + 'common-master', { params: {master_type}});
  }

  // Get Regions
  getRegions(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'region');
  }

  // Sync logs
  getSyncJobs(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'sync-jobs');
  }

  // Sync logs
  getFilteredSyncJobs(params): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'sync-jobs/filter', {params});
  }

  // Sync Blocks
  getSyncBlocks(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'sync-block');
  }
  // Get social media platform
  getOrderStatus(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'order-status');
  }

  // Sync logs
  getLTDDataSyncLogs(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'get-ltd-sync-data');
  }

  // Get community service
  getCommunityService(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'community-service');
  }

  // Get Product Type
  getProductType(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'product-type');
  }

  // Get community service
  getCommunityType(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'community-type');
  }

  retryOrIgnoreSync(params): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'retry-canvas-sync', {params});
  }


}
