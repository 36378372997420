import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'formatDate',
})
export class FormatDatePipe implements PipeTransform {
  transform(value: any, dateTimeFormat: string): any {
    try {
      return moment(value).format(dateTimeFormat);
    } catch (error) {
      return '-';
    }
  }
}
