<ejs-sidebar id="sidebar-menu" #sidebarInstance type='Over' [width]='width' [position]='position'
             style="visibility: hidden" [enableGestures]="false" [showBackdrop]=true>

  <div class="offcanvas-close mt-n1 pr-5" style="float:right; padding: 48px;">
    <a href="javascript:;" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_panel_close"
       (click)="closeSidebar()">
      <i class="ki ki-close icon-xs text-muted"></i>
    </a>
  </div>
  <div class="p-15">
    <h2>Send Email</h2>
    <form id="formId" [formGroup]="sendEmailForm" #formDir="ngForm" class="form-horizontal" novalidate="">

      <div class="form-group sf-outline-input mb-10">
        <ejs-dropdowntree formControlName="email_template" [fields]="emailTemplateFields" cssClass="e-filled"
                          (change)="onSelectEmail($event)" [allowFiltering]=true [filterType]="'contains'"
                          placeholder='Email Template' floatLabelType="Auto"
                          (blur)="sendEmailForm.controls.email_template.markAsTouched()"></ejs-dropdowntree>
        <div *ngIf="sendEmailForm.controls.email_template.invalid">
          <div
            *ngIf="sendEmailForm.controls.email_template.errors.required && sendEmailForm.controls.email_template.touched "
            class="e-error">
            Please select an email template
          </div>
        </div>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-dropdownlist id="sender_email" formControlName="sender_email" [dataSource]='sendEmailList'
                          [fields]="sendEmailField" cssClass="e-filled"
                          placeholder='Send email as' floatLabelType="Auto" (change)="onSelectSenderEmail($event)"
                          (blur)="sendEmailForm.controls.sender_email.markAsTouched()"
        ></ejs-dropdownlist>
        <div *ngIf="sendEmailForm.controls.sender_email.invalid">
          <div
            *ngIf="sendEmailForm.controls.sender_email.errors.required && sendEmailForm.controls.sender_email.touched "
            class="e-error">
            Please select a sender
          </div>
        </div>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <div class="float-right mb-3 d-flex align-items-center" *ngIf="sendEmailForm.controls.receiver_email.value !== '' && sendEmailForm.controls.receiver_email.value !== null">
          <span
            class="text-info cursor-pointer"
            (click)="openEmailLog()">
           {{selectedLastEmailedOn}}
          </span>
        </div>
        <ejs-dropdownlist id="receiver_email" placeholder="Receiver Email" cssClass="e-filled"
                          [dataSource]='receiverEmailList' [fields]="senderEmailField"
                          formControlName="receiver_email" floatLabelType="Auto"
                          (change)="onSelectReceiverEmail($event)"
                          (blur)="sendEmailForm.controls.receiver_email.markAsTouched()"
        ></ejs-dropdownlist>
        <div *ngIf="sendEmailForm.controls.receiver_email.invalid">
          <div
            *ngIf="sendEmailForm.controls.receiver_email.errors.required && sendEmailForm.controls.receiver_email.touched "
            class="e-error">
            Please select a receiver
          </div>
        </div>

        <div class="float-right mb-3 d-flex align-items-center" *ngIf="sendEmailForm.controls.receiver_email.value !== '' && sendEmailForm.controls.receiver_email.value !== null">
          <button
            class="btn btn-light btn-text-primary btn-hover-text-primary text-uppercase font-weight-bold mr-2 btn-sm p-3  ml-3 add-btn"
            (click)="openCompanyContactPage()" type="button">
            Open selected Company/Contact
          </button>
          <button
            class="btn btn-light btn-text-primary btn-hover-text-primary text-uppercase font-weight-bold mr-2 btn-sm p-3  ml-3 add-btn"
            (click)="refreshEmailList(true)" type="button">
            Refresh
          </button>
        </div>
      </div>

<!--      <div class="form-group sf-outline-input mb-10">-->
<!--        <ejs-textbox placeholder="Bcc" cssClass="e-filled"-->
<!--                     formControlName="bcc_email" floatLabelType="Auto"-->
<!--        ></ejs-textbox>-->
<!--      </div>-->

<!--      <div class="form-group sf-outline-input mb-10">-->
<!--        <ejs-textbox placeholder="cc" cssClass="e-filled"-->
<!--                     formControlName="cc_email" floatLabelType="Auto"-->
<!--        ></ejs-textbox>-->
<!--      </div>-->

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox placeholder="Subject" cssClass="e-filled"
                     (blur)="sendEmailForm.controls.email_subject.markAsTouched()"
                     formControlName="email_subject" floatLabelType="Auto"
        ></ejs-textbox>
        <div *ngIf="sendEmailForm.controls.email_subject.invalid">
          <div
            *ngIf="sendEmailForm.controls.email_subject.errors.required && sendEmailForm.controls.email_subject.touched "
            class="e-error">
            Email Subject is required
          </div>
        </div>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox style="height: auto" placeholder="Content" cssClass="e-filled" multiline="true"
                     formControlName="email_content" floatLabelType="Auto" rows="12"
                     (blur)="sendEmailForm.controls.email_content.markAsTouched()"
        ></ejs-textbox>
        <div *ngIf="sendEmailForm.controls.email_content.invalid">
          <div
            *ngIf="sendEmailForm.controls.email_content.errors.required && sendEmailForm.controls.email_content.touched "
            class="e-error">
            Email Content is required
          </div>
          <div
            *ngIf="sendEmailForm.controls.email_content.errors.pattern"
            class="e-error">
            Please remove any email keywords before sending the email
          </div>
        </div>
      </div>


      <div class="my-10 pb-20">
        <button
          class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5"
          type="button" (click)="clearForm()"
          data-ripple="true">Clear All
        </button>
        <button id="filter"
                class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block"
                type="submit"
                (click)="sendEmail(sendEmailForm)" [disabled]="formSent" data-ripple="true">Send Email
        </button>
      </div>

    </form>
  </div>
</ejs-sidebar>
