// Angular
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
// RxJS
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import {environment} from '../../../../../environments/environment.prod';
import {Logout} from '../../../store/auth/auth.actions';
import {Store} from '@ngrx/store';
import {AppState} from '../../../index';

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class InterceptService implements HttpInterceptor {

  constructor(private router: Router, private store: Store<AppState> ) {

  }
  // intercept request and add token
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (request.url.indexOf('maps.googleapis.com') > -1) {
      return next.handle(request);
    }

    const token = localStorage.getItem('token');
    request = request.clone({
      setHeaders: {Authorization: 'Bearer ' + token }
    });
    return next.handle(request).pipe(
      tap(
        event => {
          if (event instanceof HttpResponse) {
            if (request.url === environment.API_URL + 'auth/token-login') {
              if (event.body) {
                if (Array.isArray(event.body.data)) {
                  localStorage.clear();
                  this.store.dispatch(new Logout());
                }
              }
            }
            // console.log('all looks good');
            // http response status code
            // console.log(event.status);
          }
        },
        error => {
          if (request.url === environment.API_URL + 'auth/token-login') {
            if (error.status === 401 || error.status === 500) {
              localStorage.clear();
              this.store.dispatch(new Logout());
              // this.router.navigateByUrl('auth/login');
            }
          }

          if (request.url !== environment.API_URL + 'auth/login') {
            if (error.status === 401) {
              localStorage.clear();
              this.store.dispatch(new Logout());
              // this.router.navigateByUrl('auth/login');
            }
          }

          if (error && error.error === 'Wrong number of segments') {
            localStorage.clear();
            this.store.dispatch(new Logout());
            // this.router.navigateByUrl('auth/login');
          }

          if (error !== null && error.error !== undefined && error.error.code !== undefined) {
            return throwError(error.error.message);
          } else {
            return throwError(error);
          }
          // http response status code
          // console.log('----response----');
          // console.error('status code:');
          // tslint:disable-next-line:no-debugger
          // console.log('--- end of response---');
        }
      )
    );
  }
}
