import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {DateToDurationPipe} from "./views/pipes/pipes/date-to-duration.pipe";

export const modules = [
  MatIconModule,
];

@NgModule({
  declarations: [
    DateToDurationPipe
  ],
  imports: [...modules],
  exports: [...modules, DateToDurationPipe],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MaterialModule {}
