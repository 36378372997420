import { Injectable } from "@angular/core";
import { Store } from '@ngrx/store';
import { AppState } from '..';
import { LoginByToken } from '../store/auth/auth.actions';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private authorizationData: any;

  constructor(private store: Store<AppState>) {
  }

  setPermissions(permissions) {
    this.authorizationData = permissions;
  }

  init = (): Promise<object> => {
    const token = localStorage.getItem('token');
    if (token) {
      return new Promise((resolve, reject) => {
        this.store.dispatch(new LoginByToken());
        return resolve();
      });
    } else {
      return new Promise((resolve, reject) => resolve());
    }
  }


  isAuthorized(featureID, accessType) {
    if (this.authorizationData === undefined || this.authorizationData === null) {
      return false;
    } else {
      return this.getAuthorization(featureID, accessType);
    }
  }

  getAuthorization = (features, accessType) => {
    let access = false;
    for (const key in features) {
      if (features.hasOwnProperty(key)) {
        const element = features[key];
        if (!access && element && this.authorizationData[element]) {
          access = this.authorizationData[element][accessType] !== 0;
        }
      }
    }
    return access;
  }
}
