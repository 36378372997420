<ejs-sidebar id="sidebar-menu" #sidebarInstance type='Over' [width]='width' [position]='position'
             style="visibility: hidden" [enableGestures]="false" [showBackdrop]=true>

  <div class="offcanvas-close mt-n1 pr-5" style="float:right; padding: 48px;">
    <a href="javascript:;" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_panel_close"
       (click)="closeSidebar()">
      <i class="ki ki-close icon-xs text-muted"></i>
    </a>
  </div>
  <div class="p-15">
    <h2>Email Log Filter</h2>
    <form id="formId" [formGroup]="filterForm" #formDir="ngForm" class="form-horizontal" novalidate="">

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="email_subject" placeholder="Email Subject" cssClass="e-filled"
                     formControlName="email_subject" floatLabelType="Auto"
                     (blur)="filterForm.controls.email_subject.markAsTouched()"
        ></ejs-textbox>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="email_content" placeholder="Email Content" cssClass="e-filled"
                     formControlName="email_content" floatLabelType="Auto"
                     (blur)="filterForm.controls.email_content.markAsTouched()"
        ></ejs-textbox>
      </div>

      <div class="form-group sf-outline-input mb-10"
           [class.hide-label]="filterForm.controls.email_template_code.value == ''">
        <ejs-dropdowntree formControlName="email_template_code" id='email_template_code' allowMultiSelection='true'
                          [fields]='emailTemplateFields' placeholder='Email Template' cssClass="e-filled" [treeSettings]='treeSettings'
                          sortOrder="Ascending" (blur)="filterForm.controls.email_template_code.markAsTouched()"
                          floatLabelType="Auto" [showCheckBox]='true'></ejs-dropdowntree>
      </div>



      <div class="form-group sf-outline-input mb-10">
        <ejs-multiselect id='sent_by' formControlName="sent_by" #checkbox [dataSource]='users'
                         placeholder='Sent From' [fields]='userFields' floatLabelType="Auto" cssClass="e-filled"
                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                         filterBarPlaceholder='Search'></ejs-multiselect>
      </div>

      <div class="form-group sf-outline-input mb-10 mr-2 w-100">
        <ejs-daterangepicker formControlName="sent_at" [allowEdit]="allowEdit"
                             placeholder='Sent between' openOnFocus="true"
                             floatLabelType='Auto' cssClass="e-filled">
        </ejs-daterangepicker>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-multiselect id='is_inactive_yn' formControlName="status" #checkbox [dataSource]='status'
                         placeholder='Status' [fields]='statusFields' floatLabelType="Auto" cssClass="e-filled"
                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true' sortOrder="Ascending"
                         filterBarPlaceholder='Search'></ejs-multiselect>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="sent_to_email" placeholder="Sent To" cssClass="e-filled"
                     formControlName="sent_to_email" floatLabelType="Auto"
                     (blur)="filterForm.controls.sent_to_email.markAsTouched()"
        ></ejs-textbox>
      </div>






<!--      <div class="d-flex">-->
<!--        <div class="form-group sf-outline-input mb-10 mr-2 w-100">-->
<!--          <ejs-daterangepicker formControlName="created_at" name="created_at" [allowEdit]="allowEdit"-->
<!--                               placeholder='Created Between' openOnFocus="true"-->
<!--                               floatLabelType='Auto' cssClass="e-filled">-->
<!--          </ejs-daterangepicker>-->
<!--        </div>-->

<!--        <div class="form-group sf-outline-input mb-10 ml-2 w-100">-->
<!--          <ejs-multiselect id='created_by' formControlName="created_by" #checkbox [dataSource]='users'-->
<!--                           placeholder='Created By' [fields]='userFields' floatLabelType="Auto" cssClass="e-filled"-->
<!--                           mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true' sortOrder="Ascending"-->
<!--                           filterBarPlaceholder='Search'></ejs-multiselect>-->
<!--        </div>-->


<!--      </div>-->



      <div class="form-group sf-outline-input mb-10" >
        <ejs-multiselect id='email-company' formControlName="company_code" #checkboxCompany [dataSource]='companyList'
                         placeholder='Company' [fields]='companyFields' cssClass="e-filled" floatLabelType="Auto"
                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                         (filtering)="onFiltering($event, 'company')"
                         filterBarPlaceholder='Search'></ejs-multiselect>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-multiselect id='email-contact' formControlName="contact_code" #checkboxContact [dataSource]='contactList'
                         placeholder='Contact' [fields]='contactFields' cssClass="e-filled" floatLabelType="Auto"
                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                         (filtering)="onFiltering($event, 'contact')" [allowFiltering]=true
                         filterBarPlaceholder='Search'></ejs-multiselect>
      </div>

      <div class="my-10 pb-20">
        <button id="resetbtn"
                class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5"
                type="button" (click)="clearForm()"
                data-ripple="true">Clear All
        </button>
        <button id="filter"
                class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block"
                type="button"
                (click)="getFilteredData()" [disabled]="formSent" data-ripple="true">Filter
        </button>
      </div>

    </form>

  </div>
</ejs-sidebar>
