import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {DynamicHeaderMenuConfig} from '../../../_config/dynamic-header-menu.config';
import {Store} from '@ngrx/store';
import {AppState} from '../../../index';
import {environment} from '../../../../../environments/environment.prod';

const emptyMenuConfig = {
  items: []
};

@Injectable({
  providedIn: 'root'
})
export class DynamicHeaderMenuService {
  private menuConfigSubject = new BehaviorSubject<any>(emptyMenuConfig);
  menuConfig$: Observable<any>;

  constructor(private store: Store<AppState>) {
    this.menuConfig$ = this.menuConfigSubject.asObservable();
    this.loadMenu();
  }

  // Here you able to load your menu from server/data-base/localeStorage
  // Default => from DynamicHeaderMenuConfig
  private loadMenu() {
    this.store.select(store => store.auth.user).subscribe((data) => {
      if (data) {
        const menuArray = [];
        for (const menu of DynamicHeaderMenuConfig.items) {
          if (menu.submenu) {
            const subMenuArray = [];
            for (const submenu of menu.submenu) {
              if (data.security_permissions && data.security_permissions.hasOwnProperty(submenu.permission)) {
                if (data.security_permissions && data.security_permissions[submenu.permission].V === 1) {
                  subMenuArray.push(submenu);
                }
              }
            }
            menu.submenu = subMenuArray;
            if (subMenuArray.length > 0) {
              menuArray.push(menu);
            }
          }
          if (data.security_permissions && data.security_permissions.hasOwnProperty(menu.permission[0]) && data.security_permissions.hasOwnProperty(menu.permission[1])) {
            if (data.security_permissions[menu.permission[0]].V === 1 && data.security_permissions[menu.permission[1]].V === 1) {
              menuArray.push(menu);
            }
          }
        }

        this.setMenu({items: menuArray});
      }
    });
  }

  private setMenu(menuConfig) {
    this.menuConfigSubject.next(menuConfig);
  }

  private getMenu(): any {
    return this.menuConfigSubject.value;
  }

  clearMenu() {
    this.menuConfigSubject.next(emptyMenuConfig);
  }
}
