import { NotificationType } from './notification-type';

export class Notification {

  public message: any = {title: '', subtitle: '', icon: '', closeDuration: 10, progressValue: 0};
  public type: NotificationType;

  constructor(message: any, type: NotificationType | null) {
    this.message = message;
    this.type = type;
  }
}
