<ejs-sidebar id="sidebar-menu" #sidebarInstance type='Over' [width]='width' [position]='position'
             style="visibility: hidden" [enableGestures]="false" [showBackdrop]=true>

  <div class="offcanvas-close mt-n1 pr-5" style="float:right; padding: 48px;">
    <a href="javascript:;" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_panel_close"
       (click)="closeSidebar()">
      <i class="ki ki-close icon-xs text-muted"></i>
    </a>
  </div>

  <div class='p-15'>
    <h2 *ngIf="!companyTypeInput">Add Company Type</h2>
    <h2 *ngIf="companyTypeInput">Update Company Type</h2>

    <form id="formId" [formGroup]="companyTypeForm" #formDir="ngForm" class="form-horizontal" novalidate=""
          style="margin-top: 16px;">

      <div class="form-group sf-outline-input mb-10" [class.hide-label]="companyTypeForm.controls.company_type_parent_code.value == ''">
        <ejs-dropdowntree formControlName="company_type_parent_code" id='company_type_parent_code' allowMultiSelection='false' sortOrder="Ascending"
                          [fields]='companyTypeFields' placeholder='Parent Company Type' floatLabelType="Auto" cssClass="e-filled"
                          (blur)="companyTypeForm.controls.company_type_parent_code.markAsTouched()"></ejs-dropdowntree>
<!--        <ejs-dropdownlist id='company_type_parent_code' formControlName="company_type_parent_code"-->
<!--                          [dataSource]='parentCompanyTypes' floatLabelType="Auto"-->
<!--                          [fields]='' cssClass="e-filled" placeholder='Parent Company Type'-->
<!--                          [allowFiltering]='true'>-->
<!--        </ejs-dropdownlist>-->
        <div>Note: If a parent type isn't selected, the new company type will be added as a parent</div>
      </div>


      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="company_type_name" placeholder="Company Type Name *" cssClass="e-filled"
                     formControlName="company_type_name" floatLabelType="Auto"
                     (blur)="companyTypeForm.controls.company_type_name.markAsTouched()"
        ></ejs-textbox>
        <div *ngIf="companyTypeForm.controls.company_type_name.errors">
          <div
            *ngIf="companyTypeForm.controls.company_type_name.errors.required && companyTypeForm.controls.company_type_name.touched"
            class="e-error">
            Company Type Name is required.
          </div>
        </div>
      </div>


      <div *ngIf="companyTypeForm.invalid" class="e-error">Please fill all fields</div>
      <div *ngIf="error$ | async as error" class="e-error">{{error.error.message}}</div>

      <div class="my-10 pb-20">
        <button id="resetbtn"
                class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5"
                type="button" (click)="clearForm();"
                data-ripple="true">Clear
        </button>
        <button id="addSubmit"
                class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit"
                (click)="onAddUpdateCompanyType(companyTypeForm)" [disabled]="formSent" data-ripple="true">
                <span *ngIf="!companyTypeInput">Add Company Type</span>
                 <span *ngIf="companyTypeInput">Update Company Type</span>
        </button>
<!--        <button *ngIf="companyTypeInput" id="updateSubmit"-->
<!--                class="btn btn-primary text-uppercase font-weight-bolder px-15 py-5 float-right btn-block" type="submit"-->
<!--                (click)="onAddUpdateActivityType(activityTypeForm, true)" [disabled]="formSent" data-ripple="true">-->
<!--          Update Activity Type-->
<!--        </button>-->
      </div>
    </form>
  </div>
</ejs-sidebar>
