import {Action} from '@ngrx/store';
import { SourceType } from '../../models/source-types.model';
import {ProjectTypeActionsType} from './project-type.actions';

export enum SourceTypeActionsType {
  LOAD_SOURCE_TYPE = '[SOURCE TYPE] Load Action',
  LOAD_SOURCE_TYPE_SUCCESS = '[SOURCE TYPE] Load Success Action',
  LOAD_SOURCE_TYPE_FAILURE = '[SOURCE TYPE] Load Failure Action',

  ADD_SOURCE_TYPE = '[SOURCE TYPE] Add Action',
  ADD_SOURCE_TYPE_SUCCESS = '[SOURCE TYPE] Add Success Action',
  ADD_SOURCE_TYPE_FAILURE = '[SOURCE TYPE] Add Failure Action',

  UPDATE_SOURCE_TYPE = '[SOURCE TYPE] Update Action',
  UPDATE_SOURCE_TYPE_SUCCESS = '[SOURCE TYPE] Update Success Action',
  UPDATE_SOURCE_TYPE_FAILURE = '[SOURCE TYPE] Update Failure Action',

  DELETE_SOURCE_TYPE = '[SOURCE TYPE] Delete Action',
  DELETE_SOURCE_TYPE_SUCCESS = '[SOURCE TYPE] Delete Success Action',
  DELETE_SOURCE_TYPE_FAILURE = '[SOURCE TYPE] Delete Failure Action',

  RESET_ERROR = '[SOURCE TYPE] Reset Error Action'
}

export class SourceTypeLoad implements Action {
  readonly type = SourceTypeActionsType.LOAD_SOURCE_TYPE;
  constructor(public payload?: {page_size?: number, page_no?: number}) { }
}

export class SourceTypeLoadSuccess implements Action {
  readonly type = SourceTypeActionsType.LOAD_SOURCE_TYPE_SUCCESS;
  constructor(public payload: { result: Array<SourceType>, total_count: number, page_size: number, page_no: number }) { }
}

export class SourceTypeLoadFailure implements Action {
  readonly type = SourceTypeActionsType.LOAD_SOURCE_TYPE_FAILURE;
  constructor(public payload: Error) { }
}

export class SourceTypeAdd implements Action {
  readonly type = SourceTypeActionsType.ADD_SOURCE_TYPE;
  constructor(public payload: {source_type_name: string, parent_source_type_code: string, description: string}) { }
}

export class SourceTypeAddSuccess implements Action {
  readonly type = SourceTypeActionsType.ADD_SOURCE_TYPE_SUCCESS;
  constructor(public payload: { sourceType: SourceType, parent_source_type_code: string, }) { }
}

export class SourceTypeAddFailure implements Action {
  readonly type = SourceTypeActionsType.ADD_SOURCE_TYPE_FAILURE;
  constructor(public payload: Error) { }
}

export class SourceTypeUpdate implements Action {
  readonly type = SourceTypeActionsType.UPDATE_SOURCE_TYPE;
  constructor(public payload: { project_source_type_code: string, project_source_type_name: string, parent_source_type_code: string, description: string }) { }
}

export class SourceTypeUpdateSuccess implements Action {
  readonly type = SourceTypeActionsType.UPDATE_SOURCE_TYPE_SUCCESS;
  constructor(public payload: { sourceType: SourceType, parent_source_type_code: string }) { }
}

export class SourceTypeUpdateFailure implements Action {
  readonly type = SourceTypeActionsType.UPDATE_SOURCE_TYPE_FAILURE;
  constructor(public payload: Error) { }
}

export class SourceTypeDelete implements Action {
  readonly type = SourceTypeActionsType.DELETE_SOURCE_TYPE;
  constructor(public payload: { sourceTypeCode: string }) { }
}

export class SourceTypeDeleteSuccess implements Action {
  readonly type = SourceTypeActionsType.DELETE_SOURCE_TYPE_SUCCESS;
  constructor(public payload: { sourceTypeCode: string }) { }
}

export class SourceTypeDeleteFailure implements Action {
  readonly type = SourceTypeActionsType.DELETE_SOURCE_TYPE_FAILURE;
  constructor(public payload: Error) { }
}

export class SourceTypeResetError implements Action {
  readonly type = SourceTypeActionsType.RESET_ERROR;
}

export type SourceTypeActions = SourceTypeLoad | SourceTypeLoadSuccess | SourceTypeLoadFailure | SourceTypeAdd |
  SourceTypeAddSuccess | SourceTypeAddFailure | SourceTypeUpdate | SourceTypeUpdateSuccess | SourceTypeUpdateFailure |
  SourceTypeDelete | SourceTypeDeleteSuccess | SourceTypeDeleteFailure | SourceTypeResetError;
