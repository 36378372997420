<div class="login-form login-signin login-bg" >
  <form novalidate="novalidate" [formGroup]="signInForm" id="can-sign-in-form" class="form mb-0" autocomplete="off">
    <div class="pb-lg-7 pt-lg-0 pt-5">
      <img src="../../../../../assets/images/canvas-dark.svg" style="width: 30%" alt=""><br><br>
<!--      <span class="text-muted font-weight-bold font-size-h4 mt-2">Sign In</span>-->
    </div>
    <app-auth-notice></app-auth-notice>
    <div class="form-group">
      <label for="can-email" class="font-size-h6 font-weight-bolder text-dark">Email</label>
      <input id="can-email" type="email" name="email" formControlName="email" autocomplete="off" class="form-control form-control-solid h-auto py-7 px-6 rounded-lg" />
      <div *ngIf="email.errors">
        <div *ngIf="email.errors.required && email.touched" class="e-error mt-1 px-2">{{msg.emailRequired}}</div>
      </div>
    </div>
    <div class="form-group">
      <div class="d-flex justify-content-between mt-n5">
        <label for="can-password" class="font-size-h6 font-weight-bolder text-dark pt-5">Password</label>
        <a class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5" id="can-login-forgot">Forgot Password?</a>
      </div>
      <input id="can-password" type="password" name="password" formControlName="password" autocomplete="off" class="form-control form-control-solid h-auto py-7 px-6 rounded-lg" />
      <div *ngIf="password.errors">
        <div *ngIf="password.errors.required && password.touched" class="e-error mt-1 px-2">{{msg.passwordRequired}}</div>
      </div>
    </div>
    <div  class="pb-lg-0 pb-5 text-right">
      <button type="submit" id="can-sign-in" class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3"
              [disabled]="!signInForm.valid" (click)="signIn()">Sign In</button>
    </div>
  </form>
</div>
