import { Component, OnInit } from '@angular/core';
import {HtmlClassService} from '../html-class.service';
import {environment} from '../../../../environments/environment.prod';
import {Router} from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
  today: number = Date.now();
  footerClasses = '';
  footerContainerClasses = '';
  releaseVersion = environment.RELEASE_VERSION;

  constructor(private uiClasses: HtmlClassService, private router: Router) { }

  ngOnInit(): void {
    this.footerClasses = this.uiClasses.getClasses('footer', true).toString();
    this.footerContainerClasses = this.uiClasses.getClasses('footer_container', true).toString();
  }

  openReleaseNotes() {
    this.router.navigate(['/release-notes']);
  }

}
