<ejs-sidebar id="sidebar-menu" #sidebarInstance type='Over' [width]='width' [position]='position'
             style="visibility: hidden" [enableGestures]="false" [showBackdrop]=true>

  <div class="offcanvas-close mt-n1 pr-5" style="float:right; padding: 48px;">
    <a href="javascript:;" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_panel_close"
       (click)="closeSidebar()">
      <i class="ki ki-close icon-xs text-muted"></i>
    </a>
  </div>

  <div *ngIf="type === 'images'" class="p-15">

    <h2>Contact - Images</h2>

<!--    <form id="formId" [formGroup]="companyLTDForm" #formDir="ngForm" class="form-horizontal" novalidate=""-->
<!--          style="margin-top: 16px;">-->

      <div *ngIf="showUploader$|async as showUploader">
        <span class="font-weight-bold">Featured Image</span>
        <app-uploader *ngIf="showUploader" (valueChange)="updateReferenceID($event)" id="contact-featured-uploader" [setCleared]="setCleared$|async" [preLoadedFiles]="preLoadedFiles"></app-uploader>
      </div>

      <div class="my-10 pb-20">
        <button id="resetbtn"
                class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5"
                type="button" (click)="setClearImages()"
                data-ripple="true">Clear
        </button>

        <button *ngIf="!showUploadingBtn"
          class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit"
          (click)="onUploadImage()" [disabled]="formSent" data-ripple="true">Update Contact Images
        </button>
        <button *ngIf="showUploadingBtn"
        class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit"
         [disabled]=true data-ripple="true">Uploading Images...
</button>
      </div>
      </div>
<!--    </form>-->

<div *ngIf="type === 'ltd-settings'" class="p-15">

  <h2>LoveThatDesign Settings</h2>

  <form id="contactForm" [formGroup]="contactLTDSettingsForm" #formDir="ngForm" class="form-horizontal" novalidate="" style="margin-top: 16px;">


  <div class="form-group sf-outline-input mb-10" >
    <div>
      <ejs-dropdownlist id='visibilityDropdown' #visibility formControlName="visibility" floatLabelType="Auto" cssClass="e-filled" [value]="selectedItem"
                        [dataSource]='visibilityList' [fields]='visibilityFields' placeholder='Visibility *' (created)="setDefaultValue()"
                        (change)="changePasswordValidation(contactLTDSettingsForm, $event)">
      </ejs-dropdownlist>
      <div *ngIf="contactLTDSettingsForm.controls.visibility.errors">
        <div *ngIf="contactLTDSettingsForm.controls.visibility.errors.required && contactLTDSettingsForm.controls.visibility.touched" class="e-error">
          Visibility is required.
        </div>
      </div>
    </div>
    
    <div *ngIf="displayPasswordInput" class="mt-4">
      <ejs-textbox id="lastName" placeholder="Password *" cssClass="e-filled"
                  formControlName="password_protected_password" floatLabelType="Auto"
                  (blur)="contactLTDSettingsForm.controls.password_protected_password.markAsTouched()">
      </ejs-textbox>
      <div *ngIf="contactLTDSettingsForm.controls.password_protected_password.errors">
        <div *ngIf="contactLTDSettingsForm.controls.password_protected_password.errors.required && contactLTDSettingsForm.controls.password_protected_password.touched" class="e-error">
          Password is required.
        </div>
        <div *ngIf="contactLTDSettingsForm.controls.password_protected_password.errors.maxlength && contactLTDSettingsForm.controls.password_protected_password.touched"
           class="e-error">
        Password cannot be more than 255 characters
      </div>
      </div>
    </div>

  </div>

  <div *ngIf="contactLTDSettingsForm.invalid" class="e-error">Please fill all fields</div>

   
    <div class="my-10 pb-20">
      <button id="resetbtn"
              class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5"
              type="button" (click)="clearForm()"
              data-ripple="true">Clear
      </button>

      <button
        class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit"
        (click)="onUpdate()" [disabled]="formSentSettings" data-ripple="true">Update Contact LTD Settings
      </button>
    </div>
    </form>

  </div>

</ejs-sidebar>
