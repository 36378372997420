import { Effect, ofType, Actions } from '@ngrx/effects';
import {AuthActionTypes, Login, UserLoaded, Logout, ChangePassword, ChangePasswordSuccess, ChangePasswordFailure} from './auth.actions';
import { switchMap, map, tap, catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { User } from '../../models/user.model';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { AuthNoticeService } from './auth-notice.service';
import {AddCompanyActivity, AddCompanyActivityFailure, AddCompanyActivitySuccess, CompanyActionTypes} from '../actions/company.actions';
import {AppService} from '../../services/app.service';

// responseData: ResponseData;

@Injectable()
export class AuthEffects {
  // user: User;

  @Effect()
  login = this.actions$.pipe(
    ofType(AuthActionTypes.LOGIN),
    switchMap((authData: Login) => {
      return this.authService.login(authData.payload.username, authData.payload.password).pipe(
        map((response: any) => {
          const user: User = Object.assign(new User(), response.data);
          localStorage.setItem('token', user.token);
          this.authService.loadUser();
          this.appService.setPermissions(user.security_permissions);

          return new UserLoaded({ user });

        }), catchError((error) => {
          if (error.status === 0) {
          this.authNoticeService.setNotice('Something went wrong. Please try again');
          } else {
            this.authNoticeService.setNotice(error.error.error);
          }
          return of();
        })
      );
    })
  );

  @Effect()
  loginByToken = this.actions$.pipe(
    ofType(AuthActionTypes.LOGIN_BY_TOKEN),
    switchMap(() => {
      return this.authService.loginByToken().pipe(
        map((response: any) => {
          const user: User = Object.assign(new User(), response.data);
          localStorage.setItem('token', user.token);
          this.appService.setPermissions(user.security_permissions);
          // this.authService.loadUser();
          return new UserLoaded({ user });

        }), catchError((error) => {
          return of();
        })
      );
    })
  );

  // TODO: CHECK BELOW FUNCTION
  @Effect({dispatch: false})
  logout$ = this.actions$.pipe(
    ofType<Logout>(AuthActionTypes.LOGOUT),
    tap(() => {
      localStorage.removeItem('token');
      this.authService.logout();
    })
  );

  @Effect()
  changePassword = this.actions$.pipe(ofType(AuthActionTypes.CHANGE_PASSWORD),
    switchMap((data: ChangePassword) =>
      this.authService.changePassword(data.payload.user_name, data.payload.current_password, data.payload.new_password)
        .pipe(map((response) => {
            return new ChangePasswordSuccess(response.data);
          }),
          catchError(error =>
            of(new ChangePasswordFailure(error.error)))
        )
    )
  );

  constructor(private actions$: Actions, private authService: AuthService, private authNoticeService: AuthNoticeService,
              private appService: AppService ) { }
}
