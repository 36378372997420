<ejs-sidebar id="sidebar-menu" #sidebarInstance type='Over' [width]='width' [position]='position'
             style="visibility: hidden" [enableGestures]="false" [showBackdrop]=true>

  <div class="offcanvas-close mt-n1 pr-5" style="float:right; padding: 48px;">
    <a href="javascript:;" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_panel_close"
       (click)="closeSidebar()">
      <i class="ki ki-close icon-xs text-muted"></i>
    </a>
  </div>
  <div class="p-15">
    <h2>Project Filter</h2>
    <form id="formId" [formGroup]="filterForm" #formDir="ngForm" class="form-horizontal" novalidate="">

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="project_name" placeholder="Project Name" cssClass="e-filled"
                     formControlName="project_name" floatLabelType="Auto"
                     (blur)="filterForm.controls.project_name.markAsTouched()"
        ></ejs-textbox>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="reference_id" placeholder="Reference ID" cssClass="e-filled"
                     formControlName="reference_id" floatLabelType="Auto"
                     (blur)="filterForm.controls.reference_id.markAsTouched()"
        ></ejs-textbox>
      </div>

      <div class="form-group sf-outline-input mb-10"
           [class.hide-label]="filterForm.controls.project_type_code.value == ''">
        <ejs-dropdowntree formControlName="project_type_code" id='project_type_code' allowMultiSelection='true'
                          [fields]='projectTypeFields' placeholder='Project Type' cssClass="e-filled" [treeSettings]='treeSettings'
                          sortOrder="Ascending" (blur)="filterForm.controls.project_type_code.markAsTouched()"
                          floatLabelType="Auto" [showCheckBox]='true'></ejs-dropdowntree>
      </div>

      <div class="form-group sf-outline-input mb-10" [class.hide-label]="filterForm.controls.status_code.value == ''">
        <ejs-dropdowntree formControlName="status_code" id='status_code' [showCheckBox]='true'
                          [treeSettings]='treeSettings'
                          floatLabelType="Auto" (blur)="filterForm.controls.status_code.markAsTouched()"
                          cssClass="e-filled" [fields]='projectStatusFields' placeholder='Status'></ejs-dropdowntree>
      </div>


      <div class="form-group sf-outline-input mb-10">
        <ejs-multiselect id='assigned_to_user' formControlName="assigned_to_user" #checkbox [dataSource]='users'
                         placeholder='Assigned To' [fields]='userFields' floatLabelType="Auto" cssClass="e-filled"
                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                         filterBarPlaceholder='Search'></ejs-multiselect>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-multiselect id='country_code' formControlName="country_code" #checkbox [dataSource]='countries'
                         placeholder='Country' [fields]='countriesField' cssClass="e-filled" floatLabelType="Auto"
                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true' sortOrder="Ascending"
                         filterBarPlaceholder='Search'></ejs-multiselect>
      </div>

      <div class="form-group sf-outline-input mb-10 mr-2 w-100">
        <ejs-daterangepicker formControlName="featured_date" name="featured_date"
                             placeholder='Featured Between' openOnFocus="true" [allowEdit]="allowEdit"
                             floatLabelType='Auto' cssClass="e-filled">
        </ejs-daterangepicker>
      </div>

      <div class="d-flex">
        <div class="form-group sf-outline-input mb-10 mr-2 w-100">
          <ejs-daterangepicker formControlName="proposed_post_date" name="proposed_post_date"
                               placeholder='Published Between' openOnFocus="true" [allowEdit]="allowEdit"
                               floatLabelType='Auto' cssClass="e-filled">
          </ejs-daterangepicker>
        </div>

        <div class="form-group sf-outline-input mb-10 ml-2 w-100">
          <ejs-multiselect id='published_by' formControlName="published_by" #checkbox [dataSource]='users'
                           placeholder='Published By' [fields]='userFields' floatLabelType="Auto" cssClass="e-filled"
                           mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true' sortOrder="Ascending"
                           filterBarPlaceholder='Search'></ejs-multiselect>
        </div>

      </div>

      <div class="d-flex">
        <div class="form-group sf-outline-input mb-10 mr-2 w-100">
          <ejs-daterangepicker formControlName="updated_at" name="updated_at" [allowEdit]="allowEdit"
                               placeholder='Updated Between' openOnFocus="true"
                               floatLabelType='Auto' cssClass="e-filled">
          </ejs-daterangepicker>
        </div>

        <div class="form-group sf-outline-input mb-10 ml-2 w-100">
          <ejs-multiselect id='updated_by' formControlName="updated_by" #checkbox [dataSource]='users'
                           placeholder='Updated By' [fields]='userFields' floatLabelType="Auto" cssClass="e-filled"
                           mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true' sortOrder="Ascending"
                           filterBarPlaceholder='Search'></ejs-multiselect>
        </div>
      </div>

      <div class="d-flex">
        <div class="form-group sf-outline-input mb-10 mr-2 w-100">
          <ejs-daterangepicker formControlName="created_at" name="created_at" [allowEdit]="allowEdit"
                               placeholder='Created Between' openOnFocus="true"
                               floatLabelType='Auto' cssClass="e-filled">
          </ejs-daterangepicker>
        </div>

        <div class="form-group sf-outline-input mb-10 ml-2 w-100">
          <ejs-multiselect id='created_by' formControlName="created_by" #checkbox [dataSource]='users'
                           placeholder='Created By' [fields]='userFields' floatLabelType="Auto" cssClass="e-filled"
                           mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true' sortOrder="Ascending"
                           filterBarPlaceholder='Search'></ejs-multiselect>
        </div>


      </div>
      <div class="form-group sf-outline-input mb-10">
        <ejs-multiselect id='tags' #tags [dataSource]="tagModelData" floatLabelType="Auto" cssClass="e-filled"
                         [fields]="tagFields" formControlName='project_tag'
                         [allowCustomValue]=true mode='Box' placeholder='Tags'
                         (actionComplete)="actionComplete($event)"
                         (ngModelChange)="syncModel($event)"
                         (change)="onChange($event)"
                         (blur)="onBlur($event, filterForm)"
                         name="tags"
                         (removed)="selectedTag = []"
                         [(ngModel)]="modelData"
                         (select)="select($event)"
                         ngModel></ejs-multiselect>
      </div>


      <div class="form-group sf-outline-input mb-10" *ngIf="!isCompanyContactLink()">
        <ejs-multiselect id='company' formControlName="company_code" #checkboxCompany [dataSource]='companyList'
                         placeholder='Company' [fields]='companyFields' cssClass="e-filled" floatLabelType="Auto"
                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                         (filtering)="onFiltering($event, 'company')"
                         filterBarPlaceholder='Search'></ejs-multiselect>
      </div>

      <div class="form-group sf-outline-input mb-10" *ngIf="!isCompanyContactLink()">
        <ejs-multiselect id='contact' formControlName="contact_code" #checkboxContact [dataSource]='contactList'
                         placeholder='Contact' [fields]='contactFields' cssClass="e-filled" floatLabelType="Auto"
                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                         (filtering)="onFiltering($event, 'contact')"
                         filterBarPlaceholder='Search'></ejs-multiselect>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-autocomplete id='source_name' cssClass="e-filled" floatLabelType="Auto" [dataSource]="sourcesList" (select)="onSelectSources($event)" [fields]='fields'
                          placeholder='Source Name' (filtering)="onFiltering($event, 'sources')"></ejs-autocomplete>
<!--        <ejs-textbox placeholder="Source Name" cssClass="e-filled"-->
<!--                     formControlName="source_name" floatLabelType="Auto"-->
<!--                     (blur)="filterForm.controls.source_name.markAsTouched()"-->
<!--        ></ejs-textbox>-->
      </div>

      <div class="form-group sf-outline-input mb-10">
<!--        <ejs-dropdowntree id='project_source_type_code_filter' formControlName="project_source_type_code"-->
<!--                          [treeSettings]='treeSettings' [allowMultiSelection]=true-->
<!--                         placeholder='Source Type' [fields]='sourceTypeFields' floatLabelType="Auto" cssClass="e-filled"-->
<!--                         showCheckBox="true" [showDropDownIcon]='true' -->
<!--                         filterBarPlaceholder='Search'></ejs-dropdowntree>-->

        <ejs-dropdowntree formControlName="project_source_type_code" id='project_source_type_code_filter' [showCheckBox]='true'
                          [treeSettings]='treeSettings'
                          floatLabelType="Auto" (blur)="filterForm.controls.project_source_type_code.markAsTouched()"
                          cssClass="e-filled" [fields]='sourceTypeFields' placeholder='Source Type'></ejs-dropdowntree>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-checkbox formControlName="is_deleted_yn" #wcheckbox label="Include Deleted Projects: " labelPosition="Before"></ejs-checkbox>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-checkbox formControlName="is_source_exist" #wwcheckbox label="Only display projects with no source: " labelPosition="Before"></ejs-checkbox>
      </div>

      <div class="my-10 pb-20">
        <button id="resetbtn"
                class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5"
                type="button" (click)="clearForm()"
                data-ripple="true">Clear All
        </button>
        <button id="filter"
                class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block"
                type="button"
                (click)="getFilteredData()" [disabled]="formSent" data-ripple="true">Filter
        </button>
      </div>

    </form>

  </div>
</ejs-sidebar>
