import {Component, NgZone, OnInit} from '@angular/core';
import {NotifierService} from './notfier.service';
import {Notifier} from './notifier';
import {notifyAnimation} from '../../../common/app-animations';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  animations: [notifyAnimation]
})
export class NotificationComponent implements OnInit {

  get src(): Notifier {
    return this._notifierService.notifier;
  }

  constructor( private _notifierService: NotifierService,
               private _notifier: NotifierService,
               private zone: NgZone) { }

  ngOnInit(): void {
  }

  hardReload() {
    this._notifier.destroyNotify();
    setTimeout(() => {
      this.zone.run(() => {
        window.location.reload();
      });
      window.location.reload();
    }, 100);

  }

  destroyNotification() {
    this._notifier.destroyNotify();
    setTimeout(() => {
      window.location.reload();
    }, 100);
  }

}
