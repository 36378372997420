<ejs-sidebar id="sidebar-menu" #sidebarInstance type='Over' [width]='width' [position]='position'
             style="visibility: hidden" [enableGestures]="false" [showBackdrop]=true>

  <div class="offcanvas-close mt-n1 pr-5" style="float:right; padding: 48px;">
    <a href="javascript:;" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_panel_close"
       (click)="closeSidebar()">
      <i class="ki ki-close icon-xs text-muted"></i>
    </a>
  </div>

  <div class="p-15">

    <h2>Activity Filter</h2>

    <form id="formId" [formGroup]="filterForm" #formDir="ngForm" class="form-horizontal" novalidate="">
      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="activity_title" placeholder="Activity Title" cssClass="e-filled"
                     formControlName="activity_title" floatLabelType="Auto"
                     (blur)="filterForm.controls.activity_title.markAsTouched()"
        ></ejs-textbox>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-multiselect id='activity_type_code' formControlName="activity_type_code" #checkbox
                         [dataSource]='typeActivity' cssClass="e-filled" floatLabelType="Auto"
                         placeholder='Activity Type' [fields]='activityTypeFields'
                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                         filterBarPlaceholder='Search'></ejs-multiselect>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-daterangepicker formControlName="meeting_date" name="meeting_date" [allowEdit]="allowEdit"
                             placeholder='Select a Meeting Date Range' openOnFocus="true"
                             floatLabelType='Auto' cssClass="e-filled">
        </ejs-daterangepicker>
      </div>

      <div class="form-group sf-outline-input" >
          <ejs-multiselect id='internal_participants'  formControlName='user_code' cssClass="e-filled"
                            floatLabelType="Auto" [dataSource]='users'
                            [fields]='userFields' [allowFiltering]=true mode='CheckBox'
                            placeholder='Internal Participants' [showDropDownIcon]='true' showSelectAll='true'
                            filterBarPlaceholder='Search'>
          </ejs-multiselect>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-multiselect id='participants' formControlName="activity_participant_code" #checkbox [dataSource]='participantList'
                         placeholder='External Participants' [fields]='participantsFields' floatLabelType="Auto"
                         cssClass="e-filled" (filtering)="onFiltering($event, 'contact')"
                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                         filterBarPlaceholder='Search'></ejs-multiselect>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-multiselect id='project_activity_tag' #tags [dataSource]="tagModelData" floatLabelType="Auto" cssClass="e-filled"
                         [fields]="tagFields" formControlName='activity_tag'
                         [allowCustomValue]=true mode='Box' placeholder='Tags'
                         (actionComplete)="actionComplete($event)"
                         (ngModelChange)="syncModel($event)"
                         (change)="onChange($event)"
                         (blur)="onBlur($event, filterForm)"
                         (select)="select($event)"
                         name="tags"
                         (removed)="selectedTag = []"
                         ([ngModel])="modelData"
                         ngModel></ejs-multiselect>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-daterangepicker formControlName="updated_at" name="updated_at" [allowEdit]="allowEdit"
                             placeholder='Select a Updated At Range' openOnFocus="true"
                             floatLabelType='Auto' cssClass="e-filled">
        </ejs-daterangepicker>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-daterangepicker formControlName="created_at" name="created_at" [allowEdit]="allowEdit"
                             placeholder='Select a Created At Date Range' openOnFocus="true"
                             floatLabelType='Auto' cssClass="e-filled">
        </ejs-daterangepicker>
      </div>

      <div class="my-10 pb-20">
        <button id="resetbtn"
                class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5"
                type="button" (click)="clearForm()"
                data-ripple="true">Clear All
        </button>
        <button id="filter"
                class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block"
                type="submit"
                (click)="getFilteredData()" [disabled]="formSent" data-ripple="true">Filter
        </button>
      </div>
    </form>
  </div>

</ejs-sidebar>
