import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {SidebarComponent} from '@syncfusion/ej2-angular-navigations';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';
import {DeliverableItem} from '../../../../../core/models/deliverable-item.model';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../core';
import {trimValidator} from '../../../../../app-validators';
import {
  AddDeliverableItems,
  AddEmailTemplate,
  MetaDataResetError,
  UpdateDeliverableItems, UpdateEmailTemplate
} from '../../../../../core/store/actions/common.actions';
import {EmailTemplate} from '../../../../../core/models/email-template.model';
import {EmailKeywordsComponent} from '../../email-keywords/email-keywords.component';

@Component({
  selector: 'app-email-template-form',
  templateUrl: './email-template.component.html',
  styleUrls: ['./email-template.component.scss']
})
export class EmailTemplateComponent implements OnInit, OnChanges {

  @ViewChild('sidebarInstance') sidebarInstance: SidebarComponent;
  @ViewChild('emailKeyword') emailKeywordComponent: EmailKeywordsComponent;

  @Input() parentEmailTemplates: any;
  @Input() categories: any;
  @Input() emailCategories: any;
  @Output() valueChange = new EventEmitter();

  emailTemplateForm: FormGroup;
  public emailTemplateFields: object;
  public categoryFields: object;
  public emailCategoryFields: object;


  public width = 'auto';
  public position = 'Right';

  formSent: boolean;
  errorMsg: any;
  error$: Observable<any>;
  success$: Observable<boolean>;
  showEmailCategoryBox = false;

  public _emailTemplateInput$: BehaviorSubject<EmailTemplate | null> = new BehaviorSubject<EmailTemplate | null>(null);

  @Input() set emailTemplateInput(mode: EmailTemplate | null) {
    this._emailTemplateInput$.next(mode);
  }

  get emailTemplateInput(): EmailTemplate | null {
    return this._emailTemplateInput$.getValue();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.sidebarInstance.isOpen) {
      this.closeSidebar();
    }
  }

  constructor(private formBuilder: FormBuilder, private store: Store<AppState>) {
  }

  ngOnInit(): void {
    if (!this.emailTemplateForm) {
      this.generateEmailTemplateForm();
    }

    this.error$ = this.store.pipe(select(store => store.metadata.error));
    this.error$.subscribe((data: any) => {

      if (data) {
        this.errorMsg = data.error.message;
        this.formSent = false;
      }
    });

    this.success$ = this.store.pipe(select(store => store.metadata.success));
    this.success$.subscribe((data: any) => {

      if (data) {
        this.formSent = false;
        this.closeSidebar();
      }
    });
  }

  generateEmailTemplateForm() {
    this.emailTemplateForm = this.formBuilder.group({
      email_template_name: new FormControl('', [Validators.required, Validators.compose([trimValidator])]),
      email_template_subject: new FormControl('', [Validators.required]),
      email_template_content: new FormControl('', [Validators.required]),
      // category: new FormControl('', Validators.required),
      email_category: new FormControl('', Validators.required),
      // email_category_name: new FormControl(''),
      email_template_parent_code: new FormControl(''),
      is_html_yn: new FormControl(false)
    });
  }

  onAddUpdateEmailTemplate(form: FormGroup, isUpdate = false) {
    if (!form.invalid) {
      this.formSent = true;
      if (!isUpdate) {
        this.store.dispatch(new AddEmailTemplate({email_template_name: form.value.email_template_name,
          email_template_subject: form.value.email_template_subject, email_template_content: form.value.email_template_content,
          parent_email_template_code: form.value.email_template_parent_code ? form.value.email_template_parent_code[0] : null,
          email_category_code: form.value.email_category, is_html_yn: form.value.is_html_yn === null || form.value.is_html_yn === false ? 0 : 1}));
      } else {
        this.store.dispatch(new UpdateEmailTemplate({email_template_name: form.value.email_template_name,
          email_template_code: this._emailTemplateInput$.getValue().email_template_code,
          email_template_subject: form.value.email_template_subject, email_template_content: form.value.email_template_content,
          parent_email_template_code: form.value.email_template_parent_code ? form.value.email_template_parent_code[0] : null,
          email_category_code: form.value.email_category, is_html_yn: form.value.is_html_yn}));
      }
    }
  }

  openSidebar(): void {
    this.sidebarInstance.show();
  }

  closeSidebar(): void {
    this.valueChange.emit('success');
    this.emailTemplateForm.reset();
    this.sidebarInstance.hide();
    this.emailTemplateForm.controls.email_template_parent_code.setValue('');

    this.store.dispatch(new MetaDataResetError());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.categories) {
      this.categoryFields = {
        dataSource: this.categories,
        value: 'application_category_code',
        text: 'application_category_name'
      };
    }

    if (changes.emailCategories) {
      this.emailCategoryFields = {
        dataSource: this.emailCategories,
        value: 'email_category_code',
        text: 'email_category_name'
      };
    }

    if (changes.parentEmailTemplates && this.parentEmailTemplates && this.parentEmailTemplates.result) {
      this.emailTemplateFields = {
        dataSource: this.parentEmailTemplates.result,
        value: 'email_template_code',
        text: 'email_template_name',
        child: 'children'
      };
    }

    if (!this.emailTemplateForm) {
      this.generateEmailTemplateForm();
    }

    if (this._emailTemplateInput$.getValue()) {
      this.emailTemplateForm.setValue({
        email_template_name: this._emailTemplateInput$.getValue().email_template_name,
        email_template_subject: this._emailTemplateInput$.getValue().email_template_subject,
        email_template_content: this._emailTemplateInput$.getValue().email_template_content,
        // category: this._emailTemplateInput$.getValue().category ?  this._emailTemplateInput$.getValue().category.category_code : null,
        email_template_parent_code: this._emailTemplateInput$.getValue().parent ? [this._emailTemplateInput$.getValue().parent] : '',
        is_html_yn: this._emailTemplateInput$.getValue().is_html_yn === 1,
        email_category: this._emailTemplateInput$.getValue().category.email_category_code

      });
    }
  }

  openKeywordSidebar() {
    this.emailKeywordComponent.openSidebar();
  }

}
