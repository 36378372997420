import { createSelector, createFeatureSelector } from '@ngrx/store';
import { Company } from '../../models/company.model';
import { CompanyState, getErrors, getCompanies, getCompanyLoading } from '../reducers/company.reducer';
import { reducers } from '../..';


export const getCompanyState = createFeatureSelector<CompanyState>('company');

export const getCompaniesState = createSelector(
  getCompanyState,
  (state: CompanyState) => state.companies
);

export const getAllCompanies = createSelector(
  getCompanyState,
  (state: CompanyState) => getCompanies
);

export const getError = createSelector(
  getCompanyState,
  (state: CompanyState) => getErrors
);

export const getLoading = createSelector(
  getCompanyState,
  (state: CompanyState) => getCompanyLoading
);
