import {
  Component,
  ViewChild,
  ViewEncapsulation,
  OnInit,
  Inject,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import {
  UploaderComponent as SyncFusionUploaderComponent,
  SelectedEventArgs,
  FileInfo,
  RemovingEventArgs
} from '@syncfusion/ej2-angular-inputs';
import {createSpinner, showSpinner, hideSpinner} from '@syncfusion/ej2-popups';
import {EmitType, detach, Browser, createElement, isNullOrUndefined, EventHandler} from '@syncfusion/ej2-base';
import {environment} from '../../../../../environments/environment.prod';
import {ImageService} from '../../../../core/services/image.service';
import {v4 as uuid} from 'uuid';


@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent implements OnInit, OnChanges {
  @ViewChild('previewupload') public uploadObj: SyncFusionUploaderComponent;
  @Input() setCleared: boolean;
  @Input() preLoadedFiles: any[];
  @Output() valueChange = new EventEmitter();

  public path: object = {
    saveUrl: environment.API_URL + 'images/store',
    // removeUrl: environment.API_URL + 'images/store',
  };

  public allowExtensions = '.png, .jpg, .jpeg';
  incorrectFileType = false;
  incorrectFileSize = false;

  public dropElement: HTMLElement;
  public filesName: string[] = [];
  public filesDetails: FileInfo[] = [];
  public filesList: HTMLElement[] = [];
  public uploadWrapper: HTMLElement;
  public parentElement: HTMLElement;

  constructor(private imageService: ImageService) {

  }

  ngOnInit(): void {
    this.dropElement = document.getElementsByClassName('control-section')[0] as HTMLElement;
    if (Browser.isDevice) {
      document.getElementById('dropimage').style.padding = '0px 10%';
    }

    document.getElementById('browse').onclick = () => {
      document.getElementsByClassName('e-file-select-wrap')[0].querySelector('button').click();
      return false;
    };

  }

  public onSelect(args: SelectedEventArgs): void {
    if (args.filesData && args.filesData.length > 0) {
      for (const validFile of args.filesData) {
        console.log(validFile.status);
        if (validFile.status === 'File type is not allowed') {
          this.incorrectFileType = true;
        this.incorrectFileSize = false;
          this.valueChange.emit({unsupportedFileType: true});
          break;
        }

        if (validFile.status === 'File size is too large') {
          this.incorrectFileSize = true;
          this.valueChange.emit({unsupportedFileType: true});
          break;
        }
        this.incorrectFileType = false;
        this.incorrectFileSize = false;
        this.valueChange.emit({unsupportedFileType: false});
      }
    } else {
      this.incorrectFileType = false;
      this.incorrectFileSize = false;
      this.valueChange.emit({unsupportedFileType: false});
    }

    args.currentRequest = [{authorization: 'Bearer ' + localStorage.getItem('token')}];
    if (!this.dropElement.querySelector('li')) {
      this.filesDetails = [];
    }
    if (isNullOrUndefined(document.getElementById('dropArea').querySelector('.e-upload-files'))) {
      this.parentElement = createElement('ul', {className: 'e-upload-files'});
      document.getElementsByClassName('e-upload')[0].appendChild(this.parentElement);
    }
    let validFiles: FileInfo[] = this.validateFiles(args, this.filesDetails);
    if (validFiles.length === 0) {
      args.cancel = true;
      return;
    }
    for (let i: number = 0; i < validFiles.length; i++) {

      if (validFiles[i].status !== "File size is too large" && validFiles[i].status !== "File type is not allowed") {
        
              this.formSelectedData(validFiles[i], this);
        
              const formData = new FormData();
        
              let referenceID = uuid();
              referenceID = referenceID.toString().toLowerCase().replaceAll('-', '');
        
              // Store form name as "file" with file data
              formData.append('file', validFiles[i].rawFile);
              formData.append('reference_id', referenceID);
              formData.append('batch_id', uuid());
              formData.append('type', 'image');
              this.valueChange.emit({reference_id: referenceID + '.' + validFiles[i].type, isUploading: true});
        
        
              this.imageService.uploadImage(formData).subscribe((data) => {
                if (data && data.data) {
                  this.valueChange.emit({reference_id: referenceID + '.' + validFiles[i].type, isUploading: false});
                  console.log('Uploaded Successfully!');
                }
              });
            }
      }

    this.filesDetails = this.filesDetails.concat(validFiles);
    args.cancel = true;

  }

  public validateFiles(args: any, viewedFiles: FileInfo[]): FileInfo[] {
    let modifiedFiles: FileInfo[] = [];
    let validFiles: FileInfo[] = [];
    let isModified: boolean = false;
    if (args.event.type === 'drop') {
      isModified = true;
      let allImages: string[] = ['png', 'jpg', 'jpeg'];
      let files: FileInfo[] = args.filesData;
      for (let file of files) {
        if (allImages.indexOf(file.type) !== -1) {
          modifiedFiles.push(file);
        }
      }
    }
    let files: FileInfo[] = modifiedFiles.length > 0 || isModified ? modifiedFiles : args.filesData;
    if (this.filesName.length > 0) {
      for (let file of files) {
        if (this.filesName.indexOf(file.name) === -1) {
          this.filesName.push(file.name);
          validFiles.push(file);
        }
      }
    } else {
      for (let file of files) {
        this.filesName.push(file.name);
        validFiles.push(file);
      }
    }
    return validFiles;
  }

  public formSelectedData(file: FileInfo, proxy: any, url?: string): void {
    let liEle: HTMLElement = createElement('li', {className: 'e-upload-file-list', attrs: {'data-file-name': file.name}});
    let imageTag: HTMLImageElement = <HTMLImageElement> createElement('IMG', {className: 'upload-image', attrs: {'alt': 'Image'}});
    let wrapper: HTMLElement = createElement('span', {className: 'wrapper pt-5'});
    wrapper.appendChild(imageTag);
    liEle.appendChild(wrapper);
    liEle.appendChild(createElement('div', {className: 'name file-name', innerHTML: file.name, attrs: {'title': file.name}}));
    liEle.appendChild(createElement('div', {className: 'file-size', innerHTML: proxy.uploadObj.bytesToSize(file.size)}));
    let clearbtn: HTMLElement;
    let uploadbtn: HTMLElement;
    if (url) {
      clearbtn = createElement('span', {id: 'removeIcon', className: 'e-icons e-file-delete-btn', attrs: {'title': 'Delete file'}});
      uploadbtn = createElement('span', {className: 'e-upload-icon e-icons e-file-remove-btn e-uploaded', attrs: {'title': 'Abort'}});
      uploadbtn.style.cursor = 'not-allowed';
      (liEle.querySelector('.file-name') as HTMLElement).style.color = 'green';
      clearbtn.onclick = () => {
        this.generateSpinner(this.dropElement.querySelector('#dropArea'));
      };
      liEle.setAttribute('title', '');

      this.imageService.getImage(url).subscribe((data) => {
        if (data) {
          var urlCreator = window.URL || window.webkitURL;
          var imageUrl = urlCreator.createObjectURL(data);
          imageTag.src = imageUrl;
          file.size = data.size;
          // imageTag.setAttribute('src', this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data)));
        }
      });
      // imageTag.setAttribute('src', url);
    } else {
      // clearbtn = createElement('span', {id: 'removeIcon', className: 'e-icons e-file-remove-btn', attrs: {'title': 'Remove'}});
      // EventHandler.add(clearbtn, 'click', this.removeFiles, proxy);
      // liEle.setAttribute('title', 'Ready to Upload');
      // uploadbtn = createElement('span', {className: 'e-upload-icon e-icons e-file-remove-btn', attrs: {'title': 'Upload'}});
      // uploadbtn.setAttribute('id', 'iconUpload');
      // // EventHandler.add(uploadbtn, 'click', this.uploadFile, proxy);
      // let progressbarContainer: HTMLElement;
      // progressbarContainer = createElement('progress', {className: 'progressbar', id: 'progressBar', attrs: {value: '0', max: '100'}});
      // liEle.appendChild(clearbtn);
      // liEle.appendChild(uploadbtn);
      // liEle.appendChild(progressbarContainer);
      // this.readURL(liEle, file);


      clearbtn = createElement('span', {id: 'removeIcon', className: 'e-icons e-file-remove-btn', attrs: {'title': 'Remove'}});
      uploadbtn = createElement('span', {className: 'e-upload-icon e-icons e-file-remove-btn', attrs: {'title': 'Upload'}});

      // EventHandler.add(uploadbtn, 'click', this.uploadFile, proxy);
      liEle.setAttribute('title', 'Ready to Upload');
      // this.readURL(liEle, file);

    }

    uploadbtn.setAttribute('id', 'iconUpload');
    EventHandler.add(clearbtn, 'click', this.removeFiles, proxy);
    let progressbarContainer: HTMLElement;
    progressbarContainer = createElement('progress', {className: 'progressbar', id: 'progressBar', attrs: {value: '0', max: '100'}});
    liEle.appendChild(clearbtn);
    liEle.appendChild(uploadbtn);
    liEle.appendChild(progressbarContainer);
    this.readURL(liEle, file);
    // document.querySelector('.e-upload-files').appendChild(liEle);
    // proxy.filesList.push(liEle);

    document.querySelector('.e-upload-files').appendChild(liEle);
    proxy.filesList.push(liEle);
  }

  public uploadFile(args: any): void {
    const formData = new FormData();

    let referenceID = uuid();
    referenceID = referenceID.toString().toLowerCase().replaceAll('-', '');

    console.log([this.filesDetails[this.filesList.indexOf(args.currentTarget.parentElement)]][0]);

    // Store form name as "file" with file data
    formData.append('file', [this.filesDetails[this.filesList.indexOf(args.currentTarget.parentElement)]][0].rawFile);
    formData.append('reference_id', referenceID);
    formData.append('batch_id', uuid());
    formData.append('type', 'image');
    this.valueChange.emit({reference_id: referenceID + '.' + [this.filesDetails[this.filesList.indexOf(args.currentTarget.parentElement)]][0].type});


    this.imageService.uploadImage(formData).subscribe((data) => {
      if (data && data.data) {
        console.log('Uploaded Successfully!');
      }
    });
    // this.uploadObj.upload([this.filesDetails[this.filesList.indexOf(args.currentTarget.parentElement)]], true);
  }

  public removeFiles(args: any): void {
    let removeFile: FileInfo = this.filesDetails[this.filesList.indexOf(args.currentTarget.parentElement)];
    let statusCode: string = removeFile.statusCode;
    if (statusCode === '2' || statusCode === '1') {
      this.uploadObj.remove(removeFile, false, true, false);
      // this.uploadObj.remove()
      this.uploadObj.element.value = '';
    }
    let index: number = this.filesList.indexOf(args.currentTarget.parentElement);
    this.filesList.splice(index, 1);
    this.filesDetails.splice(index, 1);
    this.filesName.splice(this.filesName.indexOf(removeFile.name), 1);
    if (statusCode !== '2') {
      detach(args.currentTarget.parentElement);
    }

    this.valueChange.emit({reference_id: "", isUploading: false});

    let spinnerElement: HTMLElement = document.getElementById('dropArea');
    let li: HTMLElement = document.getElementById('dropArea').querySelector('[data-file-name="' + removeFile.name + '"]');
    if (li && !isNullOrUndefined(li.querySelector('.progressbar'))) {
      (li.querySelector('.progressbar') as HTMLElement).style.visibility = 'hidden';
    }

    if (!isNullOrUndefined(li)) {
      detach(li);
    }
    if (!isNullOrUndefined(spinnerElement)) {
      hideSpinner(spinnerElement);
      detach(spinnerElement.querySelector('.e-spinner-pane'));
    }
    li.querySelector('#removeIcon').removeAttribute('.e-file-remove-btn');
    li.querySelector('#removeIcon').setAttribute('class', 'e-icons e-file-delete-btn');

  }

  public onFileUpload(args: any): void {
    let li: Element = document.getElementById('dropArea').querySelector('[data-file-name="' + args.file.name + '"]');
    let iconEle: HTMLElement = li.querySelector('#iconUpload') as HTMLElement;
    iconEle.style.cursor = 'not-allowed';
    iconEle.classList.add('e-uploaded');
    EventHandler.remove(li.querySelector('#iconUpload'), 'click', this.uploadFile);
    let progressValue: number = Math.round((args.e.loaded / args.e.total) * 100);
    if (!isNaN(progressValue) && li.querySelector('.progressbar')) {
      li.getElementsByTagName('progress')[0].value = progressValue;
    }
  }

  public onUploadSuccess(args: any): void {
    let spinnerElement: HTMLElement = document.getElementById('dropArea');
    let li: HTMLElement = document.getElementById('dropArea').querySelector('[data-file-name="' + args.file.name + '"]');
    if (li && !isNullOrUndefined(li.querySelector('.progressbar'))) {
      (li.querySelector('.progressbar') as HTMLElement).style.visibility = 'hidden';
    }
    if (args.operation === 'upload') {
      EventHandler.remove(li.querySelector('#iconUpload'), 'click', this.uploadFile);
      li.setAttribute('title', args.e.currentTarget.statusText);
      (li.querySelector('.file-name') as HTMLElement).style.color = 'green';
      (li.querySelector('.e-icons') as HTMLElement).onclick = () => {
        this.generateSpinner(this.dropElement.querySelector('#dropArea'));
      };
    } else {
      if (!isNullOrUndefined(li)) {
        detach(li);
      }
      if (!isNullOrUndefined(spinnerElement)) {
        hideSpinner(spinnerElement);
        detach(spinnerElement.querySelector('.e-spinner-pane'));
      }
    }
    li.querySelector('#removeIcon').removeAttribute('.e-file-remove-btn');
    li.querySelector('#removeIcon').setAttribute('class', 'e-icons e-file-delete-btn');

    var header = args.response.headers;
    var statusMessage = header.slice(header.indexOf('status'), header.indexOf('date'));
    statusMessage = statusMessage.slice(statusMessage.indexOf(',') + 1);
    args.statusText = statusMessage.trim();

  }

  public generateSpinner(targetElement: HTMLElement): void {
    createSpinner({target: targetElement, width: '25px'});
    showSpinner(targetElement);
  }

  public readURL(li: HTMLElement, args: any): void {
    let preview: HTMLImageElement = li.querySelector('.upload-image');
    let file: File = args.rawFile;
    let reader: FileReader = new FileReader();
    reader.addEventListener('load', () => {
      preview.src = reader.result as string;
    }, false);
    if (file) {
      reader.readAsDataURL(file);
    }
  }

  clearImages() {
    if (this.dropElement) {
      if (!this.dropElement.querySelector('ul')) {
        return;
      }
      detach(this.dropElement.querySelector('ul'));
      this.filesList = [];
      this.filesDetails = [];
      this.filesName = [];
      if (this.dropElement.querySelector('#dropArea').classList.contains('e-spinner-pane')) {
        hideSpinner(this.dropElement.querySelector('#dropArea'));
        detach(this.dropElement.querySelector('.e-spinner-pane'));
      }

    }
    this.incorrectFileType = false;

    this.valueChange.emit({cleared: true});
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.setCleared && this.setCleared === true) {
      this.incorrectFileSize = false;
      this.incorrectFileType = false;
      console.log('ehere');
      this.clearImages();
    }

    if (this.preLoadedFiles && this.preLoadedFiles.length > 0) {
      // this.preLoadImageOnCreated();
    }
    console.log(this.preLoadedFiles);
  }

  public preLoadImageOnCreated() {
    if (isNullOrUndefined(document.getElementById('dropArea').querySelector('.e-upload-files'))) {
      this.parentElement = createElement('ul', {className: 'e-upload-files'});
      document.getElementsByClassName('e-upload')[0].appendChild(this.parentElement);
    }

    if (this.preLoadedFiles) {
      console.log(this.preLoadedFiles);
      for (let i: number = 0; i < this.preLoadedFiles.length; i++) {
        const fileData: FileInfo = {
          name: this.preLoadedFiles[i].filename,
          rawFile: '',
          size: null,
          status: (this.uploadObj as any).localizedTexts('uploadSuccessMessage'),
          type: this.preLoadedFiles[i].filename ? this.preLoadedFiles[i].filename.split('.')[this.preLoadedFiles[i].filename.split('.').length - 1] : null,
          validationMessages: {minSize: '', maxSize: ''},
          statusCode: '2'
        };
        this.formSelectedData(fileData, this, this.preLoadedFiles[i].url);
        this.filesDetails = this.filesDetails.concat(fileData);
      }
    }

    console.log(this.filesDetails);
  }

  public onFileRemove(args: RemovingEventArgs): void {
    args.postRawFile = false;
  }


}
