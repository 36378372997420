import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {SidebarComponent} from '@syncfusion/ej2-angular-navigations';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import * as moment from 'moment';
import {CompanyFilter} from '../../../../core/store/actions/company.actions';
import {ProjectFilter, ProjectSetStatusNull} from '../../../../core/store/actions/project.actions';
import {FilterEmailLog, LoadEmailLog, MetaDataResetError} from '../../../../core/store/actions/common.actions';
import {Store, select} from '@ngrx/store';
import {AppState} from '../../../../core';
import {Router} from '@angular/router';
import {CommonService} from '../../../../core/services/common.service';
import {debounce, EmitType} from '@syncfusion/ej2-base';
import {FilteringEventArgs} from '@syncfusion/ej2-angular-dropdowns';
import {Query} from '@syncfusion/ej2-data';
import * as _ from 'lodash';
import {ContactService} from '../../../../core/store/services/contact.service';
import {Observable} from 'rxjs';
import {CompanyService} from '../../../../core/store/services/company.service';

@Component({
  selector: 'app-email-log-filter',
  templateUrl: './email-log-filter.component.html',
  styleUrls: ['./email-log-filter.component.scss']
})
export class EmailLogFilterComponent implements OnInit, OnChanges {

  @ViewChild('sidebarInstance') public sidebarInstance: SidebarComponent;
  @Input() users: any;
  @Input() companyList: any;
  @Input() contactList: any;
  @Input() emailTemplates: any;

  userFields: object = {value: 'user_code', text: 'first_name'};

  filterForm: FormGroup;
  public width = 'auto';
  public position = 'Right';
  formSent: boolean;
  isFilterReset = false ;

  public treeSettings: object = { autoCheck: true };
  emailTemplateFields: any;
  status = [{status: 'Pending'}, { status: 'Sent'}, {status: 'Failed'}];


  contactFields = {text: 'name', value: 'value'};
  companyFields = {text: 'company_name', value: 'company_code'};
  public statusFields: object = {dataSource: this.status, value: 'status', text: 'status'};

  error$: Observable<any>;
  success$: Observable<boolean>;

  allowEdit = false;


  constructor(private formBuilder: FormBuilder, private store: Store<AppState>, private router: Router,
              private contactService: ContactService, private companyService: CompanyService) { }

  setDebounce = debounce((e, entity: string) => {
    this.onFilter(e, entity);
  }, 700);

  ngOnInit(): void {
    this.generateFilterForm();

    this.success$ = this.store.pipe(select(store => store.metadata.success));
    this.error$ = this.store.pipe(select(store => store.metadata.error));

    this.success$.subscribe((data: any) => {
      if (data) {
        this.formSent = false;
        if (!this.isFilterReset) {
          this.closeSidebar();
        }
        this.store.dispatch(new MetaDataResetError());
      }
    });

    this.error$.subscribe((data: any) => {
      if (data) {
        this.formSent = false;
      }
    });
  }

  public closeSidebar(): void {
    this.sidebarInstance.hide();
  }

  openSidebar(): void {
    this.sidebarInstance.show();
  }

  generateFilterForm() {
    this.filterForm = this.formBuilder.group({
      email_subject: new FormControl(null),
      email_content: new FormControl(null),
      email_template_code: new FormControl(''),
      sent_by: new FormControl(null),
      sent_at: new FormControl(null),
      company_code: new FormControl(null),
      contact_code: new FormControl(null),
      status: new FormControl(''),
      sent_to_email: new FormControl(null)
    });
  }

  clearForm() {
    this.isFilterReset = true;
    this.router.navigate([], {queryParams: {}});
    this.filterForm.reset();
    this.filterForm.controls.email_template_code.setValue('');
    this.store.dispatch(new LoadEmailLog({page_size: 100, page_no: 1}));
  }

  getFilteredData() {
    this.isFilterReset = false;
    let filterValues = {...this.filterForm.value};
    console.log(filterValues);
    Object.keys(filterValues).forEach((key) => ((filterValues[key] == null) || (filterValues[key] === '')) && delete filterValues[key]);
    console.log(filterValues);

    if (filterValues.sent_at) {
      filterValues.sent_at_end = moment(filterValues.sent_at[1]).format('YYYY-MM-DD');
      filterValues.sent_at_start = moment(filterValues.sent_at[0]).format('YYYY-MM-DD');
    }
    delete filterValues.sent_at;

    for (const filterValuesKey in filterValues) {
      if (filterValues[filterValuesKey] instanceof Array) {
        if (filterValues[filterValuesKey].length > 0) {
          filterValues[filterValuesKey] = filterValues[filterValuesKey].join(',');
        } else {
          delete filterValues[filterValuesKey];
        }
      }
    }

    this.router.navigate([], {queryParams: filterValues});
    filterValues = {...filterValues, page_size: 100, page_no: 1};
    // this.store.dispatch(new FilterEmailLog(filterValues));
  }

  transformData(apiData) {
    const categories = [...apiData];
    const result = [];
    let tempResult = [];
    let categoryResult = [];
    let obj1 = {
      name: null,
      children: null,
      email_template_subject: null,
      email_template_content: null,
      id: null
    };

    for (const category of categories) {
      tempResult = [];
      categoryResult = [];
      if (category.email_category) {
        for (const categoryElement of category.email_category) {
          tempResult = [];

          if (categoryElement.email_template && categoryElement.email_template.length > 0) {
            for (const email of categoryElement.email_template) {
              obj1 = {
                children: null,
                name: email.email_template_name,
                email_template_subject: email.email_template_subject,
                email_template_content: email.email_template_content,
                id: email.email_template_code
              };

              if (email.children && email.children.length > 0) {
                obj1.children = [this.transformData(email.children)];
                // console.log(obj1.children);
              }

              // tempResult.push({children: obj1, name: category.email_category.email_category_name, id: category.email_category.email_category_code});
              tempResult.push(obj1);

            }
            categoryResult.push({
              children: tempResult,
              name: categoryElement.email_category_name,
              id: categoryElement.email_category_code
            });


          } else if (!categoryElement.email_template && categoryElement.children) {
            const obj = {
              children: null,
              name: categoryElement.email_template_name,
              email_template_subject: categoryElement.email_template_subject,
              email_template_content: categoryElement.email_template_content,
              id: categoryElement.email_template_code
            };

            if (categoryElement.children) {
              obj.children = this.transformData(categoryElement.children);
            }
            return obj;
          }
        }
      }
      result.push({children: categoryResult, name: category.application_category_name, id: category.application_category_code});
    }
    return result;
  }

  public onFiltering = (e, entity: string) => {
    e.preventDefaultAction = true;
    this.setDebounce(e, entity);
  }

  public onFilter: EmitType<any> = (e: FilteringEventArgs, entity: string) => {
    let query = new Query();
    if (entity === 'company') {

      query = (e.text !== '') ? query.where('company_name', 'contains', e.text, true) : query;
      const filterParams = {company_name: e.text, page_size: 100, page_no: 1};
      this.companyService.getSearchedCompany(filterParams).subscribe((data) => {
        if (data && data.data) {
          e.updateData(data.data.result, query);

        }
      });
    } else if (entity === 'contact') {
      query = (e.text !== '') ? query.where('name', 'contains', e.text, true) : query;
      const contactfilterParams = {name: e.text, page_size: 40, page_no: 1};
      this.contactService.getSearchedContact(contactfilterParams).subscribe((data) => {
        if (data && data.data) {
          const companyContactList = [];
          if (data.data.result) {
            for (const contact of data.data.result) {
              let company = '';

              company += ' (' + contact.reference_id + ')';
              const result = {
                value: contact.contact_code,
                name: (contact.first_name + ' ' + contact.last_name + company)
              };
              companyContactList.push(result);
            }
            // this.contactList = companyContactList;
            e.updateData(companyContactList, query);
          }
        }
      });
    }
  }

  ngOnChanges(changes : SimpleChanges) {
    this.emailTemplateFields = {dataSource: this.emailTemplates, text: 'email_template_name', value: 'email_template_code', child: 'children'};
  }

}
