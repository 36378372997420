import {Component, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Store, select} from '@ngrx/store';
import {AppState} from '../../../../../core';
import {ActivatedRoute, Router} from '@angular/router';
import {SidebarComponent} from '@syncfusion/ej2-angular-navigations';
import {FilteringEventArgs, MultiSelectComponent} from '@syncfusion/ej2-angular-dropdowns';
import {Observable} from 'rxjs';
import {Tags} from '../../../../../core/models/tags.model';
import {debounce, EmitType} from '@syncfusion/ej2-base';
import {ProjectFilter, ProjectLoad, ProjectSetStatusNull} from '../../../../../core/store/actions/project.actions';
import * as moment from 'moment';
import {CompanyFilter, CompanyGetProjects, CompanyLoad, CompanyProjectFilter} from '../../../../../core/store/actions/company.actions';
import {
  ContactFilter,
  ContactGetProjects,
  ContactProjectFilter,
  GetSearchedContact
} from '../../../../../core/store/actions/contact.actions';
import {Query} from '@syncfusion/ej2-data';
import {ProjectSourceSearchAllSources} from '../../../../../core/store/actions/project-source.actions';
import * as _ from 'lodash';
import {ArticleFilter, ArticleLoad, ArticleSetSuccessNull} from '../../../../../core/store/actions/article.actions';
import {CompanyService} from '../../../../../core/store/services/company.service';

@Component({
  selector: 'app-article-filter',
  templateUrl: './article-filter.component.html',
  styleUrls: ['./article-filter.component.scss']
})
export class ArticleFilterComponent implements OnInit, OnChanges {

  constructor(private formBuilder: FormBuilder, private store: Store<AppState>, private router: Router, private route: ActivatedRoute,
              private companyService: CompanyService) {
  }
  @ViewChild('sidebarInstance')
  public sidebarInstance: SidebarComponent;
  @ViewChild('tags') projectTag: MultiSelectComponent;

  @Input() users: object;
  @Input() companyTypes: object;
  @Input() articleTypes: any;
  @Input() companyList: any;
  @Input() contactList: any;

  public width = 'auto';
  public position = 'Right';

  error$: Observable<any>;
  success$: Observable<boolean>;

  formSent: boolean;
  isFilterReset = false ;
  articleTypeFields: object = {value: 'article_type_code', text: 'article_type_name'};
  companyTypeField: object;
  public companyFields: object = {value: 'company_code', text: 'company_name'};
  public contactFields: object = {value: 'value', text: 'name'};
  public treeSettings: object = { autoCheck: true };
  // userFields: object = {value: 'user_code', text: ('first_name')};

  countriesField: object;
  projectTypeFields: object;
  allowEdit = false;
  selectedContactCode = '';


  filterForm: FormGroup;
  sourcesList = [];

  filterFormArray = ['project_type_code', 'project_type', 'status_code', 'assigned_to_user',
    'published_by', 'country_code', 'project_tag', 'contact_code', 'company_code', 'updated_by', 'created_by', 'project_source_type_code'];

  setDebounce = debounce((e, entity: string) => {
    this.onFilter(e, entity);
  }, 700);

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.sidebarInstance.isOpen) {
      this.closeSidebar();
    }
  }

  ngOnInit(): void {
    this.generateFilterForm();

    this.success$ = this.store.pipe(select(store => store.articles.success));
    this.error$ = this.store.pipe(select(store => store.articles.error));

    this.success$.subscribe((data: any) => {
      if (data) {
        this.formSent = false;
        if (!this.isFilterReset) {
          this.closeSidebar();
        }
        this.store.dispatch(new ArticleSetSuccessNull());
      }
    });

    this.error$.subscribe((data: any) => {
      if (data) {
        this.formSent = false;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.companyTypes) {
      this.companyTypeField = {dataSource: this.companyTypes, value: 'company_type_code', text: 'company_type_name', child: 'children'};
    }
  }

  generateFilterForm() {
    this.filterForm = this.formBuilder.group({
      article_name: new FormControl(null),
      reference_id: new FormControl(null),
      article_type_code: new FormControl(''),
      company_type_code: new FormControl(''),
      created_from: new FormControl(null),
      created_till: new FormControl(null),
      updated_from: new FormControl(null),
      updated_till: new FormControl(null),
      contact_code: new FormControl(null),
      company_code: new FormControl(null),
      published_from: new FormControl(null),
      published_till: new FormControl(null),
    });

    this.route.queryParams.subscribe(res => {
      // console.log(res);
      // let filterVal = {...this.filterForm.value};
      // filterVal = {...filterVal, ...res};
      // // console.log(filterVal);
      // for (const fKey in filterVal) {
      //   if (this.filterFormArray.includes(fKey)) {
      //     // console.log(filterVal[fKey]);
      //     if (filterVal[fKey]) {
      //       filterVal[fKey] = filterVal[fKey].split(',');
      //     }
      //   }
      // }

      // this.filterForm.setValue({...filterVal});
      // this.filterForm.controls.project_type_code.setValue(res.project_type_code.split(','));
      // console.log(this.filterForm.value);
      // console.log(filterVal);
      // const queryCopy = {...res};
      // delete queryCopy.project_type_code;
      // this.filterForm.patchValue(queryCopy);
      // console.log(this.filterForm.value);
      // console.log(queryCopy);
      // const resCopy = {...res};
      // const types = resCopy.project_type_code.toString().split(',');
      // console.log(types);
      // this.filterForm.setValue(res);
      // this.filterForm.setValue({company_type_code: types});
      // this.filterForm.controls.project_type_code.value = types;
      // this.filterForm.patchValue({project_type_code: types});
      // console.log(this.filterForm.value);
    });
  }

  getFilteredData() {
    this.isFilterReset = false;
    let filterValues = {...this.filterForm.value};
    Object.keys(filterValues).forEach((key) => ((filterValues[key] == null) || (filterValues[key] === '')) && delete filterValues[key]);


    if (filterValues.published_from) {
      filterValues.published_from = moment(filterValues.published_from).format('YYYY-MM-DD');
    }

    if (filterValues.published_till) {
      filterValues.published_till = moment(filterValues.published_till).format('YYYY-MM-DD');
    }

    if (filterValues.created_from) {
      filterValues.created_from = moment(filterValues.created_from).format('YYYY-MM-DD');
    }

    if (filterValues.created_till) {
      filterValues.created_till = moment(filterValues.created_till).format('YYYY-MM-DD');
    }

    if (filterValues.updated_from) {
      filterValues.updated_from = moment(filterValues.updated_from).format('YYYY-MM-DD');
    }

    if (filterValues.updated_till) {
      filterValues.updated_till = moment(filterValues.updated_till).format('YYYY-MM-DD');
    }

    for (const filterValuesKey in filterValues) {
      if (filterValues[filterValuesKey] instanceof Array) {
        filterValues[filterValuesKey] = filterValues[filterValuesKey].join(',');
      }
    }


    filterValues = {...filterValues, page_size: 100, page_no: 1};
    // Check if project page is loaded form companies or contact pages
    if (this.isCompanyContactLink()) {
      this.route.parent.paramMap.subscribe((data: any) => {
          if (data.params.contact_code) {
            this.selectedContactCode = data.params.contact_code;
            filterValues.contact_code = data.params.contact_code ;
            this.store.dispatch(new ArticleFilter(filterValues));
        }
      });
    } else {
      this.router.navigate([], {queryParams: filterValues});
      this.store.dispatch(new ArticleFilter(filterValues));
    }
  }

  clearForm() {
    this.isFilterReset = true;
    this.router.navigate([], {queryParams: {}});
    this.filterForm.reset();
    if (this.isCompanyContactLink()) {
      this.store.dispatch(new ArticleFilter({article_contact_code: this.selectedContactCode, page_size: 100, page_no: 1}));
    } else {
      this.store.dispatch(new ArticleLoad({page_size: 100, page_no: 1}));
    }
    this.filterForm.controls.article_type_code.setValue('');
    this.filterForm.controls.company_type_code.setValue('');

  }

  openClick(): void {
    this.sidebarInstance.show();
  }

  public closeSidebar(): void {
    this.sidebarInstance.hide();
  }

  isCompanyContactLink(): boolean {
    return (this.router.url.indexOf('/company/') > -1 || this.router.url.indexOf('/contact/') > -1);
  }

  public onFiltering = (e, entity: string) => {
    e.preventDefaultAction = true;
    this.setDebounce(e, entity);
  }

  public onFilter: EmitType<any> = (e: FilteringEventArgs, entity: string) => {
    let query = new Query();
    if (entity === 'company') {
      query = (e.text !== '') ? query.where('company_name', 'startswith', e.text, true) : query;
      // if (e.text && e.text !== '') {
      const filterParams = {company_name: e.text, page_size: 100, page_no: 1};
      this.companyService.getSearchedCompany(filterParams).subscribe((data) => {
        if (data && data.data) {
          if (data.data.result) {
            const result: any = data.data.result;
            e.updateData(_.cloneDeep(result), query);
          }
        }
      });
        // this.store.dispatch(new CompanyFilter(filterParams));
        // this.store.select(store => store.companies.companies).subscribe((data) => {
        //   if (data) {
        //     this.companyList = data.result;
        //     e.updateData(_.cloneDeep(this.companyList), query);
        //   }
        // });
      // }
    // else {
    //     const filterParams = {page_size: 40, page_no: 1};
    //     this.store.dispatch(new CompanyLoad(filterParams));
    //     // this.store.select(store => store.companies.companies).subscribe((data) => {
    //     //   if (data) {
    //     //     const result: any = data.result;
    //     //     this.companyList = data.result;
    //     //     e.updateData(_.cloneDeep(this.companyList), query);
    //     //   }
    //     // });
    //   }

    } else if (entity === 'contact') {


      query = (e.text !== '') ? query.where('value', 'startswith', e.text, true) : query;
      const contactfilterParams = {name: e.text, page_size: 40, page_no: 1};
      this.store.dispatch(new GetSearchedContact(contactfilterParams));
      this.store.select(store => store.contacts.contacts).subscribe((data) => {
        if (data) {
          const companyContactList = [];
          for (const contact of data.result) {

            let company = '';
            if (contact.company) {
              company = ' - ' + contact.company.company_name;
            } else {
              company = '';
            }
            company += ' (' + contact.reference_id + ')';

            let result = {
              value: contact.contact_code, category: 'Contacts',
              name: (contact.first_name + ' ' + contact.last_name + company),
            };

            if (contact.company) {
              result = {...result, ...{company_code: contact.company.company_code, company_name: contact.company.company_name}};
            }
            companyContactList.push(result);
          }
          e.updateData(companyContactList, query);
        }
      });
    }
  }

  setDuration(args) {
  }

  isContactLink(): boolean {
    return (this.router.url.indexOf('/contact/') > -1);
  }


}
