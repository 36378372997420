import {Action} from '@ngrx/store';
import {ProjectType} from '../../models/project-type.model';

export enum ProjectTypeActionsType {
  LOAD_PROJECT_TYPE = '[PROJECT TYPE] Load Action',
  LOAD_PROJECT_TYPE_SUCCESS = '[PROJECT TYPE] Load Success Action',
  LOAD_PROJECT_TYPE_FAILURE = '[PROJECT TYPE] Load Failure Action',

  ADD_PROJECT_TYPE = '[PROJECT TYPE] Add Action',
  ADD_PROJECT_TYPE_SUCCESS = '[PROJECT TYPE] Add Success Action',
  ADD_PROJECT_TYPE_FAILURE = '[PROJECT TYPE] Add Failure Action',

  UPDATE_PROJECT_TYPE = '[PROJECT TYPE] Update Action',
  UPDATE_PROJECT_TYPE_SUCCESS = '[PROJECT TYPE] Update Success Action',
  UPDATE_PROJECT_TYPE_FAILURE = '[PROJECT TYPE] Update Failure Action',

  DELETE_PROJECT_TYPE = '[PROJECT TYPE] Delete Action',
  DELETE_PROJECT_TYPE_SUCCESS = '[PROJECT TYPE] Delete Success Action',
  DELETE_PROJECT_TYPE_FAILURE = '[PROJECT TYPE] Delete Failure Action',

  RESET_ERROR = '[PROJECT TYPE] Reset Error Action'
}

export class ProjectTypeLoad implements Action {
  readonly type = ProjectTypeActionsType.LOAD_PROJECT_TYPE;

  constructor(public payload?: { page_size?: number, page_no?: number }) {
  }
}

export class ProjectTypeLoadSuccess implements Action {
  readonly type = ProjectTypeActionsType.LOAD_PROJECT_TYPE_SUCCESS;

  constructor(public payload: { result: Array<ProjectType>, total_count: number, page_size: number, page_no: number }) {
  }
}

export class ProjectTypeLoadFailure implements Action {
  readonly type = ProjectTypeActionsType.LOAD_PROJECT_TYPE_FAILURE;

  constructor(public payload: Error) {
  }
}

export class ProjectTypeAdd implements Action {
  readonly type = ProjectTypeActionsType.ADD_PROJECT_TYPE;

  constructor(public payload: { project_type_name: string, parent_type_code?: string }) {
  }
}

export class ProjectTypeAddSuccess implements Action {
  readonly type = ProjectTypeActionsType.ADD_PROJECT_TYPE_SUCCESS;

  constructor(public payload: { projectType: ProjectType, parentCode: string }) {
  }
}

export class ProjectTypeAddFailure implements Action {
  readonly type = ProjectTypeActionsType.ADD_PROJECT_TYPE_FAILURE;

  constructor(public payload: Error) {
  }
}


export class ProjectTypeUpdate implements Action {
  readonly type = ProjectTypeActionsType.UPDATE_PROJECT_TYPE;

  constructor(public payload: { project_type_code: string, projectTypeName: string, parent_type_code: string }) {
  }
}

export class ProjectTypeUpdateSuccess implements Action {
  readonly type = ProjectTypeActionsType.UPDATE_PROJECT_TYPE_SUCCESS;

  constructor(public payload: { projectType: ProjectType, parent_type_code: string }) {
  }
}

export class ProjectTypeUpdateFailure implements Action {
  readonly type = ProjectTypeActionsType.UPDATE_PROJECT_TYPE_FAILURE;

  constructor(public payload: Error) {
  }
}

export class ProjectTypeDelete implements Action {
  readonly type = ProjectTypeActionsType.DELETE_PROJECT_TYPE;

  constructor(public payload: { projectTypeCode: string }) {
  }
}

export class ProjectTypeDeleteSuccess implements Action {
  readonly type = ProjectTypeActionsType.DELETE_PROJECT_TYPE_SUCCESS;

  constructor(public payload: { projectTypeCode: string }) {
  }
}

export class ProjectTypeDeleteFailure implements Action {
  readonly type = ProjectTypeActionsType.DELETE_PROJECT_TYPE_FAILURE;

  constructor(public payload: Error) {
  }
}

export class ProjectTypeResetError implements Action {
  readonly type = ProjectTypeActionsType.RESET_ERROR;
}

export type ProjectTypeActions = ProjectTypeLoad | ProjectTypeLoadSuccess | ProjectTypeLoadFailure | ProjectTypeAdd |
  ProjectTypeAddSuccess | ProjectTypeAddFailure | ProjectTypeUpdate | ProjectTypeUpdateSuccess | ProjectTypeUpdateFailure |
  ProjectTypeDelete | ProjectTypeDeleteSuccess | ProjectTypeDeleteFailure | ProjectTypeResetError;
