import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { FormValidators } from '@syncfusion/ej2-angular-inputs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core';
import { Login } from 'src/app/core/store/auth/auth.actions';
import { AuthNoticeService } from 'src/app/core/store/auth/auth-notice.service';
import { Router } from '@angular/router';
import {errorMessage} from '../../../common/app-error-messages';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  signInForm: FormGroup;
  msg = errorMessage;

  constructor(
    private store: Store<AppState>,
    private authNoticeService: AuthNoticeService,
    private router: Router,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle('Log In - Canvas');
    if (localStorage.getItem('token')) {
      this.router.navigate(['dashboard']);
    }

    this.signInForm = new FormGroup({
      email: new FormControl('', [FormValidators.required, FormValidators.email, FormValidators.minLength(3)]),
      password: new FormControl('', [FormValidators.required]),
    });
  }

  get email() { return this.signInForm.get('email'); }
  get password() { return this.signInForm.get('password'); }

  signIn() {
    if (!this.signInForm.valid) {
      if (this.email.errors !== null && this.email.errors.email ) {
        this.authNoticeService.setNotice(this.msg.loginError, 'light-danger');
      }
    } else {
      this.store.dispatch(new Login({ username: this.signInForm.value.email, password: this.signInForm.value.password }));
    }
  }
}
