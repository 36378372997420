// Angular
import { Component, ElementRef, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
// Layout
import { LayoutConfigService, SplashScreenService } from '../../../core/_base/layout';
// Auth
// import { AuthNoticeService } from '../../../core/auth';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AuthComponent implements OnInit {

  // Public properties
  today: number = Date.now();
  headerLogo: string;

	/**
	 * Component constructor
	 *
	 * @param el
	 * @param render
	 * @param layoutConfigService: LayoutConfigService
	 * @param translationService: TranslationService
	 * @param splashScreenService: SplashScreenService
	 */
  constructor(
    private el: ElementRef,
    private render: Renderer2,
    private layoutConfigService: LayoutConfigService,
    private splashScreenService: SplashScreenService) {
  }

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
  ngOnInit(): void {
    this.headerLogo = this.layoutConfigService.getLogo();

    this.splashScreenService.hide();

    this.renderLoginImage();


  }

  renderLoginImage = () => {
    const imageSelector = <HTMLElement> document.querySelector('.action-page-image-bg');
    const imageSelectorMobile = <HTMLElement> document.querySelector('.action-page-image-mobile');
    const levelZeroSrc = '/assets/media/login.jpg';
    const levelOneSrc = `/assets/images/login/${window.location.hostname.split('.')[0]}.jpg`;
    const id = Math.random().toString(36).substring(2);
    const levelTwoSrc = `https://source.unsplash.com/user/lovethatdesign/likes/1600x900?${id}`;
    if (imageSelector !== undefined) {
      imageSelector.style.background = `url(${levelTwoSrc}), url(${levelOneSrc}), url(${levelZeroSrc})`;
    }
    if (imageSelectorMobile !== undefined) {
      imageSelectorMobile.style.background = `url(${levelTwoSrc}), url(${levelOneSrc}), url(${levelZeroSrc})`;
    }
  };

	/**
	 * Load CSS for this specific page only, and destroy when navigate away
	 * @param styleUrl
	 */
  private loadCSS(styleUrl: string) {
    return new Promise((resolve, reject) => {
      const styleElement = document.createElement('link');
      styleElement.href = styleUrl;
      styleElement.type = 'text/css';
      styleElement.rel = 'stylesheet';
      styleElement.onload = resolve;
      this.render.appendChild(this.el.nativeElement, styleElement);
    });
  }

}
