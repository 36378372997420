export const custom_icons = [
  'can-menu-toggle',
  'can-toggle-switch',
  // Editor
  'can-edit-pen',
  'can-edit-pen-modern',
  'can-trash',
  'can-trash-modern',
  'can-create-note',
  'can-call-log',
  'can-create-task',
  'can-notebook-modern',
  'cal-call-modern',
  'can-menu-h',
  'can-more-actions',
  'Mail-notifications',
  'can-calendar',
  'can-trash-restore',
  'can-newspaper',
  'can-sync',
  'can-eye-slash'
];

// export const inlineSvgPath = (icon, path?: null) => {
//   let icon_path = `./assets/images/icons/${icon}.svg`;
//   return path ? `${path}/${icon}.svg` : icon_path;
// };
