<ejs-sidebar id="sidebar-menu" #sidebarInstance type='Over' [width]='width' [position]='position'
             style="visibility: hidden" [enableGestures]="false" [showBackdrop]=true>

  <div class="offcanvas-close mt-n1 pr-5" style="float:right; padding: 48px;">
    <a href="javascript:;" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_panel_close"
       (click)="closeSidebar()">
      <i class="ki ki-close icon-xs text-muted"></i>
    </a>
  </div>

  <ng-container [ngSwitch]="formType">


    <div *ngSwitchCase="'project'" class='p-15'>
      <h2 *ngIf="!projectInput && !isBulkEdit">Add Project</h2>
      <h2 *ngIf="projectInput && !isBulkEdit">Update Project</h2>

      <h2 *ngIf="isBulkEdit">Bulk Edit</h2>

      <form id="form-project" [formGroup]="projectForm" #formDir="ngForm" class="form-horizontal" novalidate="">

        <div class="form-group sf-outline-input mb-10" *ngIf="!projectInput && isBulkEdit">
          <ejs-dropdownlist #field id='field' [dataSource]='fieldNames' (change)="fieldChanged()"
                            placeholder='Field' [allowFiltering]='true'
                            sortOrder="Ascending"
                            cssClass="e-filled" floatLabelType="Auto">
          </ejs-dropdownlist>
        </div>

        <div class="form-group sf-outline-input mb-10" *ngIf="!isBulkEdit">
          <ejs-textbox id="project_name" placeholder="Project Name *" cssClass="e-filled"
                       formControlName="project_name" floatLabelType="Auto"
                       (blur)="projectForm.controls.project_name.markAsTouched()"
          ></ejs-textbox>
          <div *ngIf="projectForm.controls.project_name.errors">
            <div *ngIf="projectForm.controls.project_name.errors.required && projectForm.controls.project_name.touched"
                 class="e-error">
              Project Name is required.
            </div>
            <div *ngIf="projectForm.controls.project_name.errors.maxlength && projectForm.controls.project_name.touched"
                 class="e-error">
              Project Name cannot be more than 250 characters.
            </div>
          </div>
        </div>

        <div class="form-group sf-outline-input mb-10" *ngIf="!isBulkEdit">
          <ejs-textbox id="display_name" placeholder="Display Name" cssClass="e-filled"
                       formControlName="display_name" floatLabelType="Auto"
                       (blur)="projectForm.controls.display_name.markAsTouched()"
          ></ejs-textbox>
          <div *ngIf="projectForm.controls.display_name.errors">
            <div *ngIf="projectForm.controls.display_name.errors.maxlength && projectForm.controls.display_name.touched"
                 class="e-error">
              Display Name cannot be more than 250 characters.
            </div>
          </div>
        </div>


        <div class="form-group sf-outline-input mb-10" *ngIf="selectedField === 'Project Type' || !isBulkEdit"
             [class.hide-label]="projectForm.controls.project_type.value == ''">
          <ejs-dropdowntree #projectTypeDropdown formControlName="project_type" id='project_type'
                            allowMultiSelection='true'
                            sortOrder="Ascending" [showCheckBox]="true" (change)="somethingChanged($event)"
                            [fields]='projectTypeFields' placeholder='Project Type *' cssClass="e-filled"
                            [showClearButton]="showClearButton"
                            floatLabelType="Auto" (blur)="projectForm.controls.project_type.markAsTouched()"
          ></ejs-dropdowntree>
          <div *ngIf="projectForm.controls.project_type.errors">
            <div *ngIf="projectForm.controls.project_type.errors.required && projectForm.controls.project_type.touched"
                 class="e-error">
              Project Type is required
            </div>
          </div>
        </div>


        <div class="form-group sf-outline-input mb-10" *ngIf="!isBulkEdit">
          <ejs-textbox id="description" [multiline]="true" placeholder="Description" cssClass="e-filled"
                       formControlName="description" floatLabelType="Auto"
                       (blur)="projectForm.controls.description.markAsTouched()"
          ></ejs-textbox>
        </div>

        <div class="form-group sf-outline-input mb-10" *ngIf="selectedField === 'Address' || !isBulkEdit">
          <ejs-textbox id="address" [multiline]="true" placeholder="Address *" cssClass="e-filled"
                       formControlName="address" floatLabelType="Auto"
                       (blur)="projectForm.controls.address.markAsTouched()"
          ></ejs-textbox>
          <div *ngIf="projectForm.controls.address.errors">
            <div *ngIf="projectForm.controls.address.errors.required && projectForm.controls.address.touched"
                 class="e-error">
              Address is required.
            </div>
            <div *ngIf="projectForm.controls.address.errors.maxlength && projectForm.controls.address.touched"
                 class="e-error">
              Address cannot be more than 200 characters
            </div>
          </div>
        </div>

        <div class="form-group sf-outline-input mb-10" *ngIf="selectedField === 'City' || !isBulkEdit">
          <ejs-textbox id="city_name" placeholder="City *" cssClass="e-filled"
                       formControlName="city_name" floatLabelType="Auto"
                       (blur)="projectForm.controls.city_name.markAsTouched()"
          ></ejs-textbox>
          <div *ngIf="projectForm.controls.city_name.errors">
            <div *ngIf="projectForm.controls.city_name.errors.required && projectForm.controls.city_name.touched" class="e-error">
              City is required.
            </div>
            <div *ngIf="projectForm.controls.city_name.errors.invalidText"
                 class="e-error">
              Please enter a valid city
            </div>
            <div *ngIf="projectForm.controls.city_name.errors.maxlength && projectForm.controls.city_name.touched"
                 class="e-error">
              City cannot be more than 100 characters
            </div>
          </div>
        </div>

        <div class="form-group sf-outline-input mb-10" *ngIf="selectedField === 'Country' || !isBulkEdit">
          <ejs-dropdownlist id='country_code' formControlName="country_code" [dataSource]='countries' [fields]='countryFields'
                            placeholder='Country *' [allowFiltering]='true'
                            sortOrder="Ascending"
                            cssClass="e-filled" floatLabelType="Auto"
                            (blur)="projectForm.controls.country_code.markAsTouched()">
          </ejs-dropdownlist>
          <div *ngIf="projectForm.controls.country_code.invalid">
            <div *ngIf="projectForm.controls.country_code.errors.required && projectForm.controls.country_code.touched "
                 class="e-error">
              Please select a country
            </div>
          </div>
        </div>


        <div class="form-group sf-outline-input mb-10" *ngIf="selectedField === 'Tags' || !isBulkEdit">
          <ejs-multiselect id='project_tag' #tags [dataSource]="tagModelData" floatLabelType="Auto" cssClass="e-filled"
                           [fields]="tagFields" formControlName='project_tag'
                           [allowCustomValue]=true mode='Box' placeholder='Tags'
                           (actionComplete)="actionComplete($event)"
                           (ngModelChange)="syncModel($event)"
                           (change)="onChange($event)"
                           (blur)="onBlur($event, projectForm)"
                           name="tags"
                           (removed)="selectedTag = []"
                           [(ngModel)]="modelData"
                           (select)="select($event)"
                           ngModel></ejs-multiselect>
        </div>

        <div class="form-group sf-outline-input mb-10" *ngIf="selectedField === 'Status' || !isBulkEdit && !projectInput">
          <ejs-dropdownlist #status formControlName="status_code" multiselect id='status_code' [fields]='projectStatusFields'
                            placeholder='Status *' (blur)="projectForm.controls.status_code.markAsTouched()"
                            cssClass="e-filled"
                            floatLabelType="Auto" [dataSource]="projectStatus"></ejs-dropdownlist>
          <div *ngIf="projectForm.controls.status_code.invalid">
            <div *ngIf="projectForm.controls.status_code.errors.required && projectForm.controls.status_code.touched"
                 class="e-error">
              Please select a status
            </div>
          </div>
        </div>



        <div class="form-group sf-outline-input mb-10" *ngIf="projectInput || selectedField === 'Published Date'">
          <ejs-datetimepicker formControlName="proposed_post_date" name="proposed_post_date" [allowEdit]="allowEdit"
                              placeholder='Published Date' cssClass="e-filled" floatLabelType='Auto'>
          </ejs-datetimepicker>
        </div>

        <div class="form-group sf-outline-input mb-10" *ngIf="projectInput || selectedField === 'Featured Date'">
          <ejs-datepicker formControlName="featured_date" name="featured_date" [allowEdit]="allowEdit"
                          placeholder='Featured Date' cssClass="e-filled" floatLabelType='Auto'>
          </ejs-datepicker>
        </div>

        <div class="form-group sf-outline-input mb-10" *ngIf="!isBulkEdit">
          <ejs-textbox id="drive_link" placeholder="Drive Link" cssClass="e-filled"
                       formControlName="drive_link" floatLabelType="Auto"
                       (blur)="projectForm.controls.drive_link.markAsTouched()"
          ></ejs-textbox>
          <div *ngIf="projectForm.controls.drive_link.errors">
            <div *ngIf="projectForm.controls.drive_link.errors.invalidurl && projectForm.controls.drive_link.touched "
                 class="e-error">
              Please enter a valid drive link
            </div>
            <div *ngIf="projectForm.controls.drive_link.errors.maxlength && projectForm.controls.drive_link.touched "
              class="e-error">
              Drive Link cannot be more than 500 characters
            </div>
          </div>
        </div>

        <div class="form-group sf-outline-input mb-10" *ngIf="projectInput && !isBulkEdit">
          <ejs-textbox id="ltd_link" placeholder="Project Link" cssClass="e-filled"
                       formControlName="ltd_link" floatLabelType="Auto"
                       (blur)="projectForm.controls.ltd_link.markAsTouched()"
          ></ejs-textbox>
          <div *ngIf="projectForm.controls.ltd_link.errors">
            <div *ngIf="projectForm.controls.ltd_link.errors.invalidurl && projectForm.controls.ltd_link.touched "
                 class="e-error">
              Please enter a valid project link.
            </div>
            <div *ngIf="projectForm.controls.ltd_link.errors.maxlength && projectForm.controls.ltd_link.touched "
                 class="e-error">
              Project Link cannot be more than 500 characters
            </div>
          </div>
        </div>

        <div class="form-group sf-outline-input mb-10" *ngIf="selectedField === 'Assigned To'">
          <ejs-dropdownlist id='user_code' formControlName="user_code" [dataSource]='users' [fields]='userFields'
                            placeholder='Assign to *' [allowFiltering]='true' cssClass="e-filled" floatLabelType="Auto"
                            sortOrder="Ascending"
                            (filtering)='onFiltering($event, "status")'
                            (blur)="projectForm.controls.user_code.markAsTouched()">
          </ejs-dropdownlist>
          <div *ngIf="projectForm.controls.user_code.invalid">
            <div *ngIf="projectForm.controls.user_code.errors.required && projectForm.controls.user_code.touched "
                 class="e-error">
              Please select a user
            </div>
          </div>
        </div>

        <div class="form-group sf-outline-input mb-10"  *ngIf="selectedField === 'Assigned To'">
          <ejs-textbox id="assigned_to_note" [multiline]="true" placeholder="Assigned To Notes *" cssClass="e-filled"
                       formControlName="assigned_to_note" floatLabelType="Auto"
                       (blur)="projectForm.controls.assigned_to_note.markAsTouched()"
          ></ejs-textbox>
        </div>

        <span class="e-error" *ngIf="isBulkEdit && (!selectedProjectList || selectedProjectList.length === 0)">Please select some projects</span>
        <div class="e-error" *ngIf="selectedField === 'Project Type' || selectedField === 'Tags'">Warning: The existing {{selectedField}} of selected records will be overwritten!</div>
        <div  *ngIf="!projectInput && !isBulkEdit" class="separator separator-dashed mt-8 mb-8"></div>
        <form id="formId" *ngIf="!projectInput && !isBulkEdit" [formGroup]="projectSourceForm" #formDir="ngForm" class="form-horizontal mt-2" novalidate=""
              style="margin-top: 16px;">

          <h2 *ngIf="!projectInput && !isBulkEdit">Add Project Source</h2>

          <div class="form-group sf-outline-input mb-10 mt-8"  [class.hide-label]="projectSourceForm.controls.project_source_type_code.value == ''">
            <ejs-dropdowntree id='project_source_type_code' formControlName="project_source_type_code" #checkbox [showCheckBox]=true
                             placeholder='Source Type *' [fields]='sourceTypeFields' floatLabelType="Auto" cssClass="e-filled"
                             [showDropDownIcon]='true' [allowMultiSelection]=true (change)="setSourceType($event)"
                             (blur)="projectSourceForm.controls.project_source_type_code.markAsTouched()"
                             filterBarPlaceholder='Search'></ejs-dropdowntree>
            <div *ngIf="projectSourceForm.controls.project_source_type_code.invalid">
              <div
                *ngIf="projectSourceForm.controls.project_source_type_code.errors.required && projectSourceForm.controls.project_source_type_code.touched "
                class="e-error">
                Please select a source type
              </div>
            </div>
          </div>

<!--          <div class="form-group sf-outline-input mb-10">-->
<!--            <ejs-dropdownlist #company formControlName='company_code' id='companyList'-->
<!--                              [dataSource]='companyList' placeholder='Company' (change)="change($event)"-->
<!--                              [fields]='companyFields' cssClass="e-filled" floatLabelType="Auto" [showClearButton]=true-->
<!--                              [allowFiltering]='true' (filtering)="onFiltering($event, 'company')"-->
<!--                              (blur)="projectSourceForm.controls.company_code.markAsTouched()"></ejs-dropdownlist>-->
<!--            <div *ngIf="projectSourceForm.controls.company_code.invalid">-->
<!--              <div-->
<!--                *ngIf="projectSourceForm.controls.company_code.errors.required && projectSourceForm.controls.company_code.touched "-->
<!--                class="e-error">-->
<!--                Please select a company-->
<!--              </div>-->
<!--            </div>-->

<!--            <div class="d-flex align-items-center">-->
<!--              <span>Can't find who you're looking for?</span>-->
<!--              <button-->
<!--                class="btn btn-light btn-text-primary btn-hover-text-primary text-uppercase font-weight-bold mr-2 btn-sm p-3  ml-3 add-btn"-->
<!--                (click)="addNewCompanyContact('company')" type="button">-->
<!--                Add new Company-->
<!--              </button>-->
<!--            </div>-->
<!--          </div>-->

          <div class="form-group sf-outline-input mb-10">
            <ejs-dropdownlist #contact formControlName='contact_code' id='contactList' [showClearButton]=true
                              [dataSource]='contactList' placeholder='Contact *' (focus)="focus($event)"
                              [fields]='contactFields' cssClass="e-filled" floatLabelType="Auto" (change)="selectSourceContact($event, projectSourceForm)"
                              [allowFiltering]='true' (filtering)="onFiltering($event, 'contact')"
                              (blur)="projectSourceForm.controls.contact_code.markAsTouched()"></ejs-dropdownlist>
            <div *ngIf="projectSourceForm.controls.contact_code.invalid">
              <div
                *ngIf="projectSourceForm.controls.contact_code.errors.required && projectSourceForm.controls.contact_code.touched "
                class="e-error">
                Please select a contact
              </div>
            </div>

            <div class="d-flex align-items-center">
              <span>Can't find who you're looking for?</span>
              <button
                class="btn btn-light btn-text-primary btn-hover-text-primary text-uppercase font-weight-bold mr-2 btn-sm p-3  ml-3 add-btn"
                (click)="addNewCompanyContact('company')" type="button">
                Add new Company
              </button>
              <button
                class="btn btn-light btn-text-primary btn-hover-text-primary text-uppercase font-weight-bold mr-3 btn-sm p-3 ml-3 add-btn"
                (click)="addNewCompanyContact('contact')" type="button">
                Add new Contact
              </button>
            </div>
          </div>

          <div class="form-group sf-outline-input mb-10" >
            <ejs-dropdownlist id='project_source_status_code' formControlName="project_source_status_code" [dataSource]='projectSourceStatus' floatLabelType="Auto"
                              [fields]='sourceStatusFields' cssClass="e-filled" value="'open'"
                              placeholder='Status *' [allowFiltering]='true'
                              (blur)="projectSourceForm.controls.project_source_status_code.markAsTouched()">
            </ejs-dropdownlist>
            <div *ngIf="projectSourceForm.controls.project_source_status_code.invalid">
              <div *ngIf="projectSourceForm.controls.project_source_status_code.errors.required && projectSourceForm.controls.project_source_status_code.touched "
                   class="e-error">
                Please select a status
              </div>
            </div>
          </div>

          <div class="form-group sf-outline-input mb-10">
            <ejs-textbox id="drive_link" placeholder="Drive Link" cssClass="e-filled"
                         formControlName="drive_link" floatLabelType="Auto"
                         (blur)="projectSourceForm.controls.drive_link.markAsTouched()"
            ></ejs-textbox>
            <div *ngIf="projectSourceForm.controls.drive_link.errors">
              <div *ngIf="projectSourceForm.controls.drive_link.errors.invalidurl && projectSourceForm.controls.drive_link.touched "
                   class="e-error">
                Please enter a valid url.
              </div>
              <div *ngIf="projectSourceForm.controls.drive_link.errors.maxlength && projectSourceForm.controls.drive_link.touched "
                   class="e-error">
                Drive link cannot be more than 500 characters
              </div>
            </div>
          </div>

          <div class="form-group sf-outline-input mb-10">
            <ejs-textbox id="notes" [multiline]="true" placeholder="Notes" cssClass="e-filled"
                         formControlName="notes" floatLabelType="Auto"
                         (blur)="projectSourceForm.controls.notes.markAsTouched()"
            ></ejs-textbox>
          </div>

          <div class="form-group sf-outline-input mb-10">
            <ejs-datepicker formControlName="date_received"
                            placeholder='Received On *' openOnFocus="true" [allowEdit]="allowEdit"
                            floatLabelType='Auto' cssClass="e-filled"
                            (blur)="projectSourceForm.controls.date_received.markAsTouched()">
            </ejs-datepicker>
            <div *ngIf="projectSourceForm.controls.date_received.errors">
              <div *ngIf="projectSourceForm.controls.date_received.errors.required && projectSourceForm.controls.date_received.touched "
                   class="e-error">
                Please select a received date
              </div>
            </div>
          </div>

          <div *ngIf="projectSourceForm.errors" class="e-error"> <div *ngIf="projectSourceForm.errors.atLeastOne"> Please select a company or contact </div></div>
          <div *ngIf="error$ | async as error" class="e-error">{{error.error.message}}</div>

        </form>

        <div *ngIf="!projectInput && (projectForm.invalid || projectSourceForm.invalid)" class="e-error">Please fill all fields</div>
        <div *ngIf="projectInput && projectForm.invalid" class="e-error">Please fill all fields</div>

        <!-- <div *ngIf="errorMsg">{{errorMsg}}</div> -->
<!--        <div class="e-error" *ngIf="error$ | async as error">{{error.error.message}}</div>-->
<!--        <div class="e-error" *ngIf="errorProjectSource$ | async as error">{{error.error.message}}</div>-->

        <!-- Action Buttons -->

        <div class="my-10 pb-20" *ngIf="!isBulkEdit">
          <button id="resetbtn"
                  class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5"
                  type="button" (click)="clearForm()"
                  data-ripple="true">Clear
          </button>
          <button *ngIf="!projectInput" id="Add"
                  class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block"
                  type="submit"
                  (click)="onAddProject(projectForm, projectSourceForm)" [disabled]="formSent" data-ripple="true">Add Project
          </button>
          <button *ngIf="projectInput" id="update"
                  class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block"
                  type="submit"
                  (click)="onUpdateProject(projectForm)" [disabled]="formSent" data-ripple="true">Update Project
          </button>
        </div>
        <div class="my-10 pb-20" *ngIf="isBulkEdit">
          <button
            class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5"
            type="button" (click)="clearForm(); fieldDropdown.clear()"
            data-ripple="true">Clear
          </button>
          <button
            class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block"
            type="submit"
            (click)="bulkEdit(projectForm)" [disabled]="formSent" data-ripple="true">Update Projects
          </button>
        </div>

      </form>

    </div>

    <div *ngSwitchCase="'company-role'" class="p-15 ">
      <h3 *ngIf="!projectRoleInput"> Add Company Role</h3>
      <h3 *ngIf="projectRoleInput"> Update Company Role</h3>

      <form id="form-company-role" [formGroup]="projectCompanyRoleForm" #formDir="ngForm" class="form-horizontal"
            novalidate=""
            style="margin-top: 16px;">

        <div class="form-group sf-outline-input mb-10">
          <ejs-dropdowntree #dropDownTree id='company_role_code' formControlName='company_role_code'
                            [fields]='companyRoleFields' placeholder='Company Role *' floatLabelType="Auto"
                            cssClass="e-filled"
                            (blur)="projectCompanyRoleForm.controls.company_role_code.markAsTouched()"></ejs-dropdowntree>
          <div *ngIf="projectCompanyRoleForm.controls.company_role_code.invalid">
            <div
              *ngIf="projectCompanyRoleForm.controls.company_role_code.errors.required && projectCompanyRoleForm.controls.company_role_code.touched "
              class="e-error">
              Please select a role
            </div>
          </div>
        </div>

        <div class="form-group sf-outline-input mb-10">
          <ejs-dropdownlist #company id='company_code' formControlName="company_code" [dataSource]='companyList'
                            [fields]='companyFields' [showClearButton]=true
                            placeholder='Company' [allowFiltering]='true' (filtering)='onFiltering($event, "company")'
                            cssClass="e-filled" floatLabelType="Auto" (change)="change($event)"
                            (blur)="projectCompanyRoleForm.controls.company_code.markAsTouched()">
          </ejs-dropdownlist>
          <div *ngIf="projectCompanyRoleForm.controls.company_code.invalid">
            <div
              *ngIf="projectCompanyRoleForm.controls.company_code.errors.required && projectCompanyRoleForm.controls.company_code.touched "
              class="e-error">
              Please select a company
            </div>
          </div>
          <div class="d-flex align-items-center">
            <span>Can't find who you're looking for?</span>
            <button
              class="btn btn-light btn-text-primary btn-hover-text-primary text-uppercase font-weight-bold mr-3 btn-sm p-3 ml-3 add-btn"
              (click)="addNewCompanyContact('company')">
              Add new Company
            </button>
          </div>
        </div>

        <div class="form-group sf-outline-input mb-10">
          <ejs-dropdownlist #contact id='contact_code' formControlName="contact_code" [dataSource]='contactList'
                            [fields]='contactFields' (focus)="focus($event)" [showClearButton]=true
                            placeholder='Contact' [allowFiltering]='true' (filtering)='onFiltering($event,"contact")'
                            cssClass="e-filled" floatLabelType="Auto" (change)="selectRoleContact($event)"
                            (blur)="projectCompanyRoleForm.controls.contact_code.markAsTouched()">
          </ejs-dropdownlist>
          <div *ngIf="projectCompanyRoleForm.controls.contact_code.invalid">
            <div
              *ngIf="projectCompanyRoleForm.controls.contact_code.errors.required && projectCompanyRoleForm.controls.contact_code.touched "
              class="e-error">
              Please select a contact
            </div>
          </div>
          <div class="d-flex align-items-center">
            <span>Can't find who you're looking for?</span>
            <button
              class="btn btn-light btn-text-primary btn-hover-text-primary text-uppercase font-weight-bold mr-3 btn-sm p-3 ml-3 add-btn"
              (click)="addNewCompanyContact('contact')">
              Add new Contact
            </button>
          </div>
        </div>
        <div *ngIf="projectCompanyRoleForm.errors" class="e-error">
          <div
            *ngIf="projectCompanyRoleForm.errors.atLeastOne && (projectCompanyRoleForm.controls.company_code.touched || projectCompanyRoleForm.controls.contact_code.touched)">
            Please select a company or contact
          </div>
        </div>
        <div *ngIf="projectCompanyRoleForm.invalid" class="e-error">Please fill all fields</div>
        <div *ngIf="error$ | async as error" class="e-error">{{error.error.message}}</div>
        <!--        <div *ngIf="error$ | async as error">{{error.error.message}}</div>-->

        <div class="my-10 pb-20">
          <button id="resetbtncompany"
                  class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5"
                  type="reset"
                  data-ripple="true">Clear
          </button>
          <button *ngIf="!projectRoleInput"
                  class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block"
                  type="submit"
                  (click)="onAddUpdateProjectCompanyRole(projectCompanyRoleForm, false)" [disabled]="formSent"
                  data-ripple="true">Add Company Role
          </button>
          <button *ngIf="projectRoleInput"
                  class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block"
                  type="submit"
                  (click)="onAddUpdateProjectCompanyRole(projectCompanyRoleForm, true)" [disabled]="formSent"
                  data-ripple="true">Update
            Company Role
          </button>
        </div>

      </form>

    </div>
  </ng-container>
</ejs-sidebar>
