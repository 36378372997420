import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {v4 as uuid} from 'uuid';
import {Observable} from 'rxjs';

import {ResponseData} from '../../ResponseData.interface';
import {environment} from '../../../../environments/environment.prod';

const CONTACT_URL = environment.API_URL + 'contact';
const CONTACT_ACTIVITY_URL = environment.API_URL + 'contact-activity';
const PROJECT_URL = environment.API_URL + 'project';
const COMPANY_URL = environment.API_URL + 'company';
const COMPANY_CONTACT_URL = environment.API_URL + 'company-contact';
const MERGE_CONTACT_URL = CONTACT_URL + '/' + 'merge';
const CONTACT_GENERAL_URL = environment.API_URL + 'contact-general';
const CONTACT_IMAGES_URL = environment.API_URL + 'contact-images';



@Injectable()
export class ContactService {
  id: string;

  constructor(private http: HttpClient) {
  }

  getContacts(parameters): Observable<ResponseData> {
    return this.http.get<ResponseData>(CONTACT_URL, {params: parameters});
  }

  addContact(first_name: string, last_name: string, email: string, company_code: string, designation: string,
             leadSource: string, tel_no: string, contact_tag: string, start_date: string, end_date: string, excerpt: string,
             website: string, slug: string, visibility: string, password_protected_password): Observable<ResponseData> {
    this.id = uuid();
    return this.http.post<ResponseData>(CONTACT_URL, {
      first_name, last_name, company_code, email, designation, lead_source: leadSource, contact_tag,
      tel_no, batch_id: this.id, start_date, end_date, excerpt, website, slug, visibility, password_protected_password
    });
  }

  updateContact(code: string, contact: {}): Observable<ResponseData> {
    this.id = uuid();
    const request = {...contact, batch_id: this.id};
    return this.http.put<ResponseData>(CONTACT_URL + '/' + code, request);
  }

  deleteContact(contactCode: string) {
    return this.http.delete<ResponseData>(CONTACT_URL + '/' + contactCode);
  }

  getFilteredResults(parameters) {
    return this.http.get<ResponseData>(CONTACT_URL + '/filter', {params: parameters});
  }

  getContact(contactCode: string): Observable<ResponseData> {
    return this.http.get<ResponseData>(CONTACT_URL + '/' + contactCode);
  }

  // getContactActivity(contactCode: string): Observable<ResponseData> {
  //   return this.http.get<ResponseData>(CONTACT_ACTIVITY_URL + '/' + contactCode);
  // }

  addActivity(contact_code: string, activityType: string, activityTitle: string, activityNote: string,
              meeting_date: string, meeting_start_time: string, meeting_end_time: string, user_code: string[],
              project_contact_code: string[], tags: string[]):
    Observable<ResponseData> {
    this.id = uuid();

    return this.http.post<ResponseData>(CONTACT_ACTIVITY_URL, {
      contact_code, activity_type_code: activityType, activity_note: activityNote,
      activity_title: activityTitle, meeting_date, meeting_start_time, meeting_end_time, user_code, project_contact_code,
      project_activity_tag: tags, batch_id: this.id
    });
  }

  getContactHistory(contactCode: string): Observable<ResponseData> {
    return this.http.get<ResponseData>(CONTACT_URL + '/' + contactCode + '/transaction-history');
  }

  getContactActivityHistory(contactCode: string): Observable<ResponseData> {
    return this.http.get<ResponseData>(CONTACT_ACTIVITY_URL + '/' + contactCode + '/transaction-history');
  }

  getProjects(parameters): Observable<ResponseData> {
    return this.http.get<ResponseData>(PROJECT_URL + '/filter', {params: parameters});
  }

  getSearchResults(parameters): Observable<ResponseData> {
    return this.http.get<ResponseData>(CONTACT_URL + '/search', {params: parameters});
  }

  getSearchedContact(parameters): Observable<ResponseData> {
    return this.http.get<ResponseData>(CONTACT_URL + '/search-side-bar', {params: parameters});
  }

  getAllCompanyContacts(parameters): Observable<ResponseData> {
    return this.http.get<ResponseData>(COMPANY_URL + '/get-contacts', {params: parameters});
  }

  getAllAssociatedCompanies(parameters): Observable<ResponseData> {
    return this.http.get<ResponseData>(COMPANY_CONTACT_URL, {params: parameters});
  }

  addAssociatedCompany(parameters): Observable<ResponseData> {
    this.id = uuid();
    parameters = {...parameters, batch_id: this.id};
    return this.http.post<ResponseData>(COMPANY_CONTACT_URL, parameters);
  }

  deleteAssociatedCompany(companyContactCode: string): Observable<ResponseData> {
    return this.http.delete<ResponseData>(COMPANY_CONTACT_URL + '/' + companyContactCode);
  }

  updateAssociatedCompany(code: string, associated_company: {}): Observable<ResponseData> {
    this.id = uuid();
    const request = {...associated_company, batch_id: this.id};
    return this.http.put<ResponseData>(COMPANY_CONTACT_URL + '/' + code, request);
  }

  getSingleAssociateContact(code): Observable<ResponseData> {
    return this.http.get<ResponseData>(COMPANY_CONTACT_URL + '/' + code);
  }

  mergeContact(selectedDetails): Observable<ResponseData> {
    this.id = uuid();
    const request = {...selectedDetails, batch_id: this.id};
    return this.http.post<ResponseData>(MERGE_CONTACT_URL, request);
  }

  loadContactGeneral(contact_code): Observable<ResponseData> {
    return this.http.get<ResponseData>(CONTACT_GENERAL_URL, {params: {contact_code}});
  }

  updateContactGeneral(code: string, description: string, social_media: any, external_tag, internal_tag): Observable<ResponseData> {
    this.id = uuid();
    const request = {description, social_media, external_tag, internal_tag, batch_id: this.id};
    return this.http.put<ResponseData>(CONTACT_GENERAL_URL + '/' + code, request);
  }

  updateContactImages(code: string, reference_id: string): Observable<ResponseData> {
    this.id = uuid();
    const request = {featured_image_reference_id: reference_id, batch_id: this.id};
    return this.http.put<ResponseData>(CONTACT_IMAGES_URL + '/' + code, request);
  }

  getContactSlug(parameters): Observable<ResponseData> {
    return this.http.get<ResponseData>(CONTACT_URL + '/generate-contact-slug', {params: parameters});
  }
}
