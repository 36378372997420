import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseComponent } from './views/theme/base/base.component';
import { AuthGuardService } from './core/_base/crud/utils/AuthGuard.service';
import {LayoutGuardService} from './core/_base/crud/utils/layout-guard.service';
import {Feature} from './core/_base/crud/utils/feature';


const routes: Routes = [
  {path: 'auth', loadChildren: () => import('./views/pages/authentication/auth.module').then(m => m.AuthModule)},
  {
    path: '',
    component: BaseComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/pages/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'company',
        loadChildren: () => import('./views/pages/company/company.module').then(m => m.CompanyModule),
        canActivate: [AuthGuardService, LayoutGuardService],
        data: {
          feature: [Feature.getInstance().COMPANY_LIST, Feature.getInstance().COMPANY]
        }
      },
      {
        path: 'contact',
        loadChildren: () => import('./views/pages/contact/contact.module').then(m => m.ContactModule),
        canActivate: [AuthGuardService, LayoutGuardService],
        data: {
          feature:[ Feature.getInstance().CONTACT_LIST, Feature.getInstance().CONTACT]
        }
      },
      {
        path: 'project',
        loadChildren: () => import('./views/pages/project/project.module').then(m => m.ProjectModule),
        canActivate: [AuthGuardService, LayoutGuardService],
        data: {
          feature: [Feature.getInstance().PROJECT_LIST, Feature.getInstance().PROJECT]
        }
      },
      {
        path: 'sources',
        loadChildren: () => import('./views/pages/project-source/project-source.module').then(m => m.ProjectSourceModule),
        canActivate: [AuthGuardService, LayoutGuardService],
        data: {
          feature: [Feature.getInstance().PROJECT_SOURCE, Feature.getInstance().PROJECT_SOURCE_LIST]
        }
      },
      {
        path: 'admin',
        loadChildren: () => import('./views/pages/admin/admin.module').then(m => m.AdminModule),
        canActivate: [AuthGuardService, LayoutGuardService],
        data: {
          feature: [Feature.getInstance().ADMIN_LIST]
        }
      },
      {
        path: 'orders',
        loadChildren: () => import('./views/pages/company/order/order.module').then(m => m.OrderModule),
        canActivate: [AuthGuardService, LayoutGuardService],
        data: {
          feature: [Feature.getInstance().ORDER_LIST, Feature.getInstance().ORDER]
        }
      },
      {
        path: 'deliverable',
        loadChildren: () => import('./views/pages/company/order/order-details/deliverable/deliverable.module').then(m => m.DeliverableModule),
        canActivate: [AuthGuardService, LayoutGuardService],
        data: {
          feature: [Feature.getInstance().DELIVERABLE_LIST, Feature.getInstance().DELIVERABLE]
        }
      },
      {
        path: 'profile',
        loadChildren: () => import('./views/pages/profile/profile.module').then(m => m.ProfileModule),
      },
      {
        path: 'release-notes',
        loadChildren: () => import('./views/partials/layout/release-notes/release-notes.module').then(m => m.ReleaseNotesModule),
        canActivate: [AuthGuardService],
      },
      {
        path: 'article',
        loadChildren: () => import('./views/pages/article/article.module').then(m => m.ArticleModule),
        canActivate: [AuthGuardService],
      },
      {
        path: 'email-log',
        loadChildren: () => import('./views/pages/email-log/email.module').then(m => m.EmailModule),
        canActivate: [AuthGuardService],
      },
      {
        path: 'assigned-projects',
        loadChildren: () => import('./views/pages/project/limited-projects/limitedAccess.module').then(m => m.LimitedAccessModule),
      },
      {
        path: 'log',
        loadChildren: () => import('./views/pages/logs/log.module').then(m => m.LogModule),
        canActivate: [AuthGuardService, LayoutGuardService]
      },

      {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
      {path: '**', redirectTo: 'dashboard', pathMatch: 'full'},
    ],
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
