import {Component, OnInit, AfterViewInit} from '@angular/core';
import {Observable} from 'rxjs';
import {LayoutService} from '../../../../core/_base/layout/services/layout.service';
// import { AuthService } from '../../../../modules/auth/_services/auth.service';
// import { UserModel } from '../../../../modules/auth/_models/user.model';
// import KTLayoutQuickSearch from '../../../../../assets/js/layout/extended/quick-search';
// import KTLayoutQuickNotifications from '../../../../../assets/js/layout/extended/quick-notifications';
// import KTLayoutQuickActions from '../../../../../assets/js/layout/extended/quick-actions';
// import KTLayoutQuickCartPanel from '../../../../../assets/js/layout/extended/quick-cart';
// import KTLayoutQuickPanel from '../../../../../assets/js/layout/extended/quick-panel';
import KTLayoutQuickUser from '../../../../../assets/js/layout/extended/quick-user';
import KTLayoutHeaderTopbar from '../../../../../assets/js/layout/base/header-topbar';
import {KTUtil} from '../../../../../assets/js/components/util';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../core';
import {User} from '../../../../core/models/user.model';
import {Router} from '@angular/router';
import {CompanyFilterReset, CompanySearch} from '../../../../core/store/actions/company.actions';
import {ProjectFilterReset, ProjectSearch} from '../../../../core/store/actions/project.actions';
import {ContactFilterReset, ContactSearch} from '../../../../core/store/actions/contact.actions';
import {ProjectSourceFilterReset, ProjectSourceSearch} from '../../../../core/store/actions/project-source.actions';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit, AfterViewInit {
  searchParams: string;
  user$: Observable<User>;
  // tobbar extras
  extraSearchDisplay: boolean;
  extrasSearchLayout: 'offcanvas' | 'dropdown';
  extrasNotificationsDisplay: boolean;
  extrasNotificationsLayout: 'offcanvas' | 'dropdown';
  extrasQuickActionsDisplay: boolean;
  extrasQuickActionsLayout: 'offcanvas' | 'dropdown';
  extrasCartDisplay: boolean;
  extrasCartLayout: 'offcanvas' | 'dropdown';
  extrasQuickPanelDisplay: boolean;
  extrasLanguagesDisplay: boolean;
  extrasUserDisplay: boolean;
  extrasUserLayout: 'offcanvas' | 'dropdown';

  constructor(private layout: LayoutService, private store: Store<AppState>, private router: Router) {
    this.user$ = this.store.pipe(select(store => store.auth.user));
  }

  ngOnInit(): void {
    // topbar extras
    this.extraSearchDisplay = this.layout.getProp('extras.search.display');
    this.extrasSearchLayout = this.layout.getProp('extras.search.layout');
    this.extrasNotificationsDisplay = this.layout.getProp(
      'extras.notifications.display'
    );
    this.extrasNotificationsLayout = this.layout.getProp(
      'extras.notifications.layout'
    );
    this.extrasQuickActionsDisplay = this.layout.getProp(
      'extras.quickActions.display'
    );
    this.extrasQuickActionsLayout = this.layout.getProp(
      'extras.quickActions.layout'
    );
    this.extrasCartDisplay = this.layout.getProp('extras.cart.display');
    this.extrasCartLayout = this.layout.getProp('extras.cart.layout');
    this.extrasLanguagesDisplay = this.layout.getProp(
      'extras.languages.display'
    );
    this.extrasUserDisplay = this.layout.getProp('extras.user.display');
    this.extrasUserLayout = this.layout.getProp('extras.user.layout');
    this.extrasQuickPanelDisplay = this.layout.getProp(
      'extras.quickPanel.display'
    );
  }

  ngAfterViewInit(): void {
    if (KTUtil) {
      KTUtil.ready(() => {
        // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
        // Add 'implements AfterViewInit' to the class.
        if (this.extraSearchDisplay && this.extrasSearchLayout === 'offcanvas') {
          // KTLayoutQuickSearch.init('kt_quick_search');
        }

        if (this.extrasNotificationsDisplay && this.extrasNotificationsLayout === 'offcanvas') {
          // Init Quick Notifications Offcanvas Panel
          // KTLayoutQuickNotifications.init('kt_quick_notifications');
        }

        if (
          this.extrasQuickActionsDisplay &&
          this.extrasQuickActionsLayout === 'offcanvas'
        ) {
          // Init Quick Actions Offcanvas Panel
          // KTLayoutQuickActions.init('kt_quick_actions');
        }

        if (this.extrasCartDisplay && this.extrasCartLayout === 'offcanvas') {
          // Init Quick Cart Panel
          // KTLayoutQuickCartPanel.init('kt_quick_cart');
        }

        if (this.extrasQuickPanelDisplay) {
          // Init Quick Offcanvas Panel
          // KTLayoutQuickPanel.init('kt_quick_panel');
        }

        if (this.extrasUserDisplay && this.extrasUserLayout === 'offcanvas') {
          // Init Quick User Panel
          KTLayoutQuickUser.init('kt_quick_user');
        }

        // Init Header Topbar For Mobile Mode
        KTLayoutHeaderTopbar.init('kt_header_mobile_topbar_toggle');
      });
    }
  }

  search() {
    if (this.router.url.indexOf('/company') > -1) {
      if (this.router.url.indexOf('/company/details') > -1) {
        this.router.navigate(['/company'], {state: {data: 'search'}});
      }
      this.searchParams.length === 0 ? this.store.dispatch(new CompanyFilterReset()) : this.store.dispatch(new CompanySearch({
        search: this.searchParams,
        page_no: 1,
        page_size: 100
      }));
    } else if (this.router.url.indexOf('/project') > -1) {
      if (this.router.url.indexOf('/project/details') > -1) {
        this.router.navigate(['/project'], {state: {data: 'search'}});
      }
      (this.searchParams.length === 0) ? this.store.dispatch(new ProjectFilterReset()) : this.store.dispatch(new ProjectSearch({
        search: this.searchParams,
        page_no: 1,
        page_size: 100
      }));
      // this.store.dispatch(new ProjectSearch({search: this.searchParams, page_no: 1, page_size: 100}));
    } else if (this.router.url.indexOf('/contact') > -1) {
      if (this.router.url.indexOf('/contact/details') > -1) {
        this.router.navigate(['/contact'], {state: {data: 'search'}});
      }
      (this.searchParams.length === 0) ? this.store.dispatch(new ContactFilterReset()) : this.store.dispatch(new ContactSearch({
        search: this.searchParams,
        page_no: 1,
        page_size: 100
      }));
    } else if (this.router.url.indexOf('/sources') > -1) {
      (this.searchParams.length === 0) ? this.store.dispatch(new ProjectSourceFilterReset()) : this.store.dispatch(new ProjectSourceSearch({
        search: this.searchParams,
        page_no: 1,
        page_size: 100
      }));
    }
  }

  isVisibleSearchBox(): boolean {
    return (this.router.url.indexOf('/dashboard') > -1 || this.router.url.indexOf('/admin') > -1 ||
      this.router.url.indexOf('/assigned-projects') > -1);
  }
}
