import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output, QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {SidebarComponent} from '@syncfusion/ej2-angular-navigations';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../../core';
import {fromToDate, telephoneValidator, trimValidator} from '../../../../../../app-validators';
import {
  AddCompanyOrderDeliverable,
  AddCompanyOrderLineItem,
  AddDeliverableNotes,
  GetCompanyOrderLineItem,
  GetDeliverableNotes,
  GetSingleCompanyOrder,
  UpdateCompanyOrderDeliverable,
  UpdateCompanyOrderLineItem
} from '../../../../../../core/store/actions/company.actions';
import * as moment from 'moment';
import {Router} from '@angular/router';
import {DropDownListComponent} from '@syncfusion/ej2-angular-dropdowns';
import {GridComponent} from '@syncfusion/ej2-angular-grids';
import * as _ from 'lodash';
import {v4 as uuid} from 'uuid';
import {AdminService} from '../../../../../../core/store/services/admin.service';
import {CompanyService} from '../../../../../../core/store/services/company.service';
import {RadioButtonComponent} from '@syncfusion/ej2-angular-buttons';
import { ButtonPropsModel, DialogComponent } from '@syncfusion/ej2-angular-popups';


@Component({
  selector: 'app-deliverable-form',
  templateUrl: './deliverable.component.html',
  styleUrls: ['./deliverable.component.scss']
})
export class DeliverableComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('orderLineItemDropdown') public orderLineItemDropdown: DropDownListComponent;
  @ViewChild('sidebarInstance') public sidebarInstance: SidebarComponent;
  @ViewChild('grid') public grid: GridComponent;
  @ViewChildren('deliverableDateOwner') divs: QueryList<DropDownListComponent>;
  @ViewChild('endDateModal') public endDateModal: DialogComponent;


  @Input() deliverableItems: any;
  @Input() lineItems: any;
  @Input() costCenter: any;
  @Input() deliverableStatus: any;
  @Input() users: any;
  @Input() companyOrderCode: string;
  @Output() valueChange = new EventEmitter();

  deliverableForm: FormGroup;
  deliverableNoteForm: FormGroup;
  deliverableItemDateForm: FormGroup;

  formSent: boolean;
  disabledState = false;
  seletedDeliverableCode: string;
  notesData: any;

  public width = '950px';
  public position = 'Right';
  isAddDeliverable = false;
  showRelative = false;

  notes: any;
  error$: Observable<any>;
  successOrderLineItem$: Observable<boolean>;
  successFormType$: Observable<string>;
  noteForm: string;
  companyName = '';
  deliverableItemName = '';

  selectedTab = 'deliverable';
  deliverableItemDates = [];
  deliverableItemDatesCopy = [];
  notesMandatory = false;
  statusNotesMandatory = false;

  maxVal = 90;
  minVal = -90;

  calendarView = '';
  dateSelection = false;
  relativeSelection = false;
  isUpdate = true;

  public userFields: object = {value: 'user_code', text: 'first_name'};
  public orderLineItemFields: object = {value: 'order_line_item_code', text: 'name'};
  public deliverableItemFields: object;
  public costCenterFields: object = {value: 'cost_center_code', text: 'cost_center_name'};
  public deliverableItemDateFields: object = {value: 'deliverable_item_date_code', text: 'deliverable_item_date_name'};

  public deliverableStatusFields: object;
  _deliverableSubscription$: any;
  _deliverableLineItemSubscription$: any;
  beforeUpdateDateValues: any;

  changeRelativeFields = false;
  setDateFields = false;
  isAddOperation = false;

  public _deliverableInput$: BehaviorSubject<any | null> = new BehaviorSubject<any | null>(null);

  public dlgButtons: ButtonPropsModel[] = [
    {click: this.updateEndDate.bind(this), buttonModel: {content: 'Yes', isPrimary: true, }},
    {click: this.hideEndDateModal.bind(this), buttonModel: {content: 'Cancel', isPrimary: false}}];

  // @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
  //   if (this.sidebarInstance.isOpen) {
  //     this.closeSidebar();
  //   }
  // }

  @Input() set deliverableInput(mode: any | null) {
    this._deliverableInput$.next(mode);
  }

  get deliverableInput(): any | null {
    return this._deliverableInput$.getValue();
  }


  constructor(private formBuilder: FormBuilder, private store: Store<AppState>, private router: Router, private adminService: AdminService,
              private companyService: CompanyService) {
  }

  ngOnInit(): void {
    this.generateDeliverableForm();
    this.generateDeliverableNoteForm();
    this.generateDeliverableItemDateForm();


    this.successOrderLineItem$ = this.store.pipe(select(store => store.companies.success.isLoaded));
    // this.successOrderLineItem$.subscribe((data: any) => {
    //   if (data) {
    //     this.formSent = false;
    //     this.closeSidebar();
    //   }
    // });

    this.successFormType$ = this.store.pipe(select(store => store.companies.success.isForm));
    this.successFormType$.subscribe((data: any) => {
      if (data !== 'LOAD_COMPANY' && data !== '' && data !== 'GETTING_NOTES' && data !== 'ADDED_NOTES') {
        this.seletedDeliverableCode = data;
        if (this.noteForm && this.noteForm.trim() !== '' && this.seletedDeliverableCode !== '' && this.isAddDeliverable === true) {
          if (this.seletedDeliverableCode !== 'UPDATED_DELIVERABLE') {
            this.onAddDeliverableNote(this.seletedDeliverableCode, this.noteForm);
          }
        } else {
          this.formSent = false;
          this.closeSidebar();
          this.isAddDeliverable = false;
        }
      } else if (data === 'ADDED_NOTES') {
        this.formSent = false;
        this.closeSidebar();
      }
    });

    this.error$ = this.store.pipe(select(store => store.companies.error));
    this.error$.subscribe((data: any) => {
      if (data) {
        this.formSent = false;
      }
    });
  }

  generateDeliverableForm() {
    this.deliverableForm = this.formBuilder.group({
      order_line_item_code: new FormControl(''),
      deliverable_item_code: new FormControl('', [Validators.required]),
      cost_center_code: new FormControl('', [Validators.required]),
      deliverable_status_code: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.maxLength(250)]),
      start_date: new FormControl('', [Validators.required]),
      end_date: new FormControl(''),
      expiry_date: new FormControl(''),
      deliverable_owner_code: new FormControl('', [Validators.required]),
      is_external_provider_yn: new FormControl(false),
    }, {validators: [fromToDate('start_date', 'end_date'), fromToDate('start_date', 'expiry_date', 'expiry_date')]});
  }


  generateDeliverableNoteForm() {
    this.deliverableNoteForm = this.formBuilder.group({
      note: new FormControl(''),
    });
  }

  generateDeliverableItemDateForm() {
    this.deliverableItemDateForm = this.formBuilder.group({
      // shoot_date: new FormControl(''),
      publishing_date: new FormControl(null, Validators.required),
      date_selection_publishing_date: new FormControl(false),
      relative_selection_publishing_date: new FormControl(false),
      relative_days_publishing_date: new FormControl(null),
      relative_to_publishing_date: new FormControl(null),
      deliverable_date_owner_code_publishing_date: new FormControl(null),
      // microsite_submission: new FormControl(''),
      // client_approval_deadline: new FormControl(''),
      // video_submission_to_client: new FormControl(''),
      // article_submission_to_client: new FormControl(''),
      // client_content_receipt_deadline: new FormControl(''),
      is_tentative_publishing_date: new FormControl(false),
      // is_tentative_shoot_date: new FormControl(false),
      // is_tentative_microsite_submission: new FormControl(false),
      // is_tentative_client_content_receipt_deadline: new FormControl(false),
      // is_tentative_article_submission_to_client: new FormControl(false),
      // is_tentative_video_submission_to_client: new FormControl(false),
      // is_tentative_client_approval_deadline: new FormControl(false)
    });
  }

  ngOnDestroy() {
    // this._deliverableInput$.unsubscribe();
    this._deliverableInput$.complete();
    if (this._deliverableSubscription$) {
      this._deliverableSubscription$.complete();
    }

    if (this._deliverableLineItemSubscription$) {
      this._deliverableLineItemSubscription$.complete();
    }


  }

  setTab(args) {
    this.selectedTab = args;
  }

  openSidebar(calendar?): void {
    this.sidebarInstance.show();

    if (calendar) {
      this.calendarView = calendar;
    }
  }

  closeSidebar(): void {
    if (this.sidebarInstance) {
      this.sidebarInstance.hide();
    }

    if (this.calendarView !== '') {
      this.valueChange.emit(this.calendarView);
    } else {
      this.valueChange.emit('success');
    }

    this.deliverableForm.reset();
    this.deliverableNoteForm.reset();
    this.deliverableItemDateForm.enable();
    this.deliverableItemDateForm.reset();
    this.removeFormControls();
    this.noteForm = null;
    this.selectedTab = 'deliverable';

    this.calendarView = '';
    this.changeRelativeFields = false;
    this.setDateFields = false;

  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.deliverableItems) {
      this.deliverableItemFields = {
        value: 'deliverable_item_code',
        text: 'deliverable_item_name',
        dataSource: this.deliverableItems,
        child: 'children'
      };
    }

    if (changes.deliverableStatus) {
      this.deliverableStatusFields = {
        value: 'deliverable_status_code',
        text: 'deliverable_status_name',
        dataSource: this.deliverableStatus,
        child: 'children'
      };
    }

    let firstTime = false;
    let deliverableCopy;
    deliverableCopy = _.cloneDeep(this._deliverableInput$.getValue());

    if (this._deliverableInput$.getValue() && !this.isOrderDetailsLink()) {
      // const deliverableCopy = {...this._deliverableInput$.getValue()};
      deliverableCopy = _.cloneDeep(this._deliverableInput$.getValue());
      this.store.dispatch(new GetSingleCompanyOrder({
        company_code: deliverableCopy.company_order.company[0].company_code,
        company_order_code: deliverableCopy.company_order.company_order_code
      }));

      const subscribeVar = this.store.select(store => store.companies.orderDetails);
      this._deliverableSubscription$ = subscribeVar.subscribe((data) => {
        if (data && !firstTime) {
          this.store.dispatch(new GetCompanyOrderLineItem({
            company_order_code: deliverableCopy.company_order.company_order_code,
            page_size: 100, page_no: 1
          }));
          firstTime = true;

          const lineItemVar = this.store.select(store => store.companies.orderDetails.lineItem);
          this._deliverableLineItemSubscription$ = lineItemVar.subscribe((data: any) => {
            if (data && data.result) {
              const lineItemArray = [];
              for (const orderLineItem of data.result) {
                const orderLineItemCopy = {...orderLineItem};
                if (orderLineItemCopy.description) {
                  orderLineItemCopy.name = orderLineItemCopy.order_item.order_item_name + ' - ' + orderLineItemCopy.description;
                } else {
                  orderLineItemCopy.name = orderLineItemCopy.order_item.order_item_name;
                }
                lineItemArray.push(orderLineItemCopy);
              }
              this.orderLineItemDropdown.dataSource = lineItemArray;
              // this.lineItems = lineItemArray;

              if (deliverableCopy.order_line_item && deliverableCopy.order_line_item.order_line_item_code) {
                this.deliverableForm.controls.order_line_item_code.setValue(deliverableCopy.order_line_item.order_line_item_code);
              } else {
                this.deliverableForm.controls.order_line_item_code.setValue(null);
              }
            }
          });
        }
      });
    }


    if (this._deliverableInput$.getValue()) {
      this.isUpdate = true;
      this.companyName = this._deliverableInput$.getValue().company_order.company[0].company_name;
      this.deliverableItemName = this._deliverableInput$.getValue().deliverable_item.deliverable_item_name;
      this.loadDeliverableNotes();


      this.deliverableForm.setValue({
        deliverable_item_code: this._deliverableInput$.getValue().deliverable_item ? [this._deliverableInput$.getValue().deliverable_item.deliverable_item_code] : null,
        cost_center_code: this._deliverableInput$.getValue().cost_center ? this._deliverableInput$.getValue().cost_center.cost_center_code : null,
        deliverable_status_code: this._deliverableInput$.getValue().deliverable_status ? [this._deliverableInput$.getValue().deliverable_status.deliverable_status_code] : null,
        deliverable_owner_code: this._deliverableInput$.getValue().deliverable_owner ? this._deliverableInput$.getValue().deliverable_owner.user_code : null,
        order_line_item_code: this._deliverableInput$.getValue().order_line_item ? this._deliverableInput$.getValue().order_line_item.order_line_item_code : null,
        description: this._deliverableInput$.getValue().description ? this._deliverableInput$.getValue().description : null,
        start_date: this._deliverableInput$.getValue().start_date ? moment(this._deliverableInput$.getValue().start_date, 'YYYY-MM-DD').toDate() : null,
        end_date: this._deliverableInput$.getValue().end_date ? moment(this._deliverableInput$.getValue().end_date, 'YYYY-MM-DD').toDate() : null,
        is_external_provider_yn: this._deliverableInput$.getValue().is_external_provider_yn === 1,
        expiry_date: this._deliverableInput$.getValue().expiry_date ? moment(this._deliverableInput$.getValue().expiry_date, 'YYYY-MM-DD').toDate() : null,
      });

      if (this._deliverableInput$.getValue().deliverable_item_date) {
        this.beforeUpdateDateValues = this._deliverableInput$.getValue().deliverable_item_date;
        for (const deliverable_date of this._deliverableInput$.getValue().deliverable_item_date) {
          const key = deliverable_date.deliverable_item_date_code.replace(/-/g, '_');

          this.deliverableItemDateForm.addControl(key, new FormControl(''));
          this.deliverableItemDateForm.addControl('is_tentative_' + key, new FormControl(false));
          this.deliverableItemDateForm.addControl('relative_selection_' + key, new FormControl(false));
          this.deliverableItemDateForm.addControl('date_selection_' + key, new FormControl(false));
          this.deliverableItemDateForm.addControl('relative_to_' + key, new FormControl(null));
          this.deliverableItemDateForm.addControl('relative_days_' + key, new FormControl(null));
          this.deliverableItemDateForm.addControl('deliverable_date_owner_code_' + key, new FormControl(null));


          if (deliverable_date.relative_to) {
            this.deliverableItemDateForm.controls['relative_days_' + key].setValue(deliverable_date.relative_days);
            this.deliverableItemDateForm.controls['relative_to_' + key].setValue(deliverable_date.relative_to.deliverable_item_date_code);
            this.deliverableItemDateForm.controls['relative_selection_' + key].setValue('');

            this.relativeSelection = true;
          } else {
            this.dateSelection = true;
            this.deliverableItemDateForm.controls['date_selection_' + key].setValue('');

          }

          if (deliverable_date.deliverable_date_owner) {
            this.deliverableItemDateForm.controls['deliverable_date_owner_code_' + key].setValue(deliverable_date.deliverable_date_owner.user_code);
          }


          // this.deliverableItemDateForm.controls['relative_selection_' + key].setValue(deliverable_date.relative_days !== null);
          // this.deliverableItemDateForm.controls['date_selection_' + key].setValue(deliverable_date.relative_days === null);


          this.deliverableItemDateForm.controls[key].setValue(moment(deliverable_date.deliverable_date, 'YYYY-MM-DD HH:mm:ss').toDate());

          const tentativeKey = 'is_tentative_' + key.toString();
          this.deliverableItemDateForm.controls[tentativeKey].setValue(deliverable_date.is_tentative_yn === 0);

        }

        // this.isUpdate = false;
      }

    }
  }

  onAddDeliverable(form: FormGroup, noteForm: FormGroup, skipEndDateCheck = false) {
    let isModalOpen = false;
    if (!skipEndDateCheck &&!form.invalid && !noteForm.invalid && !this.deliverableItemDateForm.invalid) {
      this.isAddOperation = true;
      isModalOpen =  this.showEndDatePopup(this.deliverableForm.value.end_date, this.deliverableItemDateForm.value.publishing_date);
    }

    this.noteForm = noteForm.value.note;

    const deliverableItemDates = _.clone(this.deliverableItemDateForm.getRawValue());
    const deliverableDates = [];
    for (const deliverableItemDatesKey in deliverableItemDates) {
      const dateData = {
        deliverable_item_date_code: null,
        deliverable_item_date: null,
        is_tentative: null,
        relative_days: null,
        relative_to: null,
        deliverable_date_owner_code: null
      };


      if (deliverableItemDates[deliverableItemDatesKey] !== null && deliverableItemDates[deliverableItemDatesKey] !== ''
        && !deliverableItemDatesKey.includes('is_tentative') && !deliverableItemDatesKey.includes('relative_')
        && !deliverableItemDatesKey.includes('date_selection_') && !deliverableItemDatesKey.includes('deliverable_date_owner_')) {
        const newDeliverableItemDatesKey = deliverableItemDatesKey.replace(/_/g, '-');
        dateData.deliverable_item_date_code = newDeliverableItemDatesKey;
        dateData.deliverable_item_date = moment(deliverableItemDates[deliverableItemDatesKey]).format('YYYY-MM-DD HH:mm:ss');

        const tentativeKeyword = 'is_tentative_' + deliverableItemDatesKey.toString();
        dateData.is_tentative = deliverableItemDates[tentativeKeyword] === true ? 0 : 1;
        dateData.deliverable_date_owner_code = deliverableItemDates['deliverable_date_owner_code_' + deliverableItemDatesKey.toString()];


        if (deliverableItemDates['relative_selection_' + deliverableItemDatesKey] !== false) {
          dateData.relative_days = deliverableItemDates['relative_days_' + deliverableItemDatesKey.toString()];
          dateData.relative_to = deliverableItemDates['relative_to_' + deliverableItemDatesKey.toString()];
        }


        // dateData.is_tentative = !deliverableItemDates[deliverableItemDatesKey] || deliverableItemDates[deliverableItemDatesKey] === false ? 0 : 1;
        // delete deliverableItemDates[deliverableItemDatesKey];

        deliverableDates.push(dateData);

      }
    }


    if (!form.invalid && !this.deliverableItemDateForm.invalid && !isModalOpen) {
      this.isAddDeliverable = true;
      this.formSent = true;
      this.store.dispatch(new AddCompanyOrderDeliverable({
        deliverable_item_code: _.cloneDeep(this.deliverableForm.value.deliverable_item_code),
        cost_center_code: this.deliverableForm.value.cost_center_code,
        company_order_code: this.companyOrderCode,
        description: this.deliverableForm.value.description,
        deliverable_status_code: this.deliverableForm.value.deliverable_status_code,
        start_date: this.deliverableForm.value.start_date ? moment(this.deliverableForm.value.start_date).format('YYYY-MM-DD') : null,
        end_date: this.deliverableForm.value.end_date ? moment(this.deliverableForm.value.end_date).format('YYYY-MM-DD') : null,
        expiry_date: this.deliverableForm.value.expiry_date ? moment(this.deliverableForm.value.expiry_date).format('YYYY-MM-DD') : null,
        deliverable_owner_code: this.deliverableForm.value.deliverable_owner_code,
        order_line_item_code: this.deliverableForm.value.order_line_item_code,
        is_external_provider_yn: this.deliverableForm.value.is_external_provider_yn === false ? 0 : 1,
        deliverable_item_dates: deliverableDates
      }));
    }
  }

  onUpdateDeliverable(form: FormGroup, noteForm: FormGroup, skipEndDateCheck = false) {
  let isModalOpen = false;
    if (!skipEndDateCheck &&!form.invalid && !noteForm.invalid && !this.deliverableItemDateForm.invalid) {
      this.isAddOperation = false;
      isModalOpen =  this.showEndDatePopup(this.deliverableForm.value.end_date, this.deliverableItemDateForm.value.publishing_date);
    }
    this.noteForm = noteForm.value.note;
    let page = '';
    if (!this.isOrderDetailsLink()) {
      page = 'order';
    } else {
      page = 'company-order';
    }

    const deliverablePage = this.isOrderDetailsLink() ? 'order' : 'deliverable';

    const deliverableItemDates = _.cloneDeep(this.deliverableItemDateForm.getRawValue());

    let deliverableDates = [];
    for (const deliverableItemDatesKey in deliverableItemDates) {
      const dateData = {
        deliverable_item_date_code: null,
        deliverable_item_date: null,
        is_tentative: null,
        relative_days: null,
        relative_to: null,
        deliverable_date_owner_code: null
      };

      if (deliverableItemDates[deliverableItemDatesKey] !== null && deliverableItemDates[deliverableItemDatesKey] !== '' && !deliverableItemDatesKey.includes('is_tentative')) {
        const newDeliverableItemDatesKey = deliverableItemDatesKey.replace(/_/g, '-');
        if (this.deliverableItemDates.find(item => item.deliverable_item_date_code === newDeliverableItemDatesKey) || newDeliverableItemDatesKey === 'publishing-date') {
          dateData.deliverable_item_date_code = newDeliverableItemDatesKey;
          dateData.deliverable_item_date = moment(deliverableItemDates[deliverableItemDatesKey]).format('YYYY-MM-DD HH:mm:ss');

          const tentativeKeyword = 'is_tentative_' + deliverableItemDatesKey.toString();
          dateData.is_tentative = deliverableItemDates[tentativeKeyword] === true ? 0 : 1;
          dateData.deliverable_date_owner_code = deliverableItemDates['deliverable_date_owner_code_' + deliverableItemDatesKey.toString()];

          if (deliverableItemDates['relative_selection_' + deliverableItemDatesKey] !== false) {
            dateData.relative_days = deliverableItemDates['relative_days_' + deliverableItemDatesKey.toString()];
            dateData.relative_to = deliverableItemDates['relative_to_' + deliverableItemDatesKey.toString()];
          }


          // dateData.is_tentative = !deliverableItemDates[deliverableItemDatesKey] || deliverableItemDates[deliverableItemDatesKey] === false ? 0 : 1;
          // delete deliverableItemDates[deliverableItemDatesKey];

          deliverableDates.push(dateData);
        }


      }

      // deliverableDates.push({deliverable_item_date_code: 'publishing-date', is_tentative: deliverableItemDates[tentativeKeyword] === true ? 0 : 1;})
    }

    deliverableDates = deliverableDates.filter((deliverableDate) => deliverableDate.deliverable_item_date !== 'Invalid date');

    if (!form.invalid && !noteForm.invalid && !this.deliverableItemDateForm.invalid && !isModalOpen) {
      this.formSent = true;
      let request: any;
      request = {
        deliverable_item_code: _.cloneDeep(this.deliverableForm.value.deliverable_item_code),
        cost_center_code: this.deliverableForm.value.cost_center_code,
        deliverable_code: this._deliverableInput$.getValue().deliverable_code,
        description: this.deliverableForm.value.description,
        deliverable_status_code: this.deliverableForm.value.deliverable_status_code,
        start_date: this.deliverableForm.value.start_date ? moment(this.deliverableForm.value.start_date).format('YYYY-MM-DD') : null,
        end_date: this.deliverableForm.value.end_date ? moment(this.deliverableForm.value.end_date).format('YYYY-MM-DD') : null,
        expiry_date: this.deliverableForm.value.expiry_date ? moment(this.deliverableForm.value.expiry_date).format('YYYY-MM-DD') : null,
        deliverable_owner_code: this.deliverableForm.value.deliverable_owner_code,
        order_line_item_code: this.deliverableForm.value.order_line_item_code,
        is_external_provider_yn: this.deliverableForm.value.is_external_provider_yn === false ? 0 : 1,
        deliverable_item_dates: deliverableDates,
        page
      };

      if (this.noteForm) {
        request = {
          ...request, deliverable_note: {
            deliverable_note: this.noteForm,
            deliverable_code: this._deliverableInput$.getValue().deliverable_code, deliverablePage, batch_id: uuid()
          }
        };
      }
      this.store.dispatch(new UpdateCompanyOrderDeliverable(request));


    }
  }

  onAddDeliverableNote(deliverableCode, note: string) {
    if (this.isAddDeliverable) {
      this.formSent = true;
      this.store.dispatch(new AddDeliverableNotes({
        note,
        deliverable_code: deliverableCode, page: 'order'
      }));
    }
  }

  loadDeliverableNotes() {
    const page = this.isOrderDetailsLink() ? 'order' : 'deliverable';
    // this.store.dispatch(new GetDeliverableNotes({
    //   deliverable_code: this._deliverableInput$.getValue().deliverable_code,
    //   page_size: 100, page_no: 1, page
    // }));

    let storeSelect;
    if (this.isOrderDetailsLink()) {
      storeSelect = this.store.select(store => store.companies.orderDetails.deliverable);
    } else {
      storeSelect = this.store.select(store => store.companies.deliverables);
    }

    storeSelect.subscribe((data: any) => {
      if (data && data.result && this._deliverableInput$.getValue()) {
        const deliverableIndex = data.result.findIndex(x => x.deliverable_code === this._deliverableInput$.getValue().deliverable_code);
        const deliverable = data.result[deliverableIndex];

        if (deliverable && deliverable.deliverable_notes) {
          this.notesData = deliverable.deliverable_notes;
          // this.grid.dataSource = this.notesData;
          this.notes = of(deliverable.deliverable_notes);
        } else {
          this.notesData = [];
          this.notes = of([]);
        }
      }
    });
  }

  isCreated() {
    this.grid.dataSource = this.notesData;
  }

  onSelectStatus(args, form: FormGroup, noteForm: FormGroup) {
    if (this._deliverableInput$.getValue() && this._deliverableInput$.getValue().deliverable_status.deliverable_status_code !== args.value[0]) {
      noteForm.controls.note.setValidators([Validators.required]);
      noteForm.controls.note.markAsTouched();
      noteForm.controls.note.updateValueAndValidity();
      this.statusNotesMandatory = true;
    } else if (this._deliverableInput$.getValue() && this._deliverableInput$.getValue().deliverable_status.deliverable_status_code === args.value[0]) {
      noteForm.controls.note.clearValidators();
      noteForm.controls.note.updateValueAndValidity();
      this.statusNotesMandatory = false;
    }
  }

  isOrderDetailsLink(): boolean {
    return (this.router.url.indexOf('/orders/') > -1);
  }

  selectDeliverableItem(args) {
    if (args.value && args.value.length > 0) {
      this.adminService.getDeliverableDates(args.value).subscribe((data) => {
        if (data && data.data && data.data.deliverable_item_date) {
          this.deliverableItemDates = _.orderBy(data.data.deliverable_item_date, 'rank_order', 'asc');
          this.deliverableItemDatesCopy = _.cloneDeep(this.deliverableItemDates);
          this.deliverableItemDatesCopy.push({
            deliverable_item_date_code: 'publishing-date',
            deliverable_item_date_name: 'Publishing Date'
          });
          this.deliverableItemDatesCopy = _.uniqBy(this.deliverableItemDatesCopy, 'deliverable_item_date_code');
        }
      });
    } else {
      this.deliverableItemDates = [];
    }
  }

  findDateFromArray(date) {
    const deliverableFound = this.deliverableItemDates.find(item => item.deliverable_item_date_code === date);
    if (deliverableFound && deliverableFound.deliverable_item_date_code !== 'publishing-date') {
      this.deliverableItemDateForm.addControl(deliverableFound.deliverable_item_date_code.toString().replace(/-/g, '_'), new FormControl(''));
      this.deliverableItemDateForm.addControl('is_tentative_' + deliverableFound.deliverable_item_date_code.toString().replace(/-/g, '_'), new FormControl(false));
      this.deliverableItemDateForm.addControl('relative_selection_' + deliverableFound.deliverable_item_date_code.toString().replace(/-/g, '_'), new FormControl(false));
      this.deliverableItemDateForm.addControl('date_selection_' + deliverableFound.deliverable_item_date_code.toString().replace(/-/g, '_'), new FormControl(true));
      this.deliverableItemDateForm.addControl('relative_to_' + deliverableFound.deliverable_item_date_code.toString().replace(/-/g, '_'), new FormControl(null));
      this.deliverableItemDateForm.addControl('relative_days_' + deliverableFound.deliverable_item_date_code.toString().replace(/-/g, '_'), new FormControl(null));
      this.deliverableItemDateForm.addControl('deliverable_date_owner_code_' + deliverableFound.deliverable_item_date_code.toString().replace(/-/g, '_'), new FormControl(null));

      this.isUpdate = false;
      return true;
    } else {
      return false;
    }
  }

  strReplace(text) {
    if (text !== null && text !== undefined) {
      return text.toString().replace(/-/g, '_');
    }
  }

  onDateChange(args, formControl: string, noteForm: FormGroup, form: FormGroup) {
    if (this._deliverableInput$.getValue()) {
      this.deliverableItemDateForm.controls[formControl].setValue(args.value);


      if (this._deliverableInput$.getValue().deliverable_item_date) {
        for (const deliverableItemDate of this._deliverableInput$.getValue().deliverable_item_date) {

          for (const formControlKey in this.deliverableItemDateForm.value) {
            if (!formControlKey.includes('is_tentative')) {
              const deliverableItemDateCode = deliverableItemDate.deliverable_item_date_code.replace(/-/g, '_');

              if (deliverableItemDateCode === formControlKey) {
                const selectedDate = this.deliverableItemDateForm.value[formControlKey] ? moment(form.value[formControlKey]).format('YYYY-MM-DD HH:mm:ss') : null;

                if (deliverableItemDate.deliverable_date !== selectedDate) {
                  console.log(selectedDate);
                  console.log(deliverableItemDate.deliverable_date)

                  noteForm.controls.note.setValidators([Validators.required]);
                  noteForm.controls.note.markAsTouched();
                  noteForm.controls.note.updateValueAndValidity();
                  this.notesMandatory = true;
                  break;
                } else {
                  this.notesMandatory = false;
                }
              }
            }
          }

          if (this.notesMandatory) {
            break;
          }
        }
        if (!this.notesMandatory && !this.statusNotesMandatory && !this.changeRelativeFields) {
          console.log("LINE 713");
          
          
          noteForm.controls.note.clearValidators();
          noteForm.controls.note.updateValueAndValidity();
        }
      } else {
        noteForm.controls.note.setValidators([Validators.required]);
        noteForm.controls.note.markAsTouched();
        noteForm.controls.note.updateValueAndValidity();
        this.notesMandatory = true;
      }


      // const selectedForm = this._deliverableInput$.getValue().deliverable_item_date.find(item => item.deliverable_item_date_code === formControl);
      // form.controls[formControl].updateValueAndValidity();
      // form.

    } else if (args && args.value) {
      this.deliverableItemDateForm.controls[formControl].setValue(args.value);
      // this.deliverableItemDateForm.controls['date_selection_' + formControl].setValue('');
    }


    if (this.deliverableItemDateForm.controls['relative_selection_' + formControl].value === false) {
      this.deliverableItemDateForm.controls['date_selection_' + formControl].setValue('');
    }
    this.setRelativeDate(formControl, this.deliverableItemDateForm);
  }

  setNotesMandatory(notesMandatory: boolean, noteForm: FormGroup) {

    if (notesMandatory) {
      noteForm.controls.note.setValidators([Validators.required]);
      noteForm.controls.note.markAsTouched();
      noteForm.controls.note.updateValueAndValidity();
    } else {
      noteForm.controls.note.clearValidators();
      noteForm.controls.note.updateValueAndValidity();
    }
  }

  disableFields(args, buttonType: string, deliverableItemDate: string, form: FormGroup) {
    if (args) {
      if (buttonType === 'relativeSelection') {
        form.controls[this.strReplace(deliverableItemDate)].disable();
        form.controls['date_selection_' + this.strReplace(deliverableItemDate)].setValue(false);
        // form.controls['relative_selection_' + this.strReplace(deliverableItemDate)].setValue('');

        form.controls[this.strReplace(deliverableItemDate)].clearValidators();
        form.controls['relative_to_' + this.strReplace(deliverableItemDate)].setValidators([Validators.required]);
        form.controls['relative_to_' + this.strReplace(deliverableItemDate)].markAsTouched();

        form.controls['relative_days_' + this.strReplace(deliverableItemDate)].setValidators(Validators.required);
        form.controls['relative_days_' + this.strReplace(deliverableItemDate)].markAsTouched();


        // this.dateSelection = false;
        // this.relativeSelection = true;

        form.controls['relative_to_' + this.strReplace(deliverableItemDate)].enable();
        form.controls['relative_days_' + this.strReplace(deliverableItemDate)].enable();


        if (!this.isUpdate) {
          this.refreshFieldValidation(form);
        }
      } else {
        form.controls['relative_days_' + this.strReplace(deliverableItemDate)].setValue(null);
        form.controls['relative_to_' + this.strReplace(deliverableItemDate)].setValue(null);

        form.controls['relative_to_' + this.strReplace(deliverableItemDate)].clearValidators();
        form.controls['relative_days_' + this.strReplace(deliverableItemDate)].clearValidators();

        form.controls[this.strReplace(deliverableItemDate)].setValidators([Validators.required]);
        form.controls[this.strReplace(deliverableItemDate)].markAsTouched();

        // form.controls['relative_days_' + this.strReplace(deliverableItemDate)].updateValueAndValidity();
        // form.controls['relative_to_' + this.strReplace(deliverableItemDate)].updateValueAndValidity();

        form.controls['relative_to_' + this.strReplace(deliverableItemDate)].disable();
        form.controls['relative_days_' + this.strReplace(deliverableItemDate)].disable();

        // this.dateSelection = true;
        // this.relativeSelection = false;

        form.controls['relative_selection_' + this.strReplace(deliverableItemDate)].setValue(false);
        // form.controls['date_selection_' + this.strReplace(deliverableItemDate)].setValue('');

        form.controls[this.strReplace(deliverableItemDate)].enable();

        if (!this.isUpdate) {
          this.refreshFieldValidation(form);
        }

      }
    }
  }

  setRelativeTo(args, form: FormGroup, fieldName: string) {
    if (args && args.value) {
      form.controls['relative_to_' + this.strReplace(fieldName)].setValue(args.value);
      form.controls['relative_selection_' + this.strReplace(fieldName)].setValue('');

      if (form.controls['relative_days_' + this.strReplace(fieldName)].value === false || form.controls['relative_days_' + this.strReplace(fieldName)].value === null) {
        form.controls['relative_days_' + this.strReplace(fieldName)].setValue(null);
        form.controls['relative_days_' + this.strReplace(fieldName)].setValidators([Validators.required]);
        form.controls['relative_days_' + this.strReplace(fieldName)].markAsTouched();
      }

      form.updateValueAndValidity();

      // form.controls['relative_days_' + this.strReplace(fieldName)].setValidators([Validators.required]);
      // form.controls['relative_days_' + this.strReplace(fieldName)].markAsTouched();
      // form.controls['relative_days_' + this.strReplace(fieldName)].updateValueAndValidity();
    }
  }

  setRelativeDays(args, form: FormGroup, fieldName: string) {
    if (args && args.value) {
      form.controls['relative_days_' + this.strReplace(fieldName)].setValue(args.value);

      if (form.controls['relative_to_' + this.strReplace(fieldName)].value === false || form.controls['relative_to_' + this.strReplace(fieldName)].value === null) {
        form.controls['relative_to_' + this.strReplace(fieldName)].setValue(null);
        form.controls['relative_to_' + this.strReplace(fieldName)].setValidators([Validators.required]);
        form.controls['relative_to_' + this.strReplace(fieldName)].markAsTouched();
      }

      form.controls['relative_selection_' + this.strReplace(fieldName)].setValue('');
      form.updateValueAndValidity();
    } else if (args.value === null) {
      form.controls['relative_days_' + this.strReplace(fieldName)].setValue(null);
      form.controls['relative_days_' + this.strReplace(fieldName)].setValidators([Validators.required]);
      form.controls['relative_days_' + this.strReplace(fieldName)].markAsTouched();

      form.updateValueAndValidity();

    }
  }

  setRelativeDate(selectedField, form: FormGroup) {
    let formValues = _.cloneDeep(form.getRawValue());
    this.setActualDate(formValues, this.strReplace(selectedField), form);

    for (const formValue in formValues) {
      formValues = _.cloneDeep(form.getRawValue());
      // console.log(formValues);
      const fieldName = formValue.includes('relative_to_') ? formValue.split('relative_to_')[1] : null;

      if (fieldName !== null && fieldName !== this.strReplace(selectedField)) {
        // this.setValidators(formValues, form, fieldName);
        this.setActualDate(formValues, fieldName, form);
      }
    }

    this.checkRelativeDatesChange(formValues);
  }

  setActualDate(formValues, fieldName, form) {
    if (formValues['relative_to_' + fieldName] !== false && formValues['relative_days_' + fieldName] !== false) {
      if (formValues['relative_to_' + fieldName] !== '' && formValues['relative_to_' + fieldName] !== null) {
        const selectedDate = formValues[this.strReplace(formValues['relative_to_' + fieldName])];
        const relativeDays = formValues['relative_days_' + fieldName];
        let newDate: Date;

        // if (selectedDate) {
        if (relativeDays > 0) {
          newDate = moment(selectedDate).add(parseInt(relativeDays, 0), 'days').toDate();
        } else {
          newDate = moment(selectedDate).subtract(Math.abs(parseInt(relativeDays, 0)), 'days').toDate();
        }

        if (!moment(newDate).isValid()) {
          form.controls[this.strReplace(formValues['relative_to_' + fieldName])].setValidators([Validators.required]);
          form.controls[this.strReplace(formValues['relative_to_' + fieldName])].markAsTouched();
          form.controls[this.strReplace(formValues['relative_to_' + fieldName])].updateValueAndValidity();
        }

        form.controls[fieldName].setValue(newDate);
      }

      //
      // form.updateValueAndValidity();
    }
  }

  deliverableItemDatesFilter(deliverableItemDateCode: string, form: FormGroup) {
    const formValues = form.getRawValue();
    const datesArray = [];
    this.deliverableItemDatesCopy.filter((item: any) => {
      // const to_be_ensembled = _.pickBy(formValues, (value, key) =>
      //   _.some(['relative_to_'], str => _.includes(key, str))
      // );

      const result = _.pickBy(formValues, function(v, k) {
        return _.includes(k, 'relative_selection');
      });

      // result = result.filter((deliverable_date: any) => deliverable_date !== false);
      const resultRelativeSelection = _.pickBy(result, function(o) {
        return o !== false;
      });
      //
      // const resultNotRelativeSelection = _.pickBy(result, function(o) {
      //   return o === false;
      // });


      for (const resultKey in resultRelativeSelection) {
        const fieldName = resultKey.split('relative_selection_')[1];
        if (form.controls['relative_to_' + fieldName].value === deliverableItemDateCode) {
          datesArray.push(fieldName.replace(/_/g, '-'));
        }
      }
    });

    return this.deliverableItemDatesCopy.filter((item: any) => item.deliverable_item_date_code !== deliverableItemDateCode && !datesArray.includes(item.deliverable_item_date_code));


  }

  removeFormControls() {
    let tempArray = _.cloneDeep(this.deliverableItemDates);
    for (const deliverableItemDate of this.deliverableItemDates) {
      if (deliverableItemDate.deliverable_item_date_code !== 'publishing-date') {
        this.deliverableItemDateForm.removeControl(this.strReplace(deliverableItemDate.deliverable_item_date_code));
        this.deliverableItemDateForm.removeControl('is_tentative_' + this.strReplace(deliverableItemDate.deliverable_item_date_code));
        this.deliverableItemDateForm.removeControl('relative_selection_' + this.strReplace(deliverableItemDate.deliverable_item_date_code));
        this.deliverableItemDateForm.removeControl('date_selection_' + this.strReplace(deliverableItemDate.deliverable_item_date_code));
        this.deliverableItemDateForm.removeControl('relative_to_' + this.strReplace(deliverableItemDate.deliverable_item_date_code));
        this.deliverableItemDateForm.removeControl('relative_days_' + this.strReplace(deliverableItemDate.deliverable_item_date_code));
        this.deliverableItemDateForm.removeControl('deliverable_date_owner_code_' + this.strReplace(deliverableItemDate.deliverable_item_date_code));

      }
      tempArray = tempArray.filter((item) => item.deliverable_item_date_code !== deliverableItemDate.deliverable_item_date_code);
    }

    this.deliverableItemDates = tempArray;
    console.log(this.deliverableItemDates);
    


    this.deliverableItemDateForm.controls.publishing_date.setValue(null);
    this.deliverableItemDateForm.controls.date_selection_publishing_date.setValue(false);
    this.deliverableItemDateForm.controls.is_tentative_publishing_date.setValue(false);
    this.deliverableItemDateForm.controls.relative_selection_publishing_date.setValue(false);
    this.deliverableItemDateForm.controls.relative_to_publishing_date.setValue(null);
    this.deliverableItemDateForm.controls.relative_days_publishing_date.setValue(null);
    this.deliverableItemDateForm.controls.deliverable_date_owner_code_publishing_date.setValue(null);

  }

  checkRelativeDatesChange(formValues) {
    // get original dates
    // find through code
    // check if relative date present
    // if present, chekc if changed
    // return true if yes

    for (const deliverableDateForm in formValues) {
      const deliverableName = deliverableDateForm.split('relative_to_')[1];
      if (deliverableName && this.beforeUpdateDateValues) {
        const findDelv = this.beforeUpdateDateValues.find((item) => this.strReplace(item.deliverable_item_date_code) === deliverableName);
        const convertedDate = moment(formValues[deliverableName]).format('YYYY-MM-DD HH:mm:ss').toString();

        if (findDelv && convertedDate !== 'Invalid date' && convertedDate !== findDelv.deliverable_date) {
          this.setNotesMandatory(true, this.deliverableNoteForm);
          this.changeRelativeFields = true;
          return;
        } else if (findDelv && findDelv.relative_to) {
          if (formValues['relative_to_' + deliverableName] !== findDelv.relative_to.deliverable_item_date_code || formValues['relative_days_' + deliverableName] !== findDelv.relative_days) {
            this.setNotesMandatory(true, this.deliverableNoteForm);
            this.changeRelativeFields = true;
            return;
          } else {
            this.setNotesMandatory(false, this.deliverableNoteForm);
            this.changeRelativeFields = false;
          }
        } else if (!findDelv && findDelv === null && (formValues['relative_to_' + deliverableName] || formValues['relative_days_' + deliverableName])) {
          this.setNotesMandatory(true, this.deliverableNoteForm);
          this.changeRelativeFields = true;
          return;
        }
      }
    }

    // console.log(relativeDays, deliverableItemDateCode);
    // console.log(this.beforeUpdateDateValues);
    //
    // const deliverableDate = this.beforeUpdateDateValues.find((item) => this.strReplace(item.deliverable_item_date_code) === deliverableItemDateCode);
    // console.log(deliverableDate);
    //
    // if (deliverableDate) {
    //   console.log(deliverableDate.relative_to.deliverable_item_date_code);
    //   console.log(relativeTo);
    //   if (deliverableDate.relative_days) {
    //     if (deliverableDate.relative_days !== relativeDays || deliverableDate.relative_to.deliverable_item_date_code !== relativeTo) {
    //       return 'changed';
    //     }
    //   }
    // }

    // return 'Not changed';

  }

  refreshFieldValidation(form: FormGroup) {
    const formValues = form.getRawValue();

    for (const deliverableItemDate of this.deliverableItemDatesCopy) {
      const replacedKey = this.strReplace(deliverableItemDate.deliverable_item_date_code);

      const relativeToField = formValues['relative_to_' + replacedKey];

      if (relativeToField !== null && relativeToField !== '' && relativeToField !== false && relativeToField !== undefined) {
        form.controls[this.strReplace(relativeToField)].setValidators([Validators.required]);
        form.controls[this.strReplace(relativeToField)].markAsTouched();
        form.controls[this.strReplace(relativeToField)].updateValueAndValidity();

        // this.updateNotesDate.push(this.checkRelativeDatesChange(replacedKey, formValues['relative_days_' + replacedKey], formValues['relative_to_' + replacedKey]));
        // console.log(this.updateNotesDate);

      } else if (relativeToField === false || relativeToField === null) {
        if (replacedKey !== 'publishing_date') {
          form.controls[replacedKey].clearValidators();
          form.controls[replacedKey].updateValueAndValidity();

        }
      }


      // const value = _.pickBy(formValues, replacedKey);

      const value = _.findKey(formValues, replacedKey);

    }

    console.log(this.beforeUpdateDateValues);
    if (this.setDateFields) {
      this.checkRelativeDatesChange(formValues);
    }

    this.setDateFields = true;





    // let result = _.pickBy(formValues, function(v, k) {
    //   return _.includes(k, 'relative_selection');
    // });
    //
    // console.log(result);
    //
    // // result = _.pickBy(result, function(o) {
    // //   return o !== false;
    // // });
    //
    // for (const resultKey in result) {
    //   const fieldName = resultKey.split('relative_selection_')[1];
    //   const relativeToDeliverableDate = form.controls['relative_to_' + fieldName].value;
    //
    //   console.log(relativeToDeliverableDate);
    //   console.log(form.value[this.strReplace(relativeToDeliverableDate)]);
    //
    //   if (relativeToDeliverableDate !== false) {
    //     if (form.value[this.strReplace(relativeToDeliverableDate)] === null) {
    //       console.log("hii 1");
    //       form.controls[this.strReplace(relativeToDeliverableDate)].setValidators([Validators.required]);
    //       form.controls[this.strReplace(relativeToDeliverableDate)].markAsTouched();
    //       form.controls[this.strReplace(relativeToDeliverableDate)].updateValueAndValidity();
    //     } else {
    //       console.log("hii 232323");
    //
    //       form.controls[this.strReplace(relativeToDeliverableDate)].clearValidators();
    //       form.controls[this.strReplace(relativeToDeliverableDate)].updateValueAndValidity();
    //     }
    //   }
    // }
  }

  // checkRelativePath(deliverableItemDateArray: any[], deliverableItemDateCode, formValues) {
  //   let searchValue;
  //   let finalArray = [];
  //   for (const deliverableItemDate of deliverableItemDateArray) {
  //     searchValue = deliverableItemDate.deliverable_item_date_code;
  //     while (searchValue !== deliverableItemDateCode) {
  //       console.log('hi');
  //       if (formValues['relative_selection_' + searchValue] !== false) {
  //         searchValue = formValues['relative_to_' + searchValue];
  //         if (searchValue !== deliverableItemDateCode) {
  //           finalArray.push(deliverableItemDateArray.find((item) => item.deliverable_item_date_code === searchValue));
  //         }
  //       }
  //     }
  //   }
  //   console.log(finalArray);
  //   return finalArray;
  // }

  openDeliverableDateOwnerDropdown(deliverable_date_code) {
    this.divs[0].showPopup();
  }

  showEndDatePopup(endDate, publishDate) {
    const formattedEndDate = moment(endDate).format('YYYY/MM/DD');
    const formattedPublishDate = moment(publishDate).format('YYYY/MM/DD');

    if (moment(formattedEndDate).isBefore(formattedPublishDate)) {
      this.endDateModal.show()
      return true
    }

    return false;
  }

  updateEndDate() {
    const publishDate = this.deliverableItemDateForm.value.publishing_date;

    this.deliverableForm.controls.end_date.setValue(publishDate);
    this.hideEndDateModal();
    if (!this.isAddOperation) {
      this.onUpdateDeliverable(this.deliverableForm, this.deliverableNoteForm, true);
    } else {
      this.onAddDeliverable(this.deliverableForm, this.deliverableNoteForm, true)
    }
  }

  hideEndDateModal() {
    this.endDateModal.hide();
    if (!this.isAddOperation) {
      this.onUpdateDeliverable(this.deliverableForm, this.deliverableNoteForm, true);
    } else {
      this.onAddDeliverable(this.deliverableForm, this.deliverableNoteForm, true)
    }
  }
}
