import { Component, OnInit } from '@angular/core';
import { LayoutConfigService } from 'src/app/core/_base/layout';
import { HtmlClassService } from '../html-class.service';

@Component({
  selector: 'app-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.scss']
})
export class BrandComponent implements OnInit {
  // Public properties
  headerLogo = '';
  brandClasses = '';
  asideSelfMinimizeToggle = true;

  constructor(
    private layoutConfigService: LayoutConfigService,
    public htmlClassService: HtmlClassService
  ) {}

  ngOnInit(): void {
    this.headerLogo = `./assets/images/canvas-dark.svg`;
    this.brandClasses = this.htmlClassService.getClasses('brand', true).toString();
    this.asideSelfMinimizeToggle = this.layoutConfigService.getConfig('aside.self.minimize.toggle');
  }

  toggleAsideClick() {
    // TODO: save this operation in local-storage or in db
    document.body.classList.toggle('aside-minimize');
  }

}
