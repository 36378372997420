import {Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {
  emailValidator,
  fromToDate,
  nameValidator,
  specialCharacterValidator,
  telephoneValidator,
  trimValidator, websiteValidator, whiteSpaceValidator
} from '../../../../app-validators';
import {select, Store} from '@ngrx/store';
import {ContactAdd, ContactSetStatusNull, ContactUpdate} from '../../../../core/store/actions/contact.actions';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {AppState} from '../../../../core';
import {Contact} from '../../../../core/models/contact.model';
import {ActivatedRoute, Router} from '@angular/router';
import {debounce, EmitType} from '@syncfusion/ej2-base';
import {DropDownListComponent, FilteringEventArgs, MultiSelectComponent} from '@syncfusion/ej2-angular-dropdowns';
import {Query} from '@syncfusion/ej2-data';
import {Tags} from '../../../../core/models/tags.model';
import {LoadTags} from '../../../../core/store/actions/common.actions';
import {SidebarComponent} from '@syncfusion/ej2-angular-navigations';
import {CompanyFilter, CompanyLoad, GetSearchedCompany} from '../../../../core/store/actions/company.actions';
import * as _ from 'lodash';
import * as moment from 'moment';
import {CompanyService} from '../../../../core/store/services/company.service';
import {ContactService} from '../../../../core/store/services/contact.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, OnChanges, OnDestroy {

  @Input() set contactInput(mode: Contact | null) {
    this._contactInput$.next(mode);
  }

  get contactInput(): Contact | null {
    return this._contactInput$.getValue();
  }

  constructor(private formBuilder: FormBuilder, private store: Store<AppState>, private router: Router,
              private activatedRoute: ActivatedRoute, private companyService: CompanyService, private contactService: ContactService) {
  }

  @ViewChild('sidebarInstance')
  public sidebarInstance: SidebarComponent;
  @ViewChild('company') dropdownObj: DropDownListComponent;
  @ViewChild('visibility') visibilityDropdown: DropDownListComponent;
  @ViewChild('tags') contactTag: MultiSelectComponent;
  @Input() events: Observable<void>;
  @Input() companyList: any;
  @Input() selectedCompany: any;
  @Input() allTags: Observable<Array<Tags>>;
  @Output() valueChange = new EventEmitter();
  public width = 'auto';
  public position = 'Right';
  selectedCompanyName = '';
  companyCode: string;
  contactForm: FormGroup;
  page_no = 1;
  selectedTag = [];
  modelData = [];
  tagModelData = [];

  tagSelected = '';
  displayPasswordInput = false;

  errorMsg: any;
  error$: Observable<any>;
  successContact$: Observable<boolean>;
  public formSent: boolean;
  companies: Observable<any>;

  public tagFields: object = {value: 'tag', text: 'tag'};
  public companyFields: object = {value: 'company_code', text: 'company_name'};
  public visibilityFields: object = {value: 'visibility_code', text: 'visibility_name'};
  public _contactInput$: BehaviorSubject<Contact | null> = new BehaviorSubject<Contact | null>(null);

  public selectedItem:string = "public";

  public visibilityList: any[] = [
    {visibility_code: 'private', visibility_name: 'Private'},
    {visibility_code: 'password_protected', visibility_name: 'Password Protected'},
    {visibility_code: 'public', visibility_name: 'Public'},
  ];


  setDebounce = debounce((e, entity: string) => {
    this.onFilter(e, entity);
  }, 700);

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.sidebarInstance.isOpen) {
      this.closeSidebar();
    }
  }

  ngOnInit(): void {
    if (!this.contactForm) {
      this.generateContactForm();
    }

    if (this.allTags) {
      this.allTags.subscribe(data => {
        this.tagModelData = data;
      });
    }

    // this.eventsSubscription = this.events.subscribe(() => this.contactForm.reset());

    this.successContact$ = this.store.pipe(select(store => store.contacts.success.isLoaded));
    this.successContact$.subscribe((data: any) => {
      if (data) {
        this.formSent = false;
        this.closeSidebar();
        this.store.dispatch(new ContactSetStatusNull());
      }
    });

    this.error$ = this.store.pipe(select(store => store.contacts.error));
    this.error$.subscribe((data: any) => {
      if (data) {
        this.errorMsg = data.error;
        this.formSent = false;
      }
    });

    if (this.isCompanyLink()) {
      this.companyCode = this.activatedRoute.snapshot.paramMap.get('company_code');
      this.contactForm.patchValue({
        company: this.companyCode
      });
    }
  }

  generateContactForm() {
    this.contactForm = this.formBuilder.group({
      firstName: new FormControl('', [Validators.required, Validators.compose([trimValidator])]),
      lastName: new FormControl('', [Validators.required, Validators.compose([trimValidator])]),
      email: new FormControl('', [Validators.required, Validators.compose([emailValidator])]),
      company: new FormControl(null),
      designation: new FormControl('', [Validators.maxLength(100)]),
      website: new FormControl('', [Validators.compose([websiteValidator]), Validators.maxLength(200)]),
      leadSource: new FormControl('', [Validators.maxLength(100)]),
      contactNumber: new FormControl(null, [Validators.compose([telephoneValidator]), Validators.maxLength(50)]),
      tags: new FormControl(null),
      excerpt: new FormControl(''),
      start_date: new FormControl(null, [Validators.required]),
      end_date: new FormControl(null),
      slug: new FormControl('', [Validators.required])
    }, {validator: fromToDate('start_date', 'end_date')});
  }

  onAddContact(form: FormGroup) {
    let currentScreen: string;
    let company;
    if (this.isCompanyLink()) {
      company = this.companyCode;
      currentScreen = 'company';
    } else {
      company = form.value.company;
      if (this.isProjectLink()) {
        if (this.selectedCompany && this.selectedCompany.company_code !== form.value.company) {
          currentScreen = 'project';
        } else {
          currentScreen = 'contact';
        }
      } else {
        currentScreen = 'contact';
      }
    }

    let start_date, end_date;
    if (form.value.start_date) {
      start_date = moment(form.value.start_date).format('YYYY-MM-DD HH:mm:ss');
    } else {
      start_date = null;
    }
    if (form.value.end_date) {
      end_date = moment(form.value.end_date).format('YYYY-MM-DD');
    } else {
      end_date = null;
    }

    if (!form.invalid) {
      this.formSent = true;
      this.store.dispatch(new ContactAdd({
        first_name: form.value.firstName, last_name: form.value.lastName, email: form.value.email, company_code: company,
        designation: form.value.designation, leadSource: form.value.leadSource, tel_no: form.value.contactNumber ? form.value.contactNumber.trim() : null, currentScreen,
        contact_tag: form.value.tags, start_date, end_date, excerpt: form.value.excerpt, website: form.value.website, slug: form.value.slug, password_protected_password: form.value.password_protected_password,
        visibility: form.value.visibility
      }));
    }
  }

  onUpdateContact(form: FormGroup) {
    let currentScreen: string;
    if (this.router.url.indexOf('/company/details') > -1) {
      currentScreen = 'company';
    } else {
      currentScreen = 'contact';
    }

    let start_date, end_date;
    if (form.value.start_date) {
      start_date = moment(form.value.start_date).format('YYYY-MM-DD HH:mm:ss');
    } else {
      start_date = null;
    }
    if (form.value.end_date) {
      end_date = moment(form.value.end_date).format('YYYY-MM-DD');
    } else {
      end_date = null;
    }

    const company = {company_code: form.value.company};
    const contact = {
      contact_code: this._contactInput$.getValue().contact_code,
      company_code: form.value.company,
      first_name: form.value.firstName,
      last_name: form.value.lastName,
      designation: form.value.designation,
      email: form.value.email,
      tel_no: form.value.contactNumber ? form.value.contactNumber.trim() : null,
      lead_source: form.value.leadSource,
      user_code: form.value.userResponsible,
      contact_tag: form.value.tags,
      start_date,
      end_date,
      excerpt: form.value.excerpt,
      website: form.value.website,
      slug: form.value.slug,
      password_protected_password: this._contactInput$.getValue().password_protected_password,
      visibility: this._contactInput$.getValue().visibility,
      description: this._contactInput$.getValue().description ? this._contactInput$.getValue().description : null
    };
    // const contact = new Contact(this._contactInput$.getValue().contact_code, company, form.value.firstName, form.value.lastName,
    //   form.value.designation, form.value.email, form.value.contactNumber, form.value.leadSource, null, null,
    //   {user_code: form.value.userResponsible, first_name: null, last_name: null}, contact_tag: form.value.tag);
    if (!form.invalid) {
      this.formSent = true;
      this.store.dispatch(new ContactUpdate({contact_code: this._contactInput$.getValue().contact_code, contact, currentScreen}));
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (!this.contactForm) {
      this.generateContactForm();
    }
    if (this._contactInput$.getValue()) {

      let contact_tags_array = [];
      if (this._contactInput$.getValue().contact_tag) {
        for (const tag of this._contactInput$.getValue().contact_tag) {
          contact_tags_array.push(tag.tag);
        }
      }
      if (this._contactInput$.getValue().company) {
        this.searchAndSetCompany();
      }
      this.contactForm.setValue({
        firstName: this._contactInput$.getValue().first_name ? this._contactInput$.getValue().first_name : null,
        lastName: this._contactInput$.getValue().last_name ? this._contactInput$.getValue().last_name : null,
        email: this._contactInput$.getValue().email ? this._contactInput$.getValue().email : null,
        company: this._contactInput$.getValue().company ? this._contactInput$.getValue().company.company_code : null,
        designation: this._contactInput$.getValue().designation ? this._contactInput$.getValue().designation : null,
        leadSource: this._contactInput$.getValue().lead_source ? this._contactInput$.getValue().lead_source : null,
        contactNumber: this._contactInput$.getValue().tel_no ? this._contactInput$.getValue().tel_no : null,
        tags: this._contactInput$.getValue().contact_tag ? contact_tags_array : null,
        start_date: this._contactInput$.getValue().start_date ? moment(this._contactInput$.getValue().start_date, 'YYYY-MM-DD').toDate() : null,
        end_date: this._contactInput$.getValue().end_date ? moment(this._contactInput$.getValue().end_date, 'YYYY-MM-DD').toDate() : null,
        excerpt: this._contactInput$.getValue().excerpt ? this._contactInput$.getValue().excerpt : null,
        website: this._contactInput$.getValue().website ? this._contactInput$.getValue().website : null,
        slug: this._contactInput$.getValue().slug ? this._contactInput$.getValue().slug : null,
      });

    }

    if (this.selectedCompany && this.isProjectLink()) {
      this.searchAndSetCompanyCode();
      // this.contactForm.controls.company.setValue(this.selectedCompanyCode);
    }
  }

  public onFiltering = (e, entity: string) => {
    e.preventDefaultAction = true;
    this.setDebounce(e, entity);
  };

  public onFilter: EmitType<any> = (e: FilteringEventArgs) => {
    let query = new Query();
    query = (e.text !== '') ? query.where('company_name', 'startswith', e.text, true) : query;
    this.selectedCompanyName = e.text;
    if (e.text && e.text !== '') {
      const filterParams = {company_name: e.text, page_size: 40, page_no: 1};
      this.companyService.getSearchedCompany(filterParams).subscribe((data) => {
        if (data && data.data) {
          if (data.data.result) {
            const result: any = data.data.result;
            // this.companyList = data.result;
            e.updateData(result, query);
            // this.dropdownObj.dataSource = result
          }
        }
      });
    }
  }

  searchAndSetCompany() {
    this.selectedCompanyName = this._contactInput$.getValue().company.company_name;
    if (this.selectedCompanyName) {
      let isCompanyExistsInList = this.companyList.filter(el => el.company_name === this.selectedCompanyName).length > 0;
      if (!isCompanyExistsInList) {
        let companyList = [];
        companyList.push(this._contactInput$.getValue().company);
        this.companyList.forEach(el => companyList.push(el));
        this.dropdownObj.dataSource = companyList;
        this.contactForm.controls['company'].setValue(this._contactInput$.getValue().company);
      }
    }
  }

  searchAndSetCompanyCode() {
    this.selectedCompanyName = this.selectedCompany;
    if (this.selectedCompany) {
      const isCompanyExistsInList = this.companyList.filter(el => el.company_code === this.selectedCompany.company_code).length > 0;
      if (!isCompanyExistsInList) {

        let companyList = [];
        companyList.push(this.selectedCompany);
        this.companyList.forEach(el => companyList.push(el));
        this.dropdownObj.dataSource = companyList;
        this.contactForm.controls['company'].setValue(this.selectedCompany.company_code);
      } else {
        this.contactForm.controls['company'].setValue(this.selectedCompany.company_code);
      }
    }
  }

  ngOnDestroy(): void {
    // this.eventsSubscription.unsubscribe();
  }

  openSidebar(): void {
    this.sidebarInstance.show();
  }

  closeSidebar(): void {
    this.contactForm.reset();
    this.sidebarInstance.hide();
    this.selectedCompany = null;
    this.valueChange.emit('success');
  }

  isCompanyLink(): boolean {
    return this.router.url.indexOf('/company/details') > -1;
  }

  isProjectLink(): boolean {
    return this.router.url.indexOf('/project') > -1;
  }

  onBlur(args, form) {
    this.tagSelected = this.selectedTag[this.selectedTag.length - 1];

    if (!this.modelData.includes(this.tagSelected)) {
      this.contactTag.addItem({tag: this.tagSelected, tag_code: this.tagSelected});
      form.controls.tags.setValue(_.uniq(_.compact([...this.modelData, this.tagSelected])));
    }

    this.modelData = _.compact([...this.modelData, this.tagSelected]);
    this.modelData.push(this.tagSelected);

    this.selectedTag = [];
  }

  syncModel = (event) => {
    this.modelData = event;
  };

  actionComplete(args) {
    if (_.get(args, 'result[0]')) {
      this.selectedTag.push(args.result[0].tag);
    }
  }

  onChange(event) {
    const tagsArray = _.compact(this.modelData);
    if (tagsArray.length === 0) {
      this.modelData = [];
    }
  }

  select(args) {
    this.selectedTag = [];
  }

  setCompanyValue(args, form: FormGroup) {
    console.log(args);
    form.controls.company.setValue(args.itemData);
  }

  getContactSlug(firstName: string, lastName: string, form: FormGroup) {
    if (firstName && lastName) {
      const name = firstName + ' ' + lastName;
      if ((this._contactInput$.getValue() && (this._contactInput$.getValue().first_name + ' ' + this._contactInput$.getValue().last_name) !== (firstName + ' ' + lastName).trim() && (firstName + ' ' + lastName).trim() !== '') || (!this._contactInput$.getValue() && (firstName + ' ' + lastName).trim() !== '')) {
        this.contactService.getContactSlug({name}).subscribe(data => {
          if (data && data.data) {
            form.controls.slug.setValue(data.data);
          }
        }, err => {
          console.log(err);
        });
      }
    }

  }

  // changePasswordValidation(form: FormGroup, args) {
  //   if (form && args && args.itemData) {
  //     form.controls.visibility.setValue(args.itemData.visibility_code);
  //     if (form.controls.visibility.value === 'password_protected') {
  //       form.controls.password_protected_password.setValidators([Validators.required, Validators.maxLength(255)]);
  //       form.updateValueAndValidity();
  //       this.displayPasswordInput = true;
  //     } else {
  //       this.displayPasswordInput = false;
  //       form.controls.password_protected_password.clearValidators();
  //       form.controls.password_protected_password.updateValueAndValidity();
  //       form.updateValueAndValidity();
  
  //     }
  //   }
  // }

  // setDefaultValue () {
  //   if (!this._contactInput$.getValue()) {
  //     this.visibilityDropdown.value = this.selectedItem;
  //   }
  // }

}
