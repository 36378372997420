import { Action } from '@ngrx/store';
import { ProjectSource } from '../../models/project-source.model';
import {ProjectActionTypes} from './project.actions';

export enum ProjectSourceActionTypes {
  LOAD = '[PROJECT SOURCE] Load Action',
  LOAD_SUCCESS = '[PROJECT SOURCE] Load Success Action',
  LOAD_FAILURE = '[PROJECT SOURCE] Load Failure Action',

  ADD = '[PROJECT SOURCE] Add Action',
  ADD_SUCCESS = '[PROJECT SOURCE] Add Success Action',
  ADD_FAILURE = '[PROJECT SOURCE] Add Failure Action',

  UPDATE = '[PROJECT SOURCE] Update Action',
  UPDATE_SUCCESS = '[PROJECT SOURCE] Update Success Action',
  UPDATE_FAILURE = '[PROJECT SOURCE] Update Failure Action',

  DELETE = '[PROJECT SOURCE] Delete Action',
  DELETE_SUCCESS = '[PROJECT SOURCE] Delete Success Action',
  DELETE_FAILURE = '[PROJECT SOURCE] Delete Failure Action',

  LOAD_SINGLE_PROJECT_SOURCE = '[PROJECT SOURCE] Load Single Project Source',
  LOAD_SINGLE_PROJECT_SOURCE_SUCCESS = '[PROJECT SOURCE] Load Single Project Source Success',
  LOAD_SINGLE_PROJECT_SOURCE_FAILURE = '[PROJECT SOURCE] Load Single Project Source Failure',

  FILTER = '[PROJECT SOURCE] Filter Action',
  FILTER_SUCCESS = '[PROJECT SOURCE] Filter Success Action',
  FILTER_FAILURE = '[PROJECT SOURCE] Filter Failure Action',

  GET_PROJECT_SOURCE_FROM_ONE_PROJECT = '[PROJECT SOURCE] Get Project Source from one project action',
  GET_PROJECT_SOURCE_FROM_ONE_PROJECT_SUCCESS = '[PROJECT SOURCE] Get Project Source from one project Success action',
  GET_PROJECT_SOURCE_FROM_ONE_PROJECT_FAILURE = '[PROJECT SOURCE] Get Project Source from one project Failure action',

  SEARCH = '[PROJECT SOURCE] Search Action',
  SEARCH_SUCCESS = '[PROJECT SOURCE] Search Success Action',
  SEARCH_FAILURE = '[PROJECT SOURCE] Search Failure Action',

  // Search for dropdown
  SEARCH_SOURCES = '[PROJECT SOURCE] Search Sources Action',
  SEARCH_SOURCES_SUCCESS = '[PROJECT SOURCE] Search Sources Success Action',
  SEARCH_SOURCES_FAILURE = '[PROJECT SOURCE] Search Sources Failure Action',

  FILTER_RESET = '[PROJECT SOURCE] FILTER RESET',
  SET_SUCCESS_NULL = '[PROJECT SOURCE] Set Success Null'

}
export class ProjectSourceLoad implements Action {
  readonly type = ProjectSourceActionTypes.LOAD;
  constructor(public payload?: {page_size?: number, page_no?: number}) { }

}

export class ProjectSourceLoadSuccess implements Action {
  readonly type = ProjectSourceActionTypes.LOAD_SUCCESS;
  constructor(public payload: {
    result: Array<ProjectSource>,
    total_count: number,
    page_no: number,
    page_size: number
  }) { }
}

export class ProjectSourceLoadFailure implements Action {
  readonly type = ProjectSourceActionTypes.LOAD_FAILURE;
  constructor(public payload: Error) { }
}

export class ProjectSourceAdd implements Action {
  readonly type = ProjectSourceActionTypes.ADD;
  constructor(public payload: { contactCode: string, projectCode: string, companyCode: string, currentScreen: string, drive_link: string,
    notes: string, project_source_type_code: [], project_source_status_code: string, company_contact_code?: string, date_received: any; }) { }
}

export class ProjectSourceAddSuccess implements Action {
  readonly type = ProjectSourceActionTypes.ADD_SUCCESS;
  constructor(public payload: { projectSource: ProjectSource }) { }
}

export class ProjectSourceAddFailure implements Action {
  readonly type = ProjectSourceActionTypes.ADD_FAILURE;
  constructor(public payload: Error) { }
}

export class ProjectSourceUpdate implements Action {
  readonly type = ProjectSourceActionTypes.UPDATE;
  constructor(public payload: { projectSourceCode: string, projectCode: string, contactCode: string, companyCode: string,
    currentScreen: string, drive_link: string, notes: string, project_source_type_code: [], project_source_status_code: string,
    company_contact_code?: string, date_received: any; }) { }
}

export class ProjectSourceUpdateSuccess implements Action {
  readonly type = ProjectSourceActionTypes.UPDATE_SUCCESS;
  constructor(public payload: { projectSource: ProjectSource }) { }
}

export class ProjectSourceUpdateFailure implements Action {
  readonly type = ProjectSourceActionTypes.UPDATE_FAILURE;
  constructor(public payload: Error) { }
}

export class ProjectSourceDelete implements Action {
  readonly type = ProjectSourceActionTypes.DELETE;
  constructor(public payload: {project_source_code: string }) { }
}

export class ProjectSourceDeleteSuccess implements Action {
  readonly type = ProjectSourceActionTypes.DELETE_SUCCESS;
  constructor(public payload: { project_source_code: string }) { }
}

export class ProjectSourceDeleteFailure implements Action {
  readonly type = ProjectSourceActionTypes.DELETE_FAILURE;
  constructor(public payload: Error) { }
}

export class GetSingleProjectSource implements Action {
  readonly type = ProjectSourceActionTypes.LOAD_SINGLE_PROJECT_SOURCE;
  constructor(public payload: { projectSourceCode: string }) { }
}

export class GetSingleProjectSourceSuccess implements Action {
  readonly type = ProjectSourceActionTypes.LOAD_SINGLE_PROJECT_SOURCE_SUCCESS;
  constructor(public payload: { projectSource: ProjectSource }) { }
}

export class GetSingleProjectSourceFailure implements Action {
  readonly type = ProjectSourceActionTypes.LOAD_SINGLE_PROJECT_SOURCE_FAILURE;
  constructor(public payload: Error) { }
}

export class ProjectSourceSetStatusNull implements Action {
  readonly type = ProjectSourceActionTypes.SET_SUCCESS_NULL;
  constructor() { }
}

export class ProjectSourceGetSourcesForOneProject implements Action {
  readonly type = ProjectSourceActionTypes.GET_PROJECT_SOURCE_FROM_ONE_PROJECT;
  constructor(public payload: {project_code: string, page_size: number, page_no: number}) { }

}

export class ProjectSourceGetSourcesForOneProjectSuccess implements Action {
  readonly type = ProjectSourceActionTypes.GET_PROJECT_SOURCE_FROM_ONE_PROJECT_SUCCESS;
  constructor(public payload: {
    result: Array<ProjectSource>,
    total_count: number,
    page_no: number,
    page_size: number
  }) { }
}

export class ProjectSourceGetSourcesForOneProjectFailure implements Action {
  readonly type = ProjectSourceActionTypes.GET_PROJECT_SOURCE_FROM_ONE_PROJECT_FAILURE;
  constructor(public payload: Error) { }
}

export class ProjectSourceSearch implements Action {
  readonly type = ProjectSourceActionTypes.SEARCH;
  constructor(public payload: { search?: string, page_no?: number, page_size?: number}) { }
}

export class ProjectSourceSearchSuccess implements Action {
  readonly type = ProjectSourceActionTypes.SEARCH_SUCCESS;
  constructor(public payload: { result: Array<ProjectSource>, total_count: number, page_no: number, page_size: number }) { }
}

export class ProjectSourceSearchFailure implements Action {
  readonly type = ProjectSourceActionTypes.SEARCH_FAILURE;
  constructor(public payload: Error) { }
}

export class ProjectSourceSearchAllSources implements Action {
  readonly type = ProjectSourceActionTypes.SEARCH_SOURCES;
  constructor(public payload: { search?: string, page_no?: number, page_size?: number}) { }
}

export class ProjectSourceSearchAllSourcesSuccess implements Action {
  readonly type = ProjectSourceActionTypes.SEARCH_SOURCES_SUCCESS;
  constructor(public payload: { result: Array<ProjectSource>, total_count: number, page_no: number, page_size: number }) { }
}

export class ProjectSourceSearchAllSourcesFailure implements Action {
  readonly type = ProjectSourceActionTypes.SEARCH_SOURCES_FAILURE;
  constructor(public payload: Error) { }
}

export class ProjectSourceFilterReset implements Action {
  readonly type = ProjectSourceActionTypes.FILTER_RESET;
}

export class ProjectSourceFilter implements Action {
  readonly type = ProjectSourceActionTypes.FILTER;
  constructor(public payload: {}) { }
}

export class ProjectSourceFilterSuccess implements Action {
  readonly type = ProjectSourceActionTypes.FILTER_SUCCESS;
  constructor(public payload: { result: Array<ProjectSource>, total_count: number, page_no: number, page_size: number }) { }
}

export class ProjectSourceFilterFailure implements Action {
  readonly type = ProjectSourceActionTypes.FILTER_FAILURE;
  constructor(public payload: Error) { }
}

export type ProjectSourceActions = ProjectSourceAdd | ProjectSourceAddSuccess | ProjectSourceAddFailure | ProjectSourceLoad |
ProjectSourceLoadSuccess | ProjectSourceLoadFailure | ProjectSourceDelete | ProjectSourceDeleteSuccess | ProjectSourceDeleteFailure |
GetSingleProjectSource| GetSingleProjectSourceSuccess | GetSingleProjectSourceFailure | ProjectSourceSetStatusNull |
  ProjectSourceGetSourcesForOneProject | ProjectSourceGetSourcesForOneProjectSuccess | ProjectSourceGetSourcesForOneProjectFailure |
  ProjectSourceSearch | ProjectSourceSearchSuccess | ProjectSourceSearchFailure | ProjectSourceFilterReset | ProjectSourceUpdate |
  ProjectSourceUpdateSuccess | ProjectSourceUpdateFailure |ProjectSourceSearchAllSources | ProjectSourceSearchAllSourcesSuccess |
  ProjectSourceSearchAllSourcesFailure | ProjectSourceFilter | ProjectSourceFilterSuccess | ProjectSourceFilterFailure;


