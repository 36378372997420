export class Feature {
  static ADD_ACCESS = 'A';
  static UPDATE_ACCESS = 'U';
  static VIEW_ACCESS = 'V';
  static DELETE_ACCESS = 'D';

  static getInstance() {
    return {
      PROJECT: 'project',
      PROJECT_LIST: 'project-list',
      PROJECT_ACTIVITY: 'project-activity',
      PROJECT_HISTORY: 'project-history',
      PROJECT_STATUS: 'project-status',
      PROJECT_COMPANY_ROLE: 'project-company-role',

      COMPANY: 'company',
      COMPANY_LIST: 'company-list',
      COMPANY_ACTIVITY: 'company-activity',
      COMPANY_HISTORY: 'company-history',

      CONTACT: 'contact',
      CONTACT_LIST: 'contact-list',
      CONTACT_ACTIVITY: 'contact-activity',
      CONTACT_HISTORY: 'contact-history',

      PROJECT_SOURCE: 'project-source',
      PROJECT_SOURCE_LIST: 'project-source-list',

      ORDER: 'order',
      ORDER_LIST: 'order-list',
      ORDER_HISTORY: 'order-history',
      ORDER_DRIVE_LINK: 'order-drive-link',
      ORDER_CONTACT_ROLES: 'order-contact-roles',

      DELIVERABLE: 'deliverable',
      DELIVERABLE_LIST: 'deliverable-list',

      ORDER_LINE_ITEM: 'order-line-item',

      ARTICLE_LIST: 'article-list',
      ARTICLE: 'article',

      ADMIN_LIST: 'admin-list',
      COMPANY_TYPES_MASTER: 'company-types-master',
      COMPANY_ROLES_MASTER: 'company-roles-master',
      PROJECT_STATUS_MASTER: 'project-status-master',
      PROJECT_TYPES_MASTER: 'project-types-master',
      SOURCE_TYPE_MASTER: 'source-type-master',
      DELIVERABLE_ITEMS_MASTER: 'deliverable-items-master',
      COST_CENTER_MASTER: 'cost-center-master',
      DELIVERABLE_STATUS_MASTER: 'deliverable-status-master',
      ORDER_LINE_ITEMS_MASTER: 'order-line-items-master',

      EMAIL_TEMPLATE_MASTER: 'email-template-master',
      EMAIL: 'email-view',

      LOGS: 'logs'

    };
  }
}
