<ejs-sidebar id="sidebar-menu" #sidebarInstance type='Over' [width]='width' [position]='position'
             style="visibility: hidden" [enableGestures]="false" [showBackdrop]=true>

  <div class="offcanvas-close mt-n1 pr-5" style="float:right; padding: 48px;">
    <a href="javascript:;" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_panel_close"
       (click)="closeSidebar()">
      <i class="ki ki-close icon-xs text-muted"></i>
    </a>
  </div>
  <div class="p-15">
    <h2>Deliverable Filter</h2>

    <ul class="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-bold nav-tabs-line-3x mb-15" role="tablist">
      <li class="nav-item mr-0" [class.menu-item-active]="selectedTab === 'deliverable'"
          (click)="setTab('deliverable')">
        <a class="nav-link mx-2" data-toggle="tab" [class.active]="selectedTab === 'deliverable'">
          <span class="nav-text">Deliverable</span>

        </a>
      </li>

      <li class="nav-item mr-0" [class.menu-item-active]="selectedTab === 'dates'" (click)="setTab('dates')">
        <a class="nav-link mx-2" data-toggle="tab" [class.active]="selectedTab === 'dates'">
          <span class="nav-text">Dates</span>
        </a>
      </li>
    </ul>
    <form id="formId" [formGroup]="filterForm" #formDir="ngForm" class="form-horizontal" novalidate=""
          [ngClass]="selectedTab === 'deliverable' ? 'show' : 'hide'">

      <div class="form-group sf-outline-input mb-10"
           [class.hide-label]="filterForm.controls.deliverable_item_code.value == ''">
        <ejs-dropdowntree formControlName="deliverable_item_code" id='deliverable_item_code' [showSelectAll]=true
                          [fields]='deliverableItemFields' placeholder='Deliverable Item (Service)' cssClass="e-filled"
                          allowMultiSelection='true' [allowFiltering]=true [filterType]="'contains'"
                          sortOrder="Ascending" (blur)="filterForm.controls.deliverable_item_code.markAsTouched()"
                          floatLabelType="Auto" [treeSettings]='treeSettings' [showCheckBox]='true'></ejs-dropdowntree>
      </div>

      <div class="form-group sf-outline-input mb-10"
           [class.hide-label]="filterForm.controls.deliverable_status_code.value == ''">
        <ejs-dropdowntree formControlName="deliverable_status_code" id='deliverable_status_codee' [showSelectAll]=true
                          [fields]='deliverableStatusFields' placeholder='Deliverable Status' cssClass="e-filled"
                          allowMultiSelection='true'
                          sortOrder="Ascending" (blur)="filterForm.controls.deliverable_status_code.markAsTouched()"
                          floatLabelType="Auto" [showCheckBox]=true [treeSettings]='treeSettings'></ejs-dropdowntree>
      </div>


      <div class="form-group sf-outline-input mb-10 w-100">
        <ejs-multiselect id='deliverable_owner_code' formControlName="deliverable_owner_code" #checkbox
                         [dataSource]='users'
                         placeholder='Deliverable Owner' [fields]='userFields' floatLabelType="Auto" cssClass="e-filled"
                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true' sortOrder="Ascending"
                         filterBarPlaceholder='Search'></ejs-multiselect>
      </div>

      <div class="form-group sf-outline-input mb-10 w-100">
        <ejs-multiselect id='deliverable_date_owner_code' formControlName="deliverable_date_owner_code" #checkbox
                         [dataSource]='users'
                         placeholder='Deliverable Date Owner' [fields]='userFields' floatLabelType="Auto" cssClass="e-filled"
                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true' sortOrder="Ascending"
                         filterBarPlaceholder='Search'></ejs-multiselect>
      </div>



      <div class="form-group sf-outline-input mb-10" *ngIf="!isCompanyLink()">
        <ejs-multiselect id='company_code_deliverable' formControlName="company_code" #checkboxCompany
                         [dataSource]='companyList'
                         placeholder='Company' [fields]='companyFields' cssClass="e-filled" floatLabelType="Auto"
                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                         (filtering)="onFiltering($event, 'company')"
                         (blur)="filterForm.controls.company_code.markAsTouched()"
                         filterBarPlaceholder='Search'></ejs-multiselect>
      </div>

      <!--      <div class="form-group sf-outline-input mb-10 mr-2 w-100">-->
      <!--        <ejs-daterangepicker formControlName="duration" name="duration"-->
      <!--                             placeholder='Duration' openOnFocus="true" [allowEdit]="allowEdit"-->
      <!--                             floatLabelType='Auto' cssClass="e-filled">-->
      <!--        </ejs-daterangepicker>-->
      <!--      </div>-->

      <div class="d-flex">
        <div class="form-group sf-outline-input mb-10 mr-2 w-100">
          <ejs-datepicker formControlName="duration_from" name="duration" (change)="setDuration($event)"
                          placeholder='Start' openOnFocus="true" [allowEdit]="allowEdit"
                          floatLabelType='Auto' cssClass="e-filled">
          </ejs-datepicker>
        </div>

        <div class="form-group sf-outline-input mb-10 mr-2 w-100">
          <ejs-datepicker formControlName="duration_to" name="duration"
                          placeholder='End' openOnFocus="true" [allowEdit]="allowEdit"
                          floatLabelType='Auto' cssClass="e-filled" [min]="filterForm.value.duration_from">
          </ejs-datepicker>
        </div>

      </div>


      <div class="d-flex">
        <div class="form-group sf-outline-input mb-10 mr-2 w-100">
          <ejs-datepicker formControlName="expiry_from" name="expiry_from" [allowEdit]="allowEdit"
                          placeholder='Expiry From' openOnFocus="true"
                          floatLabelType='Auto' cssClass="e-filled">
          </ejs-datepicker>
        </div>

        <div class="form-group sf-outline-input mb-10 mr-2 w-100">
          <ejs-datepicker formControlName="expiry_to" [allowEdit]="allowEdit" name="expiry_to"
                          placeholder='Expiry To' openOnFocus="true" [min]="filterForm.value.expiry_from"
                          floatLabelType='Auto' cssClass="e-filled">
          </ejs-datepicker>
        </div>
      </div>

      <div class="d-flex">
        <div class="form-group sf-outline-input mb-10 mr-2 w-100">
          <ejs-datepicker formControlName="updated_at_from" name="updated_at_from" [allowEdit]="allowEdit"
                          placeholder='Updated From' openOnFocus="true"
                          floatLabelType='Auto' cssClass="e-filled">
          </ejs-datepicker>
        </div>

        <div class="form-group sf-outline-input mb-10 mr-2 w-100">
          <ejs-datepicker formControlName="updated_at_to" name="updated_at_from" [allowEdit]="allowEdit"
                          placeholder='Updated To' openOnFocus="true" [min]="filterForm.value.updated_at_from"
                          floatLabelType='Auto' cssClass="e-filled">
          </ejs-datepicker>
        </div>
      </div>

      <div class="d-flex">
        <div class="form-group sf-outline-input mb-10 mr-2 w-100">
          <ejs-datepicker formControlName="created_at_from" name="created_at" [allowEdit]="allowEdit"
                          placeholder='Created From' openOnFocus="true"
                          floatLabelType='Auto' cssClass="e-filled">
          </ejs-datepicker>
        </div>

        <div class="form-group sf-outline-input mb-10 mr-2 w-100">
          <ejs-datepicker formControlName="created_at_to" name="created_at_to" [allowEdit]="allowEdit"
                          placeholder='Created To' openOnFocus="true" [min]="filterForm.value.created_at_from"
                          floatLabelType='Auto' cssClass="e-filled">
          </ejs-datepicker>
        </div>
      </div>

      <div class="d-flex">
        <div class="form-group sf-outline-input mb-10 w-100">
          <ejs-multiselect id='updated_by' formControlName="updated_by" #checkbox [dataSource]='users'
                           placeholder='Updated By' [fields]='userFields' floatLabelType="Auto" cssClass="e-filled"
                           mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true' sortOrder="Ascending"
                           filterBarPlaceholder='Search'></ejs-multiselect>
        </div>

        <div class="form-group sf-outline-input mb-10 ml-2 w-100">
          <ejs-multiselect id='created_by' formControlName="created_by" #checkbox [dataSource]='users'
                           placeholder='Created By' [fields]='userFields' floatLabelType="Auto" cssClass="e-filled"
                           mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                           filterBarPlaceholder='Search'></ejs-multiselect>
        </div>
      </div>

      <div class="form-group sf-outline-input mb-10"
           [class.hide-label]="filterForm.controls.cost_center_code.value == ''">
        <ejs-multiselect formControlName="cost_center_code" id='cost_center_code' [showSelectAll]=true
                         [fields]='costCenterFields' placeholder='Cost Center' cssClass="e-filled"
                         sortOrder="Ascending" (blur)="filterForm.controls.cost_center_code.markAsTouched()"
                         floatLabelType="Auto" mode="CheckBox" [dataSource]="costCenter"></ejs-multiselect>
      </div>

      <div class="form-group sf-outline-input mb-10"
           [class.hide-label]="filterForm.controls.order_item_code.value == ''">
        <ejs-dropdowntree formControlName="order_item_code" id='order_item_code' [showSelectAll]=true
                          [fields]='lineItemFields' placeholder='Order Item' cssClass="e-filled"
                          allowMultiSelection="true"
                          sortOrder="Ascending" (blur)="filterForm.controls.order_item_code.markAsTouched()"
                          floatLabelType="Auto" [showCheckBox]=true [treeSettings]='treeSettings'></ejs-dropdowntree>
      </div>


    </form>

    <form [ngClass]="selectedTab === 'dates' ? 'show' : 'hide'" [formGroup]="deliverableItemDateForm" #formDir="ngForm"
          class="form-horizontal" novalidate="">

      <div class="roww mxx-2">
        <div *ngFor="let deliverableDates of deliverableItemDates">
          <div class="d-flex" *ngIf="addDateToFilter(deliverableDates.deliverable_item_date_code)">
            <div class="form-group sf-outline-input mb-10 mr-2 w-100">
              <ejs-datepicker [formControlName]="strReplace(deliverableDates.deliverable_item_date_code) + '_from'" openOnFocus="true" [allowEdit]=false
                              [placeholder]='deliverableDates.deliverable_item_date_name + " From"' floatLabelType='Auto'
                              cssClass="e-filled">
              </ejs-datepicker>
            </div>

            <div class="form-group sf-outline-input mb-10 mr-2 w-100">
              <ejs-datepicker [formControlName]="strReplace(deliverableDates.deliverable_item_date_code) + '_to'" [allowEdit]="allowEdit"
                              [placeholder]="deliverableDates.deliverable_item_date_name+' To'" openOnFocus="true"
                              [min]="deliverableItemDateForm.value.client_content_receipt_deadline_from"
                              floatLabelType='Auto' cssClass="e-filled">
              </ejs-datepicker>
            </div>
  
            <div class="form-group sf-outline-input mb-10 w-75">
              <ejs-dropdownlist [formControlName]="'is_tentative_' + strReplace(deliverableDates.deliverable_item_date_code)"
                                [dataSource]='tentativeOptions'
                                floatLabelType="Auto"
                                [fields]='tentativeFields' cssClass="e-filled"
                                placeholder='Is Tentative'>
              </ejs-dropdownlist>
            </div>


          </div>
        </div>
        <!--        <div class="px-0 col-4">-->
<!-- 
        <div class="d-flex">


          
        </div>

        <div class="d-flex">
          <div class="form-group sf-outline-input mb-10 mr-2 w-100">
            <ejs-datepicker formControlName="shoot_date_from" openOnFocus="true" [allowEdit]=false
                            placeholder='Shoot Date From' floatLabelType='Auto' cssClass="e-filled">
            </ejs-datepicker>
          </div>

          <div class="form-group sf-outline-input mb-10 mr-2 w-100">
            <ejs-datepicker formControlName="shoot_date_to" [allowEdit]="allowEdit"
                            placeholder='Shoot Date To' openOnFocus="true"
                            [min]="deliverableItemDateForm.value.shoot_date_from"
                            floatLabelType='Auto' cssClass="e-filled">
            </ejs-datepicker>
          </div>

          <div class="form-group sf-outline-input mb-10 w-75">
            <ejs-dropdownlist formControlName="is_tentative_shoot_date" [dataSource]='tentativeOptions'
                              floatLabelType="Auto"
                              [fields]='tentativeFields' cssClass="e-filled"
                              placeholder='Is Tentative'>
            </ejs-dropdownlist>
          </div>
        </div>

        <div class="d-flex">
          <div class="form-group sf-outline-input mb-10 mr-2 w-100">
            <ejs-datepicker formControlName="microsite_submission_from" openOnFocus="true" [allowEdit]=false
                            placeholder='Microsite Submission From' floatLabelType='Auto' cssClass="e-filled">
            </ejs-datepicker>
          </div>

          <div class="form-group sf-outline-input mb-10 mr-2 w-100">
            <ejs-datepicker formControlName="microsite_submission_to" [allowEdit]="allowEdit"
                            placeholder='Microsite Submission To' openOnFocus="true"
                            [min]="deliverableItemDateForm.value.microsite_submission_from"
                            floatLabelType='Auto' cssClass="e-filled">
            </ejs-datepicker>
          </div>

          <div class="form-group sf-outline-input mb-10 w-75">
            <ejs-dropdownlist formControlName="is_tentative_microsite_submission" [dataSource]='tentativeOptions'
                              floatLabelType="Auto"
                              [fields]='tentativeFields' cssClass="e-filled"
                              placeholder='Is Tentative'>
            </ejs-dropdownlist>
          </div>
        </div>


        <div class="d-flex">
          <div class="form-group sf-outline-input mb-10 mr-2 w-100">
            <ejs-datepicker formControlName="article_submission_to_client_from" openOnFocus="true"
                            [allowEdit]=false
                            placeholder='Article Submission To Client From' floatLabelType='Auto'
                            cssClass="e-filled">
            </ejs-datepicker>
          </div>

          <div class="form-group sf-outline-input mb-10 mr-2 w-100">
            <ejs-datepicker formControlName="article_submission_to_client_to" [allowEdit]="allowEdit"
                            placeholder='Article Submission To Client To' openOnFocus="true"
                            [min]="deliverableItemDateForm.value.article_submission_to_client_from"
                            floatLabelType='Auto' cssClass="e-filled">
            </ejs-datepicker>
          </div>

          <div class="form-group sf-outline-input mb-10 w-75">
            <ejs-dropdownlist formControlName="is_tentative_article_submission_to_client"
                              [dataSource]='tentativeOptions'
                              floatLabelType="Auto"
                              [fields]='tentativeFields' cssClass="e-filled"
                              placeholder='Is Tentative'>
            </ejs-dropdownlist>
          </div>
        </div>

        <div class="d-flex">
          <div class="form-group sf-outline-input mb-10 mr-2 w-100">
            <ejs-datepicker formControlName="video_submission_to_client_from" openOnFocus="true" [allowEdit]=false
                            placeholder='Video Submission To Client From' floatLabelType='Auto'
                            cssClass="e-filled">
            </ejs-datepicker>
          </div>

          <div class="form-group sf-outline-input mb-10 mr-2 w-100">
            <ejs-datepicker formControlName="video_submission_to_client_to" [allowEdit]="allowEdit"
                            placeholder='Video Submission To Client To' openOnFocus="true"
                            [min]="deliverableItemDateForm.value.video_submission_to_client_from"
                            floatLabelType='Auto' cssClass="e-filled">
            </ejs-datepicker>
          </div>

          <div class="form-group sf-outline-input mb-10 w-75">
            <ejs-dropdownlist formControlName="is_tentative_video_submission_to_client"
                              [dataSource]='tentativeOptions'
                              floatLabelType="Auto" [value]="defaultTentativeValue"
                              [fields]='tentativeFields' cssClass="e-filled"
                              placeholder='Is Tentative'>
            </ejs-dropdownlist>
          </div>


        </div>

        <div class="d-flex">
          <div class="form-group sf-outline-input mb-10 mr-2 w-100">
            <ejs-datepicker formControlName="client_approval_deadline_from" openOnFocus="true" [allowEdit]=false
                            placeholder='Client Approval Deadline From' floatLabelType='Auto' cssClass="e-filled">
            </ejs-datepicker>
          </div>

          <div class="form-group sf-outline-input mb-10 mr-2 w-100">
            <ejs-datepicker formControlName="client_approval_deadline_to" [allowEdit]="allowEdit"
                            placeholder='Client Approval Deadline To' openOnFocus="true"
                            [min]="deliverableItemDateForm.value.client_approval_deadline_from"
                            floatLabelType='Auto' cssClass="e-filled">
            </ejs-datepicker>
          </div>

          <div class="form-group sf-outline-input mb-10 w-75">
            <ejs-dropdownlist formControlName="is_tentative_client_approval_deadline"
                              [dataSource]='tentativeOptions'
                              floatLabelType="Auto"
                              [fields]='tentativeFields' cssClass="e-filled"
                              placeholder='Is Tentative'>
            </ejs-dropdownlist>

          </div>
        </div>

        <div class="d-flex">
          <div class="form-group sf-outline-input mb-10 mr-2 w-100">
            <ejs-datepicker formControlName="publishing_date_from" openOnFocus="true" [allowEdit]=false
                            placeholder='Publishing Date From' floatLabelType='Auto' cssClass="e-filled">
            </ejs-datepicker>
          </div>

          <div class="form-group sf-outline-input mb-10 mr-2 w-100">
            <ejs-datepicker formControlName="publishing_date_to" [allowEdit]="allowEdit"
                            placeholder='Publishing Date To' openOnFocus="true"
                            [min]="deliverableItemDateForm.value.publishing_date_from"
                            floatLabelType='Auto' cssClass="e-filled">
            </ejs-datepicker>
          </div>

          <div class="form-group sf-outline-input mb-10 w-75">
            <ejs-dropdownlist formControlName="is_tentative_publishing_date" [dataSource]='tentativeOptions'
                              floatLabelType="Auto"
                              [fields]='tentativeFields' cssClass="e-filled"
                              placeholder='Is Tentative'>
            </ejs-dropdownlist>
          </div>
        </div> -->

      </div>


    </form>

    <div class="my-10 pb-20">
      <button id="resetbtn"
              class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5"
              type="button" (click)="clearForm()"
              data-ripple="true">Clear All
      </button>
      <button id="filter"
              class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block"
              type="button"
              (click)="getFilteredData()" [disabled]="formSent" data-ripple="true">Filter
      </button>
    </div>

  </div>
</ejs-sidebar>
