import { Component, OnInit, Input, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-search-dropdown',
  templateUrl: './search-dropdown.component.html',
})
export class SearchDropdownComponent implements OnInit {
    // Public properties
    @Input() layout = 'dropdown';
    // Set icon class name
    @Input() icon = 'flaticon2-search-1';

    // Set true to icon as SVG or false as icon class
    @Input() useSVG: boolean;

    @Input() type: 'brand' | 'success' | 'warning' = 'success';

    @ViewChild('searchInput', {static: true}) searchInput: ElementRef;

    data: any[] = null;
    loading: boolean;

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  search(e) {
    this.data = null;
    if (e.target.value.length > 1) {
      this.loading = true;
      // simulate getting search result
      setTimeout(() => {
        // Uncomment this. Right now it's just mock
        this.data = this.searchInData(e.target.value);
        // this.data = [documents, members, files];
        this.loading = false;
        this.cdr.markForCheck();
      }, 500);
    }
  }

  clear(e) {
    this.data = null;
    this.searchInput.nativeElement.value = '';
  }

  openChange() {
    setTimeout(() => this.searchInput.nativeElement.focus());
  }

  showCloseButton() {
    return this.data && this.data.length && !this.loading;
  }

  searchInData(searchText: string): any {
    searchText = searchText.toLowerCase().trim();
    const result = [];
    // const docsResult = this.searchInContainer(searchText, documents);
    // if (docsResult) {
    //   result.push(docsResult);
    // }

    // const membersResult = this.searchInContainer(searchText, members);
    // if (membersResult) {
    //   result.push(membersResult);
    // }

    // const filesResult = this.searchInContainer(searchText, files);
    // if (filesResult) {
    //   result.push(filesResult);
    // }

    return result;
  }

  searchInContainer(searchText, source) {
    const items = source.items.filter(
      el =>
        el.title.toLowerCase().indexOf(searchText) > -1 ||
        el.description.toLowerCase().indexOf(searchText) > -1
    );
    if (items.length === 0) {
      return undefined; // No results
    }

    return Object.assign({items}, source);
  }

}
