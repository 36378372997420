<ejs-sidebar id="sidebar-menu" #sidebarInstance type='Over' [width]='width' [position]='position'
             style="visibility: hidden" [enableGestures]="false" [showBackdrop]=true>

  <div class="offcanvas-close mt-n1 pr-5" style="float:right; padding: 48px;">
    <a href="javascript:;" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_panel_close"
       (click)="closeSidebar()">
      <i class="ki ki-close icon-xs text-muted"></i>
    </a>
  </div>

  <div class='p-15'>
    <h2 *ngIf="!projectStatusInput">Add Project Status</h2>
    <h2 *ngIf="projectStatusInput">Update Project Status</h2>

    <form id="formId" [formGroup]="projectStatusForm" #formDir="ngForm" class="form-horizontal" novalidate=""
          style="margin-top: 16px;">

      <div class="form-group sf-outline-input mb-10" [class.hide-label]="projectStatusForm.controls.project_status_parent_code.value == ''">
        <ejs-dropdowntree formControlName="project_status_parent_code" id='project_status_parent_code' allowMultiSelection='false'
                          [fields]='projectStatusFields' placeholder='Parent Project Status' floatLabelType="Auto" cssClass="e-filled"
                          (blur)="projectStatusForm.controls.project_status_parent_code.markAsTouched()"></ejs-dropdowntree>
        <div>Note: If a parent status isn't selected, the new project status will be added as a parent</div>
      </div>


      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="project_status_name" placeholder="Project Status Name *" cssClass="e-filled"
                     formControlName="project_status_name" floatLabelType="Auto"
                     (blur)="projectStatusForm.controls.project_status_name.markAsTouched()"
        ></ejs-textbox>
        <div *ngIf="projectStatusForm.controls.project_status_name.errors">
          <div
            *ngIf="projectStatusForm.controls.project_status_name.errors.required && projectStatusForm.controls.project_status_name.touched"
            class="e-error">
            Project Status Name is required.
          </div>
        </div>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="description" [multiline]="true" placeholder="Description" cssClass="e-filled"
                     formControlName="description" floatLabelType="Auto"
                     (blur)="projectStatusForm.controls.description.markAsTouched()"
        ></ejs-textbox>
        <div *ngIf="projectStatusForm.controls.description.errors">
          <div *ngIf="projectStatusForm.controls.description.errors.maxlength && projectStatusForm.controls.description.touched"
               class="e-error">
            Description cannot be more than 200 characters
          </div>
        </div>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-checkbox formControlName="is_notes_mandatory_yn" #wcheckbox label="Note Mandatory: " labelPosition="Before"></ejs-checkbox>
      </div>


      <div *ngIf="projectStatusForm.invalid" class="e-error">Please fill all fields</div>
      <div *ngIf="error$ | async as error" class="e-error">{{error.error.message}}</div>

      <div class="my-10 pb-20">
        <button id="resetbtn"
                class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5"
                type="button" (click)="clearForm()"

                data-ripple="true">Clear
        </button>
        <button id="addSubmit"
                class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit"
                (click)="onAddUpdateProjectStatus(projectStatusForm)" [disabled]="formSent" data-ripple="true">
          <span *ngIf="!projectStatusInput">Add Project Status</span>
          <span *ngIf="projectStatusInput">Update Project Status</span>
        </button>
      </div>
    </form>
  </div>
</ejs-sidebar>
