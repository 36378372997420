import { Component, OnInit, Output, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthNoticeService, AuthNotice } from 'src/app/core/store/auth/auth-notice.service';

@Component({
  selector: 'app-auth-notice',
  templateUrl: './auth-notice.component.html'
})
export class AuthNoticeComponent implements OnInit, OnDestroy {
  @Output() type: any;
  @Output() message: any = '';
  private subscriptions: Subscription[] = [];

  constructor(
    public authNoticeService: AuthNoticeService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(this.authNoticeService.onNoticeChanged$.subscribe((notice: AuthNotice) => {
      notice = Object.assign({}, { message: '', type: '' }, notice);
      this.message = notice.message;
      this.type = notice.type ? notice.type : 'light-danger';
      this.cdr.markForCheck();
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }
}
