import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {SidebarComponent} from '@syncfusion/ej2-angular-navigations';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';
import {DeliverableItem} from '../../../../../core/models/deliverable-item.model';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../core';
import {trimValidator} from '../../../../../app-validators';
import {
  AddDeliverableItems,
  AddDeliverableStatus,
  MetaDataResetError,
  UpdateDeliverableItems, UpdateDeliverableStatus
} from '../../../../../core/store/actions/common.actions';
import {DeliverableStatus} from '../../../../../core/models/deliverable-status.model';

@Component({
  selector: 'app-deliverable-status-form',
  templateUrl: './deliverable-status.component.html',
  styleUrls: ['./deliverable-status.component.scss']
})
export class DeliverableStatusComponent implements OnInit, OnChanges {
  @ViewChild('sidebarInstance') sidebarInstance: SidebarComponent;

  @Input() deliverableStatus: any;
  @Output() valueChange = new EventEmitter();
  deliverableStatusForm: FormGroup;

  public width = 'auto';
  public position = 'Right';

  deliverableStatusFields: any;
  formSent: boolean;
  errorMsg: any;
  error$: Observable<any>;
  success$: Observable<boolean>;

  public _deliverableStatusInput$: BehaviorSubject<DeliverableStatus | null> = new BehaviorSubject<DeliverableStatus | null>(null);

  @Input() set deliverableStatusInput(mode: DeliverableStatus | null) {
    this._deliverableStatusInput$.next(mode);
  }

  get deliverableStatusInput(): DeliverableStatus | null {
    return this._deliverableStatusInput$.getValue();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.sidebarInstance.isOpen) {
      this.closeSidebar();
    }
  }

  constructor(private formBuilder: FormBuilder, private store: Store<AppState>) {
  }

  ngOnInit(): void {
    if (!this.deliverableStatusForm) {
      this.generateDeliverableStatusForm();
    }

    this.error$ = this.store.pipe(select(store => store.metadata.error));
    this.error$.subscribe((data: any) => {

      if (data) {
        this.errorMsg = data.error.message;
        this.formSent = false;
      }
    });

    this.success$ = this.store.pipe(select(store => store.metadata.success));
    this.success$.subscribe((data: any) => {

      if (data) {
        this.formSent = false;
        this.closeSidebar();
      }
    });
  }

  generateDeliverableStatusForm() {
    this.deliverableStatusForm = this.formBuilder.group({
      deliverable_status_name: new FormControl('', [Validators.required, Validators.compose([trimValidator])]),
      description: new FormControl('', [Validators.maxLength(200)]),
      deliverable_status_parent_code: new FormControl(''),
    });
  }

  onAddUpdateDeliverableStatus(form: FormGroup, isUpdate = false) {
    if (!form.invalid) {
      this.formSent = true;
      if (!isUpdate) {
        this.store.dispatch(new AddDeliverableStatus({
          deliverable_status_name: form.value.deliverable_status_name,
          deliverable_status_parent_code: form.value.deliverable_status_parent_code ? form.value.deliverable_status_parent_code[0] : null,
          description: form.value.description
        }));
      } else {
        this.store.dispatch(new UpdateDeliverableStatus({
          deliverable_status_name: form.value.deliverable_status_name,
          deliverable_status_code: this._deliverableStatusInput$.getValue().deliverable_status_code,
          deliverable_status_parent_code: form.value.deliverable_status_parent_code ? form.value.deliverable_status_parent_code[0] : null,
          description: form.value.description
        }));
      }
    }
  }

  openSidebar(): void {
    this.sidebarInstance.show();
  }

  closeSidebar(): void {
    this.valueChange.emit('success');
    this.deliverableStatusForm.reset();
    this.sidebarInstance.hide();
    this.deliverableStatusForm.controls.deliverable_status_parent_code.setValue('');

    this.store.dispatch(new MetaDataResetError());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.deliverableStatus) {
      this.deliverableStatusFields = {
        dataSource: this.deliverableStatus,
        value: 'deliverable_status_code',
        text: 'deliverable_status_name',
        child: 'children'
      };
    }

    if (!this.deliverableStatusForm) {
      this.generateDeliverableStatusForm();
    }

    if (this._deliverableStatusInput$.getValue()) {
      this.deliverableStatusForm.setValue({
        deliverable_status_name: this._deliverableStatusInput$.getValue().deliverable_status_name,
        description: this._deliverableStatusInput$.getValue().description ? this._deliverableStatusInput$.getValue().description : null,
        deliverable_status_parent_code: this._deliverableStatusInput$.getValue().parent ? [this._deliverableStatusInput$.getValue().parent] : '',
      });
    }
  }
}
