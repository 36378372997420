import { Injectable } from '@angular/core';
import { HttpEvent, HttpRequest, HttpResponse, HttpInterceptor, HttpHandler } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import { startWith, tap } from 'rxjs/operators';
import 'rxjs/add/observable/of';

import { RequestCache } from './request-cache.service';
import {environment} from '../../../../../environments/environment.prod';

@Injectable()
export class CachingInterceptor implements HttpInterceptor {
  constructor(private cache: RequestCache) {}
  baseURL = environment.API_URL;
  urlsToCache: string[] = [
    this.baseURL + 'order-role',
    this.baseURL + 'company-type',
    this.baseURL + 'country',
    this.baseURL + 'user',
    this.baseURL + 'project-source-type',
    this.baseURL + 'company-role',
    this.baseURL + 'project-type',
    this.baseURL + 'project-status',
    this.baseURL + 'tag',
  ];

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const cachedResponse = this.cache.get(req);
    if (req.method !== 'GET') {
      return this.sendRequest(req, next, this.cache);
    } else {
      return cachedResponse ? Observable.of(cachedResponse) : this.sendRequest(req, next, this.cache);
    }
  }

  sendRequest(
    req: HttpRequest<any>,
    next: HttpHandler,
    cache: RequestCache): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse && this.urlsToCache.includes(req.url)) {
          cache.put(req, event);
        }
      })
    );
  }
}
