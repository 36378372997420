import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {SidebarComponent} from '@syncfusion/ej2-angular-navigations';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';
import {DeliverableItem} from '../../../../../core/models/deliverable-item.model';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../core';
import {trimValidator} from '../../../../../app-validators';
import {
  AddCostCenter,
  MetaDataResetError, UpdateCostCenter,
} from '../../../../../core/store/actions/common.actions';
import {CostCenter} from '../../../../../core/models/cost-center.model';

@Component({
  selector: 'app-cost-center-form',
  templateUrl: './cost-center.component.html',
  styleUrls: ['./cost-center.component.scss']
})
export class CostCenterComponent implements OnInit, OnChanges {
  @ViewChild('sidebarInstance') sidebarInstance: SidebarComponent;

  @Output() valueChange = new EventEmitter();
  costCenterForm: FormGroup;

  public width = 'auto';
  public position = 'Right';

  formSent: boolean;
  errorMsg: any;
  error$: Observable<any>;
  success$: Observable<boolean>;

  public _costCenterInput$: BehaviorSubject<CostCenter | null> = new BehaviorSubject<CostCenter | null>(null);

  @Input() set costCenterInput(mode: CostCenter | null) {
    this._costCenterInput$.next(mode);
  }

  get costCenterInput(): CostCenter | null {
    return this._costCenterInput$.getValue();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.sidebarInstance.isOpen) {
      this.closeSidebar();
    }
  }

  constructor(private formBuilder: FormBuilder, private store: Store<AppState>) {
  }

  ngOnInit(): void {
    if (!this.costCenterForm) {
      this.generateCostCenterForm();
    }

    this.error$ = this.store.pipe(select(store => store.metadata.error));
    this.error$.subscribe((data: any) => {

      if (data) {
        this.errorMsg = data.error.message;
        this.formSent = false;
      }
    });

    this.success$ = this.store.pipe(select(store => store.metadata.success));
    this.success$.subscribe((data: any) => {

      if (data) {
        this.formSent = false;
        this.closeSidebar();
      }
    });
  }

  generateCostCenterForm() {
    this.costCenterForm = this.formBuilder.group({
      cost_center_name: new FormControl('', [Validators.required, Validators.compose([trimValidator])]),
    });
  }

  onAddUpdateCostCenter(form: FormGroup, isUpdate = false) {
    if (!form.invalid) {
      this.formSent = true;
      if (!isUpdate) {
        this.store.dispatch(new AddCostCenter({cost_center_name: form.value.cost_center_name}));
      } else {
        this.store.dispatch(new UpdateCostCenter({cost_center_name: form.value.cost_center_name,
          cost_center_code: this._costCenterInput$.getValue().cost_center_code}));
      }
    }
  }

  openSidebar(): void {
    this.sidebarInstance.show();
  }

  closeSidebar(): void {
    this.valueChange.emit('success');
    this.costCenterForm.reset();
    this.sidebarInstance.hide();

    this.store.dispatch(new MetaDataResetError());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.costCenterForm) {
      this.generateCostCenterForm();
    }

    if (this._costCenterInput$.getValue()) {
      this.costCenterForm.setValue({
        cost_center_name: this._costCenterInput$.getValue().cost_center_name
      });
    }
  }

}
