import {Injectable} from '@angular/core';
import {ResponseData} from '../../ResponseData.interface';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {v4 as uuid} from 'uuid';
import {environment} from '../../../../environments/environment.prod';

const CONTACT_ACTIVITY_URL = environment.API_URL + 'contact-activity';
const PROJECT_ACTIVITY_URL = environment.API_URL + 'project-activity';
const COMPANY_ACTIVITY_URL = environment.API_URL + 'company-activity';

@Injectable()
export class ActivityService {
  id: string;

  constructor(private http: HttpClient) {
  }

  getActivity(code: string, entity: string) {
    return this.http.get<ResponseData>(this.getActivityUrl(entity) + '/' + code);
  }

  addActivity(code: string, activityType: string, activityTitle: string, activityNote: string,
              meeting_date: string, meeting_start_time: string, meeting_end_time: string, user_code: string[],
              activity_participant_code: string[], tags: string[], entitiy: string):
    Observable<ResponseData> {
    this.id = uuid();

    let request: any = {
      activity_type_code: activityType, activity_note: activityNote,
      activity_title: activityTitle, meeting_date, meeting_start_time, meeting_end_time, user_code, activity_participant_code,
      activity_tag: tags, batch_id: this.id
    };

    switch (entitiy) {
      case 'project':
        request = {...request, project_code: code};
        break;
      case 'contact':
        request = {...request, contact_code: code};
        break;
      case 'company':
        request = {...request, company_code: code};
        break;
    }

    return this.http.post<ResponseData>(this.getActivityUrl(entitiy), request);
  }

  deleteActivity(code: string, entity: string) {
    return this.http.delete<ResponseData>(this.getActivityUrl(entity) + '/' + code);
  }

  updateActivity(code: string, activity: any, entity: string): Observable<ResponseData> {
    this.id = uuid();

    return this.http.put<ResponseData>(this.getActivityUrl(entity) + '/' + code, {
      activity_title: activity.activityTitle, activity_note: activity.activityNote, activity_type: activity.activity_type,
      activity_tag: activity.activityTags, batch_id: this.id,
      user_code: activity.userParticipants, activity_participant_code: activity.contactParticipants, meeting_date: activity.meeting_date,
      meeting_start_time: activity.meeting_start_time, meeting_end_time: activity.meeting_end_time
    });
  }

  getActivityFilteredResults(entity: string, parameters, code: string) {
    return this.http.get<ResponseData>(this.getActivityUrl(entity) + '/' + code + '/filter', {params: parameters});
  }

  getActivityUrl(entity: string) {
    switch (entity) {
      case 'contact':
        return CONTACT_ACTIVITY_URL;
      case 'project':
        return PROJECT_ACTIVITY_URL;
      case 'company':
        return COMPANY_ACTIVITY_URL;
    }
  }
}
