import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation} from '@angular/core';
import {SidebarComponent} from '@syncfusion/ej2-angular-navigations';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';
import {CostCenter} from '../../../../../core/models/cost-center.model';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../core';
import {trimValidator} from '../../../../../app-validators';
import {
  AddCostCenter,
  AddDeliverableItemDate,
  MetaDataResetError,
  UpdateCostCenter, UpdateDeliverableItemDate
} from '../../../../../core/store/actions/common.actions';
import {DeliverableItemDate} from '../../../../../core/models/admin/deliverable-item-date.model';
import { NumericTextBoxComponent } from '@syncfusion/ej2-angular-inputs';

@Component({
  selector: 'app-deliverable-item-date-form',
  templateUrl: './deliverable-item-date.component.html',
  styleUrls: ['./deliverable-item-date.component.scss'],
})
export class DeliverableItemDateComponent implements OnInit, OnChanges {
  @ViewChild('sidebarInstance') sidebarInstance: SidebarComponent;
  @ViewChild('rankOrder') rankOrder: NumericTextBoxComponent;

  @Input() maxValue: number;
  @Output() valueChange = new EventEmitter();

  deliverableItemDateForm: FormGroup;

  public width = 'auto';
  public position = 'Right';

  public minValue: number = 1;
  
  formSent: boolean;
  errorMsg: any;
  error$: Observable<any>;
  success$: Observable<boolean>;

  public _deliverableItemDateInput$: BehaviorSubject<DeliverableItemDate | null> = new BehaviorSubject<DeliverableItemDate | null>(null);

  @Input() set deliverableItemDateInput(mode: DeliverableItemDate | null) {
    this._deliverableItemDateInput$.next(mode);
  }

  get deliverableItemDateInput(): DeliverableItemDate | null {
    return this._deliverableItemDateInput$.getValue();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.sidebarInstance.isOpen) {
      this.closeSidebar();
    }
  }

  constructor(private formBuilder: FormBuilder, private store: Store<AppState>) {
  }

  ngOnInit(): void {
    if (!this.deliverableItemDateForm) {
      this.generateDeliverableItemDateForm();
    }

    this.error$ = this.store.pipe(select(store => store.metadata.error));
    this.error$.subscribe((data: any) => {

      if (data) {
        this.errorMsg = data.error.message;
        this.formSent = false;
      }
    });

    this.success$ = this.store.pipe(select(store => store.metadata.success));
    this.success$.subscribe((data: any) => {

      if (data) {
        this.formSent = false;
        this.closeSidebar();
      }
    });
  }

  generateDeliverableItemDateForm() {
    this.deliverableItemDateForm = this.formBuilder.group({
      deliverable_item_date_name: new FormControl('', [Validators.required, Validators.compose([trimValidator])]),
      filter_color: new FormControl('', [Validators.required]),
      short_code: new FormControl('', [Validators.required, Validators.maxLength(6)]),
      filter_text_color: new FormControl('', [Validators.required]),
      rank_order: new FormControl('', [Validators.required]),
    });
  }

  onAddUpdateDeliverableItemDate(form: FormGroup, isUpdate = false) {
     if (!form.invalid) {
      this.formSent = true;
      if (!isUpdate) {
        this.store.dispatch(new AddDeliverableItemDate({deliverable_item_date_name: form.value.deliverable_item_date_name,
        filter_color: form.value.filter_color, short_code: form.value.short_code, filter_text_color: form.value.filter_text_color, rank_order: this.rankOrder.value}));
      } else {
        this.store.dispatch(new UpdateDeliverableItemDate({deliverable_item_date_name: form.value.deliverable_item_date_name,
          filter_color: form.value.filter_color, short_code: form.value.short_code,
          deliverable_item_date_code: this._deliverableItemDateInput$.getValue().deliverable_item_date_code,
          filter_text_color: form.value.filter_text_color, rank_order: this.rankOrder.value}));
      }
    }
  }

  openSidebar(): void {
    this.sidebarInstance.show();
  }

  closeSidebar(): void {
    this.valueChange.emit('success');
    this.deliverableItemDateForm.reset();
    this.sidebarInstance.hide();

    this.store.dispatch(new MetaDataResetError());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.deliverableItemDateForm) {
      this.generateDeliverableItemDateForm();
    }

    if (this._deliverableItemDateInput$.getValue()) {
      this.deliverableItemDateForm.setValue({
        deliverable_item_date_name: this._deliverableItemDateInput$.getValue().deliverable_item_date_name,
        filter_color: this._deliverableItemDateInput$.getValue().filter_color,
        short_code: this._deliverableItemDateInput$.getValue().short_code,
        filter_text_color: this._deliverableItemDateInput$.getValue().filter_text_color,
        rank_order: this._deliverableItemDateInput$.getValue().rank_order,
      });
    }
  }

}
