import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';

export function telephoneValidator(control: FormControl): { [key: string]: any } {
  // const telephoneRegex = new RegExp('^\d+$');
  const telephoneRegex = new RegExp('^[0-9 ,/]+$');
  // const telephoneRegex = /^(?:\+971|971|00971|0|\+966|966|00966)(?:50|51|52|54|55|56|58)[0-9]{7}$/;
  if (control.value) {
    if (!telephoneRegex.test(control.value)) {
      return {invalidNumber: true};
    }
  }
}

export function websiteValidator(control: FormControl): { [key: string]: any } {
  const websiteRegex = new RegExp('^(https?:\\/\\/)?(www\\.)?([a-zA-Z0-9]+(-?[a-zA-Z0-9])*\\.)+[\\w]{2,}(\\/\\S*)?$');
  if (control.value) {
    if (!websiteRegex.test(control.value)) {
      return {invalidurl: true};
    }
  }
}

export function emailValidator(control: FormControl): { [key: string]: any } {
  const emailRegex = new RegExp('^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$');
  // const emailRegex = new RegExp('^[a-zA-Z0–9_.+-]+@[a-zA-Z0–9-]+.[a-zA-Z0–9-.]+$');

  if (control.value) {
    if (!emailRegex.test(control.value)) {
      return {invalidEmail: true};
    }
  }
}

export function matchValidator(group: FormGroup): { [key: string]: any } {
  if (group.value.new_password !== group.value.confirm_new_password) {
    return {invalidMatch: true};
  }
}

export function trimValidator(control: FormControl): { [key: string]: any } {
  if (!control.value || typeof control.value === 'string' && !control.value.trim()) {
    return { required: true };
  }
}

export function specialCharacterValidator(control: FormControl): { [key: string]: any } {
  const splcharacterRegex = new RegExp('^[\\w\\s]+$');
  if (control.value) {
    if (!splcharacterRegex.test(control.value)) {
      return {invalidText: true};
    }
  }
}

export function nameValidator(control: FormControl): { [key: string]: any } {
  const splcharacterRegex = new RegExp('^[a-zA-Z][0-9a-zA-Z \'-]*$');  // Add ., in regex later if needed
  if (control.value) {
    if (!splcharacterRegex.test(control.value)) {
      return {invalidText: true};
    }
  }
}

export function companyNameValidator(control: FormControl): { [key: string]: any } {
  const splcharacterRegex = new RegExp('^[À-ÿa-zA-Z0-9\\s\\-\\_\\(\\)\\[\\]\\&\\/\\,\\.\\+\'\\:]+$');
  if (control.value) {
    if (!splcharacterRegex.test(control.value)) {
      return {invalidCompanyName: true};
    }
  }
}

export const atLeastOne = (validator: ValidatorFn, controls: string[] = null) => (group: FormGroup,): ValidationErrors | null => {
  if (!controls){
    controls = Object.keys(group.controls)
  }

  const hasAtLeastOne = group && group.controls && controls
    .some(k => !validator(group.controls[k]));

  return hasAtLeastOne ? null : {
    atLeastOne: true,
  };
};

export function fromToDate(fromDateField: string, toDateField: string, errorName: string = 'fromToDate'): ValidatorFn {
  return (formGroup: AbstractControl): { [key: string]: boolean } | null => {
    const fromDate = formGroup.get(fromDateField).value;
    const toDate = formGroup.get(toDateField).value;
    // Ausing the fromDate and toDate are numbers. In not convert them first after null check
    if ((fromDate !== null && toDate !== null && toDate !== '') && fromDate > toDate) {
      return {[errorName]: true};
    }
    return null;
  };
}

export function whiteSpaceValidator(control: FormControl): { [key: string]: any } {
  if (control.value.startsWith(' ')) {
    return {
      trimError: 'control has leading whitespace'
    };
  }
  if (control.value.endsWith(' ')) {
    return {
      trimError: 'control has trailing whitespace'
    };
  }

  return null;
};


