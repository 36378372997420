import {Component, HostListener, OnInit} from '@angular/core';
import {CompanyDetailsComponent} from '../../../pages/company/company-details/company-details.component';
import {ActionsService} from '../../../../core/services/actions.service';
import {Router} from '@angular/router';
import {BaseComponent} from '../../../common/base.component';
import {AppService} from '../../../../core/services/app.service';

@Component({
  selector: 'app-action-dropdown-menu',
  templateUrl: './action-dropdown-menu.component.html',
})
export class ActionDropdownMenuComponent extends BaseComponent implements OnInit {
  message: string;

  constructor(private actionsService: ActionsService, private router: Router, protected appService: AppService) {
    super(appService);
  }

  ngOnInit(): void {
    this.actionsService.currentMessage.subscribe(message => this.message = message);
  }

  edit() {
    this.actionsService.changeMessage('edit');
  }

  merge() {
    this.actionsService.changeMessage('merge');
  }

  delete() {
    this.actionsService.changeMessage('delete');
  }

  checkEditPermission() {
    if (this.router.url.indexOf('/company/details/') > -1) {
      return this.canUpdate(this.FEATURE_INSTANCE.COMPANY);
    } else if (this.router.url.indexOf('/project/details/') > -1) {
      return this.canUpdate(this.FEATURE_INSTANCE.PROJECT);
    } else if (this.router.url.indexOf('/contact/details/') > -1 || this.router.url.indexOf('/contact') > -1) {
      return this.canUpdate(this.FEATURE_INSTANCE.CONTACT);
    } else if (this.router.url.indexOf('/orders/details/') > -1) {
      return this.canUpdate(this.FEATURE_INSTANCE.ORDER);
    }
  }

  checkDeletePermission() {
    if (this.router.url.indexOf('/company/details/') > -1) {
      return this.canDelete(this.FEATURE_INSTANCE.COMPANY);
    } else if (this.router.url.indexOf('/project/details/') > -1) {
      return this.canDelete(this.FEATURE_INSTANCE.PROJECT);
    } else if (this.router.url.indexOf('/contact/details/') > -1) {
      return this.canDelete(this.FEATURE_INSTANCE.CONTACT);
    } else if (this.router.url.indexOf('/orders/details/') > -1) {
      return this.canDelete(this.FEATURE_INSTANCE.ORDER);
    }
  }

  isVisible() {
    return this.router.url.indexOf('/contact') > -1 && this.router.url.indexOf('/contact/details') === - 1;
  }

  isVisibleContact() {
    return this.router.url.indexOf('/contact/details') > -1 || this.router.url.indexOf('/orders/details/') > -1
      || this.router.url.indexOf('/company/details/') > -1 || this.router.url.indexOf('/project/details/') > -1;
  }
}
