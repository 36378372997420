import {Component, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../../../core';
import {ActivatedRoute, Router} from '@angular/router';
import {SidebarComponent} from '@syncfusion/ej2-angular-navigations';
import {Observable} from 'rxjs';
import {debounce, EmitType} from '@syncfusion/ej2-base';
import * as moment from 'moment';
import {
  CompanyFilter,
  CompanyOrder, CompanyOrderDeliverablesFilter,
  CompanyOrderFilter,
  GetAllCompanyOrders, GetCompanyOrderDeliverable
} from '../../../../../../../core/store/actions/company.actions';
import {FilteringEventArgs} from '@syncfusion/ej2-angular-dropdowns';
import {Query} from '@syncfusion/ej2-data';
import { LoadDeliverableItemDate } from 'src/app/core/store/actions/common.actions';
import * as _ from 'lodash';

@Component({
  selector: 'app-deliverable-filter',
  templateUrl: './deliverable-filter.component.html',
  styleUrls: ['./deliverable-filter.component.scss']
})
export class DeliverableFilterComponent implements OnInit, OnChanges {

  @ViewChild('sidebarInstance') public sidebarInstance: SidebarComponent;

  @Input() users: object;
  @Input() deliverableStatus: object;
  @Input() costCenter: object;
  @Input() companyList: any;
  @Input() deliverableItem: [];
  @Input() lineItem: [];
  @Input() isPopulateSidebar: any;

  public width = 'auto';
  public position = 'Right';
  queryParams: any;

  error$: Observable<any>;
  success$: Observable<boolean>;

  selectedTab = 'deliverable';

  formSent: boolean;
  isFilterReset = false;
  companyFields: object = {value: 'company_code', text: 'company_name'};
  userFields: object = {value: 'user_code', text: 'first_name'};
  costCenterFields = {value: 'cost_center_code', text: 'cost_center_name'};
  deliverableItemFields: any = {value: 'deliverable_item_code', text: 'deliverable_item_name'};
  lineItemFields: object;
  deliverableStatusFields: any;
  tentativeFields = {value: 'tentative_value', text: 'tentative'};

  public treeSettings: object = {autoCheck: true};

  allowEdit = false;
  isFiltering = false;
  durationTo: any;

  deliverableItemDateForm: FormGroup;

  filterForm: FormGroup;
  defaultTentativeValue = 'all';
  deliverableItemDates = [];

  tentativeOptions = [
    {
      tentative_value: 'tentative',
      tentative: 'Tentative'
    },
    {
      tentative_value: 'not-tentative',
      tentative: 'Not Tentative'
    }
  ];

  setDebounce = debounce((e, entity: string) => {
    this.onFilter(e, entity);
  }, 700);

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.sidebarInstance.isOpen) {
      this.closeSidebar();
    }
  }


  constructor(private formBuilder: FormBuilder, private store: Store<AppState>, private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.generateFilterForm();
    this.getDeliverableDates();

    this.success$ = this.store.pipe(select(store => store.companies.success.isLoaded));
    this.error$ = this.store.pipe(select(store => store.companies.error));

    this.success$.subscribe((data: any) => {
      if (data) {
        this.formSent = false;
        if (!this.isFilterReset && !this.isFiltering) {
          this.closeSidebar();
        }
        this.isFiltering = false;
        // this.store.dispatch(new ProjectSetStatusNull());
      }
    });

    this.error$.subscribe((data: any) => {
      if (data) {
        this.formSent = false;
      }
    });


  }


  generateFilterForm() {
    this.filterForm = this.formBuilder.group({
      created_at_from: new FormControl(null),
      created_at_to: new FormControl(null),
      updated_at_from: new FormControl(null),
      updated_at_to: new FormControl(null),
      duration_from: new FormControl(null),
      duration_to: new FormControl(null),
      deliverable_item_code: new FormControl([]),
      deliverable_status_code: new FormControl([]),
      cost_center_code: new FormControl([]),
      company_code: new FormControl([]),
      updated_by: new FormControl(null),
      created_by: new FormControl(null),
      order_item_code: new FormControl([]),
      deliverable_owner_code: new FormControl(null),
      deliverable_date_owner_code: new FormControl(null),
      expiry_from: new FormControl(null),
      expiry_to: new FormControl(null),
    });

    this.deliverableItemDateForm = this.formBuilder.group({
      // client_content_receipt_deadline_from: new FormControl(null),
      // client_content_receipt_deadline_to: new FormControl(null),
      // is_tentative_client_content_receipt_deadline: new FormControl(null),
      // shoot_date_from: new FormControl(null),
      // shoot_date_to: new FormControl(null),
      // is_tentative_shoot_date: new FormControl(null),
      // microsite_submission_from: new FormControl(null),
      // microsite_submission_to: new FormControl(null),
      // is_tentative_microsite_submission: new FormControl(null),
      // article_submission_to_client_from: new FormControl(null),
      // article_submission_to_client_to: new FormControl(null),
      // is_tentative_article_submission_to_client: new FormControl(null),
      // video_submission_to_client_from: new FormControl(null),
      // video_submission_to_client_to: new FormControl(null),
      // is_tentative_video_submission_to_client: new FormControl(null),
      // client_approval_deadline_from: new FormControl(null),
      // client_approval_deadline_to: new FormControl(null),
      // is_tentative_client_approval_deadline: new FormControl(null),
      // publishing_date_from: new FormControl(null),
      // publishing_date_to: new FormControl(null),
      // is_tentative_publishing_date: new FormControl(null)
    });

    // this.deliverableItemDateForm.controls.is_tentative_client_content_receipt_deadline.setValue('all');
    // this.deliverableItemDateForm.controls.is_tentative_shoot_date.setValue('all');
    // this.deliverableItemDateForm.controls.is_tentative_microsite_submission.setValue('all');
    // this.deliverableItemDateForm.controls.is_tentative_article_submission_to_client.setValue('all');
    // this.deliverableItemDateForm.controls.is_tentative_video_submission_to_client.setValue('all');
    // this.deliverableItemDateForm.controls.is_tentative_client_approval_deadline.setValue('all');
    // this.deliverableItemDateForm.controls.is_tentative_publishing_date.setValue('all');

    this.route.queryParams.subscribe(res => {
      if (Object.keys(res).length !== 0) {
        this.queryParams = res;
      }
      // console.log(res);
      // let filterVal = {...this.filterForm.value};
      // filterVal = {...filterVal, ...res};
      // // console.log(filterVal);
      // for (const fKey in filterVal) {
      //   if (this.filterFormArray.includes(fKey)) {
      //     // console.log(filterVal[fKey]);
      //     if (filterVal[fKey]) {
      //       filterVal[fKey] = filterVal[fKey].split(',');
      //     }
      //   }
      // }

      // this.filterForm.setValue({...filterVal});
      // this.filterForm.controls.project_type_code.setValue(res.project_type_code.split(','));
      // console.log(this.filterForm.value);
      // console.log(filterVal);
      // const queryCopy = {...res};
      // delete queryCopy.project_type_code;
      // this.filterForm.patchValue(queryCopy);
      // console.log(this.filterForm.value);
      // console.log(queryCopy);
      // const resCopy = {...res};
      // const types = resCopy.project_type_code.toString().split(',');
      // console.log(types);
      // this.filterForm.setValue(res);
      // this.filterForm.setValue({company_type_code: types});
      // this.filterForm.controls.project_type_code.value = types;
      // this.filterForm.patchValue({project_type_code: types});
      // console.log(this.filterForm.value);
    });
  }

  getFilteredData() {
    console.log(this.filterForm.value)
    this.isFilterReset = false;

    const deliverableDates = {...this.deliverableItemDateForm.value};
    for (const deliverableDate in deliverableDates) {
      if (!deliverableDate.includes('is_tentative') && deliverableDates[deliverableDate] !== null && deliverableDates[deliverableDate] !== '') {
        deliverableDates[deliverableDate] = moment(deliverableDates[deliverableDate]).format('YYYY-MM-DD');
      }
    }

    let filterValues = {...this.filterForm.value, ...deliverableDates};
    console.log(filterValues);

    Object.keys(filterValues).forEach((key) => ((filterValues[key] == null) || (filterValues[key] === '') || (filterValues[key] === 'Invalid date') || (filterValues[key] === false)) && delete filterValues[key]);

    if (filterValues.duration_from) {
      filterValues.start_date = moment(filterValues.duration_from).format('YYYY-MM-DD');
    }
    delete filterValues.duration_from;

    if (filterValues.duration_to) {
      filterValues.end_date = moment(filterValues.duration_to).format('YYYY-MM-DD');
    }
    delete filterValues.duration_to;

    if (filterValues.expiry_from) {
      filterValues.expiry_from = moment(filterValues.expiry_from).format('YYYY-MM-DD');
    }

    if (filterValues.expiry_to) {
      filterValues.expiry_to = moment(filterValues.expiry_to).format('YYYY-MM-DD');
    }

    if (filterValues.created_at_from) {
      filterValues.created_at_start = moment(filterValues.created_at_from).format('YYYY-MM-DD');
    }
    delete filterValues.created_at_from;

    if (filterValues.created_at_to) {
      filterValues.created_at_end = moment(filterValues.created_at_to).format('YYYY-MM-DD');
    }
    delete filterValues.created_at_to;

    if (filterValues.updated_at_from) {
      filterValues.updated_at_start = moment(filterValues.updated_at_from).format('YYYY-MM-DD');
    }
    delete filterValues.updated_at_from;

    if (filterValues.updated_at_to) {
      filterValues.updated_at_end = moment(filterValues.updated_at_to).format('YYYY-MM-DD');
    }
    delete filterValues.updated_at_to;

    for (const filterValuesKey in filterValues) {
      if (filterValues[filterValuesKey] instanceof Array) {
        filterValues[filterValuesKey] = filterValues[filterValuesKey].join(',');
      }
    }




    this.router.navigate([], {queryParams: filterValues});
    filterValues = {...filterValues, page_size: 100, page_no: 1};
    this.store.dispatch(new CompanyOrderDeliverablesFilter(filterValues));
  }

  clearForm() {
    this.isFilterReset = true;
    this.router.navigate([], {queryParams: {}});
    this.filterForm.reset();
    this.deliverableItemDateForm.reset();
    this.filterForm.controls.order_item_code.setValue([]);
    this.filterForm.controls.deliverable_item_code.setValue([]);
    this.filterForm.controls.deliverable_status_code.setValue([]);
    // this.filterForm.controls.company_code.setValue([]);
    this.filterForm.controls.cost_center_code.setValue([]);


    this.isFiltering = false;

    this.store.dispatch(new GetCompanyOrderDeliverable({page_size: 100, page_no: 1}));
  }

  openClick(): void {
    this.sidebarInstance.show();
  }

  public closeSidebar(): void {
    this.sidebarInstance.hide();
  }

  isCompanyLink(): boolean {
    return (this.router.url.indexOf('/company/') > -1);
  }

  setTab(args) {
    this.selectedTab = args;
  }

  public onFiltering = (e, entity: string) => {
    e.preventDefaultAction = true;
    this.setDebounce(e, entity);
  };

  public onFilter: EmitType<any> = (e: FilteringEventArgs, entity: string) => {
    let query = new Query();
    if (entity === 'company') {

      query = (e.text !== '') ? query.where('company_name', 'startswith', e.text, true) : query;
      const filterParams = {company_name: e.text, page_size: 100, page_no: 1};
      this.store.dispatch(new CompanyFilter(filterParams));
      this.store.select(store => store.companies.companies).subscribe((data) => {
        if (data && data.result) {
          this.companyList = data.result;
          e.updateData(this.companyList, query);
        }
      });

      this.isFiltering = true;
    }
  };

  setDuration(args) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.deliverableItem) {
      this.deliverableItemFields = {
        value: 'deliverable_item_code',
        text: 'deliverable_item_name',
        dataSource: this.deliverableItem,
        child: 'children'
      };
    }

    if (changes.deliverableStatus) {
      this.deliverableStatusFields = {
        value: 'deliverable_status_code',
        text: 'deliverable_status_name',
        dataSource: this.deliverableStatus,
        child: 'children'
      };
    }

    if (changes.lineItem) {
      this.lineItemFields = {
        value: 'order_item_code',
        text: 'order_item_name',
        dataSource: this.lineItem,
        child: 'children'
      };
    }

    if (changes.isPopulateSidebar) {
      this.populateSidebar(this.queryParams);
    }
  }

  getDeliverableDates() {
    this.store.dispatch(new LoadDeliverableItemDate());

    this.store.select(store => store.metadata.deliverableItemDate).subscribe((data) => {
      if (data && data.result) {
        this.deliverableItemDates = data.result;
        // this.deliverableItemDatesCopy = _.cloneDeep(this.deliverableItemDates);
      }
    });
  }

  strReplace(text) {
    if (text !== null) {
      return text.toString().replace(/-/g, '_');
    }
  }

  addDateToFilter(date) {
    const deliverableFound = this.deliverableItemDates.find(item => item.deliverable_item_date_code === date);
    if (deliverableFound) {
      this.deliverableItemDateForm.addControl('is_tentative_' + deliverableFound.deliverable_item_date_code.toString().replace(/-/g, '_'), new FormControl(false));
      this.deliverableItemDateForm.addControl(deliverableFound.deliverable_item_date_code.toString().replace(/-/g, '_') + '_to', new FormControl(false));
      this.deliverableItemDateForm.addControl(deliverableFound.deliverable_item_date_code.toString().replace(/-/g, '_') + '_from', new FormControl(true));

      // if (this.deliverableItemDateForm.controls['is_tentative_' + deliverableFound.deliverable_item_date_code.toString().replace(/-/g, '_')].value === false) {
      //   this.deliverableItemDateForm.controls['is_tentative_' + deliverableFound.deliverable_item_date_code.toString().replace(/-/g, '_')].setValue('all');
      // }
      return true;
    } else {
      return false;
    }
  }

  populateSidebar(filters) {
    for (const key in filters) {

      if (filters[key]) {
        this.filterForm.controls[key].setValue(_.cloneDeep(filters[key].split(',')));
      }
    }
  }
}
