<ejs-sidebar id="sidebar-menu" #sidebarInstance type='Over' [width]='width' [position]='position'
             style="visibility: hidden" [enableGestures]="false" [showBackdrop]=true>

  <div class="offcanvas-close mt-n1 pr-5" style="float:right; padding: 48px;">
    <a href="javascript:;" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_panel_close"
       (click)="closeSidebar()">
      <i class="ki ki-close icon-xs text-muted"></i>
    </a>
  </div>

  <div class='p-15'>
    <h2 *ngIf="!companyOrderInput && isOrderForm">Add Company Order</h2>
    <h2 *ngIf="companyOrderInput && isOrderForm">Update Company Order</h2>

    <h2 *ngIf="isContactRoleForm && !isContactRoleUpdate">Add Contact Role </h2>
    <h2 *ngIf="isContactRoleUpdate && isContactRoleForm">Update Contact Role</h2>

    <h2 *ngIf="isDriveLinkForm && !isDriveLinkUpdate">Add Company Drive Link</h2>
    <h2 *ngIf="isDriveLinkForm && isDriveLinkUpdate">Update Company Drive Link</h2>

    <form *ngIf="isOrderForm" id="formId" [formGroup]="companyOrderForm" #formDir="ngForm" class="form-horizontal"
          novalidate=""
          style="margin-top: 16px;">

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="order_name" placeholder="Order Name *" cssClass="e-filled"
                     formControlName="order_name" floatLabelType="Auto"
                     (blur)="companyOrderForm.controls.order_name.markAsTouched()"
        ></ejs-textbox>
        <div *ngIf="companyOrderForm.controls.order_name.errors">
          <div
            *ngIf="companyOrderForm.controls.order_name.errors.maxlength && companyOrderForm.controls.order_name.touched"
            class="e-error">
            Order name cannot be more than 50 characters
          </div>
          <div
            *ngIf="companyOrderForm.controls.order_name.errors.required && companyOrderForm.controls.order_name.touched"
            class="e-error">
            Order Name is required.
          </div>
        </div>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="notes" [multiline]="true" placeholder="Notes" cssClass="e-filled"
                     formControlName="notes" floatLabelType="Auto"
                     (blur)="companyOrderForm.controls.notes.markAsTouched()"
        ></ejs-textbox>
        <div *ngIf="companyOrderForm.controls.notes.errors">
          <div
            *ngIf="companyOrderForm.controls.notes.errors.maxlength && companyOrderForm.controls.notes.touched"
            class="e-error">
            Notes cannot be more than 250 characters
          </div>
        </div>
      </div>

      <div class="d-flex">
        <div class="form-group sf-outline-input mb-10 mr-2 w-65">
          <ejs-numerictextbox id="amount" placeholder="Amount *" cssClass="e-filled" maxlength="15"
                       formControlName="amount" floatLabelType="Auto" [showSpinButton]=false min="0"
                       (blur)="companyOrderForm.controls.amount.markAsTouched()"
          ></ejs-numerictextbox>
          <div *ngIf="companyOrderForm.controls.amount.errors">
            <div *ngIf="companyOrderForm.controls.amount.errors.required && companyOrderForm.controls.amount.touched "
                 class="e-error">
              Amount is required
            </div>
            <div *ngIf="companyOrderForm.controls.amount.errors.maxlength && companyOrderForm.controls.amount.touched "
                 class="e-error">
              Amount cannot be more than 15 digits
            </div>
          </div>
        </div>

        <div class="form-group sf-outline-input mb-10 w-35 ml-2">
          <ejs-dropdownlist showClearButton="true" #company id='currency_code' formControlName="currency_code" floatLabelType="Auto" cssClass="e-filled"
                            [dataSource]='currency' [fields]='currencyFields' placeholder='Currency *'
                            [allowFiltering]='true' (blur)="companyOrderForm.controls.currency_code.markAllAsTouched()">
          </ejs-dropdownlist>
          <div *ngIf="companyOrderForm.controls.currency_code.errors">
            <div *ngIf="companyOrderForm.controls.currency_code.errors.required && companyOrderForm.controls.currency_code.touched "
                 class="e-error">
              Currency is required
            </div>
          </div>
        </div>
      </div>



      <div class="d-flex">
        <div class="form-group sf-outline-input mb-10">
          <ejs-datepicker formControlName="start_date" openOnFocus="true" [allowEdit]=false
                          (blur)="companyOrderForm.controls.start_date.markAsTouched()" [showClearButton]=false
                          placeholder='Validity From *' floatLabelType='Auto' cssClass="e-filled">
          </ejs-datepicker>
          <div *ngIf="companyOrderForm.controls.start_date.errors">
            <div *ngIf="companyOrderForm.controls.start_date.errors.required && companyOrderForm.controls.start_date.touched "
                 class="e-error">
              Validity from is required
            </div>
          </div>
        </div>

        <div class="form-group sf-outline-input mb-10 ml-2">
          <ejs-datepicker formControlName="end_date" openOnFocus="true" [allowEdit]=false [min]="companyOrderForm.value.start_date"
                          (blur)="companyOrderForm.controls.end_date.markAsTouched()" [showClearButton]=false
                          placeholder='Validity Till *' floatLabelType='Auto' cssClass="e-filled">
          </ejs-datepicker>
          <div *ngIf="companyOrderForm.controls.end_date.errors">
            <div *ngIf="companyOrderForm.controls.end_date.errors.required && companyOrderForm.controls.end_date.touched "
                 class="e-error">
              Validity till is required
            </div>
          </div>
          <div *ngIf="companyOrderForm.errors">
            <div *ngIf="companyOrderForm.errors.fromToDate"
                 class="e-error">
              End date cannot be less than start date
            </div>
          </div>
        </div>

      </div>

      <div class="d-flex">
        <div class="form-group sf-outline-input mb-10">
          <ejs-datepicker formControlName="start_free_period_date" openOnFocus="true" [allowEdit]=false
                          (blur)="companyOrderForm.controls.start_free_period_date.markAsTouched()" (change)="onSelectFreePeriodDate($event, companyOrderForm)"
                          placeholder='Free Period From' floatLabelType='Auto' cssClass="e-filled">
          </ejs-datepicker>
        </div>

        <div class="form-group sf-outline-input mb-10 ml-2">
          <ejs-datepicker formControlName="end_free_period_date" openOnFocus="true" [allowEdit]=false [enabled]="companyOrderForm.value.start_free_period_date"
                          (blur)="companyOrderForm.controls.end_free_period_date.markAsTouched()" [min]="companyOrderForm.value.start_free_period_date"
                          placeholder='Free Period Till' floatLabelType='Auto' cssClass="e-filled">
          </ejs-datepicker>
          <div *ngIf="companyOrderForm.controls.end_free_period_date.errors">
            <div *ngIf="companyOrderForm.controls.end_free_period_date.errors.required && companyOrderForm.controls.end_free_period_date.touched "
                 class="e-error">
              Free Period till is required
            </div>
          </div>
          <div *ngIf="companyOrderForm.errors">
            <div *ngIf="companyOrderForm.errors.freePeriodDateError"
                 class="e-error">
              End date cannot be less than start date
            </div>
          </div>
        </div>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-dropdownlist id='order_owner_code' formControlName="order_owner_code" [dataSource]='users' floatLabelType="Auto"
                          [fields]='userFields' cssClass="e-filled" sortOrder="Ascending"
                          placeholder='Order Owner *' [allowFiltering]='true'
                          (blur)="companyOrderForm.controls.order_owner_code.markAsTouched()">
        </ejs-dropdownlist>
        <div *ngIf="companyOrderForm.controls.order_owner_code.errors">
          <div *ngIf="companyOrderForm.controls.order_owner_code.errors.required && companyOrderForm.controls.order_owner_code.touched "
               class="e-error">
            Order Owner is required
          </div>
        </div>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-dropdownlist id='order_status_code' formControlName="order_status_code" [dataSource]='orderStatus' floatLabelType="Auto"
                          [fields]='orderStatusFields' cssClass="e-filled" sortOrder="Ascending"
                          placeholder='Order Status *' [allowFiltering]='true'
                          (blur)="companyOrderForm.controls.order_status_code.markAsTouched()">
        </ejs-dropdownlist>
        <div *ngIf="companyOrderForm.controls.order_status_code.errors">
          <div *ngIf="companyOrderForm.controls.order_status_code.errors.required && companyOrderForm.controls.order_status_code.touched"
               class="e-error">
            Order Status is required
          </div>
        </div>
      </div>


<!--      <div class="form-group sf-outline-input mb-10">-->
<!--        <ejs-daterangepicker formControlName="validity" [allowEdit]="allowEdit"-->
<!--                             placeholder='Validity *' openOnFocus="true" (blur)="companyOrderForm.controls.validity.markAsTouched()"-->
<!--                             floatLabelType='Auto' cssClass="e-filled">-->
<!--        </ejs-daterangepicker>-->
<!--        <div *ngIf="companyOrderForm.controls.validity.errors">-->
<!--          <div *ngIf="companyOrderForm.controls.validity.errors.required && companyOrderForm.controls.validity.touched "-->
<!--               class="e-error">-->
<!--            Validity is required-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="form-group sf-outline-input mb-10">-->
<!--        <ejs-daterangepicker formControlName="freePeriod" [allowEdit]="allowEdit"-->
<!--                             placeholder='Free period' openOnFocus="true"-->
<!--                             floatLabelType='Auto' cssClass="e-filled">-->
<!--        </ejs-daterangepicker>-->
<!--      </div>-->

      <div *ngIf="companyOrderForm.invalid" class="e-error">Please fill all fields</div>
      <div *ngIf="error$ | async as error" class="e-error">{{error.error.message}}</div>

      <div class="my-10 pb-20">
        <button id="resetbtn"
                class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5"
                type="button" (click)="companyOrderForm.reset();"
                data-ripple="true">Clear
        </button>
        <button *ngIf="!companyOrderInput" id="addSubmit"
                class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit"
                (click)="onAddUpdateCompanyOrder(companyOrderForm)" [disabled]="formSent" data-ripple="true">Add Order
        </button>
        <button *ngIf="companyOrderInput" id="updateSubmit"
                class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit"
                (click)="onAddUpdateCompanyOrder(companyOrderForm, false)" [disabled]="formSent" data-ripple="true">
          Update Order
        </button>
      </div>
    </form>

    <form *ngIf="isContactRoleForm" [formGroup]="contactRoleForm" #formDir="ngForm" class="form-horizontal"
          novalidate=""
          style="margin-top: 16px;">

      <div class="form-group sf-outline-input mb-10">
        <ejs-dropdownlist #contactDropdown formControlName='contact_code' id='contactList'
                          [dataSource]='contactList' placeholder='Select a contact *'
                          [fields]='contactFields' cssClass="e-filled" floatLabelType="Auto"
                          [allowFiltering]='true' (filtering)="onFiltering($event, 'contact')"
                          (blur)="contactRoleForm.controls.contact_code.markAsTouched()"></ejs-dropdownlist>
        <div *ngIf="contactRoleForm.controls.contact_code.invalid">
          <div
            *ngIf="contactRoleForm.controls.contact_code.errors.required && contactRoleForm.controls.contact_code.touched "
            class="e-error">
            Please select a contact
          </div>
        </div>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-multiselect formControlName="order_role_code" id='order_role_code' mode='CheckBox'
                         [dataSource]="orderRoleList"
                         floatLabelType="Auto" (blur)="contactRoleForm.controls.order_role_code.markAsTouched()"
                         cssClass="e-filled" [fields]='orderRoleFields' placeholder='Order Roles *'></ejs-multiselect>
        <div *ngIf="contactRoleForm.controls.order_role_code.errors">
          <div
            *ngIf="contactRoleForm.controls.order_role_code.errors.required && contactRoleForm.controls.order_role_code.touched"
            class="e-error">
            Order Role is required.
          </div>
        </div>
      </div>


      <div *ngIf="contactRoleForm.invalid" class="e-error">Please fill all fields</div>
      <div *ngIf="error$ | async as error" class="e-error">{{error.error.message}}</div>

      <div class="my-10 pb-20">
        <button id="resetbtn"
                class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5"
                type="button" (click)="contactRoleForm.reset();"
                data-ripple="true">Clear
        </button>
        <button id="addSubmit" *ngIf="!isContactRoleUpdate"
                class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit"
                (click)="addContactRole(contactRoleForm)" [disabled]="formSent" data-ripple="true">Add Contact Role
        </button>
        <button id="addSubmit" *ngIf="isContactRoleUpdate"
                class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit"
                (click)="addContactRole(contactRoleForm, false)" [disabled]="formSent" data-ripple="true">
         Update Contact Role
        </button>
      </div>
    </form>

    <form *ngIf="isDriveLinkForm" [formGroup]="companyOrderDriveLinkForm" #formDir="ngForm" class="form-horizontal"
          novalidate=""
          style="margin-top: 16px;">

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="drive_link" placeholder="Drive Link *" cssClass="e-filled"
                     formControlName="drive_link" floatLabelType="Auto"
                     (blur)="companyOrderDriveLinkForm.controls.drive_link.markAsTouched()"
        ></ejs-textbox>
        <div *ngIf="companyOrderDriveLinkForm.controls.drive_link.errors">
          <div
            *ngIf="companyOrderDriveLinkForm.controls.drive_link.errors.required && companyOrderDriveLinkForm.controls.drive_link.touched "
            class="e-error">
            Drive Link is required.
          </div>
          <div
            *ngIf="companyOrderDriveLinkForm.controls.drive_link.errors.invalidurl && companyOrderDriveLinkForm.controls.drive_link.touched "
            class="e-error">
            Please enter a valid url.
          </div>
        </div>
      </div>

      <div *ngIf="companyOrderDriveLinkForm.invalid" class="e-error">Please fill all fields</div>
      <div *ngIf="error$ | async as error" class="e-error">{{error.error.message}}</div>

      <div class="my-10 pb-20">
        <button
          class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5"
          type="button" (click)="companyOrderDriveLinkForm.reset();"
          data-ripple="true">Clear
        </button>
        <button *ngIf="!isDriveLinkUpdate"
                class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit"
                (click)="addDriveLink(companyOrderDriveLinkForm)" [disabled]="formSent" data-ripple="true">Add Drive Link
        </button>
        <button *ngIf="isDriveLinkUpdate"
                class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit"
                (click)="updateDriveLink(companyOrderDriveLinkForm)" [disabled]="formSent" data-ripple="true">Update Drive Link
        </button>
      </div>
    </form>
  </div>
</ejs-sidebar>
