export const DynamicHeaderMenuConfig = {
  items: [
    {
      title: 'Projects',
      root: true,
      bullet: 'dot',
      page: '/project',
      alignment: 'left',
      toggle: 'click',
      permission: ['project-list', 'project']
    },
    {
      title: 'Companies',
      root: true,
      bullet: 'dot',
      page: '/company',
      alignment: 'left',
      toggle: 'click',
      permission: ['company-list', 'company']
    },
    {
      title: 'Contacts',
      root: true,
      bullet: 'dot',
      page: '/contact',
      alignment: 'left',
      toggle: 'click',
      permission: ['contact-list', 'contact']
    },
    {
      title: 'Sources',
      root: true,
      bullet: 'dot',
      page: '/sources',
      alignment: 'left',
      toggle: 'click',
      permission: ['project-source-list', 'project-source']
    },
    {
      title: 'Admin',
      bullet: 'dot',
      alignment: 'left',
      page: '/admin',
      permission: ['admin-list'],
      submenu: [
        {
          title: 'Company Types',
          page: '/admin/company-types',
          bullet: 'dot',
          icon: 'flaticon-business',
          permission: 'company-types-master'
        },
        {
          title: 'Company Role',
          page: '/admin/company-roles',
          bullet: 'dot',
          icon: 'flaticon-user',
          permission: 'company-roles-master'
        },
        {
          title: 'Project Types',
          page: '/admin/project-types',
          bullet: 'dot',
          icon: 'flaticon-user',
          permission: 'project-types-master'
        },
        {
          title: 'Project Status',
          page: '/admin/project-status',
          bullet: 'dot',
          icon: 'flaticon-user',
          permission: 'project-status-master'
        },
        {
          title: 'Source Types',
          page: '/admin/source-types',
          bullet: 'dot',
          icon: 'flaticon-user',
          permission: 'source-type-master'
        },
        {
          title: 'Deliverable Items',
          page: '/admin/deliverable-items',
          bullet: 'dot',
          icon: 'flaticon-user',
          permission: 'deliverable-items-master'
        },
        {
          title: 'Deliverable Item Dates',
          page: '/admin/deliverable-item-date',
          bullet: 'dot',
          icon: 'flaticon-user',
          permission: 'deliverable-items-master'
        },
        {
          title: 'Deliverable Status',
          page: '/admin/deliverable-status',
          bullet: 'dot',
          icon: 'flaticon-user',
          permission: 'deliverable-status-master'
        },
        {
          title: 'Order Items',
          page: '/admin/order-items',
          bullet: 'dot',
          icon: 'flaticon-user',
          permission: 'order-line-items-master'
        },
        {
          title: 'Cost Center',
          page: '/admin/cost-center',
          bullet: 'dot',
          icon: 'flaticon-user',
          permission: 'cost-center-master'
        },
        {
          title: 'Email Template',
          page: '/admin/email-template',
          bullet: 'dot',
          icon: 'flaticon-user',
          permission: 'email-template-master'
        },
        {
          title: 'Community Service',
          page: '/admin/community-service',
          bullet: 'dot',
          icon: 'flaticon-user',
          permission: 'cost-center-master'
        },
        {
          title: 'Product Type',
          page: '/admin/product-type',
          bullet: 'dot',
          icon: 'flaticon-user',
          permission: 'cost-center-master'
        },
        {
          title: 'Community Type',
          page: '/admin/community-type',
          bullet: 'dot',
          icon: 'flaticon-user',
          permission: 'cost-center-master'
        },
      ]
    },
    {
      title: 'Orders',
      root: true,
      bullet: 'dot',
      page: '/orders',
      alignment: 'left',
      toggle: 'click',
      permission: ['order-list', 'order']
    },
    {
      title: 'Deliverables',
      root: true,
      bullet: 'dot',
      page: '/deliverable',
      alignment: 'left',
      toggle: 'click',
      permission: ['deliverable-list', 'deliverable']
    },
    {
      title: 'Articles',
      root: true,
      bullet: 'dot',
      page: '/article',
      alignment: 'left',
      toggle: 'click',
      permission: ['article-list', 'article']
    },
    {
      title: 'Emails',
      root: true,
      bullet: 'dot',
      page: '/email-log',
      alignment: 'left',
      toggle: 'click',
      permission: ['email-list', 'email-view']
    }
  ]
};
