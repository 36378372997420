import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {SidebarComponent} from '@syncfusion/ej2-angular-navigations';
import {BehaviorSubject} from 'rxjs';
import {ItemModel} from '@syncfusion/ej2-angular-splitbuttons';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../../core';

@Component({
  selector: 'app-order-links',
  templateUrl: './order-links.component.html',
  styleUrls: ['./order-links.component.scss']
})

export class OrderLinksComponent implements OnInit, OnChanges {
  @ViewChild('sidebarInstance') sidebarInstance: SidebarComponent;
  contactRoles: any;
  driveLinks: any;
  @Input() orderCode: string;
  @Output() valueChange = new EventEmitter();

  public selectedForm$: BehaviorSubject<any | null> = new BehaviorSubject<any | null>(null);

  public items: ItemModel[] = [{text: 'Edit'}];

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
  }

  openSidebar(): void {
    this.sidebarInstance.show();
  }

  closeSidebar() {
    this.sidebarInstance.hide();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.orderCode) {
      this.store.select(store => store.companies.details.orders).subscribe((data: any) => {
        if (data) {
          const value = data.result.find(x => x.company_order_code === this.orderCode);
          if (value) {
            this.driveLinks = value.drive_links ? value.drive_links : null;
          }
        }
      });
    }
  }

  addNewLink() {
    this.valueChange.emit({action: 'addLink'});
  }

  select(args, drive) {
    if (args.item.text === 'Edit') {
      this.selectedForm$.next(drive);
      this.valueChange.emit({drive, action: 'updateLink'});
    }
  }



}
