import { Component, OnInit } from '@angular/core';
import { OffcanvasOptions } from 'src/app/core/_base/layout';
import {Observable} from 'rxjs';
import { User } from 'src/app/core/models/user.model';
import {select, Store} from '@ngrx/store';
import { AppState } from 'src/app/core';
import { Logout } from 'src/app/core/store/auth/auth.actions';
import {LayoutService} from '../../../../core/_base/layout/services/layout.service';
import {DynamicHeaderMenuService} from '../../../../core/_base/layout/services/dynamic-header-menu.service';

@Component({
  selector: 'app-quick-user-panel',
  templateUrl: './quick-user-panel.component.html',
  styleUrls: ['./quick-user-panel.component.scss']
})
export class QuickUserPanelComponent implements OnInit {
  extrasUserOffcanvasDirection = 'offcanvas-right';
  user$: Observable<User>;
  constructor(private store: Store<AppState>, private layout: LayoutService, private menu: DynamicHeaderMenuService) { }

  ngOnInit(): void {
    this.extrasUserOffcanvasDirection = `offcanvas-${this.layout.getProp(
      'extras.user.offcanvas.direction'
    )}`;
    this.user$ = this.store.pipe(select(store => store.auth.user));
  }

  logout() {
    this.store.dispatch(new Logout());
    localStorage.clear();
    this.menu.clearMenu();
  }

}
