<div>
  <ejs-sidebar id="sidebar-menu" #sidebarInstance type='Over' [width]='width' [position]='position'
               style="visibility: hidden" [enableGestures]="false" [showBackdrop]=true>

    <div class="offcanvas-close mt-n1 pr-5" style="float:right; padding: 48px;">
      <a href="javascript:;" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_panel_close"
         (click)="closeSidebar()">
        <i class="ki ki-close icon-xs text-muted"></i>
      </a>
    </div>

    <div class='p-15'>
      <h2 *ngIf="!projectSourceInput">Add Project Source</h2>
      <h2 *ngIf="projectSourceInput">Update Project Source</h2>

      <form id="formId" [formGroup]="projectSourceForm" #formDir="ngForm" class="form-horizontal" novalidate=""
            style="margin-top: 16px;">

        <div class="form-group sf-outline-input mb-10" [class.hide-label]="projectSourceForm.controls.project_source_type_code.value == ''">
          <ejs-dropdowntree id='project_source_type_codess' formControlName="project_source_type_code" #sourceType [showCheckBox]=true
                            placeholder='Source Type *' [fields]='sourceTypeFields' floatLabelType="Auto" cssClass="e-filled"
                            [showDropDownIcon]='true' [allowMultiSelection]=true (change)="setSourceType($event)"
                            (blur)="projectSourceForm.controls.project_source_type_code.markAsTouched()" [showClearButton]=false
                            filterBarPlaceholder='Search'></ejs-dropdowntree>
          <div *ngIf="projectSourceForm.controls.project_source_type_code.errors">
            <div
              *ngIf="projectSourceForm.controls.project_source_type_code.errors.required && projectSourceForm.controls.project_source_type_code.touched"
              class="e-error">
              Please select a source type
            </div>
          </div>
        </div>

<!--        <div class="form-group sf-outline-input mb-10">-->
<!--          <ejs-dropdownlist #company formControlName='company_code' id='companyList'-->
<!--                            [dataSource]='companyList' placeholder='Company' (change)="change($event)"-->
<!--                            [fields]='companyFields' cssClass="e-filled" floatLabelType="Auto" [showClearButton]="true"-->
<!--                            [allowFiltering]='true' (filtering)="onFiltering($event, 'company')" (select)="onSelect($event)"-->
<!--                            (blur)="projectSourceForm.controls.company_code.markAsTouched()"></ejs-dropdownlist>-->
<!--          <div *ngIf="projectSourceForm.controls.company_code.invalid">-->
<!--            <div-->
<!--              *ngIf="projectSourceForm.controls.company_code.errors.required && projectSourceForm.controls.company_code.touched "-->
<!--              class="e-error">-->
<!--              Please select a company-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="d-flex align-items-center">-->
<!--            <span>Can't find who you're looking for?</span>-->
<!--            <button-->
<!--              class="btn btn-light btn-text-primary btn-hover-text-primary text-uppercase font-weight-bold mr-2 btn-sm p-3  ml-3 add-btn"-->
<!--              (click)="addNewCompanyContact('company')" type="button">-->
<!--              Add new Company-->
<!--            </button>-->
<!--          </div>-->
<!--        </div>-->

        <div class="form-group sf-outline-input mb-10">
          <ejs-dropdownlist #contact formControlName='contact_code' id='contactList' [showClearButton]=true
                            [dataSource]='contactList' placeholder='Contact *' (focus)="focus($event)"
                            [fields]='contactFields' cssClass="e-filled" floatLabelType="Auto" (change)="onSelectContact($event)"
                            [allowFiltering]='true' (filtering)="onFiltering($event, 'contact')"
                            (blur)="projectSourceForm.controls.contact_code.markAsTouched()"></ejs-dropdownlist>
          <div *ngIf="projectSourceForm.controls.contact_code.invalid">
            <div
              *ngIf="projectSourceForm.controls.contact_code.errors.required && projectSourceForm.controls.contact_code.touched "
              class="e-error">
              Please select a contact
            </div>
          </div>

          <div class="d-flex align-items-center">
            <span>Can't find who you're looking for?</span>
            <button
              class="btn btn-light btn-text-primary btn-hover-text-primary text-uppercase font-weight-bold mr-2 btn-sm p-3  ml-3 add-btn"
              (click)="addNewCompanyContact('company')" type="button">
              Add new Company
            </button>
            <button
              class="btn btn-light btn-text-primary btn-hover-text-primary text-uppercase font-weight-bold mr-3 btn-sm p-3 ml-3 add-btn"
              (click)="addNewCompanyContact('contact')" type="button">
              Add new Contact
            </button>
          </div>
        </div>

        <div class="form-group sf-outline-input mb-10" >
          <ejs-dropdownlist id='project_source_status_codeee' formControlName="project_source_status_code" [dataSource]='projectSourceStatus' floatLabelType="Auto"
                            [fields]='sourceStatusFields' cssClass="e-filled" value="'open'"
                            placeholder='Status *' [allowFiltering]='true'
                            (blur)="projectSourceForm.controls.project_source_status_code.markAsTouched()">
          </ejs-dropdownlist>
          <div *ngIf="projectSourceForm.controls.project_source_status_code.invalid">
            <div *ngIf="projectSourceForm.controls.project_source_status_code.errors.required && projectSourceForm.controls.project_source_status_code.touched "
                 class="e-error">
              Please select a status
            </div>
          </div>
        </div>

        <div class="form-group sf-outline-input mb-10">
          <ejs-textbox id="drive_link" placeholder="Drive Link" cssClass="e-filled"
                       formControlName="drive_link" floatLabelType="Auto"
                       (blur)="projectSourceForm.controls.drive_link.markAsTouched()"
          ></ejs-textbox>
          <div *ngIf="projectSourceForm.controls.drive_link.errors">
            <div *ngIf="projectSourceForm.controls.drive_link.errors.invalidurl && projectSourceForm.controls.drive_link.touched"
                 class="e-error">
              Please enter a valid url.
            </div>
            <div *ngIf="projectSourceForm.controls.drive_link.errors.maxlength && projectSourceForm.controls.drive_link.touched "
            class="e-error">
              Drive link cannot be more than 500 characters
            </div>
          </div>
        </div>

        <div class="form-group sf-outline-input mb-10">
          <!-- <ejs-textbox id="notes" [multiline]="true" placeholder="Notes" cssClass="e-filled"
                       formControlName="notes" floatLabelType="Auto"
                       (blur)="projectSourceForm.controls.notes.markAsTouched()"
          ></ejs-textbox> -->

          <ejs-richtexteditor #fromRTE formControlName="notes"  (created)="rteCreated()" placeholder="Notes" cssClass="e-filled" [toolbarSettings]="tools"
                              [pasteCleanupSettings]="pasteCleanupSettings" (actionBegin)="actionBegin($event)">
          </ejs-richtexteditor>
        </div>

        <div class="form-group sf-outline-input mb-10">
          <ejs-datepicker formControlName="date_received"
                          placeholder='Received On *' openOnFocus="true" [allowEdit]="allowEdit"
                          floatLabelType='Auto' cssClass="e-filled" [value]="currentDate"
                          (blur)="projectSourceForm.controls.date_received.markAsTouched()">
          </ejs-datepicker>
          <div *ngIf="projectSourceForm.controls.date_received.errors">
            <div *ngIf="projectSourceForm.controls.date_received.errors.required && projectSourceForm.controls.date_received.touched "
                 class="e-error">
              Please select a received date
            </div>
          </div>
        </div>

<!--        <div *ngIf="projectSourceForm.errors" class="e-error"> <div *ngIf="projectSourceForm.errors.atLeastOne"> Please select a company or contact </div></div>-->
        <div *ngIf="projectSourceForm.errors" class="e-error">
          <div
            *ngIf="projectSourceForm.errors.atLeastOne && (projectSourceForm.controls.company_code.touched || projectSourceForm.controls.contact_code.touched)">
            Please select a company or contact
          </div>
        </div>
        <div *ngIf="projectSourceForm.invalid" class="e-error">Please fill all fields</div>
        <div *ngIf="error$ | async as error" class="e-error">{{error.error.message}}</div>

        <div class="my-10 pb-20">
          <button id="resetbtnsources"
                  class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5"
                  type="button" (click)="projectSourceForm.reset(); projectSourceForm.controls.project_source_type_code.setValue('')"
                  data-ripple="true">Clear
          </button>
          <button id="validateSubmitaddPrjectsource" *ngIf="!projectSourceInput"
                  class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block"
                  type="submit"
                  (click)="onAddUpdateProjectSource(projectSourceForm, true)" [disabled]="formSent" data-ripple="true">Add Project
            Source
          </button>
          <button id="validateSubmitupdatePrjectsource" *ngIf="projectSourceInput"
                  class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block"
                  type="submit"
                  (click)="onAddUpdateProjectSource(projectSourceForm, false)" [disabled]="formSent" data-ripple="true">Update Project
            Source
          </button>
        </div>

      </form>
    </div>


  </ejs-sidebar>
</div>
