import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { authReducer, AuthState } from './store/auth/auth.reducer';
import { companyReducer, CompanyState } from './store/reducers/company.reducer';
import { ContactState, contactReducer } from './store/reducers/contact.reducer';
import { ProjectState, projectReducer } from './store/reducers/project.reducer';
import { ProjectSourceState, projectSourceReducer } from './store/reducers/project-source.reducer';
import {commonReducer, CommonState} from './store/reducers/common.reducer';
import {sourceTypeReducer, SourceTypeState} from './store/reducers/source-type.reducer';
import {companyTypeReducer, CompanyTypeState} from './store/reducers/company-type.reducer';
import {companyRoleReducer, CompanyRoleState} from './store/reducers/company-role.reducer';
import {projectTypeReducer, ProjectTypeState} from './store/reducers/project-type.reducer';
import {projectStatusReducer, ProjectStatusState} from './store/reducers/project-status.reducer';
import {articleReducer, ArticleState} from './store/reducers/article.reducer';
import {brandReducer, BrandState} from './store/reducers/brand.reducer';
export { getLoading, getError, getAllCompanies } from './store/selectors/company.selector';

// import { environment } from '../../../environments/environment';

// tslint:disable-next-line:no-empty-interface
export interface AppState {
  readonly auth: AuthState;
  readonly companies: CompanyState;
  readonly contacts: ContactState;
  readonly projects: ProjectState;
  readonly projectSources: ProjectSourceState;
  readonly metadata: CommonState;
  readonly activityType: SourceTypeState;
  readonly companyType: CompanyTypeState;
  readonly companyRole: CompanyRoleState;
  readonly projectType: ProjectTypeState;
  readonly projectStatus: ProjectStatusState;
  readonly articles: ArticleState;
  readonly brands: BrandState
}

export const reducers: ActionReducerMap<AppState> = {
  auth: authReducer,
  companies: companyReducer,
  contacts: contactReducer,
  projects: projectReducer,
  projectSources: projectSourceReducer,
  metadata: commonReducer,
  activityType: sourceTypeReducer,
  companyType: companyTypeReducer,
  companyRole: companyRoleReducer,
  projectType: projectTypeReducer,
  projectStatus: projectStatusReducer,
  articles: articleReducer,
  brands: brandReducer
};



// export const metaReducers: Array<MetaReducer<AppState>> = !environment.production ? [storeFreeze] : [];
