// Actions
import {ProjectActions, ProjectActionTypes} from '../actions/project.actions';

// Models
import {Project} from '../../models/project.model';
import {Activity} from '../../models/activity.model';
import {ProjectSource} from '../../models/project-source.model';
import {formatDataSet} from '../../../views/common/base.component';
import {TransactionHistory} from '../../models/transaction-history.model';
import * as moment from 'moment';
import {ArticleActions, ArticleActionTypes} from '../actions/article.actions';

export interface ArticleState {
  articles: {
    result: [],
    total_count?: number,
    page_no?: number,
    page_size?: number
  };
  error?: Error;
  loading: boolean;
  loaded: boolean;
  success?: boolean;
  db?: {
    result: [],
    total_count?: number,
    page_no?: number,
    page_size?: number
  };
}

export const initialState: ArticleState = {
  articles: {
    result: [],
    total_count: 0,
    page_no: 0,
    page_size: 0
  },
  loading: false,
  loaded: false,
};

export const ReturnLoading = (State: ArticleState) => {
  return {
    ...State,
    loading: true,
    loaded: false,
    error: undefined,
    success: false,
  };
};

export function articleReducer(state: ArticleState = initialState, action: ArticleActions): ArticleState {
  switch (action.type) {

    case ArticleActionTypes.LOAD: {
      return ReturnLoading(state);
    }

    case ArticleActionTypes.LOAD_SUCCESS: {
      return {
        ...state, articles: {
          result: action.payload.result,
          total_count:  action.payload.total_count,
          page_no: action.payload.page_no,
          page_size: action.payload.page_size
        }, loading: false, error: undefined, loaded: true, db: {
          result:  action.payload.result,
          total_count: action.payload.total_count,
          page_no: action.payload.page_no,
          page_size: action.payload.page_size,
        }
      };
    }

    case ArticleActionTypes.LOAD_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case ArticleActionTypes.FILTER: {
      return ReturnLoading(state);
    }

    case ArticleActionTypes.FILTER_SUCCESS: {
      return {
        ...state, articles: {
          result: action.payload.result,
          total_count:  action.payload.total_count,
          page_no: action.payload.page_no,
          page_size: action.payload.page_size
        }, loading: false, error: undefined, loaded: true, db: {
          result:  action.payload.result,
          total_count: action.payload.total_count,
          page_no: action.payload.page_no,
          page_size: action.payload.page_size,
        }, success: true
      };
    }

    case ArticleActionTypes.FILTER_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case ArticleActionTypes.SET_SUCCESS_NULL:
      return ReturnLoading(state);

    default:
      return state;
  }

}
