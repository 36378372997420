import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateToDuration'
})
export class DateToDurationPipe implements PipeTransform {

  transform(value: string[], ...args: unknown[]): unknown {
    if (value.length === 2 && value[0] && value[1]) {
      const startTime = moment(value[0], 'hh:mm:ss'),
        endTime = moment(value[1], 'hh:mm:ss'),
        duration = moment.duration(endTime.diff(startTime)).asHours();
        return duration + ' Hours';
    } else {
      return null;
    }
  }

}
