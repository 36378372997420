import { Action } from '@ngrx/store';
import { Project } from '../../models/project.model';
import { Activity } from '../../models/activity.model';
import { ProjectSource } from '../../models/project-source.model';
import {ProjectRole} from '../../models/project-role.model';
import {TransactionHistory} from '../../models/transaction-history.model';
import {CompanyActionTypes} from './company.actions';
import {Company} from '../../models/company.model';

export enum ProjectActionTypes {
  LOAD = '[PROJECT] Load Action',
  LOAD_SUCCESS = '[PROJECT] Load Success Action',
  LOAD_FAILURE = '[PROJECT] Load Failure Action',

  ADD = '[PROJECT] Add Action',
  ADD_SUCCESS = '[PROJECT] Add Success Action',
  ADD_FAILURE = '[PROJECT] Add Failure Action',

  UPDATE = '[PROJECT] Update Action',
  UPDATE_SUCCESS = '[PROJECT] Update Success Action',
  UPDATE_FAILURE = '[PROJECT] Update Failure Action',

  DELETE = '[PROJECT] Delete Action',
  DELETE_SUCCESS = '[PROJECT] Delete Success Action',
  DELETE_FAILURE = '[PROJECT] Delete Failure Action',

  LOAD_SINGLE_PROJECT = '[PROJECT] Load Single Project',
  LOAD_SINGLE_PROJECT_SUCCESS = '[PROJECT] Load Single Project Success',
  LOAD_SINGLE_PROJECT_FAILURE = '[PROJECT] Load Single Project Failure',

  FILTER = '[PROJECT] Filter Action',
  FILTER_SUCCESS = '[PROJECT] Filter Success Action',
  FILTER_FAILURE = '[PROJECT] Filter Failure Action',

  // ACTIVITY ENDPOINTS
  GET_ACTIVITY = '[PROJECT] Get Activity Action',
  GET_ACTIVITY_SUCCESS = '[PROJECT] Get Activity Success Action',
  GET_ACTIVITY_FAILURE = '[PROJECT] Get Activity Failure Action',

  ADD_ACTIVITY = '[PROJECT] Add Activity Action',
  ADD_ACTIVITY_SUCCESS = '[PROJECT] Add Activity Success Action',
  ADD_ACTIVITY_FAILURE = '[PROJECT] Add Activity Failure Action',

  DELETE_ACTIVITY = '[PROJECT] Delete Activity Action',
  DELETE_ACTIVITY_SUCCESS = '[PROJECT] Delete Activity Success Action',
  DELETE_ACTIVITY_FAILURE = '[PROJECT] Delete Activity Failure Action',

  UPDATE_ACTIVITY = '[PROJECT] Update Activity Action',
  UPDATE_ACTIVITY_SUCCESS = '[PROJECT] Update Activity Success Action',
  UPDATE_ACTIVITY_FAILURE = '[PROJECT] Update Activity Failure Action',

  ACTIVITY_FILTER = '[PROJECT] Activity Filter Action',
  ACTIVITY_FILTER_SUCCESS = '[PROJECT] Activity Filter Success Action',
  ACTIVITY_FILTER_FAILURE = '[PROJECT] Activity Filter Failure Action',

  // Leads
  GET_PROJECT_SOURCES = '[PROJECT] Get Project Sources Action',
  GET_PROJECT_SOURCES_SUCCESS = '[PROJECT] Get Project Sources Success Action',
  GET_PROJECT_SOURCES_FAILURE = '[PROJECT] Get Project Sources Failure Action',

  // Company Roles

  LOAD_COMPANY_ROLES = '[PROJECT] Load Company Roles',
  LOAD_COMPANY_ROLES_SUCCESS = '[PROJECT] Load Company Roles Success',
  LOAD_COMPANY_ROLES_FAILURE = '[PROJECT] Load Company Roles Failure',

  ADD_COMPANY_ROLE = '[PROJECT] Add Company Role Action',
  ADD_COMPANY_ROLE_SUCCESS = '[PROJECT] Add Company Role Success Action',
  ADD_COMPANY_ROLE_FAILURE = '[PROJECT] Add Company Role Failure Action',

  UPDATE_COMPANY_ROLE = '[PROJECT] Update Company Role Action',
  UPDATE_COMPANY_ROLE_SUCCESS = '[PROJECT] Update Company Role Success Action',
  UPDATE_COMPANY_ROLE_FAILURE = '[PROJECT] Update Company Role Failure Action',

  DELETE_COMPANY_ROLE = '[PROJECT] Delete Company Role Action',
  DELETE_COMPANY_ROLE_SUCCESS = '[PROJECT] Delete Company Role Success Action',
  DELETE_COMPANY_ROLE_FAILURE = '[PROJECT] Delete Company Role Failure Action',

  // Transaction History
  GET_PROJECT_HISTORY = '[PROJECT] Get Project Transaction History Action',
  GET_PROJECT_HISTORY_SUCCESS = '[PROJECT] Get Project Transaction History Success Action',
  GET_PROJECT_HISTORY_FAILURE = '[PROJECT] Get Project Transaction History Failure Action',

  BULK_EDIT = '[PROJECT] Bulk Edit Action',
  BULK_EDIT_SUCCESS = '[PROJECT] Bulk Edit Success Action',
  BULK_EDIT_FAILURE = '[PROJECT] Bulk Edit Failure Action',

  SEARCH = '[PROJECT] Search Action',
  SEARCH_SUCCESS = '[PROJECT] Search Success Action',
  SEARCH_FAILURE = '[PROJECT] Search Failure Action',

  FILTER_RESET = '[PROJECT] FILTER RESET',
  SET_SUCCESS_NULL = '[PROJECT] Set Success Null',
  SET_PROJECTS_NULL = '[PROJECT] Set Projects null',

  GET_PROJECT_ACTIVITY_HISTORY = '[PROJECT] Get Project Activity Transaction History Action',
  GET_PROJECT_ACTIVITY_HISTORY_SUCCESS = '[PROJECT] Get Project Activity Transaction History Success Action',
  GET_PROJECT_ACTIVITY_HISTORY_FAILURE = '[PROJECT] Get Project Activity Transaction History Failure Action',


  STATUS_CHANGED = '[PROJECT] Status Changed Action',
  STATUS_CHANGED_CLEARED = '[PROJECT] Status Changed Cleared Action',
  SET_UPDATED_AT_TIME = '[PROJECT] Set Updated At Action'

}
export class ProjectLoad implements Action {
  readonly type = ProjectActionTypes.LOAD;
  constructor(public payload?: {page_size?: number, page_no?: number, sort_by?: string, sort_order?: string, user_code?:string }) { }
}

export class ProjectLoadSuccess implements Action {
  readonly type = ProjectActionTypes.LOAD_SUCCESS;
  constructor(public payload: {
    data?: [],
    columns: [],
    total_count?: number,
    page_no?: number,
    page_size?: number
  }) { }
}

export class ProjectLoadFailure implements Action {
  readonly type = ProjectActionTypes.LOAD_FAILURE;
  constructor(public payload: Error) { }
}

export class ProjectAdd implements Action {
  readonly type = ProjectActionTypes.ADD;
  constructor(public payload: {
    project_name: string, project_type: [], address: string, city_name: string, display_name: string,
    country_id: string, description: string, tags: string, drive_link: string, status: string, project_source?: any
  }) { }
}

export class ProjectAddSuccess implements Action {
  readonly type = ProjectActionTypes.ADD_SUCCESS;
  constructor(public payload: { project: Project }) { }
}

export class ProjectAddFailure implements Action {
  readonly type = ProjectActionTypes.ADD_FAILURE;
  constructor(public payload: Error) { }
}

export class ProjectUpdate implements Action {
  readonly type = ProjectActionTypes.UPDATE;
  constructor(public payload: { project_code: string, project: {} }) { }
}

export class ProjectUpdateSuccess implements Action {
  readonly type = ProjectActionTypes.UPDATE_SUCCESS;
  constructor(public payload: { project_code: string, project: Project }) { }
}

export class ProjectUpdateFailure implements Action {
  readonly type = ProjectActionTypes.UPDATE_FAILURE;
  constructor(public payload: Error) { }
}

export class ProjectDelete implements Action {
  readonly type = ProjectActionTypes.DELETE;
  constructor(public payload: {project_code: string }) { }
}

export class ProjectDeleteSuccess implements Action {
  readonly type = ProjectActionTypes.DELETE_SUCCESS;
  constructor(public payload: { project_code: string }) { }
}

export class ProjectDeleteFailure implements Action {
  readonly type = ProjectActionTypes.DELETE_FAILURE;
  constructor(public payload: Error) { }
}

export class ProjectGetSingleProject implements Action {
  readonly type = ProjectActionTypes.LOAD_SINGLE_PROJECT;
  constructor(public payload: { projectCode: string }) { }
}

export class ProjectGetSingleProjectSuccess implements Action {
  readonly type = ProjectActionTypes.LOAD_SINGLE_PROJECT_SUCCESS;
  constructor(public payload: { project: Project }) { }
}

export class ProjectGetSingleProjectFailure implements Action {
  readonly type = ProjectActionTypes.LOAD_SINGLE_PROJECT_FAILURE;
  constructor(public payload: Error) { }
}

export class ProjectSetStatusNull implements Action {
  readonly type = ProjectActionTypes.SET_SUCCESS_NULL;
  constructor() { }
}

export class ProjectGetActivity implements Action {
  readonly type = ProjectActionTypes.GET_ACTIVITY;
  constructor(public payload: {project_code: string, entity: string}) { }

}

export class ProjectGetActivitySuccess implements Action {
  readonly type = ProjectActionTypes.GET_ACTIVITY_SUCCESS;
  constructor(public payload: Array<Activity>) { }
}

export class ProjectGetActivityFailure implements Action {
  readonly type = ProjectActionTypes.GET_ACTIVITY_FAILURE;
  constructor(public payload: Error) { }
}

export class ProjectAddActivity implements Action {
  readonly type = ProjectActionTypes.ADD_ACTIVITY;
  constructor(public payload: {project_code: string, activity_type: string, activityTitle: string, activityNote: string,
    userParticipants: string[], contactParticipants: string[], meeting_start_time: string, meeting_end_time: string,
    meeting_date: string, activityTags: string[], entity: string}) { }
}

export class ProjectAddActivitySuccess implements Action {
  readonly type = ProjectActionTypes.ADD_ACTIVITY_SUCCESS;
  constructor(public payload: {activity: Activity}) { }
}

export class ProjectAddActivityFailure implements Action {
  readonly type = ProjectActionTypes.ADD_ACTIVITY_FAILURE;
  constructor(public payload: Error) { }
}

export class ProjectActivityDelete implements Action {
  readonly type = ProjectActionTypes.DELETE_ACTIVITY;
  constructor(public payload: {activity_code: string, entity: string }) { }
}

export class ProjectActivityDeleteSuccess implements Action {
  readonly type = ProjectActionTypes.DELETE_ACTIVITY_SUCCESS;
  constructor(public payload: { activity_code: string }) { }
}

export class ProjectActivityDeleteFailure implements Action {
  readonly type = ProjectActionTypes.DELETE_ACTIVITY_FAILURE;
  constructor(public payload: Error) { }
}

export class ProjectActivityUpdate implements Action {
  readonly type = ProjectActionTypes.UPDATE_ACTIVITY;
  constructor(public payload: { activity_code: string, projectActivity: {}, entity: string }) { }
}

export class ProjectActivityUpdateSuccess implements Action {
  readonly type = ProjectActionTypes.UPDATE_ACTIVITY_SUCCESS;
  constructor(public payload: { activity_code: string, projectActivity: Activity }) { }
}

export class ProjectActivityUpdateFailure implements Action {
  readonly type = ProjectActionTypes.UPDATE_ACTIVITY_FAILURE;
  constructor(public payload: Error) { }
}

export class ProjectGetSources implements Action {
  readonly type = ProjectActionTypes.GET_PROJECT_SOURCES;
  constructor(public payload: {project_code: string, page_size: number, page_no: number}) { }

}

export class ProjectGetSourcesSuccess implements Action {
  readonly type = ProjectActionTypes.GET_PROJECT_SOURCES_SUCCESS;
  constructor(public payload: Array<ProjectSource>) { }
}

export class ProjectGetSourcesFailure implements Action {
  readonly type = ProjectActionTypes.GET_PROJECT_SOURCES_FAILURE;
  constructor(public payload: Error) { }
}

export class ProjectFilter implements Action {
  readonly type = ProjectActionTypes.FILTER;
  constructor(public payload: { project_name?: string, reference_id?: string, project_type?: [], country?: [], tags?: string[],
    assigned_to?: string, proposed_post_date?: string, status?: string[], page_no?: number, page_size?: number, sort_by?: string,
    sort_order?: string, company_code?: string, contact_code?: string, company_code_source?: string }) { }
}

export class ProjectFilterSuccess implements Action {
  readonly type = ProjectActionTypes.FILTER_SUCCESS;
  constructor(public payload: {
    data?: [],
    columns: [],
    total_count?: number,
    page_no?: number,
    page_size?: number
  }) { }
}

export class ProjectFilterFailure implements Action {
  readonly type = ProjectActionTypes.FILTER_FAILURE;
  constructor(public payload: Error) { }
}

export class ProjectFilterReset implements Action {
  readonly type = ProjectActionTypes.FILTER_RESET;
}

export class ProjectActivityFilter implements Action {
  readonly type = ProjectActionTypes.ACTIVITY_FILTER;
  constructor(public payload: { filterValues: { activity_title: string, activity_type: [], participants: [], tags: string[],
      meeting_date: string }, entity: string, code: string}) { }
}

export class ProjectActivityFilterSuccess implements Action {
  readonly type = ProjectActionTypes.ACTIVITY_FILTER_SUCCESS;
  constructor(public payload: Array<Activity>) { }
}

export class ProjectActivityFilterFailure implements Action {
  readonly type = ProjectActionTypes.ACTIVITY_FILTER_FAILURE;
  constructor(public payload: Error) { }
}

export class AddProjectCompanyRole implements Action {
  readonly type = ProjectActionTypes.ADD_COMPANY_ROLE;
  constructor(public payload: { projectCode: string, company_role_code: string, company_code?: string, contact_code?: string, company_contact_code?: string }) { }
}

export class AddProjectCompanyRoleSuccess implements Action {
  readonly type = ProjectActionTypes.ADD_COMPANY_ROLE_SUCCESS;
  constructor(public payload: ProjectRole) { }
}

export class AddProjectCompanyRoleFailure implements Action {
  readonly type = ProjectActionTypes.ADD_COMPANY_ROLE_FAILURE;
  constructor(public payload: Error) { }
}

export class UpdateProjectCompanyRole implements Action {
  readonly type = ProjectActionTypes.UPDATE_COMPANY_ROLE;
  constructor(public payload: { project_role_code: string, company_role_code: string, company_code?: string, contact_code?: string, company_contact_code?: string }) { }
}

export class UpdateProjectCompanyRoleSuccess implements Action {
  readonly type = ProjectActionTypes.UPDATE_COMPANY_ROLE_SUCCESS;
  constructor(public payload: ProjectRole) { }
}

export class UpdateProjectCompanyRoleFailure implements Action {
  readonly type = ProjectActionTypes.UPDATE_COMPANY_ROLE_FAILURE;
  constructor(public payload: Error) { }
}

export class DeleteProjectCompanyRole implements Action {
  readonly type = ProjectActionTypes.DELETE_COMPANY_ROLE;
  constructor(public payload: { project_role_code: string}) { }
}

export class DeleteProjectCompanyRoleSuccess implements Action {
  readonly type = ProjectActionTypes.DELETE_COMPANY_ROLE_SUCCESS;
  constructor(public payload: {project_role_code: string}) { }
}

export class DeleteProjectCompanyRoleFailure implements Action {
  readonly type = ProjectActionTypes.DELETE_COMPANY_ROLE_FAILURE;
  constructor(public payload: Error) { }
}

export class LoadProjectCompanyRole implements Action {
  readonly type = ProjectActionTypes.LOAD_COMPANY_ROLES;
  constructor(public payload: {projectCode: string}) { }

}

export class LoadProjectCompanyRoleSuccess implements Action {
  readonly type = ProjectActionTypes.LOAD_COMPANY_ROLES_SUCCESS;
  constructor(public payload: Array<ProjectRole>) { }
}

export class LoadProjectCompanyRoleFailure implements Action {
  readonly type = ProjectActionTypes.LOAD_COMPANY_ROLES_FAILURE;
  constructor(public payload: Error) { }
}

export class GetProjectHistory implements Action {
  readonly type = ProjectActionTypes.GET_PROJECT_HISTORY;
  constructor(public payload: { projectCode: string }) { }
}

export class GetProjectHistorySuccess implements Action {
  readonly type = ProjectActionTypes.GET_PROJECT_HISTORY_SUCCESS;
  constructor(public payload: { projectCode: string, history: TransactionHistory }) { }
}

export class GetProjectHistoryFailure implements Action {
  readonly type = ProjectActionTypes.GET_PROJECT_HISTORY_FAILURE;
  constructor(public payload: Error) { }
}

export class GetProjectActivityHistory implements Action {
  readonly type = ProjectActionTypes.GET_PROJECT_ACTIVITY_HISTORY;
  constructor(public payload: { projectCode: string, history: TransactionHistory }) { }
}

export class GetProjectActivityHistorySuccess implements Action {
  readonly type = ProjectActionTypes.GET_PROJECT_ACTIVITY_HISTORY_SUCCESS;
  constructor(public payload: { projectCode: string, history: TransactionHistory }) { }
}

export class GetProjectActivityHistoryFailure implements Action {
  readonly type = ProjectActionTypes.GET_PROJECT_ACTIVITY_HISTORY_FAILURE;
  constructor(public payload: Error) { }
}

export class ProjectSearch implements Action {
  readonly type = ProjectActionTypes.SEARCH;
  constructor(public payload: { search?: string, page_no?: number, page_size?: number}) { }
}

export class ProjectSearchSuccess implements Action {
  readonly type = ProjectActionTypes.SEARCH_SUCCESS;
  constructor(public payload: { data?: [],
    columns: [],
    total_count?: number,
    page_no?: number,
    page_size?: number}) { }
}

export class ProjectSearchFailure implements Action {
  readonly type = ProjectActionTypes.SEARCH_FAILURE;
  constructor(public payload: Error) { }
}

export class ProjectBulkEdit implements Action {
  readonly type = ProjectActionTypes.BULK_EDIT;
  constructor(public payload: { request: any}) { }
}

export class ProjectBulkEditSuccess implements Action {
  readonly type = ProjectActionTypes.BULK_EDIT_SUCCESS;
  constructor(public payload: Array<Project>) { }
}

export class ProjectBulkEditFailure implements Action {
  readonly type = ProjectActionTypes.BULK_EDIT_FAILURE;
  constructor(public payload: Error) { }
}

export class ProjectSetProjectsNull implements Action {
  readonly type = ProjectActionTypes.SET_PROJECTS_NULL;
}

export class ProjectSetUpdatedAtTime implements Action {
  readonly type = ProjectActionTypes.SET_UPDATED_AT_TIME;
  constructor(public payload: string) { }
}

export class ProjectStatusChanged implements Action {
  readonly type = ProjectActionTypes.STATUS_CHANGED;
  constructor(public payload: any) { }
}

export class ProjectStatusChangedCleared implements Action {
  readonly type = ProjectActionTypes.STATUS_CHANGED_CLEARED;
}


export type ProjectActions = ProjectAdd | ProjectAddSuccess | ProjectAddFailure | ProjectLoad | ProjectLoadSuccess | ProjectLoadFailure |
  ProjectUpdate | ProjectUpdateSuccess | ProjectUpdateFailure | ProjectDelete | ProjectDeleteSuccess | ProjectDeleteFailure |
  ProjectGetSingleProject | ProjectGetSingleProjectSuccess | ProjectGetSingleProjectFailure | ProjectGetActivity|
  ProjectGetActivitySuccess| ProjectGetActivityFailure | ProjectAddActivity| ProjectAddActivitySuccess | ProjectAddActivityFailure|
  ProjectActivityDelete | ProjectActivityDeleteSuccess | ProjectActivityDeleteFailure | ProjectActivityUpdate |
  ProjectActivityUpdateSuccess | ProjectActivityUpdateFailure | ProjectGetSources | ProjectGetSourcesSuccess | ProjectGetSourcesFailure |
  ProjectFilter| ProjectFilterSuccess | ProjectFilterFailure | ProjectSetStatusNull | ProjectFilterReset | ProjectActivityFilter |
  ProjectActivityFilterSuccess | ProjectActivityFilterFailure | LoadProjectCompanyRole | LoadProjectCompanyRoleSuccess |
  LoadProjectCompanyRoleFailure | AddProjectCompanyRole | AddProjectCompanyRoleSuccess | AddProjectCompanyRoleFailure |
  UpdateProjectCompanyRole | UpdateProjectCompanyRoleSuccess | UpdateProjectCompanyRoleFailure | DeleteProjectCompanyRole |
  DeleteProjectCompanyRoleSuccess | DeleteProjectCompanyRoleFailure | GetProjectHistory | GetProjectHistorySuccess |
  GetProjectHistoryFailure | ProjectSetProjectsNull | ProjectSearch | ProjectSearchSuccess | ProjectSearchFailure | ProjectBulkEdit |
  ProjectBulkEditSuccess | ProjectBulkEditFailure | GetProjectActivityHistory | GetProjectActivityHistorySuccess |
  GetProjectActivityHistoryFailure | ProjectSetUpdatedAtTime | ProjectStatusChanged | ProjectStatusChangedCleared;
