<ejs-sidebar id="sidebar-menu" #sidebarInstance type='Over' width='400px' position='Right'
             style="visibility: hidden" [enableGestures]="false" [showBackdrop]=true>

  <div class="offcanvas-close mt-n1 pr-5" style="float:right; padding: 32px;">
    <a href="javascript:;" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_panel_close"
       (click)="closeSidebar()">
      <i class="ki ki-close icon-xs text-muted"></i>
    </a>
  </div>

  <div class='p-10'>
    <div class="d-flex mt-4">
      <h2>Drive Links</h2>
      <button class="btn btn-primary text-uppercase font-weight-bold btn-sm float-right px-8 ml-auto" id="addNewProject"
              (click)="addNewLink()">
        Add new
      </button>
    </div>
    <div class="mt-15">
      <div *ngIf="!driveLinks || driveLinks.length === 0"> No records to display</div>
      <div class="d-flex align-items-center mb-10" *ngFor="let drive of driveLinks; let i = index">
        <div class="mr-5">{{i +1}}.</div>
        <div class="d-flex flex-column flex-grow-1 font-weight-bold"><a
                                                                        class="text-dark text-hover-primary mb-1 font-size-lg">
          {{drive.drive_link}}
        </a></div>
        <div>
          <button ejs-dropdownbutton [items]='items' class="btn btn-icon btn-light btn-hover-primary btn-sm"
                  cssClass='e-caret-hide' (select)='select($event, drive)'>
            <mat-icon svgIcon="can-more-actions"
                      class="svg-icon svg-icon-md svg-icon-primary h-auto"></mat-icon>
          </button>
        </div>
      </div>
    </div>

  </div>
</ejs-sidebar>
