import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Form, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {
  ContactFilter,
  ContactFilterReset, ContactLoad,
  ContactSetContactsNull,
  ContactSetStatusNull
} from '../../../../../core/store/actions/contact.actions';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../core';
import {Observable} from 'rxjs';
import {Tags} from '../../../../../core/models/tags.model';
import {SidebarComponent} from '@syncfusion/ej2-angular-navigations';
import {Router} from '@angular/router';
import * as moment from 'moment';
import * as _ from 'lodash';
import {MultiSelectComponent} from '@syncfusion/ej2-angular-dropdowns';

@Component({
  selector: 'app-contact-filter',
  templateUrl: './contact-filter.component.html',
  styleUrls: ['./contact-filter.component.scss']
})
export class ContactFilterComponent implements OnInit, OnChanges {

  @ViewChild('sidebarInstance')
  public sidebarInstance: SidebarComponent;
  @ViewChild('tags') contactTag: MultiSelectComponent;


  @Input() countries: any;
  @Input() companyTypes: any;
  @Input() users: any;
  @Input() articleTypes: any;
  @Input() allTags: Observable<Array<Tags>>;

  public width = 'auto';
  public position = 'Right';
  selectedTag = [];
  tagSelected = '';
  public tagModelData = [];
  modelData = [];

  errorMsg: any;
  error$: Observable<any>;
  success$: Observable<object>;
  selectedTab = 'contact';
  selectedCompanyTypes = [];

  allowEdit = false;
  isFilterReset = false;
  formSent: boolean;
  public tagFields: object = {value: 'tag', text: 'tag'};
  articleTypeFields: object = {value: 'article_type_code', text: 'article_type_name'};
  public userFields: object = {dataSource: this.users, value: 'user_code', text: ('first_name')};
  public countryField = {value: 'country_code', text: 'country_name'};
  public companyTypeField: object;
  public treeSettings: object = { autoCheck: true };

  filterForm: FormGroup;
  articleFilterForm: FormGroup;

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.sidebarInstance.isOpen) {
      this.closeSidebar();
    }
  }

  constructor(private formBuilder: FormBuilder, private store: Store<AppState>, private router: Router) {
  }

  ngOnInit(): void {
    this.generateFilterForm();
    this.allTags.subscribe(data => {
      this.tagModelData = data;
    });

    this.error$ = this.store.pipe(select(store => store.contacts.error));
    this.error$.subscribe((data: any) => {
      this.errorMsg = data ? data.error : null;

      if (this.errorMsg) {
        // this.errorMsg = data.error;
        this.formSent = false;
      }
    });

    this.success$ = this.store.pipe(select(store => store.contacts.success));
    this.success$.subscribe((data: any) => {

      if (data && data.isForm !== '') {
        this.formSent = false;
        if (!this.isFilterReset) {
          this.closeSidebar();
        }
        // this.store.dispatch(new ContactSetStatusNull());
      }
    });
  }

  generateFilterForm() {
    this.articleFilterForm = this.formBuilder.group({
      article_type_code: new FormControl(''),
    });

    this.filterForm = this.formBuilder.group({
      contact_name: new FormControl(null),
      company_name: new FormControl(null),
      company_type_code: new FormControl(''),
      country_code: new FormControl(null),
      contact_tag: new FormControl(null),
      updated_at: new FormControl(null),
      created_at: new FormControl(null),
      published_by: new FormControl(null),
      reference_id: new FormControl(null),
      email: new FormControl(null),
    });


  }

  getFilteredData() {
    this.isFilterReset = false;
    let filterValues = {...this.filterForm.value, ...this.articleFilterForm.value};
    delete filterValues.company_type_code;
    filterValues = {...filterValues, company_type_code: this.selectedCompanyTypes};
    this.filterForm.controls.company_type_code.setValue(this.selectedCompanyTypes);
    Object.keys(filterValues).forEach((key) => ((filterValues[key] === null) || (filterValues[key] === '')) && delete filterValues[key]);

    if (filterValues.created_at) {
      filterValues.created_at_end = moment(filterValues.created_at[1]).format('YYYY-MM-DD');
      filterValues.created_at_start = moment(filterValues.created_at[0]).format('YYYY-MM-DD');
    }
    delete filterValues.created_at;

    if (filterValues.updated_at) {
      filterValues.updated_at_end = moment(filterValues.updated_at[1]).format('YYYY-MM-DD');
      filterValues.updated_at_start = moment(filterValues.updated_at[0]).format('YYYY-MM-DD');
    }
    delete filterValues.updated_at;

    for (const filterValuesKey in filterValues) {
      if (filterValues[filterValuesKey] instanceof Array) {
        filterValues[filterValuesKey] = filterValues[filterValuesKey].join(',');
      }
    }

    this.router.navigate([], {queryParams: filterValues});
    filterValues = {...filterValues, page_size: 100, page_no: 1};
    this.store.dispatch(new ContactFilter(filterValues));
  }

  clearForm() {
    this.filterForm.reset();
    if (this.articleFilterForm) {
      this.articleFilterForm.reset();
    }
    this.isFilterReset = true;
    this.filterForm.controls.company_type_code.setValue('');
    this.store.dispatch(new ContactLoad({page_size: 100, page_no: 1}));
    this.router.navigate([], { queryParams: {}});
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.companyTypes) {
      this.companyTypeField = {dataSource: this.companyTypes, value: 'company_type_code', text: 'company_type_name', child: 'children'};
    }
  }

  openSidebar(): void {
    this.sidebarInstance.show();
  }

  closeSidebar(): void {
    this.store.dispatch(new ContactSetStatusNull());
    this.sidebarInstance.hide();
  }

  onBlur(args, form) {
    this.tagSelected = this.selectedTag[this.selectedTag.length - 1];

    if(!this.modelData.includes(this.tagSelected)) {
      this.contactTag.addItem({tag: this.tagSelected, tag_code: this.tagSelected});
      form.controls.contact_tag.setValue( _.uniq(_.compact([...this.modelData, this.tagSelected])));
    }

    this.modelData = _.compact([...this.modelData, this.tagSelected]);
    this.modelData.push(this.tagSelected);

    this.selectedTag = [];
  }

  syncModel = (event) => {
    this.modelData = event;
  }

  actionComplete(args) {
    if (_.get(args, 'result[0]')) {
      this.selectedTag.push(args.result[0].tag);
    }
  }
  onChange(event) {
    const tagsArray = _.compact(this.modelData);
    if ( tagsArray.length === 0) {
      this.modelData = [];
    }
  }

  select(args) {
    this.selectedTag = [];
  }

  setTab(args) {
    this.selectedTab = args;
  }

  setCompanyType(args, form) {
    this.selectedCompanyTypes = _.cloneDeep(args.value);
  }

}
