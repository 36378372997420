<!--begin::Navigation-->
<ul class="navi navi-hover" *ngIf="checkDeletePermission() || checkEditPermission()">
  <li class="navi-header font-weight-bold py-4">
  </li>
<!--  <li class="navi-separator mb-3 opacity-70"></li>-->
  <li class="navi-item" *ngIf="checkEditPermission() && isVisibleContact()">
    <a role="button" class="navi-link cursor-pointer" (click)="edit()">
      <span class="navi-text">
        <span
        >Edit</span
        >
      </span>
    </a>
  </li>
  <li class="navi-separator mt-3 opacity-70"  *ngIf="checkEditPermission() && isVisibleContact()"></li>

  <li class="navi-item" *ngIf="checkDeletePermission()">
    <a class="navi-link cursor-pointer" (click)="delete()" role="button">
      <span class="navi-text">
        <span class=""
        >Delete</span
        >
      </span>
    </a>
  </li>
  <li class="navi-separator mt-3 opacity-70" *ngIf="checkDeletePermission()"></li>

  <li class="navi-item" *ngIf="checkEditPermission() && isVisible()">
    <a role="button" class="navi-link cursor-pointer" (click)="merge()">
      <span class="navi-text">
        <span
        >Merge</span>
      </span>
    </a>
  </li>
</ul>
<!--end::Navigation-->
