import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {SidebarComponent} from '@syncfusion/ej2-angular-navigations';
import {ItemModel, MenuEventArgs} from '@syncfusion/ej2-angular-splitbuttons';
import {BehaviorSubject} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../../core';

@Component({
  selector: 'app-contact-roles',
  templateUrl: './contact-roles.component.html',
  styleUrls: ['./contact-roles.component.scss']
})
export class ContactRolesComponent implements OnInit, OnChanges {
  @ViewChild('sidebarInstance') sidebarInstance: SidebarComponent;
  contactRoles: any;
  @Input() orderCode: string;
  @Output() valueChange = new EventEmitter();

  public selectedForm$: BehaviorSubject<any | null> = new BehaviorSubject<any | null>(null);

  public items: ItemModel[] = [{text: 'Edit'}];

  constructor(private store: Store<AppState>) {
  }

  ngOnInit(): void {

  }

  openSidebar(): void {
    this.sidebarInstance.show();
  }

  closeSidebar() {
    this.sidebarInstance.hide();
  }

  addNewRole() {
    this.valueChange.emit({action: 'addRole'});
  }

  public select(args: MenuEventArgs, role) {
    if (args.item.text === 'Edit') {
      this.selectedForm$.next(role);
      this.valueChange.emit({role, action: 'updateRole'});
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.orderCode) {
      this.store.select(store => store.companies.details.orders).subscribe((data: any) => {
        if (data) {
          const value = data.result.find(x => x.company_order_code === this.orderCode);
          if (value && value) {
            this.contactRoles = this.transformData(value);
            // this.contactRoles = value.contact_role ? value.contact_role : null;
            // if (this.contactRoles) {
            //   this.contactRoles = this.contactRoles.filter((thing, index, self) =>
            //     index === self.findIndex((t) => (
            //       t.contact_code === thing.contact_code
            //     ))
            //   );
            // }
          }
        }
      });
    }
  }

  transformData(data) {
    const contactList = [];
    if (data.contact_role) {
      for (const contactRoles of data.contact_role) {
        const contact = contactList.find(x => x.contact_code === contactRoles.contact.contact_code);
        if (contact) {
          contact.company_order_contact_role_code =  contactRoles.company_order_contact_role_code;
          contact.contact_role.push({order_role_code: contactRoles.order_role_code, order_role_name: contactRoles.order_role_name});
        } else {
          const contactCopy = {...contactRoles.contact};
          contactCopy.contact_role = [];
          contactCopy.company_order_contact_role_code =  contactRoles.company_order_contact_role_code;
          contactCopy.contact_role.push({order_role_code: contactRoles.order_role_code, order_role_name: contactRoles.order_role_name});
          contactList.push(contactCopy);
        }
      }
    }
    return contactList;
  }

}
