// Actions
import {ProjectSourceActionTypes, ProjectSourceActions} from '../actions/project-source.actions';

// Models
import {ProjectSource} from '../../models/project-source.model';
import {ProjectActionTypes} from '../actions/project.actions';
import {act} from '@ngrx/effects';
import {Project} from '../../models/project.model';
import * as _ from 'lodash';

export interface ProjectSourceState {
  projectSources: {
    result: ProjectSource[],
    total_count?: number,
    page_no?: number,
    page_size?: number
  };
  single_project_sources?: {
    result: ProjectSource[],
    total_count?: number,
    page_no?: number,
    page_size?: number
  };
  error?: Error;
  loading: boolean;
  loaded: boolean;
  success?: boolean;
  details: ProjectSource;
  db?: {
    result: ProjectSource[],
    total_count?: number,
    page_no?: number,
    page_size?: number
  };
}

export const initialState: ProjectSourceState = {
  projectSources: {
    result: [],
    total_count: 0,
    page_no: 0,
    page_size: 0
  },
  loading: false,
  loaded: false,
  details: undefined

};

export const ReturnLoading = (State: ProjectSourceState) => {
  return {
    ...State,
    loading: true,
    loaded: false,
    error: undefined,
    success: false,
  };
};

export function projectSourceReducer(state: ProjectSourceState = initialState, action: ProjectSourceActions): ProjectSourceState {
  switch (action.type) {

    case ProjectSourceActionTypes.LOAD: {
      return ReturnLoading(state);
    }

    case ProjectSourceActionTypes.LOAD_SUCCESS: {
      return {
        ...state, single_project_sources: {
          result: action.payload.result ? action.payload.result.filter(item => (item.company) || (item.contact)) : [],
          total_count: action.payload.total_count,
          page_no: action.payload.page_no,
          page_size: action.payload.page_size,
        }, loading: false, error: undefined, loaded: true, db: {
          result: action.payload.result ? action.payload.result.filter(item => (item.company) || (item.contact)) : [],
          total_count: action.payload.total_count,
          page_no: action.payload.page_no,
          page_size: action.payload.page_size,
        }
      };
    }

    case ProjectSourceActionTypes.LOAD_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case ProjectSourceActionTypes.ADD: {
      return ReturnLoading(state);
    }

    case ProjectSourceActionTypes.ADD_SUCCESS: {
      let result;
      if (!state.projectSources.result || state.projectSources.result.length === 0) {
        result = {result: [action.payload.projectSource], total_count: 1};
      } else {
        const count = state.projectSources.total_count + 1;
        result = {
          result: [_.clone(action.payload.projectSource), ...state.projectSources.result],
          total_count: count,
          page_no: state.projectSources.page_no,
          page_size: state.projectSources.page_size
        };
      }
      return {
        ...state,
        loading: false,
        projectSources: result,
        error: undefined,
        loaded: true,
        success: true
      };
    }

    case ProjectSourceActionTypes.ADD_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case ProjectSourceActionTypes.UPDATE: {
      return ReturnLoading(state);
    }

    case ProjectSourceActionTypes.UPDATE_SUCCESS: {
      const projectSourceIndex = state.projectSources.result.findIndex(x => x.project_source_code === action.payload.projectSource.project_source_code);
      const projectSource = state.projectSources[projectSourceIndex];
      const updatedProjectSource = {...projectSource, ...action.payload.projectSource};
      const updatedProjectSources = [...state.projectSources.result];

      const index = updatedProjectSources.findIndex(x => x.project_source_code === action.payload.projectSource.project_source_code);
      updatedProjectSources[index] = updatedProjectSource;

      return {
        ...state, projectSources: {...state.projectSources, result: updatedProjectSources}, error: undefined, loading: false, loaded: true,
        success: true
      };
    }

    case ProjectSourceActionTypes.UPDATE_FAILURE:
      return {...state, error: action.payload, loading: false, loaded: false};

    case ProjectSourceActionTypes.DELETE:
      return ReturnLoading(state);

    case ProjectSourceActionTypes.DELETE_SUCCESS: {
      return {
        ...state,
        projectSources: {result: state.projectSources.result.filter(item => item.project_source_code !== action.payload.project_source_code)},
        loading: false,
        error: undefined,
        loaded: true
      };
    }

    case ProjectSourceActionTypes.DELETE_FAILURE:
      return {...state, error: action.payload, loading: false, loaded: false};

    case ProjectSourceActionTypes.LOAD_SINGLE_PROJECT_SOURCE:
      return ReturnLoading(state);

    case ProjectSourceActionTypes.LOAD_SINGLE_PROJECT_SOURCE_SUCCESS: {
      return {...state, details: action.payload.projectSource, error: undefined, loading: false, loaded: true};
    }

    case ProjectSourceActionTypes.LOAD_SINGLE_PROJECT_SOURCE_FAILURE:
      return {...state, error: action.payload, loading: false, loaded: false};

    case ProjectSourceActionTypes.SET_SUCCESS_NULL:
      return ReturnLoading(state);


    case ProjectSourceActionTypes.GET_PROJECT_SOURCE_FROM_ONE_PROJECT:
      return ReturnLoading(state);

    case ProjectSourceActionTypes.GET_PROJECT_SOURCE_FROM_ONE_PROJECT_SUCCESS:
      return {
        ...state,
        projectSources: {
          result: action.payload.result ? action.payload.result.filter(item => (item.company) || (item.contact)) : [], page_no: action.payload.page_no, page_size: action.payload.page_size,
          total_count: action.payload.total_count
        },
        error: undefined,
        loading: false,
        loaded: true
      };


    case ProjectSourceActionTypes.GET_PROJECT_SOURCE_FROM_ONE_PROJECT_FAILURE:
      return {...state, error: action.payload, loading: false, loaded: false};

    case ProjectSourceActionTypes.SEARCH: {
      return ReturnLoading(state);
    }

    case ProjectSourceActionTypes.SEARCH_SUCCESS: {
      return {
        ...state, single_project_sources: {
          result: action.payload.result, total_count: action.payload.total_count,
          page_no: action.payload.page_no, page_size: action.payload.page_size
        }, loading: false, error: undefined, loaded: true, success: true
      };
    }

    case ProjectSourceActionTypes.SEARCH_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }


    // case ProjectSourceActionTypes.FILTER: {
    //   return ReturnLoading(state);
    // }

    // case ProjectSourceActionTypes.FILTER_SUCCESS: {
    //   return { ...state, contacts: action.payload, loading: false, error: undefined, loaded: true };
    // }

    // case ProjectSourceActionTypes.FILTER_FAILURE: {
    //   return { ...state, loading: false, error: action.payload, loaded: false };
    // }

    case ProjectSourceActionTypes.FILTER_RESET: {
      return {
        ...state,
        projectSources: {result: [...state.db.result], total_count: state.db.total_count},
        loading: false,
        error: undefined,
        loaded: true,
        success: true
      };
    }

    case ProjectSourceActionTypes.SEARCH_SOURCES: {
      return ReturnLoading(state);
    }

    case ProjectSourceActionTypes.SEARCH_SOURCES_SUCCESS: {
      return {
        ...state, single_project_sources: {
          result: action.payload.result, total_count: action.payload.total_count,
          page_no: action.payload.page_no, page_size: action.payload.page_size
        }, loading: false, error: undefined, loaded: true, success: true
      };
    }

    case ProjectSourceActionTypes.SEARCH_SOURCES_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case ProjectSourceActionTypes.FILTER: {
      return ReturnLoading(state);
    }

    case ProjectSourceActionTypes.FILTER_SUCCESS: {
      return {
        ...state, single_project_sources: {
          result: action.payload.result, total_count: action.payload.total_count,
          page_no: action.payload.page_no, page_size: action.payload.page_size
        }, loading: false, error: undefined, loaded: true, success: true
      };
    }

    case ProjectSourceActionTypes.FILTER_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    default:
      return state;
  }
}
