import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private readonly notifier: NotifierService;

  constructor(public notifierService: NotifierService) {
    this.notifier = notifierService;
  }

  success(message: string) {
    this.notifier.notify('default', message);
  }

  error(message: string = null) {
    if (!message) {
      message = 'Something went wrong!';
    }
    this.notifier.notify('error', message);
  }

  info(message: string = null) {
    if (!message) {
      message = 'Info';
    }

    this.notifier.notify('info', message);
  }
}
