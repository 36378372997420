import {AppService} from '../../core/services/app.service';
import {Feature} from '../../core/_base/crud/utils/feature';

export class BaseComponent {
  FEATURE_INSTANCE = Feature.getInstance();

  constructor(protected appService: AppService) {
  }



  canDelete(feature) {
    return this.appService.isAuthorized([feature], Feature.DELETE_ACCESS);
  }

  canUpdate(feature) {
    return this.appService.isAuthorized([feature], Feature.UPDATE_ACCESS);
  }

  canView(feature) {
    return this.appService.isAuthorized([feature], Feature.VIEW_ACCESS);
  }

  canAdd(feature) {
    return this.appService.isAuthorized([feature], Feature.ADD_ACCESS);
  }

}

export function formatDataSet(columns, result) {
  const data = [];
  if (result && result.data && result.data.length > 0) {
    if (!Array.isArray(result.data[0])) {
      return(data);
    } else {
      result.data.forEach((values, id) => {
        data.push({});
        values.forEach((value, idx) => {
          if (columns[idx]) {
            data[id][columns[idx]] = value;
          }
        });
        if (id === result.data.length - 1) {
          result.data = data;
          return(data);
        }
      });
      return result;
    }
  }
}

export function findAndReplace(arrayList, findValue, replaceValue, code) {
  for (const arrayItem in arrayList) {
    if (arrayList[arrayItem][code] === findValue) {
      arrayList[arrayItem] = replaceValue;
      return arrayList;
    }

    if (arrayList[arrayItem].children) {
      const children = findAndReplace([...arrayList[arrayItem].children], findValue, replaceValue, code);
      const companyRoleListChildCopy: any = {...arrayList[arrayItem]};
      companyRoleListChildCopy.children = children;
      arrayList[arrayItem] = companyRoleListChildCopy;
    }
  }
  return arrayList;
}

export function findAndDelete(arrayList, findValue, code) {
  for (const arrayItem in arrayList) {
    if (arrayList[arrayItem][code] === findValue) {
      delete arrayList[arrayItem];
      const arrayItemArray = arrayList.filter((el) => {
        return el != null;
      });
      return arrayItemArray;
    }

    if (arrayList[arrayItem].children) {
      const children = findAndDelete([...arrayList[arrayItem].children], findValue, code);
      const arrayListChildCopy: any = {...arrayList[arrayItem]};
      arrayListChildCopy.children = children;
      arrayList[arrayItem] = arrayListChildCopy;
    }
  }
  return arrayList;
}

export function find(array, id, code) {
  let result;
  array.some(o => result = o[code] === id ? o : find(o.children || [], id, code));
  return result;
}



