import {Action} from '@ngrx/store';
import {ProjectStatus} from '../../models/project-status.model';

export enum ProjectStatusActionsType {
  LOAD_PROJECT_STATUS = '[PROJECT STATUS] Load Action',
  LOAD_PROJECT_STATUS_SUCCESS = '[PROJECT STATUS] Load Success Action',
  LOAD_PROJECT_STATUS_FAILURE = '[PROJECT STATUS] Load Failure Action',

  ADD_PROJECT_STATUS = '[PROJECT STATUS] Add Action',
  ADD_PROJECT_STATUS_SUCCESS = '[PROJECT STATUS] Add Success Action',
  ADD_PROJECT_STATUS_FAILURE = '[PROJECT STATUS] Add Failure Action',

  UPDATE_PROJECT_STATUS = '[PROJECT STATUS] Update Action',
  UPDATE_PROJECT_STATUS_SUCCESS = '[PROJECT STATUS] Update Success Action',
  UPDATE_PROJECT_STATUS_FAILURE = '[PROJECT STATUS] Update Failure Action',

  DELETE_PROJECT_STATUS = '[PROJECT STATUS] Delete Action',
  DELETE_PROJECT_STATUS_SUCCESS = '[PROJECT STATUS] Delete Success Action',
  DELETE_PROJECT_STATUS_FAILURE = '[PROJECT STATUS] Delete Failure Action',

  RESET_ERROR = '[PROJECT STATUS] Project Status Reset Error Action'
}

export class ProjectStatusLoad implements Action {
  readonly type = ProjectStatusActionsType.LOAD_PROJECT_STATUS;
  constructor(public payload?: {page_size?: number, page_no?: number}) { }
}

export class ProjectStatusLoadSuccess implements Action {
  readonly type = ProjectStatusActionsType.LOAD_PROJECT_STATUS_SUCCESS;
  constructor(public payload: { result: Array<ProjectStatus>, total_count: number, page_size: number, page_no: number }) { }
}

export class ProjectStatusLoadFailure implements Action {
  readonly type = ProjectStatusActionsType.LOAD_PROJECT_STATUS_FAILURE;
  constructor(public payload: Error) { }
}

export class ProjectStatusAdd implements Action {
  readonly type = ProjectStatusActionsType.ADD_PROJECT_STATUS;
  constructor(public payload: {status_name: string, parent_type_code?: string, description: string, is_notes_mandatory_yn: number}) { }
}

export class ProjectStatusAddSuccess implements Action {
  readonly type = ProjectStatusActionsType.ADD_PROJECT_STATUS_SUCCESS;
  constructor(public payload: { projectStatus: ProjectStatus , parentCode: string}) { }
}

export class ProjectStatusAddFailure implements Action {
  readonly type = ProjectStatusActionsType.ADD_PROJECT_STATUS_FAILURE;
  constructor(public payload: Error) { }
}


export class ProjectStatusUpdate implements Action {
  readonly type = ProjectStatusActionsType.UPDATE_PROJECT_STATUS;
  constructor(public payload: { status_code: string, projectStatusName: string, parent_type_code: string, description: string,
    is_notes_mandatory_yn: number }) { }
}

export class ProjectStatusUpdateSuccess implements Action {
  readonly type = ProjectStatusActionsType.UPDATE_PROJECT_STATUS_SUCCESS;
  constructor(public payload: { projectStatus: ProjectStatus, parent_type_code: string }) { }
}

export class ProjectStatusUpdateFailure implements Action {
  readonly type = ProjectStatusActionsType.UPDATE_PROJECT_STATUS_FAILURE;
  constructor(public payload: Error) { }
}

export class ProjectStatusDelete implements Action {
  readonly type = ProjectStatusActionsType.DELETE_PROJECT_STATUS;
  constructor(public payload: { projectStatusCode: string }) { }
}

export class ProjectStatusDeleteSuccess implements Action {
  readonly type = ProjectStatusActionsType.DELETE_PROJECT_STATUS_SUCCESS;
  constructor(public payload: { projectStatusCode: string }) { }
}

export class ProjectStatusDeleteFailure implements Action {
  readonly type = ProjectStatusActionsType.DELETE_PROJECT_STATUS_FAILURE;
  constructor(public payload: Error) { }
}

export class ProjectStatusResetError implements Action {
  readonly type = ProjectStatusActionsType.RESET_ERROR;
}

export type ProjectStatusActions = ProjectStatusLoad | ProjectStatusLoadSuccess | ProjectStatusLoadFailure | ProjectStatusAdd |
  ProjectStatusAddSuccess | ProjectStatusAddFailure | ProjectStatusUpdate | ProjectStatusUpdateSuccess | ProjectStatusUpdateFailure |
  ProjectStatusDelete | ProjectStatusDeleteSuccess | ProjectStatusDeleteFailure | ProjectStatusResetError;
