import {
  Component,
  EventEmitter,
  HostListener,
  Input, NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import * as _ from 'lodash';
import {
  companyNameValidator, emailValidator,
  specialCharacterValidator,
  telephoneValidator,
  trimValidator,
  websiteValidator
} from '../../../../app-validators';
import {Company} from '../../../../core/models/company.model';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {CompanyAdd, CompanySetErrorNull, CompanySetStatusNull, CompanyUpdate} from '../../../../core/store/actions/company.actions';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../core';
import {EmitType} from '@syncfusion/ej2-base';
import {DropDownListComponent, FilteringEventArgs, MultiSelectComponent, SelectEventArgs} from '@syncfusion/ej2-angular-dropdowns';
import {Query} from '@syncfusion/ej2-data';
import {Tags} from '../../../../core/models/tags.model';
import {SidebarComponent} from '@syncfusion/ej2-angular-navigations';
import {CompanyService} from '../../../../core/store/services/company.service';
import {map} from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-company-form',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('sidebarInstance') public sidebarInstance: SidebarComponent;
  @ViewChild('industryType') public industryType: MultiSelectComponent;
  @ViewChild('visibility') visibilityDropdown: DropDownListComponent;


  @Input() users: any;
  @Input() companyTypes: any;
  @Input() communityTypes: any;
  @Input() allTags: Observable<Array<Tags>>;
  @Input() countries: { [key: string]: object }[];
  @Output() valueChange = new EventEmitter();

  companyForm: FormGroup;
  showUploadingBtn = false;
  @ViewChild('tags') companyTag: MultiSelectComponent;

  public width = 'auto';
  public position = 'Right';
  selectedTag = [];
  tagSelected = '';
  imgFile: string;
  companyLogoReferenceID = '';
  allowEdit = false;
  preSelectedFiles = [];
  selectedCommunityTypesObs = new BehaviorSubject ([]);
  selectedCommunityTypes: any[] = [];
  // preSelectedFiles$ = new BehaviorSubject([]);

  errorMsg: any;
  error$: Observable<any>;
  success$: Observable<string>;
  public formSent: boolean;
  duplicateMsg$ = new BehaviorSubject('');
  url: any;
  public setCleared$: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(false);
  preLoadedFiles: any;
  companyFirstEdit = true;

  public industryFields: object;
  public tagFields: object = {value: 'tag', text: 'tag'};
  public communityTypeField: any = {value: 'community_type_code', text: 'community_type_name'};
  public countryFields: object = {value: 'country_code', text: 'country_name'};
  public userFields: object = {dataSource: this.users, value: 'user_code', text: ('first_name')};
  public visibilityFields: object = {value: 'visibility_code', text: 'visibility_name'};

  public _companyInput$: BehaviorSubject<Company | null> = new BehaviorSubject<Company | null>(null);
  public tagModelData = [];
  modelData = [];
  displayPasswordInput = false;

  public selectedItem:string = "public";

  public visibilityList: any[] = [
    {visibility_code: 'private', visibility_name: 'Private'},
    {visibility_code: 'password_protected', visibility_name: 'Password Protected'},
    {visibility_code: 'public', visibility_name: 'Public'},
  ];

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.sidebarInstance.isOpen) {
      this.closeSidebar();
    }
  }

  @Input() set companyInput(mode: Company | null) {
    this._companyInput$.next(mode);
  }

  get companyInput(): Company | null {
    return this._companyInput$.getValue();
  }

  constructor(private formBuilder: FormBuilder, private store: Store<AppState>, private companyService: CompanyService) {
  }


  ngOnChanges(changes: SimpleChanges) {
    if (this._companyInput$.getValue()) {

      if (this._companyInput$.getValue().company_logo_reference_id) {
        this.preLoadedFiles = [{
          filename: this._companyInput$.getValue().company_logo_filename,
          url: this._companyInput$.getValue().company_logo_reference_id
        }];
      }

      const community_type_array = [];
      if (this._companyInput$.getValue().community_type) {
        for (const companyType of this._companyInput$.getValue().community_type) {
          community_type_array.push(companyType.community_type_code);
        }
      }

      if (!this.companyForm) {
        this.generateCompanyForm();
      }

      const company_tags_array = [];
      if (this._companyInput$.getValue().company_tag) {
        for (const tag of this._companyInput$.getValue().company_tag) {
          company_tags_array.push(tag.tag);
        }
      }


      const primary_community_type_array = [];
      if (this._companyInput$.getValue().primary_community_type) {
        for (const companyType of this._companyInput$.getValue().primary_community_type) {
          primary_community_type_array.push(companyType.community_type_code);
        }
      }

      this.companyForm.patchValue({
        companyName: this._companyInput$.getValue().company_name ? this._companyInput$.getValue().company_name : null,
        display_name: this._companyInput$.getValue().display_name ? this._companyInput$.getValue().display_name : null,
        accountOwner: this._companyInput$.getValue().account_owner ? this._companyInput$.getValue().account_owner.user_code : null,
        industryType: community_type_array ? community_type_array : null,
        telephone: this._companyInput$.getValue().tel_no ? this._companyInput$.getValue().tel_no : null,
        website: this._companyInput$.getValue().website ? this._companyInput$.getValue().website : null,
        address: this._companyInput$.getValue().address ? this._companyInput$.getValue().address : null,
        city: this._companyInput$.getValue().city_name ? this._companyInput$.getValue().city_name : null,
        country: this._companyInput$.getValue().country.country_code ? this._companyInput$.getValue().country.country_code : null,
        tags: this._companyInput$.getValue().company_tag ? company_tags_array : null,
        excerpt: this._companyInput$.getValue().excerpt ? this._companyInput$.getValue().excerpt : null,
        email: this._companyInput$.getValue().email ? this._companyInput$.getValue().email : null,
        slug: this._companyInput$.getValue().slug ? this._companyInput$.getValue().slug : null,
        member_since: this._companyInput$.getValue().member_since ? moment(this._companyInput$.getValue().member_since, 'YYYY-MM-DD').format('MM/DD/YYYY')  : null,
        p_o_box: this._companyInput$.getValue().p_o_box ? this._companyInput$.getValue().p_o_box : null,
        visibility: this._companyInput$.getValue().visibility ? this._companyInput$.getValue().visibility : null,
        password_protected_password: this._companyInput$.getValue().password_protected_password ? this._companyInput$.getValue().password_protected_password : null,
        // companyLogo: null
      });

      if (this._companyInput$.getValue().company_logo_reference_id) {
        this.companyLogoReferenceID = this._companyInput$.getValue().company_logo_reference_id;
      }


    }

    if (changes.companyTypes) {
      this.industryFields = {dataSource: this.companyTypes, value: 'company_type_code', text: 'company_type_name', child: 'children'};
    }
  }

  ngOnInit(): void {
    if (!this.companyForm) {
      this.generateCompanyForm();
    }

    if (this.allTags) {
      this.allTags.subscribe(data => {
        this.tagModelData = data;
      });
    }
    // this.companyForm.controls.tags.valueChanges.subscribe(value => {
    //   // setTimeout(()=> { this.selectedTag = []; }, 1000);
    //
    //
    // });

    this.error$ = this.store.pipe(select(store => store.companies.error));
    this.error$.subscribe((data: any) => {

      if (data) {
        this.errorMsg = data.error.message;
        this.formSent = false;

      }
    });

    this.success$ = this.store.pipe(select(store => store.companies.success.isForm));
    this.success$.subscribe((data: any) => {

      if (data) {
        this.formSent = false;
        this.closeSidebar();
        this.store.dispatch(new CompanySetStatusNull());
      }
    });
  }

  generateCompanyForm() {
    this.companyForm = this.formBuilder.group({
      companyName: new FormControl('', [Validators.required, Validators.compose([trimValidator])
      ]),
      display_name: new FormControl('', [Validators.maxLength(100)]),
      accountOwner: new FormControl(''),
      industryType: new FormControl(''),
      telephone: new FormControl('', [Validators.compose([telephoneValidator]), Validators.maxLength(50)]),
      website: new FormControl('', [Validators.compose([websiteValidator]), Validators.maxLength(200)]),
      address: new FormControl('', [Validators.maxLength(200)]),
      city: new FormControl('', [Validators.required, Validators.maxLength(100), Validators.compose([trimValidator])]),
      country: new FormControl('', [Validators.required]),
      tags: new FormControl(''),
      excerpt: new FormControl(''),
      email: new FormControl('', [Validators.compose([emailValidator])]),
      slug: new FormControl('', [Validators.maxLength(200), Validators.required, Validators.compose([Validators.pattern('^[A-Za-z0-9-]+$')])]),
      member_since: new FormControl(''),
      p_o_box: new FormControl(''),
      companyLogo: new FormControl(''),
      // visibility: new FormControl('', Validators.required),
      // password_protected_password: new FormControl('', Validators.maxLength(255)),
      primary_community_type: new FormControl('')
    });

  }

  onAddCompany(form: FormGroup) {
    if (!form.invalid) {
      this.formSent = true;
      this.store.dispatch(new CompanyAdd({
        company_name: this.companyForm.value.companyName.trim(),
        display_name: this.companyForm.value.display_name ? this.companyForm.value.display_name.trim() : null,
        address: this.companyForm.value.address,
        city_name: this.companyForm.value.city,
        country: this.companyForm.value.country,
        website: this.companyForm.value.website,
        tel_no: this.companyForm.value.telephone,
        industry_type: _.cloneDeep(this.companyForm.value.industryType),
        accountOwner: form.value.accountOwner,
        company_tag: form.value.tags,
        excerpt: this.companyForm.value.excerpt,
        email: this.companyForm.value.email,
        slug: this.companyForm.value.slug,
        member_since: this.companyForm.value.member_since ? moment(this.companyForm.value.member_since).format('YYYY-MM-DD'): null ,
        p_o_box: this.companyForm.value.p_o_box,
        company_logo_reference_id: this.companyLogoReferenceID,
        primary_community_type_code: this.companyForm.value.primary_community_type

      }));

    }
  }

  onUpdateCompany(form: FormGroup) {
    console.log(form)
    // var selected_country: { [key: string]: object }[] = [];
    // selected_country[0] = this.countries.find(x => x.country_code === form.value.country);

    const company = {
      company_code: this._companyInput$.getValue().company_code,
      display_name: form.value.display_name,
      company_name: form.value.companyName,
      address: form.value.address,
      city_name: form.value.city,
      country_code: form.value.country,
      website: form.value.website,
      tel_no: form.value.telephone,
      community_type_code: _.cloneDeep(form.value.industryType),
      account_owner: form.value.accountOwner,
      company_tag: form.value.tags,
      excerpt: form.value.excerpt,
      is_inactive_yn: this._companyInput$.getValue().is_inactive_yn,
      is_published_yn: this._companyInput$.getValue().is_published_yn,
      email: form.value.email,
      slug: form.value.slug,
      member_since: form.value.member_since ? moment(form.value.member_since).format('YYYY-MM-DD') : null,
      p_o_box: form.value.p_o_box,
      company_logo_reference_id: this.companyLogoReferenceID,
      description: this._companyInput$.getValue().description,
      password_protected_password: this._companyInput$.getValue().password_protected_password,
      primary_community_type_code: this.companyForm.value.primary_community_type
    };
    // const company = new Company(this._companyInput$.getValue().company_code, form.value.companyName, form.value.address, form.value.city,
    //   selected_country, form.value.website, form.value.telephone, form.value.industryType, null, {
    //     user_code: form.value.accountOwner,
    //     first_name: null, last_name: null
    //   }, {user_code: form.value.userResponsible, first_name: null, last_name: null});
    if (!form.invalid) {
      this.formSent = true;
      this.store.dispatch(new CompanyUpdate({index: this._companyInput$.getValue().company_code, company}));
    }
  }

  public onFiltering: EmitType<any> = (e: FilteringEventArgs) => {
    let query = new Query();
    query = (e.text !== '') ? query.where('country_name', 'startswith', e.text, true) : query;
    e.updateData(this.countries, query);
  };

  ngOnDestroy() {
    this._companyInput$.unsubscribe();
    this.duplicateMsg$.unsubscribe();
    this.selectedCommunityTypesObs.unsubscribe();
  }


  openSidebar(): void {
    this.sidebarInstance.show();
    this.setCleared$.next(false);

  }

  closeSidebar(): void {
    this.errorReset();
    this.companyForm.reset();
    this.valueChange.emit('success');
    if (this.sidebarInstance && this.sidebarInstance.isOpen) {
      this.sidebarInstance.hide();
    }
    this.setCleared$.next(true);
    this.preLoadedFiles = [];
    this.companyFirstEdit = true;
  }

  onBlur(args, form) {
    this.tagSelected = this.selectedTag[this.selectedTag.length - 1];

    if (!this.modelData.includes(this.tagSelected)) {
      this.companyTag.addItem({tag: this.tagSelected, tag_code: this.tagSelected});
      form.controls.tags.setValue(_.uniq(_.compact([...this.modelData, this.tagSelected])));
    }

    this.modelData = _.compact([...this.modelData, this.tagSelected]);
    this.modelData.push(this.tagSelected);

    this.selectedTag = [];
  }

  syncModel = (event) => {
    this.modelData = event;
  };

  actionComplete(args) {
    if (_.get(args, 'result[0]')) {
      this.selectedTag.push(args.result[0].tag);
    }
  }

  onChange(event) {
    const tagsArray = _.compact(this.modelData);
    if (tagsArray.length === 0) {
      this.modelData = [];
    }
  }

  select(args) {
    this.selectedTag = [];
  }

  errorReset() {
    this.store.dispatch(new CompanySetErrorNull());
  }

  checkDuplicates(companyName: string) {
    if ((this._companyInput$.getValue() && this._companyInput$.getValue().company_name !== companyName.trim() && companyName.trim() !== '') || (!this._companyInput$.getValue() && companyName.trim() !== '')) {
      this.companyService.checkDuplicates({company_name: companyName}).subscribe(data => {
        if (_.size(data.data)) {
          this.duplicateMsg$.next('Warning: We found ' + data.data.length + ' more company/companies with the same name!');
        } else {
          this.duplicateMsg$.next('');
        }
      }, err => {
        console.log(err);
      });
    } else {
      this.duplicateMsg$.next('');
    }

  }

  getCompanySlug(companyName: string, form: FormGroup) {
    if ((this._companyInput$.getValue() && this._companyInput$.getValue().company_name !== companyName.trim() && companyName.trim() !== '') || (!this._companyInput$.getValue() && companyName.trim() !== '')) {
      this.companyService.getCompanySlug({company_name: companyName}).subscribe(data => {
        if (data && data.data) {
          form.controls.slug.setValue(data.data);
        }
      }, err => {
        console.log(err);
      });
    }
  }

  setCompanyType(args, companyForm: FormGroup) {

    this.selectedCommunityTypes = [];
    this.selectedCommunityTypesObs.next([]);

    const communityTypes = args.value;
    this.companyForm.controls.industryType.setValue(args.value);
    if (communityTypes) {
      communityTypes.forEach(element => {
        const foundIndex = this.communityTypes.findIndex((item) => item.community_type_code === element);
        console.log(element);
        console.log(foundIndex)
        if (foundIndex !== -1) {
          this.selectedCommunityTypes.push(this.communityTypes[foundIndex]);
        } 
      });
    }

    

    if (this.selectedCommunityTypes.length > 0) {
      companyForm.controls.primary_community_type.setValidators(Validators.required);
      companyForm.controls.primary_community_type.markAsTouched();
      companyForm.controls.primary_community_type.updateValueAndValidity();
    } else {
      companyForm.controls.primary_community_type.clearValidators();
      companyForm.controls.primary_community_type.updateValueAndValidity();
    }

    console.log(this.companyForm.controls.primary_community_type.value);
    
    if (this.companyForm.controls.primary_community_type.value !== '' && !this.selectedCommunityTypes.includes(this.companyForm.controls.primary_community_type.value)) {
      this.companyForm.controls.primary_community_type.setValue(null);
    }

    this.selectedCommunityTypesObs.next(this.selectedCommunityTypes);

    if (this._companyInput$.getValue() && this.companyFirstEdit) {
      console.log(this._companyInput$.getValue());
      this.companyForm.controls.primary_community_type.setValue(this._companyInput$.getValue().primary_community_type[0].community_type_code);
      this.companyFirstEdit = false;
    }

  }

  updateReferenceID(args) {
    console.log(args);
    if (args.reference_id && args.isUploading === false) {
      console.log("line 380");
      this.companyLogoReferenceID = args.reference_id;
      this.formSent = false;
      this.showUploadingBtn = false;
      console.log("UPLOADING COMPLETE");
    } else if (args.cleared === true) {
      // this.setCleared$.next(null);
      this.formSent = false;
    } else if (args.isUploading === true) {
      this.formSent = true;
      this.showUploadingBtn = true;
      console.log("UPLOADING NOW");
    } else if (args.reference_id === '' && args.isUploading === false) {
      console.log("line 393");
      this.companyLogoReferenceID = args.reference_id;
      this.formSent = false;
      this.showUploadingBtn = false;
    }
  }


  onOpen(event)
  {
    for(let [index, communityType] of (this.industryType as any).popupObj.element.querySelectorAll(".e-list-item").entries()) {
      let foundVal = this.communityTypes.find((item) => item.community_type_code === communityType.dataset.value);
      if (foundVal.publish_status === 'draft') {
        if(! (this.industryType as any).popupObj.element.querySelectorAll(".e-list-item")[index].classList.contains("e-hide")) {
          // (this.industryType as any).popupObj.element.querySelectorAll(".e-list-item")[index].classList.add("e-hide")
          (this.industryType as any).popupObj.element.querySelectorAll(".e-list-item")[index].classList.add("e-disabled")

        }
      }
    }
  }

  onSelect(args: SelectEventArgs) {
    if (args.item.classList.contains('e-hide')) {
      args.cancel = true;
    }
  }



}
