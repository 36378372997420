import { Injectable } from '@angular/core';
import { Notification } from './notification';
import { NotificationType } from './notification-type';
import { Notifier } from './notifier';

@Injectable({
  providedIn: 'root'
})
export class NotifierService {

  public notifier = new Notifier();
  timer;

  public notify(message: any, notificationType: NotificationType, duration: number = 8000): void {

    const notification: Notification = new Notification(message, notificationType);

    const dismissWait = () => {
      new Promise<void>((resolve) => setTimeout(resolve, duration)).then(() => {
        this.notifier.destroy(notification);
      });
    };

    this.notifier.add(notification);

    if (notificationType === NotificationType.Reload) {
      const progressValue = 0;
      notification.message.closeDuration = 10;
      notification.message.progressValue = 0;
      this.timer = setInterval(() => {
        notification.message.closeDuration = notification.message.closeDuration - 1;
        notification.message.progressValue = Math.abs((notification.message.closeDuration * 10) - 100);
        if (notification.message.closeDuration < 0) {
          notification.message.progressValue = 100;
          clearInterval(this.timer);
          window.location.reload();
        }
      }, 1000);
    } else {
      dismissWait();
    }
  }

  public destroyNotify() {
    clearInterval(this.timer);
  }
}
