<ejs-sidebar id="sidebar-menu" #sidebarInstance type='Over' [width]='width' [position]='position'
             style="visibility: hidden" [enableGestures]="false" [showBackdrop]=true>

  <div class="offcanvas-close mt-n1 pr-5" style="float:right; padding: 48px;">
    <a href="javascript:;" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_panel_close"
       (click)="closeSidebar()">
      <i class="ki ki-close icon-xs text-muted"></i>
    </a>
  </div>

  <div class='p-15'>
    <h2 *ngIf="!deliverableItemInput">Add Deliverable Item</h2>
    <h2 *ngIf="deliverableItemInput">Update Deliverable Item</h2>

    <form id="formId" [formGroup]="deliverableItemForm" #formDir="ngForm" class="form-horizontal" novalidate=""
          style="margin-top: 16px;">

      <div class="form-group sf-outline-input mb-10" [class.hide-label]="deliverableItemForm.controls.deliverable_item_parent_code.value == ''">
        <ejs-dropdowntree formControlName="deliverable_item_parent_code" id='deliverable_item_parent_code' allowMultiSelection='false'
                          [fields]='deliverableItemFields' placeholder='Parent Deliverable Item' floatLabelType="Auto" cssClass="e-filled" (valueChange)="setParentValue($event, deliverableItemForm)"
                          (blur)="deliverableItemForm.controls.deliverable_item_parent_code.markAsTouched()"></ejs-dropdowntree>
        <div>Note: If a parent item isn't selected, the new deliverable item will be added as a parent</div>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="deliverable_item_name" placeholder="Deliverable Item Name *" cssClass="e-filled"
                     formControlName="deliverable_item_name" floatLabelType="Auto"
                     (blur)="deliverableItemForm.controls.deliverable_item_name.markAsTouched()"
        ></ejs-textbox>
        <div *ngIf="deliverableItemForm.controls.deliverable_item_name.errors">
          <div
            *ngIf="deliverableItemForm.controls.deliverable_item_name.errors.required && deliverableItemForm.controls.deliverable_item_name.touched"
            class="e-error">
            Deliverable Item Name is required.
          </div>
        </div>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="description" [multiline]="true" placeholder="Description" cssClass="e-filled"
                     formControlName="description" floatLabelType="Auto"
                     (blur)="deliverableItemForm.controls.description.markAsTouched()"
        ></ejs-textbox>
        <div *ngIf="deliverableItemForm.controls.description.errors">
          <div *ngIf="deliverableItemForm.controls.description.errors.maxlength && deliverableItemForm.controls.description.touched"
               class="e-error">
            Description cannot be more than 200 characters
          </div>
        </div>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-multiselect id='deliverable_item_date' formControlName="deliverable_item_date" #checkbox
                         [dataSource]='deliverableDateList' cssClass="e-filled" floatLabelType="Auto"
                         placeholder='Deliverable Item Date' [fields]='deliverableDateFields'
                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                         filterBarPlaceholder='Search'></ejs-multiselect>
      </div>


      <div *ngIf="deliverableItemForm.invalid" class="e-error">Please fill all fields</div>
      <div *ngIf="error$ | async as error" class="e-error">{{error.error.message}}</div>

      <div class="my-10 pb-20">
        <button id="resetbtn"
                class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5"
                type="reset"
                data-ripple="true">Clear
        </button>
        <button *ngIf="!deliverableItemInput" id="addSubmit"
                class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit"
                (click)="onAddUpdateDeliverableInput(deliverableItemForm)" [disabled]="formSent" data-ripple="true"> Add
          Deliverable Item
        </button>
        <button *ngIf="deliverableItemInput" id="updateSubmit"
                class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit"
                (click)="onAddUpdateDeliverableInput(deliverableItemForm, true)" [disabled]="formSent" data-ripple="true">
          Update Deliverable Item
        </button>
      </div>
    </form>
  </div>
</ejs-sidebar>
