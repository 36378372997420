import {Component, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {Tags} from '../../../../../core/models/tags.model';
import {
  ProjectFilter,
  ProjectFilterReset, ProjectLoad,
  ProjectSetProjectsNull,
  ProjectSetStatusNull
} from '../../../../../core/store/actions/project.actions';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../core';
import {SidebarComponent} from '@syncfusion/ej2-angular-navigations';
import {ActivatedRoute, Router} from '@angular/router';
import * as moment from 'moment';
import {CompanyFilter, CompanyGetProjects, CompanyProjectFilter} from '../../../../../core/store/actions/company.actions';
import {ContactFilter, ContactGetProjects, ContactProjectFilter} from '../../../../../core/store/actions/contact.actions';
import {debounce, EmitType} from '@syncfusion/ej2-base';
import {FilteringEventArgs, MultiSelectComponent} from '@syncfusion/ej2-angular-dropdowns';
import {Query} from '@syncfusion/ej2-data';
import * as _ from 'lodash';
import {ProjectSourceSearchAllSources} from '../../../../../core/store/actions/project-source.actions';
import {ContactService} from '../../../../../core/store/services/contact.service';

@Component({
  selector: 'app-project-filter',
  templateUrl: './project-filter.component.html',
  styleUrls: ['./project-filter.component.scss']
})
export class ProjectFilterComponent implements OnInit, OnChanges {

  constructor(private formBuilder: FormBuilder, private store: Store<AppState>, private router: Router, private route: ActivatedRoute,
              private contactService: ContactService) {
  }
  @ViewChild('sidebarInstance')
  public sidebarInstance: SidebarComponent;
  @ViewChild('tags') projectTag: MultiSelectComponent;

  @Input() users: object;
  @Input() status: object;
  @Input() countries: object;
  @Input() sourceTypes: object;
  @Input() projectTypes: object;
  @Input() companyList: any;
  @Input() contactList: any;
  @Input() allTags: Observable<Array<Tags>>;

  public width = 'auto';
  public position = 'Right';

  error$: Observable<any>;
  success$: Observable<boolean>;

  formSent: boolean;
  isFilterReset = false ;
  public companyFields: object = {value: 'company_code', text: 'company_name'};
  public contactFields: object = {value: 'value', text: 'name'};
  userFields: object = {value: 'user_code', text: ('first_name')};
  sourceTypeFields: object = {value: 'project_source_type_code', text: 'project_source_type_name'};
  public fields: object = { groupBy: 'category', value: 'name' };

  countriesField: object;
  projectTypeFields: object;
  projectStatusFields: object;
  selectedCompanyName = '';
  allowEdit = false;

  selectedTag = [];
  tagSelected = '';
  public tagModelData = [];
  modelData = [];
  selectedSourceCode: any;

  tagFields: object = {value: 'tag', text: 'tag'};
  public treeSettings: object = { autoCheck: true };

  filterForm: FormGroup;
  sourcesList = [];

  filterFormArray = ['project_type_code', 'project_type', 'status_code', 'assigned_to_user',
  'published_by', 'country_code', 'project_tag', 'contact_code', 'company_code', 'updated_by', 'created_by', 'project_source_type_code'];

  setDebounce = debounce((e, entity: string) => {
    this.onFilter(e, entity);
  }, 700);

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.sidebarInstance.isOpen) {
      this.closeSidebar();
    }
  }

  ngOnInit(): void {
    this.generateFilterForm();
    this.allTags.subscribe(data => {
      this.tagModelData = data;
    });

    this.success$ = this.store.pipe(select(store => store.projects.success));
    this.error$ = this.store.pipe(select(store => store.projects.error));

    this.success$.subscribe((data: any) => {
      if (data) {
        this.formSent = false;
        if (!this.isFilterReset) {
          this.closeSidebar();
        }
        this.store.dispatch(new ProjectSetStatusNull());
      }
    });

    this.error$.subscribe((data: any) => {
      if (data) {
        this.formSent = false;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.projectTypes) {
      this.projectTypeFields = {dataSource: this.projectTypes, value: 'project_type_code', text: 'project_type_name', child: 'children'};
    }

    if (changes.countries) {
      this.countriesField = {dataSource: this.countries, value: 'country_code', text: 'country_name'};
    }

    if (changes.status) {
      this.projectStatusFields = {dataSource: this.status, value: 'status_code', text: 'status_name', child: 'children'};
    }

    if (changes.sourceTypes) {
      this.sourceTypeFields = {value: 'project_source_type_code', text: 'project_source_type_name', child: 'children', dataSource: this.sourceTypes};

    }
  }

  generateFilterForm() {
    this.filterForm = this.formBuilder.group({
      project_name: new FormControl(null),
      reference_id: new FormControl(null),
      project_type_code: new FormControl(''),
      status_code: new FormControl(''),
      assigned_to_user: new FormControl(''),
      published_by: new FormControl(''),
      country_code: new FormControl(''),
      proposed_post_date: new FormControl(null),
      project_tag: new FormControl(''),
      created_at: new FormControl(null),
      updated_at: new FormControl(null),
      contact_code: new FormControl(null),
      company_code: new FormControl(null),
      updated_by: new FormControl(null),
      created_by: new FormControl(null),
      featured_date: new FormControl(null),
      project_source_type_code: new FormControl(null),
      is_deleted_yn: new FormControl(null),
      is_source_exist: new FormControl(null)
    });

    this.route.queryParams.subscribe(res => {
      // console.log(res);
      // let filterVal = {...this.filterForm.value};
      // filterVal = {...filterVal, ...res};
      // // console.log(filterVal);
      // for (const fKey in filterVal) {
      //   if (this.filterFormArray.includes(fKey)) {
      //     // console.log(filterVal[fKey]);
      //     if (filterVal[fKey]) {
      //       filterVal[fKey] = filterVal[fKey].split(',');
      //     }
      //   }
      // }

      // this.filterForm.setValue({...filterVal});
      // this.filterForm.controls.project_type_code.setValue(res.project_type_code.split(','));
      // console.log(this.filterForm.value);
      // console.log(filterVal);
      // const queryCopy = {...res};
      // delete queryCopy.project_type_code;
      // this.filterForm.patchValue(queryCopy);
      // console.log(this.filterForm.value);
      // console.log(queryCopy);
      // const resCopy = {...res};
      // const types = resCopy.project_type_code.toString().split(',');
      // console.log(types);
      // this.filterForm.setValue(res);
      // this.filterForm.setValue({company_type_code: types});
      // this.filterForm.controls.project_type_code.value = types;
      // this.filterForm.patchValue({project_type_code: types});
      // console.log(this.filterForm.value);
    });
  }

  getFilteredData() {
    this.isFilterReset = false;
    let filterValues = {...this.filterForm.value};
    Object.keys(filterValues).forEach((key) => ((filterValues[key] == null) || (filterValues[key] === '')) && delete filterValues[key]);

    if (this.selectedSourceCode) {
      if (this.selectedSourceCode.category === 'Contacts') {
        filterValues.contact_code_source = this.selectedSourceCode.value;
      } else if (this.selectedSourceCode.category === 'Company') {
        filterValues.company_code_source = this.selectedSourceCode.value;
      }
    }
    if (filterValues.proposed_post_date) {
      filterValues.proposed_post_date_end = moment(filterValues.proposed_post_date[1]).format('YYYY-MM-DD');
      filterValues.proposed_post_date_start = moment(filterValues.proposed_post_date[0]).format('YYYY-MM-DD');
    }
    delete filterValues.proposed_post_date;

    if (filterValues.featured_date) {
      filterValues.featured_date_end = moment(filterValues.featured_date[1]).format('YYYY-MM-DD');
      filterValues.featured_date_start = moment(filterValues.featured_date[0]).format('YYYY-MM-DD');
    }
    delete filterValues.featured_date;

    if (filterValues.created_at) {
      filterValues.created_at_end = moment(filterValues.created_at[1]).format('YYYY-MM-DD');
      filterValues.created_at_start = moment(filterValues.created_at[0]).format('YYYY-MM-DD');
    }
    delete filterValues.created_at;

    if (filterValues.updated_at) {
      filterValues.updated_at_end = moment(filterValues.updated_at[1]).format('YYYY-MM-DD');
      filterValues.updated_at_start = moment(filterValues.updated_at[0]).format('YYYY-MM-DD');
    }
    delete filterValues.updated_at;

    for (const filterValuesKey in filterValues) {
      if (filterValues[filterValuesKey] instanceof Array) {
        filterValues[filterValuesKey] = filterValues[filterValuesKey].join(',');
      }
    }

    filterValues.is_deleted_yn = !filterValues.is_deleted_yn || filterValues.is_deleted_yn === false ? 0 : 1;
    filterValues.is_source_exist = !filterValues.is_source_exist || filterValues.is_source_exist === false ? 0 : 1;


    this.router.navigate([], {queryParams: filterValues});
    filterValues = {...filterValues, page_size: 100, page_no: 1};
    // Check if project page is loaded form companies or contact pages
    if (this.isCompanyContactLink()) {
      this.route.parent.paramMap.subscribe((data: any) => {
        if (data.params.company_code) {
          if (this.router.url.indexOf('source-project') > -1) {
            filterValues.company_code_source = data.params.company_code ;
          } else {
            filterValues.company_code = data.params.company_code ;
          }
          this.store.dispatch(new CompanyProjectFilter(filterValues));
        } else if (data.params.contact_code) {
          if (this.router.url.indexOf('source-project') > -1) {
            filterValues.contact_code_source = data.params.contact_code ;
          } else {
            filterValues.contact_code = data.params.contact_code ;
          }
          this.store.dispatch(new ContactProjectFilter(filterValues));
        }
        // this.projects = this.store.select(store => store.companies.details.projects);
      });
    } else {
      this.store.dispatch(new ProjectFilter(filterValues));
    }
  }

  clearForm() {
    this.isFilterReset = true;
    this.router.navigate([], {queryParams: {}});
    this.filterForm.reset();
    this.filterForm.controls.is_deleted_yn.setValue(false);
    this.filterForm.controls.is_source_exist.setValue(false);
    this.filterForm.controls.project_type_code.setValue('');
    this.filterForm.controls.status_code.setValue('');
    this.selectedSourceCode = null;
    if (!this.isCompanyContactLink()) {
      // this.store.dispatch(new ProjectFilterReset());
      this.store.dispatch(new ProjectLoad());
    } else {
      this.route.parent.paramMap.subscribe((data: any) => {
        if (data.params.company_code) {
          this.store.dispatch(new CompanyGetProjects({project_company_code: data.params.company_code}));
        } else if (data.params.contact_code) {
          this.store.dispatch(new ContactGetProjects({contact_code: data.params.contact_code}));
        }
        // this.projects = this.store.select(store => store.companies.details.projects);
      });
    }
  }

  openClick(): void {
    this.sidebarInstance.show();
  }

  public closeSidebar(): void {
    this.sidebarInstance.hide();
  }

  isCompanyContactLink(): boolean {
    return (this.router.url.indexOf('/company/') > -1 || this.router.url.indexOf('/contact/') > -1);
  }

  public onFiltering = (e, entity: string) => {
    e.preventDefaultAction = true;
    this.setDebounce(e, entity);
  }

  public onFilter: EmitType<any> = (e: FilteringEventArgs, entity: string) => {
    let query = new Query();
    if (entity === 'company') {

      query = (e.text !== '') ? query.where('company_name', 'startswith', e.text, true) : query;
      const filterParams = {company_name: e.text, page_size: 100, page_no: 1};
      this.store.dispatch(new CompanyFilter(filterParams));
      this.store.select(store => store.companies.companies).subscribe((data) => {
        if (data) {
          this.companyList = data.result;
          e.updateData(this.companyList, query);
        }
      });
    } else if (entity === 'contact') {


      query = (e.text !== '') ? query.where('name', 'startswith', e.text, true) : query;
      const contactfilterParams = {contact_name: e.text, page_size: 40, page_no: 1};
      // this.store.dispatch(new ContactFilter(contactfilterParams));
      this.contactService.getSearchedContact(contactfilterParams).subscribe((data) => {
        if (data && data.data) {
          const companyContactList = [];
          if (data.data.result) {
            for (const contact of data.data.result) {
              if (contact.associated_companies) {
                for (const associatedCompany of contact.associated_companies) {
                  let company = '';
                  let company_code = '';
                  const company_contact_code = _.clone(associatedCompany.company_contact_code);

                  if (associatedCompany.company) {
                    company = ' - ' + associatedCompany.company.company_name;
                    company_code = associatedCompany.company.company_code;
                  } else {
                    company = ' (' + associatedCompany.email + ')';
                    company_code = '';
                  }

                  const result = {
                    value: company_contact_code,
                    name: (contact.first_name + ' ' + contact.last_name + company),
                    company_code, contact_code: contact.contact_code
                  };
                  companyContactList.push(result);
                }
              }
            }
            this.contactList = companyContactList;
            e.updateData(companyContactList, query);
          }
        }
      });
    } else if (entity === 'sources' && e.text !== '') {
      query = (e.text !== '') ? query.where('value', 'startswith', e.text, true) : query;
      const searchfilterParams = {search: e.text, page_size: 150, page_no: 1};
      this.store.dispatch(new ProjectSourceSearchAllSources(searchfilterParams));
      this.store.select(store => store.projectSources.single_project_sources).subscribe((data) => {
        if (data) {
          let sourcesList = [];
          for (const record of data.result) {
            let resultData: any = {};
            if (record.contact && record.company) {
              resultData = {
                value: record.contact.contact_code, category: 'Contacts',
                name: (record.contact.first_name + ' ' + record.contact.last_name + ' - ' + record.company.company_name),
              };
            } else if (record.contact && !record.company) {
              resultData = {
                value: record.contact.contact_code, category: 'Contacts',
                name: (record.contact.first_name + ' ' + record.contact.last_name),
              };
            } else if (record.company && !record.contact) {
              resultData = {
                value: record.company.company_code, category: 'Company',
                name: record.company.company_name,
              };
            }
            sourcesList.push(resultData);
          }
          sourcesList = _.uniqBy(sourcesList, 'name');
          e.updateData(sourcesList);
        }
      });
    }
  }


  onBlur(args, form) {
    this.tagSelected = this.selectedTag[this.selectedTag.length - 1];

    if (!this.modelData.includes(this.tagSelected)) {
      this.projectTag.addItem({tag: this.tagSelected, tag_code: this.tagSelected});
      form.controls.project_tag.setValue( _.uniq(_.compact([...this.modelData, this.tagSelected])));
    }

    this.modelData = _.compact([...this.modelData, this.tagSelected]);
    this.modelData.push(this.tagSelected);

    this.selectedTag = [];
  }

  syncModel = (event) => {
    this.modelData = event;
  }

  actionComplete(args) {
    if (_.get(args, 'result[0]')) {
      this.selectedTag.push(args.result[0].tag);
    }
  }

  onSelectSources(args) {
    this.selectedSourceCode = args.itemData;
  }

  onChange(event) {
    const tagsArray = _.compact(this.modelData);
    if ( tagsArray.length === 0) {
      this.modelData = [];
    }
  }

  select(args) {
    this.selectedTag = [];
  }
}
