// Angular
import {RouterModule} from '@angular/router';
import {NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

// Core module
import {CoreModule} from '../../core/core.module';

// Layout partials
import {ActionDropdownMenuComponent, SearchDropdownComponent, SplashScreenComponent} from './layout';

// SVG inline
import {InlineSVGModule} from 'ng-inline-svg';

// Sync Fusion
import {SidebarModule} from '@syncfusion/ej2-angular-navigations';
import {AutoCompleteModule, CheckBoxSelectionService, MultiSelectModule} from '@syncfusion/ej2-angular-dropdowns';
import {DropDownTreeModule} from '@syncfusion/ej2-angular-dropdowns';
import {DropDownListModule} from '@syncfusion/ej2-angular-dropdowns';
import {DatePickerModule, DateTimePickerModule, TimePickerModule} from '@syncfusion/ej2-angular-calendars';

// NGRX
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {companyReducer} from 'src/app/core/store/reducers/company.reducer';
import {CompanyEffects} from 'src/app/core/store/effects/company.effects';
import {CompanyService} from 'src/app/core/store/services/company.service';
import {SyncfusionModule} from '../../syncfusion.module';

// Components
import {CompanyComponent} from './forms/company/company.component';
import {ContactComponent} from './forms/contact/contact.component';
import {ProjectComponent} from './forms/project/project.component';
import {ActivityComponent} from './forms/activity/activity.component';
import {commonReducer} from '../../core/store/reducers/common.reducer';
import {CommonEffects} from '../../core/store/effects/common.effects';
import {CommonService} from '../../core/services/common.service';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {UserProfileComponent} from './layout/topbar/user-profile/user-profile.component';
import {MainPipe} from '../../core/_base/layout/pipes/pipe.module';
import {QuickUserPanelComponent} from './layout/quick-user-panel/quick-user-panel.component';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {reducers} from '../../core';
import {metaReducers} from '../../core/store/reducers/meta-reducer.reducer';
import {ActivityFilterComponent} from './forms/activity/activity-filter/activity-filter.component';
import {CompanyFilterComponent} from './forms/company/company-filter/company-filter.component';
import {ContactFilterComponent} from './forms/contact/contact-filter/contact-filter.component';
import {ProjectFilterComponent} from './forms/project/project-filter/project-filter.component';
import {SourceTypesComponent} from './forms/admin/source-types/source-types.component';
import {CompanyTypesComponent} from './forms/admin/company-types/company-types.component';
import {OnlynumberDirective} from 'src/app/core/_base/layout/directives/onlynumber.directive';
import {CompanyRolesComponent} from './forms/admin/company-roles/company-roles.component';
import {ProjectSourceComponent} from './forms/project-source/project-source.component';
import {ProjectTypesComponent} from './forms/admin/project-types/project-types.component';
import {ProjectStatusComponent} from './forms/admin/project-status/project-status.component';
import {OrderComponent} from './forms/company/order/order.component';
import {OrderLinksComponent} from './forms/company/order/order-links/order-links.component';
import {ContactRolesComponent} from './forms/company/order/contact-roles/contact-roles.component';
import {DropDownButtonModule} from '@syncfusion/ej2-angular-splitbuttons';
import {MaterialModule} from '../../material.module';
import {NotificationComponent} from './layout/notification/notification.component';
import {ProgressBarModule} from '@syncfusion/ej2-angular-progressbar';
import {AssociateCompanyFormComponent} from './forms/contact/associate-company-form/associate-company-form.component';
import {ButtonModule, CheckBoxModule, RadioButtonModule} from '@syncfusion/ej2-angular-buttons';
import {AdminService} from '../../core/store/services/admin.service';
import {DeliverableItemComponent} from './forms/admin/deliverable-item/deliverable-item.component';
import {LineItemComponent} from './forms/admin/line-item/line-item.component';
import {OrderLineItemComponent} from './forms/company/order/order-line-item/order-line-item.component';
import {DeliverableComponent} from './forms/company/order/deliverable/deliverable.component';
import {DeliverableStatusComponent} from './forms/admin/deliverable-status/deliverable-status.component';
import {CostCenterComponent} from './forms/admin/cost-center/cost-center.component';
import {OrderFilterComponent} from './forms/company/order/order-filter/order-filter.component';
import {DeliverableFilterComponent} from './forms/company/order/deliverable/deliverable-filter/deliverable-filter.component';
import {OrderLineItemFilterComponent} from './forms/company/order/order-line-item/order-line-item-filter/order-line-item-filter.component';
import {NotifierService} from './layout/notification/notfier.service';
import {ArticleFilterComponent} from './forms/article/article-filter/article-filter.component';
import {ProjectSourceFilterComponent} from './forms/project-source/project-source-filter/project-source-filter.component';
import {EmailTemplateComponent} from './forms/admin/email-template/email-template.component';
import {SendEmailFormComponent} from './forms/send-email-form/send-email-form.component';
import {EmailLogFilterComponent} from './forms/email-log-filter/email-log-filter.component';
import {EmailKeywordsComponent} from './forms/email-keywords/email-keywords.component';
import {DeliverableItemDateComponent} from './forms/admin/deliverable-item-date/deliverable-item-date.component';
import {ColorPickerModule, UploaderModule} from '@syncfusion/ej2-angular-inputs';
import {CompanyGeneralComponent} from './forms/company/company-general/company-general.component';
import {CompanyLovethatdesignComponent} from './forms/company/company-lovethatdesign/company-lovethatdesign.component';
import {ContactGeneralComponent} from './forms/contact/contact-general/contact-general.component';
import {AgmCoreModule} from '@agm/core';
import {environment} from '../../../environments/environment.prod';
import {BrandService} from '../../core/store/services/BrandService';
import {UploaderComponent} from './layout/uploader/uploader.component';
import {ImageService} from '../../core/services/image.service';
import { ContactLtdSettingsComponent } from './forms/contact/contact-ltd-settings/contact-ltd-settings.component';
import { SyncJobFilterComponent } from './forms/logs/sync-job-filter/sync-job-filter.component';

@NgModule({
  declarations: [
    SplashScreenComponent,
    SearchDropdownComponent,
    CompanyComponent,
    ContactComponent,
    ProjectComponent,
    ActivityComponent,
    UserProfileComponent,
    QuickUserPanelComponent,
    ActivityFilterComponent,
    CompanyFilterComponent,
    ContactFilterComponent,
    ProjectFilterComponent,
    SourceTypesComponent,
    CompanyTypesComponent,
    OnlynumberDirective,
    CompanyRolesComponent,
    ProjectSourceComponent,
    ProjectTypesComponent,
    ProjectStatusComponent,
    ActionDropdownMenuComponent,
    OrderComponent,
    OrderLinksComponent,
    ContactRolesComponent,
    AssociateCompanyFormComponent,
    DeliverableItemComponent,
    LineItemComponent,
    OrderLineItemComponent,
    DeliverableComponent,
    DeliverableStatusComponent,
    CostCenterComponent,
    OrderFilterComponent,
    DeliverableFilterComponent,
    OrderLineItemFilterComponent,
    ArticleFilterComponent,
    ProjectSourceFilterComponent,
    EmailTemplateComponent,
    SendEmailFormComponent,
    EmailLogFilterComponent,
    EmailKeywordsComponent,
    DeliverableItemDateComponent,
    CompanyGeneralComponent,
    CompanyLovethatdesignComponent,
    ContactGeneralComponent,
    UploaderComponent,
    ContactLtdSettingsComponent,
    SyncJobFilterComponent,
  ],
    exports: [
        SplashScreenComponent,
        SearchDropdownComponent,
        UserProfileComponent,
        QuickUserPanelComponent,
        ProjectComponent,
        ProjectFilterComponent,
        ActivityComponent,
        ActivityFilterComponent,
        CompanyComponent,
        CompanyFilterComponent,
        ContactComponent,
        ContactFilterComponent,
        SourceTypesComponent,
        CompanyTypesComponent,
        CompanyRolesComponent,
        ProjectSourceComponent,
        ProjectTypesComponent,
        ProjectStatusComponent,
        ActionDropdownMenuComponent,
        OrderComponent,
        ContactRolesComponent,
        OrderLinksComponent,
        AssociateCompanyFormComponent,
        DeliverableItemComponent,
        LineItemComponent,
        OrderLineItemComponent,
        DeliverableComponent,
        DeliverableStatusComponent,
        CostCenterComponent,
        OrderFilterComponent,
        DeliverableFilterComponent,
        OrderLineItemFilterComponent,
        ArticleFilterComponent,
        ProjectSourceFilterComponent,
        EmailTemplateComponent,
        SendEmailFormComponent,
        EmailLogFilterComponent,
        DeliverableItemDateComponent,
        CompanyGeneralComponent,
        CompanyLovethatdesignComponent,
        ContactGeneralComponent,
        ContactLtdSettingsComponent,
        SyncJobFilterComponent,

    ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    InlineSVGModule,
    CoreModule,
    SidebarModule,
    MultiSelectModule,
    DropDownTreeModule,
    DropDownListModule,
    DatePickerModule,
    SyncfusionModule,
    StoreModule.forFeature('companies', companyReducer),
    EffectsModule.forFeature([CompanyEffects]),
    StoreModule.forFeature('metadata', commonReducer),
    EffectsModule.forFeature([CommonEffects]),
    FontAwesomeModule,
    TimePickerModule,
    MainPipe,
    PerfectScrollbarModule,
    DateTimePickerModule,
    DropDownButtonModule,
    MaterialModule,
    ProgressBarModule,
    AutoCompleteModule,
    CheckBoxModule,
    ButtonModule,
    ColorPickerModule,
    RadioButtonModule,
    AgmCoreModule.forRoot({
      apiKey: environment.GOOGLE_MAPS_API_KEY
    }),
    UploaderModule,

  ],
  providers: [
    CompanyService,
    CommonService,
    AdminService,
    CheckBoxSelectionService,
    ImageService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PartialsModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: PartialsModule,
      providers: [
        CompanyService,
        NotifierService,
        ImageService

        // CommonService
        // AuthGuard
      ]
    };
  }
}
