// Angular
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
// Sync Fusion
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// CRUD
import { InterceptService } from '../../../core/_base/crud//utils/intercept.service';
// Module components
import { AuthComponent } from './auth.component';
import { LoginComponent } from './login/login.component';
import { AuthNoticeComponent } from './auth-notice/auth-notice.component';
import { authReducer } from 'src/app/core/store/auth/auth.reducer';
import { AuthEffects } from 'src/app/core/store/auth/auth.effects';
import { AuthService } from 'src/app/core/store/auth/auth.service';
import { BearerAuthInterceptor } from 'src/app/core/_base/crud/utils/bearer-auth.interceptor';
import {ThemeModule} from '../../theme/theme.module';
// Auth
// import { AuthEffects, AuthGuard, authReducer, AuthService } from '../../../core/auth';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      },
      {
        path: 'login',
        component: LoginComponent,
        data: { returnUrl: window.location.pathname }
      }
    ]
  }
];


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ButtonModule,
        RouterModule.forChild(routes),
        StoreModule.forFeature('auth', authReducer),
        EffectsModule.forFeature([AuthEffects]),
        ThemeModule
    ],
  providers: [
    InterceptService,
    // BearerAuthInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: BearerAuthInterceptor,
    //   multi: true
    // },
  ],
  exports: [AuthComponent],
  declarations: [
    AuthComponent,
    LoginComponent,
    AuthNoticeComponent,
  ]
})

export class AuthModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: AuthModule,
      providers: [
        AuthService
        // AuthGuard
      ]
    };
  }
}
