import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../index';
import {filter, first, map, mapTo, mergeMap, skipWhile, tap} from 'rxjs/operators';
import {environment} from '../../../../../environments/environment.prod';
import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs';
import {UserRequested} from '../../../store/auth/auth.actions';

@Injectable()
export class AuthGuardService implements CanActivate {
  authLoaded$ = this.store.pipe(select(store => store.auth.isUserLoaded));
  authAccount$ = this.store.pipe(select(store => store.auth.user));

  constructor(public router: Router, private store: Store<AppState>) {
  }

  canActivate(): Observable<boolean> {

    if (!localStorage.getItem('token')) {
      this.router.navigate(['auth/login']);
      return of(false);
    }


    // const storeVal = this.authLoaded$.pipe( tap(loaded => {
    //   if (!loaded) {
    //     this.store.dispatch(new UserRequested());
    //   }
    // }), filter(loaded => !!loaded), mergeMap(() => this.authAccount$), map(userAccount => {
    //   if (environment.limitedUsers.includes(userAccount.user_code)) {
    //     this.router.navigateByUrl('assigned-projects');
    //     return false;
    //   } else {
    //     return true;
    //   }
    // }), first());
    //
    // return storeVal;


    return of(true);

  }
}
