import {SourceTypeActions, SourceTypeActionsType} from '../actions/source-type.actions';
import {findAndDelete, findAndReplace} from '../../../views/common/base.component';

export interface SourceTypeState {
  sourceType?: {
    result?: any,
    total_count?: number,
    page_no?: number,
    page_size?: number
  };
  error?: Error;
  loading: boolean;
  loaded: boolean;
  success?: boolean;
}

export const initialState: SourceTypeState = {
  sourceType: {
    result: [],
    total_count: 0,
    page_no: 0,
    page_size: 0
  },
  error: undefined,
  loading: false,
  loaded: false,
};

export const ReturnLoading = (State: SourceTypeState) => {
  return {
    ...State,
    loading: true,
    loaded: false,
    error: undefined,
    success: undefined
  };
};

export function sourceTypeReducer(state: SourceTypeState = initialState, action: SourceTypeActions): SourceTypeState {
  switch (action.type) {

    case SourceTypeActionsType.LOAD_SOURCE_TYPE: {
      return ReturnLoading(state);
    }

    case SourceTypeActionsType.LOAD_SOURCE_TYPE_SUCCESS: {
      let sourceTypeList = [...state.sourceType.result, ...action.payload.result];

      sourceTypeList = sourceTypeList.filter((thing, index, self) =>
        index === self.findIndex((t) => (
          t.project_source_type_code === thing.project_source_type_code && t.project_source_type_name === thing.project_source_type_name
        ))
      );
      state = {
        ...state, sourceType: {
          result: sourceTypeList, page_no: action.payload.page_no, page_size: action.payload.page_size,
          total_count: action.payload.total_count
        }, loading: false, error: undefined, loaded: true,
      };

      return state;
    }

    case SourceTypeActionsType.LOAD_SOURCE_TYPE_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case SourceTypeActionsType.ADD_SOURCE_TYPE: {
      return ReturnLoading(state);
    }

    case SourceTypeActionsType.ADD_SOURCE_TYPE_SUCCESS: {
      let newProjectSourceType;
      if (action.payload.parent_source_type_code) {
        const parentProjectSourceType = {...find([...state.sourceType.result], action.payload.parent_source_type_code)};
        const childrenArray = [...parentProjectSourceType.children];
        childrenArray.push(action.payload.sourceType);
        parentProjectSourceType.children = childrenArray;
        const findParent = findAndReplace([...state.sourceType.result], action.payload.parent_source_type_code, parentProjectSourceType, 'project_source_type_code');
        newProjectSourceType = findParent;
      } else {
        newProjectSourceType = [action.payload.sourceType, ...state.sourceType.result];
      }
      return {
        ...state, loading: false, sourceType: {
          result: newProjectSourceType
        }, error: undefined, loaded: true,
        success: true
      };
    }

    case SourceTypeActionsType.ADD_SOURCE_TYPE_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case SourceTypeActionsType.UPDATE_SOURCE_TYPE: {
      return ReturnLoading(state);
    }

    case SourceTypeActionsType.UPDATE_SOURCE_TYPE_SUCCESS: {
      const projectSourceTypeList = findAndDelete([...state.sourceType.result], action.payload.sourceType.project_source_type_code, 'project_source_type_code');
      let newProjectSourceType;
      if (action.payload.parent_source_type_code) {
        const parentProjectSourceType = {...find(projectSourceTypeList, action.payload.parent_source_type_code)};
        const childrenArray = [...parentProjectSourceType.children];
        childrenArray.push(action.payload.sourceType);
        parentProjectSourceType.children = childrenArray;
        const findParent = findAndReplace(projectSourceTypeList, action.payload.parent_source_type_code, parentProjectSourceType, 'project_source_type_code');
        newProjectSourceType = findParent;
      } else {
        newProjectSourceType = [action.payload.sourceType, ...projectSourceTypeList];
      }

      return {
        ...state, sourceType: {result: newProjectSourceType}, error: undefined, loading: false, loaded: true,
        success: true
      };
    }

    case SourceTypeActionsType.UPDATE_SOURCE_TYPE_FAILURE:
      return {...state, error: action.payload, loading: false, loaded: false};


    case SourceTypeActionsType.DELETE_SOURCE_TYPE:
      return ReturnLoading(state);

    case SourceTypeActionsType.DELETE_SOURCE_TYPE_SUCCESS: {
      const projectSourceTypeList = findAndDelete([...state.sourceType.result], action.payload.sourceTypeCode, 'project_source_type_code');

      return {
        ...state,
        sourceType: {result: projectSourceTypeList},
        loading: false,
        error: undefined,
        loaded: true
      };
    }

    case SourceTypeActionsType.DELETE_SOURCE_TYPE_FAILURE:
      return {...state, error: action.payload, loading: false, loaded: false};

    case SourceTypeActionsType.RESET_ERROR:
      return ReturnLoading(state);

    default:
      return state;
  }

  function find(array, id) {
    let result;
    array.some(o => result = o.project_source_type_code === id ? o : find(o.children || [], id));
    return result;
  }
}
