<div class="control-section pb-4">
  <div class="col-lg-9" style="min-width: 100%">
    <div class="control_wrapper">
      <div class='imagepreview'>
        <div id='dropArea' style='height: auto'>
          <span id='dropimage'> Drop your files here or <a href="" id='browse'><u>Browse</u></a> </span>
          <ejs-uploader #previewupload id='previewfileupload' [asyncSettings]='path' autoUpload="false"
                        [dropArea]='dropElement' [allowedExtensions]='allowExtensions' (selected)='onSelect($event)'
                        (success)='onUploadSuccess($event)' (removing)='onFileRemove($event)'
                        (created) ='preLoadImageOnCreated()' [multiple]=false maxFileSize = 4000000>
          </ejs-uploader>
        </div>
      </div>
    </div>
  </div>
  <div class="e-error" *ngIf="incorrectFileType">
    Unsupported file type
  </div>
  <div class="e-error" *ngIf="incorrectFileSize">
    File too large. Maximum file size: 4MB
  </div>
</div>
