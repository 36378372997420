import {ProjectTypeActions, ProjectTypeActionsType} from '../actions/project-type.actions';

export interface ProjectTypeState {
  projectType?: {
    result?: any,
    total_count?: number,
    page_no?: number,
    page_size?: number
  };
  error?: Error;
  loading: boolean;
  loaded: boolean;
  success?: boolean;
}

export const initialState: ProjectTypeState = {
  projectType: {
    result: [],
    total_count: 0,
    page_no: 0,
    page_size: 0
  },
  error: undefined,
  loading: false,
  loaded: false,
};

export const ReturnLoading = (State: ProjectTypeState) => {
  return {
    ...State,
    loading: true,
    loaded: false,
    error: undefined,
    success: undefined
  };
};

export function projectTypeReducer(state: ProjectTypeState = initialState, action: ProjectTypeActions): ProjectTypeState {
  switch (action.type) {

    case ProjectTypeActionsType.LOAD_PROJECT_TYPE: {
      return ReturnLoading(state);
    }

    case ProjectTypeActionsType.LOAD_PROJECT_TYPE_SUCCESS: {
      let projectTypeList = [...state.projectType.result, ...action.payload.result];

      // projectTypeList = childrenFunction(projectTypeList, action.payload.result, null);
      projectTypeList = projectTypeList.filter((thing, index, self) =>
        index === self.findIndex((t) => (
          t.project_type_code === thing.project_type_code && t.project_type_name === thing.project_type_name
        ))
      );

      state = {
        ...state, projectType: {
          result: projectTypeList, page_no: action.payload.page_no, page_size: action.payload.page_size,
          total_count: action.payload.total_count
        }, loading: false, error: undefined, loaded: true,
      };


      return state;
    }

    case ProjectTypeActionsType.LOAD_PROJECT_TYPE_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case ProjectTypeActionsType.ADD_PROJECT_TYPE: {
      return ReturnLoading(state);
    }

    case ProjectTypeActionsType.ADD_PROJECT_TYPE_SUCCESS: {
      let newProjectType;
      if (action.payload.parentCode) {
        const parentProjectType = {...find([...state.projectType.result], action.payload.parentCode)};
        const childrenArray = [...parentProjectType.children];
        childrenArray.push(action.payload.projectType);
        parentProjectType.children = childrenArray;
        const findParent = findAndReplace([...state.projectType.result], action.payload.parentCode, parentProjectType);
        newProjectType = findParent;
      } else {
        newProjectType = [action.payload.projectType, ...state.projectType.result];
      }
      return {
        ...state, loading: false, projectType: {
          result: newProjectType
        }, error: undefined, loaded: true,
        success: true
      };
    }

    case ProjectTypeActionsType.ADD_PROJECT_TYPE_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }


    case ProjectTypeActionsType.UPDATE_PROJECT_TYPE: {
      return ReturnLoading(state);
    }

    case ProjectTypeActionsType.UPDATE_PROJECT_TYPE_SUCCESS: {
      const projectTypeList = findAndDelete([...state.projectType.result], action.payload.projectType.project_type_code);

      let newProjectType;
      if (action.payload.parent_type_code) {
        const parentProjectType = {...find(projectTypeList, action.payload.parent_type_code)};
        const childrenArray = [...parentProjectType.children];
        childrenArray.push(action.payload.projectType);
        parentProjectType.children = childrenArray;
        const findParent = findAndReplace(projectTypeList, action.payload.parent_type_code, parentProjectType);
        newProjectType = findParent;
      } else {
        newProjectType = [action.payload.projectType, ...projectTypeList];
      }

      return {
        ...state, projectType: {result: newProjectType}, error: undefined, loading: false, loaded: true,
        success: true
      };
    }

    case ProjectTypeActionsType.UPDATE_PROJECT_TYPE_FAILURE:
      return {...state, error: action.payload, loading: false, loaded: false};


    case ProjectTypeActionsType.DELETE_PROJECT_TYPE:
      return ReturnLoading(state);

    case ProjectTypeActionsType.DELETE_PROJECT_TYPE_SUCCESS: {
      const projectTypeList = findAndDelete([...state.projectType.result], action.payload.projectTypeCode);

      return {
        ...state,
        projectType: {result: projectTypeList},
        loading: false,
        error: undefined,
        loaded: true
      };
    }

    case ProjectTypeActionsType.DELETE_PROJECT_TYPE_FAILURE:
      return {...state, error: action.payload, loading: false, loaded: false};

    case ProjectTypeActionsType.RESET_ERROR:
      return ReturnLoading(state);

    default:
      return state;
  }
}

function find(array, id) {
  let result;
  array.some(o => result = o.project_type_code === id ? o : find(o.children || [], id));
  return result;
}

function findAndReplace(projectTypeList, findValue, replaceValue) {
  for (const projectType in projectTypeList) {
    if (projectTypeList[projectType].project_type_code === findValue) {
      projectTypeList[projectType] = replaceValue;
      return projectTypeList;
    }

    if (projectTypeList[projectType].children) {
      const children = findAndReplace([...projectTypeList[projectType].children], findValue, replaceValue);
      const companyRoleListChildCopy: any = {...projectTypeList[projectType]};
      companyRoleListChildCopy.children = children;
      projectTypeList[projectType] = companyRoleListChildCopy;
    }
  }
  return projectTypeList;
}

function findAndDelete(projectTypeList, findValue) {
  for (const projectType in projectTypeList) {
    if (projectTypeList[projectType].project_type_code === findValue) {
      delete projectTypeList[projectType];
      const companyRoleArray = projectTypeList.filter((el) => {
        return el != null;
      });
      return companyRoleArray;
    }

    if (projectTypeList[projectType].children) {
      const children = findAndDelete([...projectTypeList[projectType].children], findValue);
      const companyTypeListChildCopy: any = {...projectTypeList[projectType]};
      companyTypeListChildCopy.children = children;
      projectTypeList[projectType] = companyTypeListChildCopy;
    }
  }
  return projectTypeList;
}

