<ejs-sidebar id="sidebar-menu" #sidebarInstance type='Over' [width]='width' [position]='position'
             style="visibility: hidden" [enableGestures]="false" [showBackdrop]=true>

  <div class="offcanvas-close mt-n1 pr-5" style="float:right; padding: 48px;">
    <a href="javascript:;" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_panel_close"
       (click)="closeSidebar()">
      <i class="ki ki-close icon-xs text-muted"></i>
    </a>
  </div>

  <div class='p-15'>
    <h2 *ngIf="!emailTemplateInput">Add Email Template</h2>
    <h2 *ngIf="emailTemplateInput">Update Email Template</h2>

    <form id="formId" [formGroup]="emailTemplateForm" #formDir="ngForm" class="form-horizontal" novalidate=""
          style="margin-top: 16px;">

<!--      <div class="form-group sf-outline-input mb-10" [class.hide-label]="emailTemplateForm.controls.category.value == ''">-->
<!--        <ejs-dropdownlist formControlName="category" id='category' [dataSource]="categories"-->
<!--                          [fields]='categoryFields' placeholder='Application Category *' floatLabelType="Auto" cssClass="e-filled"-->
<!--                          (blur)="emailTemplateForm.controls.category.markAsTouched()"></ejs-dropdownlist>-->
<!--        <div *ngIf="emailTemplateForm.controls.category.errors">-->
<!--          <div-->
<!--            *ngIf="emailTemplateForm.controls.category.errors.required && emailTemplateForm.controls.category.touched"-->
<!--            class="e-error">-->
<!--            Application Category is required.-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

      <div class="form-group sf-outline-input mb-10" [class.hide-label]="emailTemplateForm.controls.email_category.value == ''">
        <ejs-dropdownlist formControlName="email_category" id='email_category' [dataSource]="emailCategories"
                          [fields]='emailCategoryFields' placeholder='Email Category *' floatLabelType="Auto" cssClass="e-filled"
                          (blur)="emailTemplateForm.controls.email_category.markAsTouched()"></ejs-dropdownlist>
        <div *ngIf="emailTemplateForm.controls.email_category.errors">
          <div
            *ngIf="emailTemplateForm.controls.email_category.errors.required && emailTemplateForm.controls.email_category.touched"
            class="e-error">
            Email Category is required.
          </div>
        </div>

<!--        <div class="d-flex align-items-center">-->
<!--          <span>Can't find the category you're looking for?</span>-->
<!--          <button-->
<!--            class="btn btn-light btn-text-primary btn-hover-text-primary text-uppercase font-weight-bold mr-2 btn-sm p-3  ml-3 add-btn"-->
<!--            type="button" (click)="showEmailCategoryBox = true">-->
<!--            Add new Category-->
<!--          </button>-->
<!--          <br>-->
<!--          <ejs-textbox *ngIf="showEmailCategoryBox" id="email_category_name" placeholder="Email Category Name" cssClass="e-filled"-->
<!--                       formControlName="email_category_name" floatLabelType="Auto"-->
<!--                       (blur)="emailTemplateForm.controls.email_category_name.markAsTouched()"-->
<!--          ></ejs-textbox>-->
<!--        </div>-->
      </div>

<!--      <div class="form-group sf-outline-input mb-10" [class.hide-label]="emailTemplateForm.controls.email_template_parent_code.value == ''">-->
<!--        <ejs-dropdowntree formControlName="email_template_parent_code" id='email_template_parent_code' allowMultiSelection='false'-->
<!--                          [fields]='emailTemplateFields' placeholder='Parent Email Template' floatLabelType="Auto" cssClass="e-filled"-->
<!--                          (blur)="emailTemplateForm.controls.email_template_parent_code.markAsTouched()"></ejs-dropdowntree>-->
<!--        <div>Note: If a parent item isn't selected, the new email template will be added as a parent</div>-->
<!--      </div>-->

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="email_template_name" placeholder="Email Template Name *" cssClass="e-filled"
                     formControlName="email_template_name" floatLabelType="Auto"
                     (blur)="emailTemplateForm.controls.email_template_name.markAsTouched()"
        ></ejs-textbox>
        <div *ngIf="emailTemplateForm.controls.email_template_name.errors">
          <div
            *ngIf="emailTemplateForm.controls.email_template_name.errors.required && emailTemplateForm.controls.email_template_name.touched"
            class="e-error">
            Email Template Name is required.
          </div>
        </div>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="email_template_subject" [multiline]="true" placeholder="Email Template Subject * " cssClass="e-filled"
                     formControlName="email_template_subject" floatLabelType="Auto"
                     (blur)="emailTemplateForm.controls.email_template_subject.markAsTouched()"
        ></ejs-textbox>
        <div *ngIf="emailTemplateForm.controls.email_template_subject.errors">
          <div *ngIf="emailTemplateForm.controls.email_template_subject.errors.required && emailTemplateForm.controls.email_template_subject.touched"
               class="e-error">
            Email Template Subject is required
          </div>
        </div>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="email_template_content" [multiline]="true" placeholder="Email Template Content * " cssClass="e-filled"
                     formControlName="email_template_content" floatLabelType="Auto" rows="8"
                     (blur)="emailTemplateForm.controls.email_template_content.markAsTouched()"
        ></ejs-textbox>
        <div *ngIf="emailTemplateForm.controls.email_template_content.errors">
          <div *ngIf="emailTemplateForm.controls.email_template_content.errors.required && emailTemplateForm.controls.email_template_content.touched"
               class="e-error">
            Email Template Content is required
          </div>
        </div>
      </div>


      <div class="float-right mb-3 d-flex align-items-center">
        <button
          class="btn btn-light btn-text-primary btn-hover-text-primary text-uppercase font-weight-bold mr-2 btn-sm p-3  ml-3 add-btn"
         type="button" (click)="openKeywordSidebar()">
          Display Email Keywords
        </button>
      </div>

<!--      <div class="form-group sf-outline-input mb-10">-->
<!--        <ejs-checkbox formControlName="is_html_yn" #wcheckbox label="HTML Template: " labelPosition="Before"></ejs-checkbox>-->
<!--      </div>-->


      <div *ngIf="emailTemplateForm.invalid" class="e-error">Please fill all fields</div>
      <div *ngIf="error$ | async as error" class="e-error">{{error.error.message}}</div>

      <div class="my-10 pb-20">
        <button id="resetbtn"
                class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5"
                type="reset"
                data-ripple="true">Clear
        </button>
        <button *ngIf="!emailTemplateInput" id="addSubmit"
                class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit"
                (click)="onAddUpdateEmailTemplate(emailTemplateForm)" [disabled]="formSent" data-ripple="true"> Add
          Email Template Item
        </button>
        <button *ngIf="emailTemplateInput" id="updateSubmit"
                class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit"
                (click)="onAddUpdateEmailTemplate(emailTemplateForm, true)" [disabled]="formSent" data-ripple="true">
          Update Email Template Item
        </button>
      </div>
    </form>
  </div>
</ejs-sidebar>

<app-email-keywords #emailKeyword></app-email-keywords>
