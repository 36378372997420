<ejs-sidebar id="sidebar-menu" #sidebarInstance type='Over' [width]='width' [position]='position'
             style="visibility: hidden" [enableGestures]="false" [showBackdrop]=true>

  <div class="offcanvas-close mt-n1 pr-5" style="float:right; padding: 48px;">
    <a href="javascript:;" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_panel_close"
       (click)="closeSidebar()">
      <i class="ki ki-close icon-xs text-muted"></i>
    </a>
  </div>
  <div class="p-15">
    <h2>Company Filter</h2>
    <form id="formId" [formGroup]="filterForm" #formDir="ngForm" class="form-horizontal" novalidate="">
      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="company_name" placeholder="Company Name" cssClass="e-filled"
                     formControlName="company_name" floatLabelType="Auto"
                     (blur)="filterForm.controls.company_name.markAsTouched()"
        ></ejs-textbox>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="reference_id" placeholder="Reference ID" cssClass="e-filled"
                     formControlName="reference_id" floatLabelType="Auto"
                     (blur)="filterForm.controls.reference_id.markAsTouched()"
        ></ejs-textbox>
      </div>

      <div class="form-group sf-outline-input mb-10" [class.hide-label]="filterForm.controls.company_type_code.value == ''">
        <ejs-dropdowntree formControlName="company_type_code" id='company_type_code' allowMultiSelection='true' [treeSettings]='treeSettings'
                          [fields]='companyTypeField' placeholder='Company Type' floatLabelType="Auto" sortOrder="Ascending"
                          cssClass="e-filled" (blur)="filterForm.controls.company_type_code.markAsTouched()"
                          [showCheckBox]='true'></ejs-dropdowntree>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-multiselect id='account_owner-checkbox' formControlName="account_owner" #checkbox [dataSource]='users'
                         placeholder='Assigned To' [fields]='userFields' floatLabelType="Auto" cssClass="e-filled"
                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true' sortOrder="Ascending"
                         filterBarPlaceholder='Search'></ejs-multiselect>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-multiselect id='published_by' formControlName="published_by" #checkbox [dataSource]='users'
                         placeholder='Published by' [fields]='userFields' floatLabelType="Auto" cssClass="e-filled"
                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true' sortOrder="Ascending"
                         filterBarPlaceholder='Search'></ejs-multiselect>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-multiselect id='country_code-checkbox' formControlName="country_code" #checkbox [dataSource]='countries'
                         placeholder='Country' [fields]='countryField' floatLabelType="Auto" cssClass="e-filled"
                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true' sortOrder="Ascending"
                         filterBarPlaceholder='Search'></ejs-multiselect>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-multiselect id='is_inactive_yn' formControlName="is_inactive_yn" #checkbox [dataSource]='status'
                         placeholder='Active Status' [fields]='statusFields' floatLabelType="Auto" cssClass="e-filled"
                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true' sortOrder="Ascending"
                         filterBarPlaceholder='Search'></ejs-multiselect>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-multiselect id='company_tag' #tags [dataSource]="tagModelData" floatLabelType="Auto" cssClass="e-filled"
                         [fields]="tagFields" formControlName='company_tag'
                         [allowCustomValue]=true mode='Box' placeholder='Tags'
                         (actionComplete)="actionComplete($event)"
                         (ngModelChange)="syncModel($event)"
                         (change)="onChange($event)"
                         (blur)="onBlur($event, filterForm)"
                         name="tags"
                         (removed)="selectedTag = []"
                         (select)="select($event)"
                         [(ngModel)]="modelData"
                         ngModel></ejs-multiselect>
      </div>

      <div class="form-group sf-outline-input mb-10" [class.hide-label]="filterForm.controls.company_role_code.value == ''">
        <ejs-dropdowntree formControlName="company_role_code" id='company_role_code' allowMultiSelection='true' [showCheckBox]="true"
                          [fields]='companyRoleField' placeholder='Company Role' floatLabelType="Auto" sortOrder="Ascending"
                          cssClass="e-filled" (blur)="filterForm.controls.company_role_code.markAsTouched()"
                          [showSelectAll]=true></ejs-dropdowntree>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-daterangepicker formControlName="updated_at" name="updated_at" [allowEdit]="allowEdit"
                             placeholder='Select a Updated At Range' openOnFocus="true"
                             floatLabelType='Auto' cssClass="e-filled">
        </ejs-daterangepicker>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-daterangepicker formControlName="created_at" name="created_at" [allowEdit]="allowEdit"
                             placeholder='Select a Created At Date Range' openOnFocus="true"
                             floatLabelType='Auto' cssClass="e-filled">
        </ejs-daterangepicker>
      </div>

      <div class="my-10 pb-20">
        <button
                class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5"
                type="button" (click)="clearForm()"
                data-ripple="true">Clear All
        </button>
        <button id="filter"
                class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block"
                type="submit"
                (click)="getFilteredData()" [disabled]="formSent" data-ripple="true">Filter
        </button>
      </div>

    </form>
  </div>
</ejs-sidebar>
