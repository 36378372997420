<ejs-sidebar id="sidebar-menu" #sidebarInstance type='Over' [width]='width' [position]='position'
             style="visibility: hidden" [enableGestures]="false" [showBackdrop]=true>

  <div class="offcanvas-close mt-n1 pr-5" style="float:right; padding: 48px;">
    <a href="javascript:;" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_panel_close"
       (click)="closeSidebar()">
      <i class="ki ki-close icon-xs text-muted"></i>
    </a>
  </div>

  <div class='p-15'>
    <h2 *ngIf="!sourceTypeInput">Add Source Type</h2>
    <h2 *ngIf="sourceTypeInput">Update Source Type</h2>

    <form id="formId" [formGroup]="sourceTypeForm" #formDir="ngForm" class="form-horizontal" novalidate=""
          style="margin-top: 16px;">

      <div class="form-group sf-outline-input mb-10" [class.hide-label]="sourceTypeForm.controls.source_type_parent_code.value == ''">
        <ejs-dropdowntree formControlName="source_type_parent_code" id='source_type_parent_code' allowMultiSelection='false'
                          [fields]='sourceTypeFields' placeholder='Parent Project Source Type' floatLabelType="Auto" cssClass="e-filled"
                          (blur)="sourceTypeForm.controls.source_type_parent_code.markAsTouched()"></ejs-dropdowntree>
        <div>Note: If a parent source type isn't selected, the new source type will be added as a parent</div>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="activity_type_name" placeholder="Source Type Name *" cssClass="e-filled"
                     formControlName="project_source_type_name" floatLabelType="Auto"
                     (blur)="sourceTypeForm.controls.project_source_type_name.markAsTouched()"
        ></ejs-textbox>
        <div *ngIf="sourceTypeForm.controls.project_source_type_name.errors">
          <div
            *ngIf="sourceTypeForm.controls.project_source_type_name.errors.required && sourceTypeForm.controls.project_source_type_name.touched"
            class="e-error">
            Source Type Name is required.
          </div>
        </div>
      </div>


      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="description" [multiline]="true" placeholder="Description" cssClass="e-filled"
                     formControlName="description" floatLabelType="Auto"
                     (blur)="sourceTypeForm.controls.description.markAsTouched()"
        ></ejs-textbox>
        <div *ngIf="sourceTypeForm.controls.description.errors">
          <div *ngIf="sourceTypeForm.controls.description.errors.maxlength && sourceTypeForm.controls.description.touched"
               class="e-error">
            Description cannot be more than 200 characters
          </div>
        </div>
      </div>


      <div *ngIf="sourceTypeForm.invalid" class="e-error">Please fill all fields</div>
      <div *ngIf="error$| async as error" class="e-error"><span *ngIf="error && error.error">{{error.error.message}}</span></div>

      <div class="my-10 pb-20">
        <button id="resetbtn"
                class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5"
                type="reset"
                data-ripple="true">Clear
        </button>
        <button *ngIf="!sourceTypeInput" id="addSubmit"
                class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit"
                (click)="onAddUpdateSourceType(sourceTypeForm)" [disabled]="formSent" data-ripple="true"> Add
          Source Type
        </button>
        <button *ngIf="sourceTypeInput" id="updateSubmit"
                class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit"
                (click)="onAddUpdateSourceType(sourceTypeForm, true)" [disabled]="formSent" data-ripple="true">
          Update Source Type
        </button>
      </div>
    </form>
  </div>
</ejs-sidebar>
