import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {atLeastOne, specialCharacterValidator, trimValidator, websiteValidator} from '../../../../app-validators';
import {AddProjectCompanyRole, ProjectAdd, ProjectBulkEdit, ProjectSetStatusNull, ProjectUpdate, UpdateProjectCompanyRole}
  from '../../../../core/store/actions/project.actions';
import * as moment from 'moment';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Project} from '../../../../core/models/project.model';
import {EmitType} from '@syncfusion/ej2-base';
import {DropDownListComponent, DropDownTreeComponent, FilteringEventArgs, MultiSelectComponent} from '@syncfusion/ej2-angular-dropdowns';
import {Query} from '@syncfusion/ej2-data';
import {Tags} from '../../../../core/models/tags.model';
import {ProjectRole} from '../../../../core/models/project-role.model';
import {SidebarComponent} from '@syncfusion/ej2-angular-navigations';
import {CompanyFilter, CompanyLoad, CompanyProjectBulkEdit, GetSearchedCompany} from '../../../../core/store/actions/company.actions';
import {
  ContactFilter,
  ContactLoad,
  ContactProjectBulkEdit,
  GetAllCompanyContacts,
  GetSearchedContact
} from '../../../../core/store/actions/contact.actions';
import * as _ from 'lodash';
import {ProjectSourceAdd} from '../../../../core/store/actions/project-source.actions';
import {debounce} from '@syncfusion/ej2-base';
import {Router} from '@angular/router';
import {ContactService} from '../../../../core/store/services/contact.service';
import {CompanyService} from '../../../../core/store/services/company.service';
import {formatDataSet} from '../../../common/base.component';

@Component({
  selector: 'app-project-form',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit, OnChanges {

  @Input() set projectInput(mode: Project | null) {
    this._projectInput$.next(mode);
  }

  get projectInput(): Project | null {
    return this._projectInput$.getValue();
  }

  @Input() set projectRoleInput(mode: ProjectRole | null) {
    this._projectRoleInput$.next(mode);
  }

  get projectRoleInput(): ProjectRole | null {
    return this._projectRoleInput$.getValue();
  }

  constructor(private formBuilder: FormBuilder, private store: Store<AppState>, private router: Router,
              private contactService: ContactService, private companyService: CompanyService) {
  }

  @Input() formType: any;
  @Input() users: any;
  @Input() projectTypes: any;
  @Input() projectStatus: any;
  @Input() companyRole: any;
  @Input() companyList: any;
  @Input() contactList: any = [];
  @Input() countries: { [key: string]: object }[];
  @Input() selectedProjectCode: string;
  @Input() allTags: Observable<Array<Tags>>;
  @Input() isBulkEdit: boolean;
  @Input() sourceTypes: any;
  @Input() selectedProjectList: any;
  @Input() projectSourceStatus: any;
  @Output() valueChange = new EventEmitter();

  @ViewChild('sidebarInstance') public sidebarInstance: SidebarComponent;
  @ViewChild('company') companyDropdown: DropDownListComponent;
  @ViewChild('contact') contactDropdown: DropDownListComponent;
  @ViewChild('status') public mulObj: DropDownTreeComponent;
  @ViewChild('tags') projectTag: MultiSelectComponent;
  @ViewChild('field') fieldDropdown: DropDownListComponent;

  public width = 'auto';
  public position = 'Right';
  selectedName = '';
  selectedCompanyName = '';
  selectedRoleContact: any;
  selectedSourceContact: any;

  public projectTypeFields: object;
  public projectStatusFields: object;
  public companyRoleFields: object;
  public companyFields: object = {value: 'company_code', text: 'company_name'};
  public contactFields: object = {value: 'value', text: 'name'};
  public countryFields: object = {value: 'country_code', text: 'country_name'};
  public userFields: object = {value: 'user_code', text: ('first_name')};
  public tagFields: object = {value: 'tag', text: 'tag'};
  sourceTypeFields: object = {value: 'project_source_type_code', text: ('project_source_type_name')};
  sourceStatusFields: object = {
    value: 'project_source_status_code',
    text: 'project_source_status_name',
    dataSource: this.projectSourceStatus
  };


  showClearButton = false;
  errorMsg: any;
  error$: Observable<any>;
  successProject$: Observable<boolean>;
  errorProjectSource$: Observable<Error>;
  successProjectSource$: Observable<boolean>;
  successCompany$: Observable<boolean>;
  successContact$: Observable<boolean>;
  resetCompany = false;

  projectForm: FormGroup;
  projectSourceForm: FormGroup;
  projectCompanyRoleForm: FormGroup;
  public formSent: boolean;
  allowEdit = false;
  selectedTag = [];
  tagSelected = '';
  public tagModelData = [];
  modelData = [];
  fieldNames = ['Status', 'Featured Date', 'Published Date', 'City', 'Country', 'Assigned To', 'Project Type', 'Tags'];
  selectedField = '';
  shortlistedCompany: any;
  settingForm = true;
  isAddProject = false;
  contactListCopy: any = [];
  isSidebarClose = false;

  currentDate = new Date();
  public _projectInput$: BehaviorSubject<Project | null> = new BehaviorSubject<Project | null>(null);
  public _projectRoleInput$: BehaviorSubject<ProjectRole | null> = new BehaviorSubject<ProjectRole | null>(null);

  setDebounce = debounce((e, entity: string) => {
    this.onFilter(e, entity);
  }, 700);

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.sidebarInstance.isOpen) {
      this.closeSidebar();
    }
  }

  ngOnInit(): void {
    if (!this.projectForm) {
      this.generateProjectForm();
    }
    this.generateProjectSourceForm();
    // this.loadTags();

    this.successProject$ = this.store.pipe(select(store => store.projects.success));
    this.successProject$.subscribe((data: any) => {

      if (data) {
        // if (this.isAddProject) {
        // this.onAddProjectSource();
        // } else {
        this.formSent = false;
        this.closeSidebar();
        // }
        // Dispatch Add project source.
      }
    });

    this.error$ = this.store.pipe(select(store => store.projects.error));
    this.error$.subscribe((data: any) => {

      if (data) {
        this.formSent = false;
      }
    });

    this.successProjectSource$ = this.store.pipe(select(store => store.projectSources.success));
    this.successProjectSource$.subscribe((data: any) => {

      if (data) {
        // Dispatch Add project source.
        this.formSent = false;
        this.closeSidebar();
        this.isAddProject = false;
      }
    });

    this.errorProjectSource$ = this.store.pipe(select(store => store.projects.error));
    this.errorProjectSource$.subscribe((data: any) => {

      if (data) {
        this.errorMsg = data.error.message;
        this.formSent = false;
      }
    });

    this.successCompany$ = this.store.pipe(select(store => store.companies.success.isLoaded));
    this.successCompany$.subscribe((data: any) => {
      if (data && this.isBulkEdit) {
        this.formSent = false;
        this.closeSidebar();
      }
    });

    this.successContact$ = this.store.pipe(select(store => store.contacts.success.isLoaded));
    this.successContact$.subscribe((data: any) => {
      if (data && this.isBulkEdit) {
        this.formSent = false;
        this.closeSidebar();
      }
    });
  }

  generateProjectForm() {
    this.projectForm = this.formBuilder.group({
      project_name: new FormControl('', [Validators.required, Validators.maxLength(250), Validators.compose([trimValidator])]),
      display_name: new FormControl('', [Validators.maxLength(250)]),
      project_type: new FormControl([], [Validators.required]),
      address: new FormControl('', [Validators.required, Validators.maxLength(200), Validators.compose([trimValidator])]),
      city_name: new FormControl('', [Validators.required, Validators.maxLength(100), Validators.compose([trimValidator])]),
      country_code: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      project_tag: new FormControl(''),
      status_code: new FormControl('', [Validators.required]),
      proposed_post_date: new FormControl(''),
      featured_date: new FormControl(''),
      ltd_link: new FormControl('', [Validators.compose([websiteValidator]), Validators.maxLength(500)]),
      user_code: new FormControl(''),
      drive_link: new FormControl('', [Validators.compose([websiteValidator]), Validators.maxLength(500)]),
      // assigned_to_user: new FormControl(''),
      assigned_to_note: new FormControl('')
    });
  }

  generateProjectSourceForm() {
    this.projectSourceForm = this.formBuilder.group({
      project_source_type_code: new FormControl([], [Validators.required]),
      contact_code: new FormControl('', [Validators.required]),
      company_code: new FormControl(null),
      drive_link: new FormControl(null, [Validators.compose([websiteValidator]), Validators.maxLength(500)]),
      notes: new FormControl(null),
      project_source_status_code: new FormControl([], [Validators.required]),
      date_received: new FormControl('', [Validators.required])
    });
  }

  onAddProject(form: FormGroup, sourceForm: FormGroup) {
    if (!form.invalid && !sourceForm.invalid) {
      if (!this.projectForm.value.project_tag) {
        if (this.projectForm.value.project_tag === null) {
          this.projectForm.value.project_tag = null;
        }
      }

      const sourceRequest = this.onAddProjectSource();

      const request = {
        project_name: this.projectForm.value.project_name,
        display_name: this.projectForm.value.display_name,
        project_type: _.cloneDeep(this.projectForm.value.project_type),
        address: this.projectForm.value.address,
        city_name: this.projectForm.value.city_name,
        country_id: this.projectForm.value.country_code,
        description: this.projectForm.value.description,
        tags: this.projectForm.value.project_tag,
        drive_link: form.value.drive_link,
        status: form.value.status_code,
        project_source: sourceRequest ? sourceRequest : null
      };
      const req = _.cloneDeep(request);

      this.isAddProject = true;

      this.formSent = true;
      this.store.dispatch(new ProjectAdd(
        req
      ));
    }

  }

  onUpdateProject(form: FormGroup) {
    let date, featuredDate;
    if (form.value.proposed_post_date) {
      date = moment(form.value.proposed_post_date).format('YYYY-MM-DD HH:mm:ss');
    } else {
      date = null;
    }

    if (form.value.featured_date) {
      featuredDate = moment(form.value.featured_date).format('YYYY-MM-DD');
    } else {
      featuredDate = null;
    }

    if (form.value.project_company_role) {
      for (let index = 0; index < form.value.project_company_role.length; index++) {
        form.value.project_company_role[index].company_role = form.value.project_company_role[index].company_role[0];
      }
    }

    if (this.projectForm.value.project_tag.length === 0) {
      this.projectForm.value.project_tag = [];
    }

    let ltdLink = form.value.ltd_link;
    if (form.value.ltd_link) {
      ltdLink = ltdLink.replace('s/?page=', '/');
      ltdLink = ltdLink.split('#')[0];
    }

    const formTags = [...form.value.project_tag];

    const formData = {
      project_name: form.value.project_name,
      display_name: form.value.display_name,
      project_type_code: _.cloneDeep(form.value.project_type),
      address: form.value.address,
      city_name: form.value.city_name,
      country_code: form.value.country_code,
      project_tag: formTags.filter(item => item),
      status_code: form.value.status_code.toString(),
      proposed_post_date: date,
      ltd_project_link: ltdLink,
      user_code: form.value.user_code,
      drive_link: form.value.drive_link,
      featured_date: featuredDate,
      project_description: form.value.description
    };

    if (!form.invalid) {
      this.formSent = true;
      this.store.dispatch(new ProjectUpdate({project_code: this._projectInput$.getValue().project_code, project: formData}));
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sourceTypes) {
      this.sourceTypeFields = {
        value: 'project_source_type_code', text: ('project_source_type_name'), child: 'children',
        dataSource: this.sourceTypes
      };
    }

    if (changes.contactList) {
      this.contactListCopy = _.cloneDeep(this.contactList);
    }


    if (changes.companyRole) {
      this.companyRoleFields = {dataSource: this.companyRole, value: 'company_role_code', text: 'company_role_name', child: 'children'};
    }

    if (!this.projectForm) {
      this.generateProjectForm();
    }
    if (!this.projectCompanyRoleForm) {
      this.generateProjectCompanyRoleForm();
    } else {
      // this.projectCompanyRoleForm.reset();
    }

    if (changes.projectTypes) {
      if (this.projectTypes) {
        this.projectTypeFields = {dataSource: this.projectTypes, value: 'project_type_code', text: 'project_type_name', child: 'children'};
      }
    }

    if (changes.projectStatus) {
      this.projectStatusFields = {
        dataSource: this.projectStatus,
        value: 'status_code',
        text: 'status_name',
        groupBy: 'parent',
        child: 'children'
      };
    }

    if (this._projectInput$.getValue() && this.formType === 'project') {

      const project_type_array = [];
      for (const type of this._projectInput$.getValue().project_type) {
        project_type_array.push(type.project_type_code);
      }

      const project_tags_array = [];
      if (this._projectInput$.getValue().project_tag) {
        for (const tag of this._projectInput$.getValue().project_tag) {
          project_tags_array.push(tag.tag);
        }

      }

      this.projectForm.setValue({
        project_name: this._projectInput$.getValue().project_name,
        display_name: this._projectInput$.getValue().display_name ? this._projectInput$.getValue().display_name : '',
        project_type: project_type_array ? project_type_array : null,
        address: this._projectInput$.getValue().address ? this._projectInput$.getValue().address : null,
        city_name: this._projectInput$.getValue().city_name ? this._projectInput$.getValue().city_name : null,
        description: this._projectInput$.getValue().project_description ? this._projectInput$.getValue().project_description : null,
        country_code: this._projectInput$.getValue().country ? this._projectInput$.getValue().country.country_code : null,
        project_tag: project_tags_array ? project_tags_array : null,
        status_code: this._projectInput$.getValue().status.status_code ? this._projectInput$.getValue().status.status_code : null,
        proposed_post_date: this._projectInput$.getValue().proposed_post_date ? moment(this._projectInput$.getValue().proposed_post_date, 'YYYY-MM-DD HH:mm').toDate() : null,
        ltd_link: this._projectInput$.getValue().ltd_project_link ? this._projectInput$.getValue().ltd_project_link : null,
        user_code: this._projectInput$.getValue().assigned_to ? this._projectInput$.getValue().assigned_to.user_code : null,
        drive_link: this._projectInput$.getValue().drive_link ? this._projectInput$.getValue().drive_link : null,
        featured_date: this._projectInput$.getValue().featured_date ? moment(this._projectInput$.getValue().featured_date, 'YYYY-MM-DD HH:mm').format('MM/DD/YYYY') : null,
        assigned_to_note: ''
      });

    }

    if (this.formType === 'company-role' && this._projectRoleInput$.getValue()) {
      this.searchAndSetCompany('company');
      // this.searchAndSetCompany('contact');

      this.projectCompanyRoleForm.setValue({
        company_code: this._projectRoleInput$.getValue().company ? this._projectRoleInput$.getValue().company[0].company_code : null,
        contact_code: this._projectRoleInput$.getValue().company_contact ? this._projectRoleInput$.getValue().company_contact.company_contact_code : null,
        company_role_code: [this._projectRoleInput$.getValue().company_role[0].company_role_code],
      });
    }

    if (changes.allTags) {
      this.allTags.subscribe(data => {
        this.tagModelData = data;
      });
    }
  }

  generateProjectCompanyRoleForm() {
    this.projectCompanyRoleForm = this.formBuilder.group({
      company_role_code: new FormControl('', Validators.required),
      company_code: new FormControl(''),
      contact_code: new FormControl(''),
    }, {validators: atLeastOne(Validators.required, ['contact_code', 'company_code'])});
  }

  searchAndSetCompany(entity) {
    this.selectedName = this._projectRoleInput$.getValue().company ? this._projectRoleInput$.getValue().company[0].company_name : null;
    if (this.selectedName) {
      const isCompanyExistsInList = this.companyList.filter(el => el.company_name === this.selectedName).length > 0;
      if (!isCompanyExistsInList) {
        const companyList = [];
        companyList.push(this._projectRoleInput$.getValue().company[0]);
        this.companyList.forEach(el => companyList.push(el));
        this.companyDropdown.dataSource = companyList;
        this.projectCompanyRoleForm.controls.company_code.setValue(this._projectRoleInput$.getValue().company[0].company_code);
      }
    }
    this.selectedName = this._projectRoleInput$.getValue().contact ? this._projectRoleInput$.getValue().contact[0].first_name : null;
    if (this.selectedName) {
      const isContactExistsInList = this.contactList.filter(el => el.name === this.selectedName).length > 0;
      if (!isContactExistsInList) {
        const contactList = [];
        let company;
        if (this._projectRoleInput$.getValue().company) {
          company = ' - ' + this._projectRoleInput$.getValue().company[0].company_name;
        } else {
          company = '';
        }
        const result = {
          value: this._projectRoleInput$.getValue().company_contact.company_contact_code,
          name: (this._projectRoleInput$.getValue().contact[0].first_name + ' ' + this._projectRoleInput$.getValue().contact[0].last_name + company),
          // contact_code: this._projectRoleInput$.getValue().contact[0].contact_code, company_code: this._projectRoleInput$.getValue().company ? this._projectRoleInput$.getValue().company[0].company_code : null
          contact_code: this._projectRoleInput$.getValue().contact[0].contact_code,
          company_code: this._projectRoleInput$.getValue().company ? this._projectRoleInput$.getValue().company[0].company_code : null
        };
        contactList.push(result);
        this.contactList.forEach(el => contactList.push(el));
        this.contactDropdown.dataSource = contactList;
        this.projectCompanyRoleForm.controls.contact_code.setValue(this._projectRoleInput$.getValue().company_contact.company_contact_code);
      }

      if (this._projectRoleInput$.getValue().company) {
        this.settingForm = false;
      }
    }
  }

  onAddUpdateProjectCompanyRole(form: FormGroup, isUpdate: boolean) {
    if (!form.invalid) {
      let request = form.value;
      Object.keys(request).forEach((key) => ((request[key] == null) || (request[key] == '')) && delete request[key]);
      request = {...request, projectCode: this.selectedProjectCode, company_role_code: form.value.company_role_code[0]};

      if (request.contact_code) {
        request = {
          ...request, company_code: this.selectedRoleContact ? this.selectedRoleContact.company_code : null,
          contact_code: this.selectedRoleContact ? this.selectedRoleContact.contact_code : null,
          company_contact_code: this.selectedRoleContact ? this.selectedRoleContact.value : null
        };
      }

      if (!isUpdate) {
        this.store.dispatch(new AddProjectCompanyRole(request));
      } else {
        request = {...request, project_role_code: this._projectRoleInput$.getValue().project_company_role_code};
        this.store.dispatch(new UpdateProjectCompanyRole(request));
      }
    }
  }

  openSidebar(): void {
    this.sidebarInstance.show();
    this.isSidebarClose = false;
    if (this.projectSourceForm) {
      this.projectSourceForm.controls.date_received.setValue(this.currentDate);
    }
  }

  public closeSidebar(): void {
    if (this.formType === 'project') {
      this.clearForm();
    } else {
      this.isSidebarClose = true;
      this.projectCompanyRoleForm.reset();
    }

    if (this.isBulkEdit) {
      this.selectedField = '';
    }

    this.valueChange.emit({message: 'success'});
    this.store.dispatch(new ProjectSetStatusNull());
    if (this.sidebarInstance && this.sidebarInstance.isOpen) {
      this.sidebarInstance.hide();
    }
  }

  clearForm() {
    this.projectForm.reset();
    if (this.projectSourceForm) {
      this.projectSourceForm.reset();
      if (this.contactDropdown) {
        this.contactDropdown.dataSource = this.contactList;
      }
      // this.projectSourceForm.controls.contact_code.setValue('');
    }
  }

  clearCompanyRoleForm() {
    this.projectCompanyRoleForm.reset();
  }


  onBlur(args, form) {

    if (this.selectedTag.length != 0) {

      this.tagSelected = this.selectedTag[this.selectedTag.length - 1];

      if (this.modelData && !this.modelData.includes(this.tagSelected)) {
        this.projectTag.addItem({tag: this.tagSelected, tag_code: this.tagSelected});
        form.controls.project_tag.setValue(_.uniq(_.compact([...this.modelData, this.tagSelected])));
      }

      this.modelData = this.modelData.length > 0 ? _.uniq(_.compact([...this.modelData, this.tagSelected])) : [];
      // this.modelData.push(this.tagSelected);

      this.selectedTag = [];
    }

  }

  syncModel = (event) => {
    this.modelData = event;
  };

  actionComplete(args) {
    if (_.get(args, 'result[0]') && this.tagModelData.length != _.get(args, 'result', []).length) {
      this.selectedTag.push(args.result[0].tag);
    }
  }


  onChange(event) {
    const tagsArray = _.compact(this.modelData);
    if (tagsArray.length === 0) {
      this.modelData = [];
    }
  }

  select(args) {
    this.selectedTag = [];
  }

  somethingChanged(args) {
    this.projectForm.controls.project_type.setValue(args.value);
  }

  fieldChanged() {
    this.selectedField = this.fieldDropdown.value ? this.fieldDropdown.value.toString() : '';
    this.projectForm.reset();
  }

  bulkEdit(form: FormGroup) {
    if (this.selectedProjectList.length > 0) {
      const formValue = {...form.value};
      Object.keys(formValue).forEach((key) => ((formValue[key] == null) || (formValue[key] === '') || (formValue[key].length === 0)) && delete formValue[key]);

      const projectList = [];
      for (const project of this.selectedProjectList) {
        projectList.push(project.project_code);
      }

      if (Object.keys(formValue)[0] === 'proposed_post_date') {
        formValue.proposed_post_date = moment(formValue.proposed_post_date).format('YYYY-MM-DD');
      } else if (Object.keys(formValue)[0] === 'featured_date') {
        formValue.featured_date = moment(formValue.featured_date).format('YYYY-MM-DD');
      } else if (Object.keys(formValue)[0] === 'project_type') {
        formValue.project_type_code = formValue.project_type;
        delete formValue.project_type;
      }

      let request = {
        project_code: projectList,
        [Object.keys(formValue)[0]]: formValue[Object.keys(formValue)[0]]
      };

      if (Object.keys(formValue)[0] === 'user_code') {
        request = {...request, project_assigned_notes: form.value.assigned_to_note};
      }

      if (this.isCompanyLink()) {
        request = {...request, page: 'company'};
        this.store.dispatch(new CompanyProjectBulkEdit({request}));
      } else if (this.isContactLink()) {
        request = {...request, page: 'contact'};
        this.store.dispatch(new ContactProjectBulkEdit({request}));
      } else {
        request = {...request, page: 'project'};
        this.store.dispatch(new ProjectBulkEdit({request}));
      }
      this.formSent = true;
    }
  }

  isCompanyLink(): boolean {
    return (this.router.url.indexOf('/company/') > -1);
  }

  isContactLink(): boolean {
    return (this.router.url.indexOf('/contact/') > -1);
  }

  addNewCompanyContact(entity: string) {
    if (entity === 'contact') {
      this.valueChange.emit({message: entity, company: this.shortlistedCompany});
    } else if (entity === 'company') {
      this.valueChange.emit({message: entity});
    }
  }

  selectRoleContact(args) {
    this.selectedRoleContact = args.itemData;
    if (this.selectedRoleContact && this.selectedRoleContact.company_code !== this.projectCompanyRoleForm.controls.company_code.value) {
      console.log('hererere');
      this.resetCompany = true;
      this.projectCompanyRoleForm.controls.company_code.setValue('');
      this.projectCompanyRoleForm.controls.company_code.reset();
      // this.resetCompany = false;
    }
    console.log(this.projectCompanyRoleForm.controls.company_code.value);
    console.log(this.selectedRoleContact);
  }

  selectSourceContact(args, form: FormGroup) {
    this.selectedSourceContact = args.itemData;
    if (args.itemData) {
      form.controls.contact_code.setValue(args.itemData.value);
    }
  }

  onAddProjectSource() {
    //
    // this.store.select(store => store.projects.projects).subscribe((data) => {
    //   if (data) {
    //     const project = data.result.find(x => x.project_name === this.projectForm.controls.project_name.value);
    //
    //   }
    // });

    if (!this.projectSourceForm.invalid) {
      let request;
      request = {
        project_source_type_code: _.cloneDeep(this.projectSourceForm.value.project_source_type_code),
        project_company_code: this.selectedSourceContact ? this.selectedSourceContact.company_code : null,
        project_contact_code: this.selectedSourceContact ? this.selectedSourceContact.contact_code : null,
        drive_link: this.projectSourceForm.value.drive_link,
        notes: this.projectSourceForm.value.notes,
        project_source_status_code: this.projectSourceForm.value.project_source_status_code,
        company_contact_code: this.selectedSourceContact ? this.selectedSourceContact.value : null,
        date_received: moment(this.projectSourceForm.value.date_received).format('YYYY-MM-DD')
      };

      request = {...request, currentScreen: 'project'};
      this.formSent = true;
      return request;
      // this.store.dispatch(new ProjectSourceAdd(request));
    }
  }

  change(args) {
    this.selectedCompanyName = args.itemData ? args.itemData.company_code : '';
    this.shortlistedCompany = args.itemData ? args.itemData : null;
    if (this.projectSourceForm) {
      this.projectSourceForm.controls.contact_code.setValue(null);
    }
    if (this.projectCompanyRoleForm) {
      console.log("reset company", this.resetCompany);
      if (this.settingForm && !this.resetCompany ) {
        this.projectCompanyRoleForm.controls.contact_code.setValue(null);
      } else {
        this.settingForm = true;
      }
    }

    this.resetCompany = false;


    if (args.itemData) {
      this.getCompanyContactData(args.itemData.company_code);
    } else {
      this.getCompanyContactData();
    }
  }

  getCompanyContactData(company_code?) {
    console.log('in company contact?????');
    if (company_code) {
      // console.log(args.itemData.company_code);
      // this.store.dispatch(new GetAllCompanyContacts({company_code}));
      this.contactService.getAllCompanyContacts({company_code}).subscribe((data) => {
        if (data && data.data) {
          const companyContactList = [];
          // const contacts = formatDataSet(data.data.columns, data.data.result);
          if (data.data.company_contact && data.data.company_contact.length > 0) {
            for (const contact of data.data.company_contact) {

              let company = '';
              if (data.data.company_name) {
                company = ' - ' + data.data.company_name;
              } else {
                company = '';
              }

              if (contact && contact.contact) {
                let result = {
                  value: contact.company_contact_code, category: 'Contacts',
                  name: (contact.contact.first_name + ' ' + contact.contact.last_name + company), contact_code: contact.contact.contact_code,
                  company_code: data.data ? data.data.company_code : null
                };
                
                if (contact.company) {
                  result = {...result, ...{company_code: contact.company.company_code, company_name: contact.company.company_name}};
                }
                companyContactList.push(result);

              }




            }
          }

          this.contactList = companyContactList;
        }
      });
    } else {
      this.contactService.getSearchedContact({}).subscribe((data) => {
        if (data && data.data) {
          const companyContactList = [];
          if (data.data.result) {
            for (const contact of data.data.result) {
              if (contact.associated_companies) {
                for (const associatedCompany of contact.associated_companies) {
                  let company = '';
                  let company_code = '';
                  const company_contact_code = _.clone(associatedCompany.company_contact_code);

                  if (associatedCompany.company) {
                    company = ' - ' + associatedCompany.company.company_name;
                    company_code = associatedCompany.company.company_code;
                  } else {
                    company = ' (' + associatedCompany.email + ')';
                    company_code = '';
                  }

                  const result = {
                    value: company_contact_code,
                    name: (contact.first_name + ' ' + contact.last_name + company),
                    company_code, contact_code: contact.contact_code
                  };
                  companyContactList.push(result);
                }
              }
            }
            if (!this.isSidebarClose) {
              this.contactList = companyContactList;
              this.contactDropdown.dataSource = this.contactList;
            }
            console.log(this.contactList);
          }
        }
      });
      // this.store.dispatch(new ContactLoad());
      // this.store.select(store => store.contacts.contacts).subscribe((data) => {
      //   if (data && data.result) {
      //     const companyContactList = [];
      //     for (const contact of data.result) {
      //
      //       let company = '';
      //       if (contact.company) {
      //         company = ' - ' + contact.company.company_name;
      //       } else {
      //         company = '';
      //       }
      //       let result = {
      //         value: contact.contact_code, category: 'Contacts',
      //         name: (contact.first_name + ' ' + contact.last_name + company),
      //       };
      //
      //       if (contact.company) {
      //         result = {...result, ...{company_code: contact.company.company_code, company_name: contact.company.company_name}};
      //       }
      //       companyContactList.push(result);
      //     }
      //     this.contactList = companyContactList;
      //   }
      // });
    }
  }

  focus(args) {
    if (this.selectedCompanyName) {
      this.getCompanyContactData(this.selectedCompanyName);
      // this.store.dispatch(new GetAllCompanyContacts({company_code: this.selectedCompanyName}));
      // this.store.select(store => store.contacts.contacts).subscribe((data) => {
      //   if (data) {
      //     const companyContactList = [];
      //     for (const contact of data.result) {
      //
      //       let company = '';
      //       if (contact.company) {
      //         company = ' - ' + contact.company.company_name;
      //       } else {
      //         company = '';
      //       }
      //       let result = {
      //         value: contact.contact_code, category: 'Contacts',
      //         name: (contact.first_name + ' ' + contact.last_name + company),
      //       };
      //
      //       if (contact.company) {
      //         result = {...result, ...{company_code: contact.company.company_code, company_name: contact.company.company_name}};
      //       }
      //       companyContactList.push(result);
      //     }
      //     this.contactList = companyContactList;
      //   }
      // });
    } else {
      this.getCompanyContactData();
    }
  }

  public onFiltering = (e, entity: string) => {
    e.preventDefaultAction = true;
    this.setDebounce(e, entity);
  };

  public onFilter: EmitType<any> = (e: FilteringEventArgs, entity: string) => {
    let query = new Query();

    if (entity === 'company') {

      query = (e.text !== '') ? query.where('company_name', 'contains', e.text, true) : query;
      const filterParams = {company_name: e.text, page_size: 40, page_no: 1};

      // if (e.text && e.text !== '') {
      //   this.store.dispatch(new GetSearchedCompany(filterParams));
      // } else {
      //   const filterParams = {page_size: 40, page_no: 1};
      //   this.store.dispatch(new CompanyLoad(filterParams));
      // }

      this.companyService.getSearchedCompany(filterParams).subscribe((data) => {
        if (data.data) {
          const companyList = [];
          for (const company of data.data.result) {
            companyList.push({company_name: company.company_name + ' (' + company.reference_id + ')', company_code: company.company_code})
          }
          // this.companyList = data.data.result;
          e.updateData(companyList, query);
        }
      });

      // this.store.select(store => store.companies.companies).subscribe((data) => {
      //   if (data) {
      //     this.companyList = data.result;
      //     e.updateData(this.companyList, query);
      //   }
      // });
    } else if (entity === 'contact') {

      // if ((this.projectCompanyRoleForm && this.projectCompanyRoleForm.controls.company_code.value !== null &&
      //   this.projectCompanyRoleForm.controls.company_code.value !== '') || this.projectSourceForm) {
        query = (e.text !== '') ? query.where('name', 'contains', e.text, true) : query;
        const contactfilterParams = {name: e.text, page_size: 40, page_no: 1};
        this.contactService.getSearchedContact(contactfilterParams).subscribe((data) => {
          if (data && data.data) {
            const companyContactList = [];
            if (data.data.result) {
              for (const contact of data.data.result) {
                if (contact.associated_companies) {
                  for (const associatedCompany of contact.associated_companies) {
                    let company = '';
                    let company_code = '';
                    const company_contact_code = _.clone(associatedCompany.company_contact_code);

                    if (associatedCompany.company) {
                      company = ' - ' + associatedCompany.company.company_name;
                      company_code = associatedCompany.company.company_code;
                    } else {
                      company = ' (' + associatedCompany.email + ')';
                      company_code = '';
                    }

                    const result = {
                      value: company_contact_code,
                      name: (contact.first_name + ' ' + contact.last_name + company),
                      company_code, contact_code: contact.contact_code
                    };
                    companyContactList.push(result);
                  }
                }
              }
              this.contactList = companyContactList;
              e.updateData(companyContactList, query);
            }
          }
        });

      // }

    }
  };

  setSourceType(args) {
    this.projectSourceForm.controls.project_source_type_code.setValue(args.value);
  }
}
