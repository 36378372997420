<ejs-sidebar id="sidebar-menuuu" #sidebarInstance type='Over' [width]='width' [position]='position'
             style="visibility: hidden" [enableGestures]="false" [showBackdrop]=true>

  <div class="offcanvas-close mt-n1 pr-5" style="float:right; padding: 48px;">
    <a href="javascript:;" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_panel_close"
       (click)="closeSidebar()">
      <i class="ki ki-close icon-xs text-muted"></i>
    </a>
  </div>
  <div class="p-15">
    <h2>Email Keywords</h2>
    <ejs-grid #grid [dataSource]="emailKeywords" class="border-0" [allowGrouping]='true' [groupSettings]='groupOptions'>
      <e-columns>
        <e-column field='email_keyword' headerText='Email Keyword' width="40%" textAlign='Left'></e-column>
        <e-column field='description' headerText='Description' width="60%" textAlign='Left'></e-column>
        <e-column field='application_category.application_category_name' headerText='Category' textAlign='Left'></e-column>
      </e-columns>
    </ejs-grid>
  </div>
</ejs-sidebar>
