import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { TextBoxModule, NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import {DatePickerModule, DateRangePickerModule} from '@syncfusion/ej2-angular-calendars';
import { GridModule, PagerModule } from '@syncfusion/ej2-angular-grids';
import {DialogModule} from '@syncfusion/ej2-angular-popups';
import { RichTextEditorModule } from '@syncfusion/ej2-angular-richtexteditor';

export const modules = [
  TextBoxModule, NumericTextBoxModule, DatePickerModule, DateRangePickerModule, GridModule, PagerModule, DialogModule, RichTextEditorModule
];

@NgModule({
  declarations: [],
  imports: [...modules],
  exports: [...modules],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SyncfusionModule {}
