import {Action} from '@ngrx/store';
import {CompanyRole} from '../../models/company-role.model';

export enum CompanyRoleActionsType {
  LOAD_COMPANY_ROLE = '[COMPANY ROLE] Load Action',
  LOAD_COMPANY_ROLE_SUCCESS = '[COMPANY ROLE] Load Success Action',
  LOAD_COMPANY_ROLE_FAILURE = '[COMPANY ROLE] Load Failure Action',

  ADD_COMPANY_ROLE = '[COMPANY ROLE] Add Action',
  ADD_COMPANY_ROLE_SUCCESS = '[COMPANY ROLE] Add Success Action',
  ADD_COMPANY_ROLE_FAILURE = '[COMPANY ROLE] Add Failure Action',

  UPDATE_COMPANY_ROLE = '[COMPANY ROLE] Update Action',
  UPDATE_COMPANY_ROLE_SUCCESS = '[COMPANY ROLE] Update Success Action',
  UPDATE_COMPANY_ROLE_FAILURE = '[COMPANY ROLE] Update Failure Action',

  DELETE_COMPANY_ROLE = '[COMPANY ROLE] Delete Action',
  DELETE_COMPANY_ROLE_SUCCESS = '[COMPANY ROLE] Delete Success Action',
  DELETE_COMPANY_ROLE_FAILURE = '[COMPANY ROLE] Delete Failure Action',

  RESET_ERROR = '[COMPANY ROLE] Reset Error Action'
}

export class CompanyRoleLoad implements Action {
  readonly type = CompanyRoleActionsType.LOAD_COMPANY_ROLE;
  constructor(public payload?: {page_size?: number, page_no?: number}) { }
}

export class CompanyRoleLoadSuccess implements Action {
  readonly type = CompanyRoleActionsType.LOAD_COMPANY_ROLE_SUCCESS;
  constructor(public payload: { result: Array<CompanyRole>, total_count: number, page_size: number, page_no: number }) { }
}

export class CompanyRoleLoadFailure implements Action {
  readonly type = CompanyRoleActionsType.LOAD_COMPANY_ROLE_FAILURE;
  constructor(public payload: Error) { }
}

export class CompanyRoleAdd implements Action {
  readonly type = CompanyRoleActionsType.ADD_COMPANY_ROLE;
  constructor(public payload: {company_role_name: string, parent_type_code?: string}) { }
}

export class CompanyRoleAddSuccess implements Action {
  readonly type = CompanyRoleActionsType.ADD_COMPANY_ROLE_SUCCESS;
  constructor(public payload: { companyRole: CompanyRole, parentCode: string }) { }
}

export class CompanyRoleAddFailure implements Action {
  readonly type = CompanyRoleActionsType.ADD_COMPANY_ROLE_FAILURE;
  constructor(public payload: Error) { }
}


export class CompanyRoleUpdate implements Action {
  readonly type = CompanyRoleActionsType.UPDATE_COMPANY_ROLE;
  constructor(public payload: { company_role_code: string, companyRoleName: string, parent_type_code: string }) { }
}

export class CompanyRoleUpdateSuccess implements Action {
  readonly type = CompanyRoleActionsType.UPDATE_COMPANY_ROLE_SUCCESS;
  constructor(public payload: { companyRole: CompanyRole, parent_type_code: string }) { }
}

export class CompanyRoleUpdateFailure implements Action {
  readonly type = CompanyRoleActionsType.UPDATE_COMPANY_ROLE_FAILURE;
  constructor(public payload: Error) { }
}

export class CompanyRoleDelete implements Action {
  readonly type = CompanyRoleActionsType.DELETE_COMPANY_ROLE;
  constructor(public payload: { companyRoleCode: string }) { }
}

export class CompanyRoleDeleteSuccess implements Action {
  readonly type = CompanyRoleActionsType.DELETE_COMPANY_ROLE_SUCCESS;
  constructor(public payload: { companyRoleCode: string }) { }
}

export class CompanyRoleDeleteFailure implements Action {
  readonly type = CompanyRoleActionsType.DELETE_COMPANY_ROLE_FAILURE;
  constructor(public payload: Error) { }
}

export class CompanyRoleResetError implements Action {
  readonly type = CompanyRoleActionsType.RESET_ERROR;
}

export type CompanyRoleActions = CompanyRoleLoad | CompanyRoleLoadSuccess | CompanyRoleLoadFailure | CompanyRoleAdd |
  CompanyRoleAddSuccess | CompanyRoleAddFailure | CompanyRoleUpdate | CompanyRoleUpdateSuccess | CompanyRoleUpdateFailure |
  CompanyRoleDelete | CompanyRoleDeleteSuccess | CompanyRoleDeleteFailure | CompanyRoleResetError;
