import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ArrayString'
})
export class ArrayStringPipe implements PipeTransform {
  transform(array: Array<any>, key: string): string {
    return array.map(item => item[key]).join(', ');
  }
}

