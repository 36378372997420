<ejs-sidebar id="sidebar-menu" #sidebarInstance type='Over' width='400px' position='Right'
             style="visibility: hidden" [enableGestures]="false" [showBackdrop]=true>

  <div class="offcanvas-close mt-n1 pr-5" style="float:right; padding: 32px;">
    <a href="javascript:;" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_panel_close"
       (click)="closeSidebar()">
      <i class="ki ki-close icon-xs text-muted"></i>
    </a>
  </div>

  <div class='p-10'>
    <div class="d-flex mt-4">
      <h2>Contact Orders</h2>
      <button class="btn btn-primary text-uppercase font-weight-bold btn-sm float-right px-8 ml-auto" id="addNewProject"
        (click)="addNewRole()">
        Add new
      </button>
    </div>
    <div class="mt-15">
      <div *ngIf="!contactRoles || contactRoles.length === 0"> No records to display</div>
      <div class="d-flex align-items-center mb-10" *ngFor="let roles of contactRoles">
        <div class="symbol symbol-40 symbol-light-success mr-5"><span
          class="symbol-label font-size-h5 font-weight-bold">{{roles.first_name | firstLetter}}{{roles.last_name | firstLetter}}</span>
        </div>
        <div class="d-flex flex-column flex-grow-1 font-weight-bold"><a
                                                                        class="text-dark text-hover-primary mb-1 font-size-lg">
          {{roles.first_name}} {{roles.last_name}}
        </a><span class="text-muted">{{roles.contact_role | ArrayString:'order_role_name'}}</span></div>
        <div>
          <button ejs-dropdownbutton [items]='items' class="btn btn-icon btn-light btn-hover-primary btn-sm"
                  cssClass='e-caret-hide' (select)='select($event, roles)'>
            <mat-icon svgIcon="can-more-actions"
                      class="svg-icon svg-icon-md svg-icon-primary h-auto"></mat-icon>
          </button>
        </div>
      </div>
    </div>

  </div>
</ejs-sidebar>
