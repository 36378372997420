// import { NgxPermissionsModule } from 'ngx-permissions';
// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Loading bar
// import { LoadingBarModule } from '@ngx-loading-bar/core';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// Ngx DatePicker
// import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
// Perfect Scrollbar
// import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
// SVG inline
import { InlineSVGModule } from 'ng-inline-svg';
// Core Module
import { CoreModule } from '../../core/core.module';
import { HeaderComponent } from './header/header.component';
import { AsideComponent } from './aside/aside.component';
import { TopbarComponent } from './header/topbar/topbar.component';
import { PartialsModule } from '../partials/partials.module';
import { BaseComponent } from './base/base.component';
import { HtmlClassService } from './html-class.service';
import { BrandComponent } from './brand/brand.component';
import { SubheaderComponent } from './subheader/subheader.component';
import {MaterialModule} from '../../material.module';
import { FooterComponent } from './footer/footer.component';
import { MenuHorizontalComponent } from './header/menu-horizontal/menu-horizontal.component';
import { HeaderMobileComponent } from './header/header-mobile/header-mobile.component';
import {HeaderDirective} from '../../core/_base/layout';
import {MainPipe} from '../../core/_base/layout/pipes/pipe.module';
import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';


@NgModule({
    declarations: [
        BaseComponent,
        // FooterComponent,

        // // headers
        HeaderComponent,
        // BrandComponent,
        HeaderMobileComponent,

        // // subheader
        // SubheaderComponent,

        // // topbar components
        TopbarComponent,

        // aside left menu components
        AsideComponent,

        BrandComponent,

        SubheaderComponent,

        FooterComponent,

        MenuHorizontalComponent,
        HeaderDirective,

        // horizontal menu components
        // MenuHorizontalComponent,
    ],
    exports: [
        BaseComponent,
        // FooterComponent,

        // // headers
        // HeaderComponent,
        // BrandComponent,
        HeaderMobileComponent,

        // // subheader
        SubheaderComponent,

        // topbar components
        TopbarComponent,

        // aside left menu components
        AsideComponent,
        FooterComponent,

        // // horizontal menu components
        // MenuHorizontalComponent,
    ],
  providers: [
    HtmlClassService,
  ],
    imports: [
        MaterialModule,
        CommonModule,
        RouterModule,
        // NgxPermissionsModule.forChild(),
        // StoreModule.forFeature('roles', rolesReducer),
        // StoreModule.forFeature('permissions', permissionsReducer),
        // EffectsModule.forFeature([PermissionEffects, RoleEffects]),
        // PagesModule,
        PartialsModule,
        CoreModule,
        // PerfectScrollbarModule,
        FormsModule,
        ReactiveFormsModule,
        // LoadingBarModule,
        // NgxDaterangepickerMd,
        InlineSVGModule,
        MainPipe,
        NgbDropdownModule,

        // // ng-bootstrap modules
        // NgbProgressbarModule,
        // NgbTooltipModule,
    ]
})
export class ThemeModule {
}
