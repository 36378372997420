<div class="d-flex flex-column flex-root" id="kt_login_wrapper">
  <div class="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white" id="kt_login">
    <div class="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10">
        <div class="action-page-image-bg"></div>
      <div class="action-page-image-overlay" style="
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.2);
"></div>
      <div class="d-flex flex-row-fluid flex-column justify-content-between" style="position: relative; z-index: 2">
        <div class="flex-column-fluid d-flex flex-column">
<!--          <h1 class="font-size-h1 mb-5 text-white">Canvas</h1>-->
<!--          <p class="font-weight-lighter text-white opacity-80">A 28 Lightbulbs software</p>-->
        </div>
        <div class="d-none flex-column-auto d-lg-flex justify-content-between">
          <div class="opacity-70 font-weight-bold text-white">
            <app-footer style="background: none; opacity: 1;" class="py-1 d-flex flex-lg-column mr-auto"></app-footer>
          </div>
          <div></div>
        </div>
      </div>
    </div>
    <div
      class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      style="position: relative; z-index: 2">
      <router-outlet></router-outlet>
      <div class="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center">
        <div class="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
          <app-footer style="background: none; opacity: 1;" class="py-1 d-flex flex-lg-column mr-auto"></app-footer>
        </div>
      </div>
    </div>
  </div>
</div>
