<ejs-sidebar id="sidebar-menu" #sidebarInstance type='Over' [width]='width' [position]='position'
             style="visibility: hidden" [enableGestures]="false" [showBackdrop]=true>

  <div class="offcanvas-close mt-n1 pr-5" style="float:right; padding: 48px;">
    <a href="javascript:;" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_panel_close"
       (click)="closeSidebar()">
      <i class="ki ki-close icon-xs text-muted"></i>
    </a>
  </div>

  <div class='p-15'>
    <h2 *ngIf="!projectTypeInput">Add Project Type</h2>
    <h2 *ngIf="projectTypeInput">Update Project Type</h2>

    <form id="formId" [formGroup]="projectTypeForm" #formDir="ngForm" class="form-horizontal" novalidate=""
          style="margin-top: 16px;">

      <div class="form-group sf-outline-input mb-10"  [class.hide-label]="projectTypeForm.controls.project_type_parent_code.value == ''">
        <ejs-dropdowntree formControlName="project_type_parent_code" id='project_type_parent_code' allowMultiSelection='false' sortOrder="Ascending"
                          [fields]='projectTypeFields' placeholder='Parent Project Type' floatLabelType="Auto" cssClass="e-filled"
                          (blur)="projectTypeForm.controls.project_type_parent_code.markAsTouched()"></ejs-dropdowntree>
        <div>Note: If a parent type isn't selected, the new project type will be added as a parent</div>
      </div>


      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="project_type_name" placeholder="Project Type Name *" cssClass="e-filled"
                     formControlName="project_type_name" floatLabelType="Auto"
                     (blur)="projectTypeForm.controls.project_type_name.markAsTouched()"
        ></ejs-textbox>
        <div *ngIf="projectTypeForm.controls.project_type_name.errors">
          <div
            *ngIf="projectTypeForm.controls.project_type_name.errors.required && projectTypeForm.controls.project_type_name.touched"
            class="e-error">
            Project Type Name is required.
          </div>
        </div>
      </div>


      <div *ngIf="projectTypeForm.invalid" class="e-error">Please fill all fields</div>
      <div *ngIf="error$ | async as error" class="e-error">{{error.error.message}}</div>

      <div class="my-10 pb-20">
        <button id="resetbtn"
                class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5"
                type="button" (click)="clearForm();"
                data-ripple="true">Clear
        </button>
        <button id="addSubmit"
                class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit"
                (click)="onAddUpdateProjectType(projectTypeForm)" [disabled]="formSent" data-ripple="true">
          <span *ngIf="!projectTypeInput">Add Project Type</span>
          <span *ngIf="projectTypeInput">Update Project Type</span>
        </button>
        <!--        <button *ngIf="companyTypeInput" id="updateSubmit"-->
        <!--                class="btn btn-primary text-uppercase font-weight-bolder px-15 py-5 float-right btn-block" type="submit"-->
        <!--                (click)="onAddUpdateActivityType(activityTypeForm, true)" [disabled]="formSent" data-ripple="true">-->
        <!--          Update Activity Type-->
        <!--        </button>-->
      </div>
    </form>
  </div>
</ejs-sidebar>
