import {Injectable} from '@angular/core';
import {Effect, Actions, ofType} from '@ngrx/effects';
import {mergeMap, map, catchError, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {CommonService} from '../../services/common.service';
import {
  AddDeliverableItems,
  AddDeliverableItemsFailure,
  AddDeliverableItemsSuccess,
  CommonActionTypes,
  DeleteDeliverableItem,
  DeleteDeliverableItemsFailure,
  DeleteDeliverableItemsSuccess,
  LoadCountriesFailure,
  LoadCountriesSuccess,
  LoadDeliverableItems,
  LoadDeliverableItemsFailure,
  LoadDeliverableItemsSuccess,
  LoadLineItems,
  LoadTagsFailure,
  LoadTagsSuccess,
  LoadUsersFailure,
  LoadUsersSuccess,
  UpdateDeliverableItems,
  UpdateDeliverableItemsFailure,
  UpdateDeliverableItemsSuccess,
  LoadLineItemsSuccess,
  LoadLineItemsFailure,
  AddLineItem,
  AddLineItemSuccess,
  AddLineItemFailure,
  DeleteLineItem,
  DeleteLineItemSuccess,
  DeleteLineItemFailure,
  UpdateLineItem,
  UpdateLineItemSuccess,
  UpdateLineItemFailure,
  LoadDeliverableStatus,
  LoadDeliverableStatusFailure,
  LoadDeliverableStatusSuccess,
  LoadCostCenter,
  LoadCostCenterSuccess,
  LoadCostCenterFailure,
  AddDeliverableStatus,
  AddDeliverableStatusSuccess,
  AddDeliverableStatusFailure,
  DeleteDeliverableStatus,
  DeleteDeliverableStatusSuccess,
  DeleteDeliverableStatusFailure,
  UpdateDeliverableStatusSuccess,
  UpdateDeliverableStatusFailure,
  UpdateDeliverableStatus,
  AddCostCenter,
  AddCostCenterSuccess,
  AddCostCenterFailure,
  DeleteCostCenter,
  DeleteCostCenterSuccess,
  DeleteCostCenterFailure,
  UpdateCostCenter,
  UpdateCostCenterSuccess,
  UpdateCostCenterFailure,
  LoadSubscriptionStage,
  LoadSubscriptionStageFailure,
  LoadSubscriptionStageSuccess,
  LoadCurrency,
  LoadCurrencySuccess,
  LoadCurrencyFailure,
  LoadProjectSourceStatusFailure,
  LoadProjectSourceStatusSuccess,
  LoadArticleType,
  LoadArticleTypeSuccess,
  LoadArticleTypeFailure,
  LoadEmailTemplate,
  LoadEmailTemplateSuccess,
  LoadEmailTemplateFailure,
  AddEmailTemplate,
  AddEmailTemplateSuccess,
  AddEmailTemplateFailure,
  DeleteEmailTemplate,
  DeleteEmailTemplateSuccess,
  DeleteEmailTemplateFailure,
  UpdateEmailTemplate,
  UpdateEmailTemplateSuccess,
  UpdateEmailTemplateFailure,
  LoadEmailLog,
  LoadEmailLogSuccess,
  LoadEmailLogFailure,
  FilterEmailLog,
  FilterEmailLogSuccess,
  FilterEmailLogFailure,
  LoadDeliverableItemDate,
  LoadDeliverableItemDateFailure,
  LoadDeliverableItemDateSuccess,
  AddDeliverableItemDate,
  AddDeliverableItemDateFailure,
  AddDeliverableItemDateSuccess,
  UpdateDeliverableItemDate,
  UpdateDeliverableItemDateSuccess,
  UpdateDeliverableItemDateFailure,
  LoadSocialMediaPlatform,
  LoadSocialMediaPlatformSuccess,
  LoadSocialMediaPlatformFailure,
  LoadRegionsFailure,
  LoadRegionsSuccess,
  LoadRegions,
  LoadOrderStatus,
  LoadOrderStatusSuccess,
  LoadOrderStatusFailure,
  LoadCommunityService,
  LoadCommunityServiceSuccess,
  LoadCommunityServiceFailure,
  LoadProductType,
  LoadProductTypeSuccess,
  LoadProductTypeFailure,
  LoadCommunityType,
  LoadCommunityTypeSuccess, LoadCommunityTypeFailure
} from '../actions/common.actions';
import {ContactLoadFailure} from '../actions/contact.actions';
import {AdminService} from '../services/admin.service';
import {
  SourceTypeActionsType,
  SourceTypeAdd,
  SourceTypeAddFailure,
  SourceTypeAddSuccess,
  SourceTypeDelete, SourceTypeDeleteFailure, SourceTypeDeleteSuccess, SourceTypeUpdate, SourceTypeUpdateFailure, SourceTypeUpdateSuccess
} from '../actions/source-type.actions';

@Injectable()
export class CommonEffects {
  isRunning = false;
  isRunningUsers = false;
  isRunningCountry = false;
  isRunningRegions = false;

  @Effect()
  loadTags = this.actions$.pipe(ofType(CommonActionTypes.LOAD_TAGS),
    mergeMap(() => {
        const errorFlag: Error = new Error();
        errorFlag.message = 'Multiple load Tags requests found. Ignoring last request ';
        if (this.isRunning) {
          return of(new LoadTagsFailure(errorFlag));
        }
        this.isRunning = true;
        return this.commonService.getTags().pipe(map((response: any) => {
            this.isRunning = false;
            return new LoadTagsSuccess(response.data);
          }),
          catchError((error) => {
            this.isRunning = false;
            return of(new LoadTagsFailure(error.error));
          }));
      }
    ));

  @Effect()
  loadUsers = this.actions$.pipe(ofType(CommonActionTypes.LOAD_USER),
    mergeMap(() => {
        const errorFlag: Error = new Error();
        errorFlag.message = 'Multiple load Users requests found. Ignoring last request ';
        if (this.isRunningUsers) {
          return of(new LoadUsersFailure(errorFlag));
        }
        this.isRunningUsers = true;
        return this.commonService.getUsers().pipe(map((response: any) => {
            this.isRunningUsers = false;
            return new LoadUsersSuccess(response.data);
          }),
          catchError((error) => {
            this.isRunningUsers = false;
            return of(new LoadUsersFailure(error.error));
          }));
      }
    ));


  @Effect()
  loadCountries = this.actions$.pipe(ofType(CommonActionTypes.LOAD_COUNTRY),
    mergeMap(() => {
        const errorFlag: Error = new Error();
        errorFlag.message = 'Multiple load Country requests found. Ignoring last request ';
        if (this.isRunningCountry) {
          return of(new LoadCountriesFailure(errorFlag));
        }
        this.isRunningCountry = true;
        return this.commonService.getCountries().pipe(map((response: any) => {
            // this.isRunningCountry = false;
            return new LoadCountriesSuccess(response.data);
          }),
          catchError((error) => {
            // this.isRunningCountry = false;
            return of(new LoadCountriesFailure(error.error));
          }));
      }
    ));

  @Effect()
  loadDeliverableItems = this.actions$.pipe(ofType(CommonActionTypes.LOAD_DELIVERABLE_ITEMS),
    mergeMap((action: LoadDeliverableItems) => {
        return this.adminService.getDeliverableItem(action.payload).pipe(map((response: any) => {
            return new LoadDeliverableItemsSuccess(response.data);
          }),
          catchError((error) => {
            return of(new LoadDeliverableItemsFailure(error.error));
          }));
      }
    ));

  @Effect()
  addDeliverableItem = this.actions$.pipe(ofType(CommonActionTypes.ADD_DELIVERABLE_ITEM),
    switchMap((data: AddDeliverableItems) =>
      this.adminService.addDeliverableItem(data.payload.deliverable_name, data.payload.description,
        data.payload.deliverable_item_parent_code, data.payload.deliverable_item_date_code)
        .pipe(map((response: any) => {
            response = response.data;
            return new AddDeliverableItemsSuccess({
              deliverableItem: response,
              deliverable_item_parent_code: data.payload.deliverable_item_parent_code
            });
          }),
          catchError(error =>
            of(new AddDeliverableItemsFailure(error.error))))
    )
  );

  @Effect()
  deleteDeliverableItem = this.actions$.pipe(
    ofType(CommonActionTypes.DELETE_DELIVERABLE_ITEM),
    mergeMap(
      (data: DeleteDeliverableItem) => this.adminService.deleteDeliverableItem(data.payload.deliverable_item_code)
        .pipe(
          map(() => new DeleteDeliverableItemsSuccess({deliverable_item_code: data.payload.deliverable_item_code})),
          catchError(error => of(new DeleteDeliverableItemsFailure(error.error)))
        )
    )
  );

  @Effect()
  updateDeliverableItem = this.actions$.pipe(
    ofType(CommonActionTypes.UPDATE_DELIVERABLE_ITEMS),
    mergeMap(
      (data: UpdateDeliverableItems) => this.adminService.updateDeliverableItem(data.payload.deliverable_code, data.payload.deliverable_name,
        data.payload.description, data.payload.deliverable_item_parent_code, data.payload.deliverable_item_date_code)
        .pipe(map((responseData: any) => {
            // console.log(responseData.data[0]);
            return new UpdateDeliverableItemsSuccess({
              deliverableItem: responseData.data[0],
              deliverable_item_parent_code: data.payload.deliverable_item_parent_code
            });
          }),
          catchError(error => of(new UpdateDeliverableItemsFailure(error.error)))
        )
    )
  );

  @Effect()
  loadLineItems = this.actions$.pipe(ofType(CommonActionTypes.LOAD_LINE_ITEM),
    mergeMap((action: LoadLineItems) => {
        return this.adminService.getLineItem(action.payload).pipe(map((response: any) => {
            return new LoadLineItemsSuccess(response.data);
          }),
          catchError((error) => {
            return of(new LoadLineItemsFailure(error.error));
          }));
      }
    ));

  @Effect()
  addLineItem = this.actions$.pipe(ofType(CommonActionTypes.ADD_LINE_ITEM),
    switchMap((data: AddLineItem) =>
      this.adminService.addLineItem(data.payload.order_item_name, data.payload.order_item_parent_code, data.payload.description)
        .pipe(map((response: any) => {
            response = response.data;
            return new AddLineItemSuccess({lineItem: response, order_item_parent_code: data.payload.order_item_parent_code});
          }),
          catchError(error =>
            of(new AddLineItemFailure(error.error))))
    )
  );

  @Effect()
  deleteLineItem = this.actions$.pipe(
    ofType(CommonActionTypes.DELETE_LINE_ITEM),
    mergeMap(
      (data: DeleteLineItem) => this.adminService.deleteLineItem(data.payload.order_item_code)
        .pipe(
          map(() => new DeleteLineItemSuccess({line_item_code: data.payload.order_item_code})),
          catchError(error => of(new DeleteLineItemFailure(error.error)))
        )
    )
  );

  @Effect()
  updateLineItem = this.actions$.pipe(
    ofType(CommonActionTypes.UPDATE_LINE_ITEM),
    mergeMap(
      (data: UpdateLineItem) => this.adminService.updateLineItem(data.payload.order_item_code, data.payload.order_item_name,
        data.payload.order_item_parent_code, data.payload.description)
        .pipe(map((responseData: any) => {
            return new UpdateLineItemSuccess({lineItem: responseData.data, order_item_parent_code: data.payload.order_item_parent_code});
          }),
          catchError(error => of(new UpdateLineItemFailure(error.error)))
        )
    )
  );

  @Effect()
  loadDeliverableStatus = this.actions$.pipe(ofType(CommonActionTypes.LOAD_DELIVERABLE_STATUS),
    mergeMap((action: LoadDeliverableStatus) => {
        return this.adminService.getDeliverableStatus(action.payload).pipe(map((response: any) => {
            return new LoadDeliverableStatusSuccess(response.data);
          }),
          catchError((error) => {
            return of(new LoadDeliverableStatusFailure(error.error));
          }));
      }
    ));

  @Effect()
  addDeliverableStatus = this.actions$.pipe(ofType(CommonActionTypes.ADD_DELIVERABLE_STATUS),
    switchMap((data: AddDeliverableStatus) =>
      this.adminService.addDeliverableStatus(data.payload.deliverable_status_name, data.payload.deliverable_status_parent_code, data.payload.description)
        .pipe(map((response: any) => {
            response = response.data;
            return new AddDeliverableStatusSuccess({
              deliverableStatus: response,
              deliverable_status_parent_code: data.payload.deliverable_status_parent_code
            });
          }),
          catchError(error =>
            of(new AddDeliverableStatusFailure(error.error))))
    )
  );

  @Effect()
  deleteDeliverableStatus = this.actions$.pipe(
    ofType(CommonActionTypes.DELETE_DELIVERABLE_STATUS),
    mergeMap(
      (data: DeleteDeliverableStatus) => this.adminService.deleteDeliverableStatus(data.payload.deliverable_status_code)
        .pipe(
          map(() => new DeleteDeliverableStatusSuccess({deliverable_status_code: data.payload.deliverable_status_code})),
          catchError(error => of(new DeleteDeliverableStatusFailure(error.error)))
        )
    )
  );

  @Effect()
  updateDeliverableStatus = this.actions$.pipe(
    ofType(CommonActionTypes.UPDATE_DELIVERABLE_STATUS),
    mergeMap(
      (data: UpdateDeliverableStatus) => this.adminService.updateDeliverableStatus(data.payload.deliverable_status_code, data.payload.deliverable_status_name,
        data.payload.deliverable_status_parent_code, data.payload.description)
        .pipe(map((responseData: any) => {
            return new UpdateDeliverableStatusSuccess({
              deliverableStatus: responseData.data[0],
              deliverable_status_parent_code: data.payload.deliverable_status_parent_code
            });
          }),
          catchError(error => of(new UpdateDeliverableStatusFailure(error.error)))
        )
    )
  );

  @Effect()
  loadCostCenter = this.actions$.pipe(ofType(CommonActionTypes.LOAD_COST_CENTER),
    mergeMap((action: LoadCostCenter) => {
        return this.adminService.getCostCenter(action.payload).pipe(map((response: any) => {
            return new LoadCostCenterSuccess(response.data);
          }),
          catchError((error) => {
            return of(new LoadCostCenterFailure(error.error));
          }));
      }
    ));

  @Effect()
  addCostCenter = this.actions$.pipe(ofType(CommonActionTypes.ADD_COST_CENTER),
    switchMap((data: AddCostCenter) =>
      this.adminService.addCostCenter(data.payload.cost_center_name)
        .pipe(map((response: any) => {
            response = response.data;
            return new AddCostCenterSuccess({costCenter: response});
          }),
          catchError(error =>
            of(new AddCostCenterFailure(error.error))))
    )
  );

  @Effect()
  deleteCostCenter = this.actions$.pipe(
    ofType(CommonActionTypes.DELETE_COST_CENTER),
    mergeMap(
      (data: DeleteCostCenter) => this.adminService.deleteCostCenter(data.payload.cost_center_code)
        .pipe(
          map(() => new DeleteCostCenterSuccess({cost_center_code: data.payload.cost_center_code})),
          catchError(error => of(new DeleteCostCenterFailure(error.error)))
        )
    )
  );

  @Effect()
  updateCostCenter = this.actions$.pipe(
    ofType(CommonActionTypes.UPDATE_COST_CENTER),
    mergeMap(
      (data: UpdateCostCenter) => this.adminService.updateCostCenter(data.payload.cost_center_code, data.payload.cost_center_name)
        .pipe(map((responseData: any) => {
            return new UpdateCostCenterSuccess({costCenter: responseData.data[0]});
          }),
          catchError(error => of(new UpdateCostCenterFailure(error.error)))
        )
    )
  );

  @Effect()
  loadSubscriptionStage = this.actions$.pipe(ofType(CommonActionTypes.LOAD_SUBSCRIPTION_STAGE),
    mergeMap((action: LoadSubscriptionStage) => {
        return this.adminService.getSubscriptionStage(action.payload).pipe(map((response: any) => {
            return new LoadSubscriptionStageSuccess({data: response.data});
          }),
          catchError((error) => {
            return of(new LoadSubscriptionStageFailure(error.error));
          }));
      }
    ));

  @Effect()
  loadCurrency = this.actions$.pipe(ofType(CommonActionTypes.LOAD_CURRENCY),
    mergeMap((action: LoadCurrency) => {
        return this.adminService.getCurrency(action.payload).pipe(map((response: any) => {
            return new LoadCurrencySuccess({data: response.data});
          }),
          catchError((error) => {
            return of(new LoadCurrencyFailure(error.error));
          }));
      }
    ));

  @Effect()
  loadProjectSourceStatus = this.actions$.pipe(ofType(CommonActionTypes.LOAD_PROJECT_SOURCE_STATUS),
    mergeMap(() => {
        return this.adminService.getProjectSourceStatus().pipe(map((response: any) => {
            return new LoadProjectSourceStatusSuccess(response.data);
          }),
          catchError((error) => {
            return of(new LoadProjectSourceStatusFailure(error.error));
          }));
      }
    ));

  @Effect()
  loadArticleType = this.actions$.pipe(ofType(CommonActionTypes.LOAD_ARTICLE_TYPE),
    mergeMap((action: LoadArticleType) => {
        return this.adminService.getArticleType(action.payload).pipe(map((response: any) => {
            return new LoadArticleTypeSuccess({data: response.data});
          }),
          catchError((error) => {
            return of(new LoadArticleTypeFailure(error.error));
          }));
      }
    ));

  @Effect()
  loadEmailTemplate = this.actions$.pipe(ofType(CommonActionTypes.LOAD_EMAIL_TEMPLATE),
    mergeMap((action: LoadEmailTemplate) => {
        return this.adminService.getEmailTemplate(action.payload).pipe(map((response: any) => {
            return new LoadEmailTemplateSuccess(response.data);
          }),
          catchError((error) => {
            return of(new LoadEmailTemplateFailure(error.error));
          }));
      }
    ));

  @Effect()
  addEmailTemplate = this.actions$.pipe(ofType(CommonActionTypes.ADD_EMAIL_TEMPLATE),
    switchMap((data: AddEmailTemplate) =>
      this.adminService.addEmailTemplate(data.payload.email_template_name, data.payload.email_template_subject, data.payload.email_template_content,
        data.payload.is_html_yn, data.payload.parent_email_template_code, data.payload.email_category_code)
        .pipe(map((response: any) => {
            response = response.data;
            return new AddEmailTemplateSuccess({
              emailTemplate: response,
              email_template_parent_code: data.payload.parent_email_template_code
            });
          }),
          catchError(error =>
            of(new AddEmailTemplateFailure(error.error))))
    )
  );

  @Effect()
  deleteEmailTemplate = this.actions$.pipe(
    ofType(CommonActionTypes.DELETE_EMAIL_TEMPLATE),
    mergeMap(
      (data: DeleteEmailTemplate) => this.adminService.deleteEmailTemplate(data.payload.email_template_code)
        .pipe(
          map(() => new DeleteEmailTemplateSuccess({email_template_code: data.payload.email_template_code})),
          catchError(error => of(new DeleteEmailTemplateFailure(error.error)))
        )
    )
  );

  @Effect()
  updateEmailTemplate = this.actions$.pipe(
    ofType(CommonActionTypes.UPDATE_EMAIL_TEMPLATE),
    mergeMap(
      (data: UpdateEmailTemplate) => this.adminService.updateEmailTemplate(data.payload.email_template_code,
        data.payload.email_template_name, data.payload.email_template_subject, data.payload.email_template_content,
        data.payload.is_html_yn, data.payload.parent_email_template_code, data.payload.email_category_code)
        .pipe(map((responseData: any) => {
            return new UpdateEmailTemplateSuccess({
              emailTemplate: responseData.data[0],
              parent_email_template_code: data.payload.parent_email_template_code
            });
          }),
          catchError(error => of(new UpdateEmailTemplateFailure(error.error)))
        )
    )
  );

  @Effect()
  loadEmailLog = this.actions$.pipe(ofType(CommonActionTypes.LOAD_EMAIL_LOG),
    mergeMap((action: LoadEmailLog) => {
        return this.adminService.getEmailLog(action.payload).pipe(map((response: any) => {
            return new LoadEmailLogSuccess(response.data);
          }),
          catchError((error) => {
            return of(new LoadEmailLogFailure(error.error));
          }));
      }
    ));

  @Effect()
  loadFilteredEmailLog = this.actions$.pipe(ofType(CommonActionTypes.FILTER_EMAIL_LOG),
    mergeMap((action: FilterEmailLog) => {
        return this.adminService.getFilteredEmailLog(action.payload).pipe(map((response: any) => {
            return new FilterEmailLogSuccess(response.data);
          }),
          catchError((error) => {
            return of(new FilterEmailLogFailure(error.error));
          }));
      }
    ));

  @Effect()
  loadDeliverableItemDate = this.actions$.pipe(ofType(CommonActionTypes.LOAD_DELIVERABLE_ITEM_DATE, CommonActionTypes.ADD_DELIVERABLE_ITEM_DATE_SUCCESS, CommonActionTypes.UPDATE_DELIVERABLE_ITEM_DATE_SUCCESS),
    mergeMap((action: LoadDeliverableItemDate) => {
        return this.commonService.getDeliverableItemDate().pipe(map((response: any) => {
            return new LoadDeliverableItemDateSuccess({result: response.data});
          }),
          catchError((error) => {
            return of(new LoadDeliverableItemDateFailure(error.error));
          }));
      }
    ));

  @Effect()
  addDeliverableItemDate = this.actions$.pipe(ofType(CommonActionTypes.ADD_DELIVERABLE_ITEM_DATE),
    switchMap((data: AddDeliverableItemDate) =>
      this.adminService.addDeliverableItemDate(data.payload.deliverable_item_date_name, data.payload.filter_color, data.payload.short_code,
        data.payload.filter_text_color, data.payload.rank_order)
        .pipe(map((response: any) => {
            response = response.data;
            return new AddDeliverableItemDateSuccess({
              deliverableItemDate: response
            });
          }),
          catchError(error =>
            of(new AddDeliverableItemDateFailure(error.error))))
    )
  );

  @Effect()
  updateDeliverableItemDate = this.actions$.pipe(
    ofType(CommonActionTypes.UPDATE_DELIVERABLE_ITEM_DATE),
    mergeMap(
      (data: UpdateDeliverableItemDate) => this.adminService.updateDeliverableItemDate(data.payload.deliverable_item_date_code,
        data.payload.deliverable_item_date_name, data.payload.filter_color, data.payload.short_code, data.payload.filter_text_color, data.payload.rank_order)
        .pipe(map((responseData: any) => {
            return new UpdateDeliverableItemDateSuccess({
              deliverableItemDate: responseData.data,
              deliverable_item_date_code: data.payload.deliverable_item_date_code
            });
          }),
          catchError(error => of(new UpdateDeliverableItemDateFailure(error.error)))
        )
    )
  );

  @Effect()
  loadSocialMediaPlatforms = this.actions$.pipe(ofType(CommonActionTypes.LOAD_SOCIAL_MEDIA_PLATFORM),
    mergeMap((action: LoadSocialMediaPlatform) => {
        return this.commonService.getSocialMediaPlatform().pipe(map((response: any) => {
            return new LoadSocialMediaPlatformSuccess({result: response.data});
          }),
          catchError((error) => {
            return of(new LoadSocialMediaPlatformFailure(error.error));
          }));
      }
    ));

  @Effect()
  loadRegions = this.actions$.pipe(ofType(CommonActionTypes.LOAD_REGION),
    mergeMap((action: LoadRegions) => {
        const errorFlag: Error = new Error();
        errorFlag.message = 'Multiple load Country requests found. Ignoring last request ';
        if (this.isRunningRegions) {
          return of(new LoadRegionsFailure(errorFlag));
        }
        this.isRunningRegions = true;
        return this.commonService.getRegions().pipe(map((response: any) => {
            // this.isRunningCountry = false;
            return new LoadRegionsSuccess(response.data);
          }),
          catchError((error) => {
            // this.isRunningCountry = false;
            return of(new LoadRegionsFailure(error.error));
          }));
      }
    ));

  @Effect()
  loadOrderStatus = this.actions$.pipe(ofType(CommonActionTypes.LOAD_ORDER_STATUS),
    mergeMap((action: LoadOrderStatus) => {
        return this.commonService.getOrderStatus().pipe(map((response: any) => {
            return new LoadOrderStatusSuccess({result: response.data});
          }),
          catchError((error) => {
            return of(new LoadOrderStatusFailure(error.error));
          }));
      }
    ));


  @Effect()
  loadCommunityService = this.actions$.pipe(ofType(CommonActionTypes.LOAD_COMMUNITY_SERVICE),
    mergeMap((action: LoadCommunityService) => {
        return this.commonService.getCommunityService().pipe(map((response: any) => {
            return new LoadCommunityServiceSuccess(response.data);
          }),
          catchError((error) => {
            return of(new LoadCommunityServiceFailure(error.error));
          }));
      }
    ));

  @Effect()
  loadProductTypes = this.actions$.pipe(ofType(CommonActionTypes.LOAD_PRODUCT_TYPE),
    mergeMap((action: LoadProductType) => {
        return this.commonService.getProductType().pipe(map((response: any) => {
            return new LoadProductTypeSuccess(response.data);
          }),
          catchError((error) => {
            return of(new LoadProductTypeFailure(error.error));
          }));
      }
    ));

  @Effect()
  loadCommunityTypes = this.actions$.pipe(ofType(CommonActionTypes.LOAD_COMMUNITY_TYPE),
    mergeMap((action: LoadCommunityType) => {
        return this.commonService.getCommunityType().pipe(map((response: any) => {
            return new LoadCommunityTypeSuccess(response.data);
          }),
          catchError((error) => {
            return of(new LoadCommunityTypeFailure(error.error));
          }));
      }
    ));

  constructor(private actions$: Actions, private commonService: CommonService, private adminService: AdminService) {
  }
}
