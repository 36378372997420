import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {SidebarComponent} from '@syncfusion/ej2-angular-navigations';
import {CommonService} from '../../../../core/services/common.service';
import {TreeGridComponent} from '@syncfusion/ej2-angular-treegrid';
import {GridComponent} from '@syncfusion/ej2-angular-grids';

@Component({
  selector: 'app-email-keywords',
  templateUrl: './email-keywords.component.html',
  styleUrls: ['./email-keywords.component.scss']
})
export class EmailKeywordsComponent implements OnInit {

  @ViewChild('sidebarInstance') sidebarInstance: SidebarComponent;
  @ViewChild('grid') public grid: GridComponent;


  emailKeywords = [];

  public width = 'auto';
  public position = 'Right';

  groupOptions = { columns: ['application_category.application_category_name'] };

  constructor(private commonService: CommonService) { }

  ngOnInit(): void {
      this.commonService.getEmailKeywords().subscribe((data) => {
        if (data.data) {
          this.emailKeywords = data.data;
          this.grid.autoFitColumns(['description', 'email_keyword']);
        }
      });
  }

  closeSidebar(): void {
    this.sidebarInstance.hide();
  }

  openSidebar(): void {
    this.sidebarInstance.show();
  }

}
