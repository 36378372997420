import {ProjectStatusActions, ProjectStatusActionsType} from '../actions/project-status.actions';

export interface ProjectStatusState {
  projectStatus?: {
    result?: any,
    total_count?: number,
    page_no?: number,
    page_size?: number
  };
  error?: Error;
  loading: boolean;
  loaded: boolean;
  success?: boolean;
}

export const initialState: ProjectStatusState = {
  projectStatus: {
    result: [],
    total_count: 0,
    page_no: 0,
    page_size: 0
  },
  error: undefined,
  loading: false,
  loaded: false,
};

export const ReturnLoading = (State: ProjectStatusState) => {
  return {
    ...State,
    loading: true,
    loaded: false,
    error: undefined,
    success: undefined
  };
};

export function projectStatusReducer(state: ProjectStatusState = initialState, action: ProjectStatusActions): ProjectStatusState {
  switch (action.type) {

    case ProjectStatusActionsType.LOAD_PROJECT_STATUS: {
      return ReturnLoading(state);
    }

    case ProjectStatusActionsType.LOAD_PROJECT_STATUS_SUCCESS: {
      let projectStatusList = [...state.projectStatus.result, ...action.payload.result];

      // projectStatusList = childrenFunction(projectStatusList, action.payload.result, null);
      projectStatusList = projectStatusList.filter((thing, index, self) =>
        index === self.findIndex((t) => (
          t.status_code === thing.status_code && t.status_name === thing.status_name
        ))
      );

      state = {
        ...state, projectStatus: {
          result: projectStatusList, page_no: action.payload.page_no, page_size: action.payload.page_size,
          total_count: action.payload.total_count
        }, loading: false, error: undefined, loaded: true,
      };

      return state;
    }

    case ProjectStatusActionsType.LOAD_PROJECT_STATUS_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case ProjectStatusActionsType.ADD_PROJECT_STATUS: {
      return ReturnLoading(state);
    }

    case ProjectStatusActionsType.ADD_PROJECT_STATUS_SUCCESS: {
      let newProjectStatus;
      if (action.payload.parentCode) {
        const parentProjectStatus = {...find([...state.projectStatus.result], action.payload.parentCode)};
        const childrenArray = [...parentProjectStatus.children];
        childrenArray.push(action.payload.projectStatus);
        parentProjectStatus.children = childrenArray;
        const findParent = findAndReplace([...state.projectStatus.result], action.payload.parentCode, parentProjectStatus);
        newProjectStatus = findParent;
      } else {
        newProjectStatus = [action.payload.projectStatus, ...state.projectStatus.result];
      }
      return {
        ...state, loading: false, projectStatus: {
          result: newProjectStatus
        }, error: undefined, loaded: true,
        success: true
      };
    }

    case ProjectStatusActionsType.ADD_PROJECT_STATUS_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }


    case ProjectStatusActionsType.UPDATE_PROJECT_STATUS: {
      return ReturnLoading(state);
    }

    case ProjectStatusActionsType.UPDATE_PROJECT_STATUS_SUCCESS: {
      const projectStatusList = findAndDelete([...state.projectStatus.result], action.payload.projectStatus.status_code);
      let newProjectStatus;
      if (action.payload.parent_type_code) {
        const parentProjectStatus = {...find(projectStatusList, action.payload.parent_type_code)};
        const childrenArray = [...parentProjectStatus.children];
        childrenArray.push(action.payload.projectStatus);
        parentProjectStatus.children = childrenArray;
        const findParent = findAndReplace(projectStatusList, action.payload.parent_type_code, parentProjectStatus);
        newProjectStatus = findParent;
      } else {
        newProjectStatus = [action.payload.projectStatus, ...projectStatusList];
      }

      return {
        ...state, projectStatus: {result : newProjectStatus}, error: undefined, loading: false, loaded: true,
        success: true
      };
    }

    case ProjectStatusActionsType.UPDATE_PROJECT_STATUS_FAILURE:
      return {...state, error: action.payload, loading: false, loaded: false};


    case ProjectStatusActionsType.DELETE_PROJECT_STATUS:
      return ReturnLoading(state);

    case ProjectStatusActionsType.DELETE_PROJECT_STATUS_SUCCESS: {
      const projectStatusList = findAndDelete([...state.projectStatus.result], action.payload.projectStatusCode);

      return {
        ...state,
        projectStatus: {result: projectStatusList},
        loading: false,
        error: undefined,
        loaded: true
      };
    }

    case ProjectStatusActionsType.DELETE_PROJECT_STATUS_FAILURE:
      return {...state, error: action.payload, loading: false, loaded: false};

    case ProjectStatusActionsType.RESET_ERROR:
      return ReturnLoading(state);

    default:
      return state;
  }
}

function find(array, id) {
  let result;
  array.some(o => result = o.status_code === id ? o : find(o.children || [], id));
  return result;
}

function findAndReplace(projectStatusList, findValue, replaceValue) {
  for (const projectStatus in projectStatusList) {
    if (projectStatusList[projectStatus].status_code === findValue) {
      projectStatusList[projectStatus] = replaceValue;
      return projectStatusList;
    }

    if (projectStatusList[projectStatus].children) {
      const children = findAndReplace([...projectStatusList[projectStatus].children], findValue, replaceValue);
      const companyRoleListChildCopy: any = {...projectStatusList[projectStatus]};
      companyRoleListChildCopy.children = children;
      projectStatusList[projectStatus] = companyRoleListChildCopy;
    }
  }
  return projectStatusList;
}

function findAndDelete(projectStatusList, findValue) {
  for (const projectStatus in projectStatusList) {
    if (projectStatusList[projectStatus].status_code === findValue) {
      delete projectStatusList[projectStatus];
      const projectStatusArray = projectStatusList.filter((el) => {
        return el != null;
      });
      return projectStatusArray;
    }

    if (projectStatusList[projectStatus].children) {
      const children = findAndDelete([...projectStatusList[projectStatus].children], findValue);
      const projectStatusListChildCopy: any = {...projectStatusList[projectStatus]};
      projectStatusListChildCopy.children = children;
      projectStatusList[projectStatus] = projectStatusListChildCopy;
    }
  }
  return projectStatusList;
}

