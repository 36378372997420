<div fxLayout="column" *ngIf="src">
  <ng-container *ngFor="let notification of src.notifications.reverse()">
    <div fxFlex class="card card-custom gutter-b magic-notifier no-border" [@notify]="'show'">
<!--      <ejs-progressbar #linear id='linear' class="custom-progress" type='Linear'  [value]='notification.message.progressValue'-->
<!--                       *ngIf="notification.message.progressValue > 0 && notification.message.progressValue <= 100">-->
<!--      </ejs-progressbar>-->
<!--      <mat-progress-bar class="custom-progress" mode="determinate" [value]="notification.message.progressValue" color="warn"-->
<!--                        *ngIf="notification.message.progressValue > 0 && notification.message.progressValue <= 100"></mat-progress-bar>-->
      <div fxLayout="column" class="px-4 pb-4 pt-3">
        <div fxFlex><h4 class="font-level--l4 mb-0"><b>{{notification.message.title}}</b></h4></div>
        <div fxFlex><h5 class="font-level--l5 color-gray mb-4">{{notification.message.subtitle}}</h5></div>
        <div fxFlex class="text-right">
          <button class="float-right btn btn-light btn-text-primary" color="primary" (click)="hardReload()">
            Refresh
          </button>
        </div>
      </div>
      <button type='button' class="close-modal" *ngIf="notification.type !== 'reload'" (click)="destroyNotification();src.destroy(notification)">
        <mat-icon svgIcon="inv-times-circle" class="inv-icon inv-icon-size--small color-grey"></mat-icon>
      </button>
    </div>
  </ng-container>
</div>
