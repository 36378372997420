import {Action} from '@ngrx/store';
import {Tags} from '../../models/tags.model';
import {User} from '../../models/user.model';
import {Country} from '../../models/country.model';
import {DeliverableItem} from '../../models/deliverable-item.model';
import {OrderItem} from '../../models/order-item.model';
import {DeliverableStatus} from '../../models/deliverable-status.model';
import {CostCenter} from '../../models/cost-center.model';
import {SubscriptionStage} from '../../models/subscription-stage.model';
import {ProjectSourceStatus} from '../../models/project-source-status.model';
import {EmailTemplate} from '../../models/email-template.model';
import {EmailLog} from '../../models/email-log.model';
import {DeliverableItemDate} from '../../models/admin/deliverable-item-date.model';

export enum CommonActionTypes {
  LOAD_TAGS = '[TAGS] Load Action',
  LOAD_TAGS_SUCCESS = '[TAGS] Load Tags Success Action',
  LOAD_TAGS_FAILURE = '[TAGS] Load Tags Failure Action',

  LOAD_COUNTRY = '[COUNTRY] Load Country Action',
  LOAD_COUNTRY_SUCCESS = '[COUNTRY] Load Country Success Action',
  LOAD_COUNTRY_FAILURE = '[COUNTRY] Load Country Failure Action',

  LOAD_USER = '[USER] Load User Action',
  LOAD_USER_SUCCESS = '[USER] Load User Success Action',
  LOAD_USER_FAILURE = '[USER] Load User Failure Action',

  // DELIVERABLE ITEMS
  LOAD_DELIVERABLE_ITEMS = '[DELIVERABLE ITEMS] Load Deliverable Items Action',
  LOAD_DELIVERABLE_ITEMS_SUCCESS = '[DELIVERABLE ITEMS] Load Deliverable Items Success Action',
  LOAD_DELIVERABLE_ITEMS_FAILURE = '[DELIVERABLE ITEMS] Load Deliverable Items Failure Action',

  ADD_DELIVERABLE_ITEM = '[DELIVERABLE ITEMS] Add Deliverable Items Action',
  ADD_DELIVERABLE_ITEMS_SUCCESS = '[DELIVERABLE ITEMS] Add Deliverable Items Success Action',
  ADD_DELIVERABLE_ITEMS_FAILURE = '[DELIVERABLE ITEMS] Add Deliverable Items Failure Action',

  UPDATE_DELIVERABLE_ITEMS = '[DELIVERABLE ITEMS] Update Deliverable Items Action',
  UPDATE_DELIVERABLE_ITEMS_SUCCESS = '[DELIVERABLE ITEMS] Update Deliverable Items Success Action',
  UPDATE_DELIVERABLE_ITEMS_FAILURE = '[DELIVERABLE ITEMS] Update Deliverable Items Failure Action',

  DELETE_DELIVERABLE_ITEM = '[DELIVERABLE ITEMS] Delete Deliverable Items Action',
  DELETE_DELIVERABLE_ITEMS_SUCCESS = '[DELIVERABLE ITEMS] Delete Deliverable Items Success Action',
  DELETE_DELIVERABLE_ITEMS_FAILURE = '[DELIVERABLE ITEMS] Delete Deliverable Items Failure Action',

  // LINE ITEMS
  LOAD_LINE_ITEM = '[LINE ITEMS] Load Line Items Action',
  LOAD_LINE_ITEM_SUCCESS = '[LINE ITEMS] Load Line Items Success Action',
  LOAD_LINE_ITEM_FAILURE = '[LINE ITEMS] Load Line Items Failure Action',

  ADD_LINE_ITEM = '[LINE ITEMS] Add Line Items Action',
  ADD_LINE_ITEM_SUCCESS = '[LINE ITEMS] Add Line Items Success Action',
  ADD_LINE_ITEM_FAILURE = '[LINE ITEMS] Add Line Items Failure Action',

  UPDATE_LINE_ITEM = '[LINE ITEMS] Update Line Items Action',
  UPDATE_LINE_ITEM_SUCCESS = '[LINE ITEMS] Update Line Items Success Action',
  UPDATE_LINE_ITEM_FAILURE = '[LINE ITEMS] Update Line Items Failure Action',

  DELETE_LINE_ITEM = '[LINE ITEMS] Delete Line Items Action',
  DELETE_LINE_ITEM_SUCCESS = '[LINE ITEMS] Delete Line Items Success Action',
  DELETE_LINE_ITEM_FAILURE = '[LINE ITEMS] Delete Line Items Failure Action',

  // DELIVERABLE STATUS
  LOAD_DELIVERABLE_STATUS = '[DELIVERABLE STATUS] Load Deliverable Status Action',
  LOAD_DELIVERABLE_STATUS_SUCCESS = '[DELIVERABLE STATUS] Load Deliverable Status Success Action',
  LOAD_DELIVERABLE_STATUS_FAILURE = '[DELIVERABLE STATUS] Load Deliverable Status Failure Action',

  ADD_DELIVERABLE_STATUS = '[DELIVERABLE STATUS] Add Deliverable Status Action',
  ADD_DELIVERABLE_STATUS_SUCCESS = '[DELIVERABLE STATUS] Add Deliverable Status Success Action',
  ADD_DELIVERABLE_STATUS_FAILURE = '[DELIVERABLE STATUS] Add Deliverable Status Failure Action',

  UPDATE_DELIVERABLE_STATUS = '[DELIVERABLE STATUS] Update Deliverable Status Action',
  UPDATE_DELIVERABLE_STATUS_SUCCESS = '[DELIVERABLE STATUS] Update Deliverable Status Success Action',
  UPDATE_DELIVERABLE_STATUS_FAILURE = '[DELIVERABLE STATUS] Update Deliverable Status Failure Action',

  DELETE_DELIVERABLE_STATUS = '[DELIVERABLE STATUS] Delete Deliverable Status Action',
  DELETE_DELIVERABLE_STATUS_SUCCESS = '[DELIVERABLE STATUS] Delete Deliverable Status Success Action',
  DELETE_DELIVERABLE_STATUS_FAILURE = '[DELIVERABLE STATUS] Delete Deliverable Status Failure Action',

  // COST CENTER
  LOAD_COST_CENTER = '[COST CENTER] Load Cost Center Action',
  LOAD_COST_CENTER_SUCCESS = '[COST CENTER] Load Cost Center Success Action',
  LOAD_COST_CENTER_FAILURE = '[COST CENTER] Load Cost Center Failure Action',

  ADD_COST_CENTER = '[COST CENTER] Add Cost Center Action',
  ADD_COST_CENTER_SUCCESS = '[COST CENTER] Add Cost Center Success Action',
  ADD_COST_CENTER_FAILURE = '[COST CENTER] Add Cost Center Failure Action',

  UPDATE_COST_CENTER = '[COST CENTER] Update Cost Center Action',
  UPDATE_COST_CENTER_SUCCESS = '[COST CENTER] Update Cost Center Success Action',
  UPDATE_COST_CENTER_FAILURE = '[COST CENTER] Update Cost Center Failure Action',

  DELETE_COST_CENTER = '[COST CENTER] Delete Cost Center Action',
  DELETE_COST_CENTER_SUCCESS = '[COST CENTER] Delete Cost Center Success Action',
  DELETE_COST_CENTER_FAILURE = '[COST CENTER] Delete Cost Center Failure Action',

  // Subscription Stage
  LOAD_SUBSCRIPTION_STAGE = '[SUBSCRIPTION STAGE] Load Subscription Stage Action',
  LOAD_SUBSCRIPTION_STAGE_SUCCESS = '[SUBSCRIPTION STAGE] Load Subscription Stage Success Action',
  LOAD_SUBSCRIPTION_STAGE_FAILURE = '[SUBSCRIPTION STAGE] Load Subscription Stage Failure Action',

  // Currency
  LOAD_CURRENCY = '[CURRENCY] Load Currency Action',
  LOAD_CURRENCY_SUCCESS = '[CURRENCY] Load Currency Success Action',
  LOAD_CURRENCY_FAILURE = '[CURRENCY] Load Currency Failure Action',

  // Project Source Status
  LOAD_PROJECT_SOURCE_STATUS = '[PROJECT SOURCE STATUS] Load Project Source Status Action',
  LOAD_PROJECT_SOURCE_STATUS_SUCCESS = '[PROJECT SOURCE STATUS] Load Project Source Status Success Action',
  LOAD_PROJECT_SOURCE_STATUS_FAILURE = '[PROJECT SOURCE STATUS] Load Project Source Status Failure Action',

  // Article Type
  LOAD_ARTICLE_TYPE = '[ARTICLE TYPE] Load Article Type Action',
  LOAD_ARTICLE_TYPE_SUCCESS = '[ARTICLE TYPE] Load Article Type Success Action',
  LOAD_ARTICLE_TYPE_FAILURE = '[ARTICLE TYPE] Load Article Type Failure Action',

  // EMAIL TEMPLATE
  LOAD_EMAIL_TEMPLATE = '[EMAIL TEMPLATE] Load Email Template Action',
  LOAD_EMAIL_TEMPLATE_SUCCESS = '[EMAIL TEMPLATE] Load Email Template Success Action',
  LOAD_EMAIL_TEMPLATE_FAILURE = '[EMAIL TEMPLATE] Load Email Template Failure Action',

  ADD_EMAIL_TEMPLATE = '[EMAIL TEMPLATE] Add Email Template Action',
  ADD_EMAIL_TEMPLATE_SUCCESS = '[EMAIL TEMPLATE] Add Email Template Success Action',
  ADD_EMAIL_TEMPLATE_FAILURE = '[EMAIL TEMPLATE] Add Email Template Failure Action',

  UPDATE_EMAIL_TEMPLATE = '[EMAIL TEMPLATE] Update Email Template Action',
  UPDATE_EMAIL_TEMPLATE_SUCCESS = '[EMAIL TEMPLATE] Update Email Template Success Action',
  UPDATE_EMAIL_TEMPLATE_FAILURE = '[EMAIL TEMPLATE] Update Email Template Failure Action',

  DELETE_EMAIL_TEMPLATE = '[EMAIL TEMPLATE] Delete Email Template Action',
  DELETE_EMAIL_TEMPLATE_SUCCESS = '[EMAIL TEMPLATE] Delete Email Template Success Action',
  DELETE_EMAIL_TEMPLATE_FAILURE = '[EMAIL TEMPLATE] Delete Email Template Failure Action',

  // EMAIL LOGS
  LOAD_EMAIL_LOG = '[EMAIL LOG] Load Email Log Action',
  LOAD_EMAIL_LOG_SUCCESS = '[EMAIL LOG] Load Email Log Success Action',
  LOAD_EMAIL_LOG_FAILURE = '[EMAIL LOG] Load Email Log Failure Action',

  FILTER_EMAIL_LOG = '[EMAIL LOG] Filter Email Log Action',
  FILTER_EMAIL_LOG_SUCCESS = '[EMAIL LOG] Filter Email Log Success Action',
  FILTER_EMAIL_LOG_FAILURE = '[EMAIL LOG] Filter Email Log Failure Action',

  // DELIVERABLE ITEM DATE
  LOAD_DELIVERABLE_ITEM_DATE = '[DELIVERABLE ITEM DATE] Load Deliverable Item Date Action',
  LOAD_DELIVERABLE_ITEM_DATE_SUCCESS = '[DELIVERABLE ITEM DATE] Load Deliverable Item Date Success Action',
  LOAD_DELIVERABLE_ITEM_DATE_FAILURE = '[DELIVERABLE ITEM DATE] Load Deliverable Item Date Failure Action',

  ADD_DELIVERABLE_ITEM_DATE = '[DELIVERABLE ITEM DATE] Add Deliverable Item Date Action',
  ADD_DELIVERABLE_ITEM_DATE_SUCCESS = '[DELIVERABLE ITEM DATE] Add Deliverable Item Date Success Action',
  ADD_DELIVERABLE_ITEM_DATE_FAILURE = '[DELIVERABLE ITEM DATE] Add Deliverable Item Date Failure Action',

  UPDATE_DELIVERABLE_ITEM_DATE = '[DELIVERABLE ITEM DATE] Update Deliverable Item Date Action',
  UPDATE_DELIVERABLE_ITEM_DATE_SUCCESS = '[DELIVERABLE ITEM DATE] Update Deliverable Item Date Success Action',
  UPDATE_DELIVERABLE_ITEM_DATE_FAILURE = '[DELIVERABLE ITEM DATE] Update Deliverable Item Date Failure Action',

  LOAD_SOCIAL_MEDIA_PLATFORM = '[SOCIAL MEDIA PLATFORM] Load Social Media Platform Action',
  LOAD_SOCIAL_MEDIA_PLATFORM_SUCCESS = '[SOCIAL MEDIA PLATFORM] Load Social Media Platform Success Action',
  LOAD_SOCIAL_MEDIA_PLATFORM_FAILURE = '[SOCIAL MEDIA PLATFORM] Load Social Media Platform Failure Action',

  LOAD_ORDER_STATUS = '[ORDER STATUS] Load Order Status Action',
  LOAD_ORDER_STATUS_SUCCESS = '[ORDER STATUS] Load Order Status Success Action',
  LOAD_ORDER_STATUS_FAILURE = '[ORDER STATUS] Load Order Status Failure Action',

  LOAD_REGION = '[REGION] Load Region Action',
  LOAD_REGION_SUCCESS = '[REGION] Load Region Success Action',
  LOAD_REGION_FAILURE = '[REGION] Load Region Failure Action',

  LOAD_COMMUNITY_SERVICE = '[COMMUNITY SERVICE] Load Community Service Action',
  LOAD_COMMUNITY_SERVICE_SUCCESS = '[COMMUNITY SERVICE] Load Community Service Success Action',
  LOAD_COMMUNITY_SERVICE_FAILURE = '[COMMUNITY SERVICE] Load Community Service Failure Action',

  LOAD_PRODUCT_TYPE = '[PRODUCT TYPE] Load Product Type Action',
  LOAD_PRODUCT_TYPE_SUCCESS = '[PRODUCT TYPE] Load Product Type Success Action',
  LOAD_PRODUCT_TYPE_FAILURE = '[PRODUCT TYPE] Load Product Type Failure Action',

  LOAD_COMMUNITY_TYPE = '[COMMUNITY TYPE] Load Community Type Action',
  LOAD_COMMUNITY_TYPE_SUCCESS = '[COMMUNITY TYPE] Load Community Type Success Action',
  LOAD_COMMUNITY_TYPE_FAILURE = '[COMMUNITY TYPE] Load Community Type Failure Action',


  RESET_ERROR = '[DELIVERABLE ITEM] Reset Error Action',
}

export class LoadTags implements Action {
  readonly type = CommonActionTypes.LOAD_TAGS;
}

export class LoadTagsSuccess implements Action {
  readonly type = CommonActionTypes.LOAD_TAGS_SUCCESS;

  constructor(public payload: Array<Tags>) {
  }
}

export class LoadTagsFailure implements Action {
  readonly type = CommonActionTypes.LOAD_TAGS_FAILURE;

  constructor(public payload: Error) {
  }
}

export class LoadUsers implements Action {
  readonly type = CommonActionTypes.LOAD_USER;
}

export class LoadUsersSuccess implements Action {
  readonly type = CommonActionTypes.LOAD_USER_SUCCESS;

  constructor(public payload: Array<User>) {
  }
}

export class LoadUsersFailure implements Action {
  readonly type = CommonActionTypes.LOAD_USER_FAILURE;

  constructor(public payload: Error) {
  }
}

export class LoadCountries implements Action {
  readonly type = CommonActionTypes.LOAD_COUNTRY;
}

export class LoadCountriesSuccess implements Action {
  readonly type = CommonActionTypes.LOAD_COUNTRY_SUCCESS;

  constructor(public payload: Array<Country>) {
  }
}

export class LoadCountriesFailure implements Action {
  readonly type = CommonActionTypes.LOAD_COUNTRY_FAILURE;

  constructor(public payload: Error) {
  }
}

export class LoadDeliverableItems implements Action {
  readonly type = CommonActionTypes.LOAD_DELIVERABLE_ITEMS;

  constructor(public payload: { page_size?: number, page_no?: number }) {
  }
}

export class LoadDeliverableItemsSuccess implements Action {
  readonly type = CommonActionTypes.LOAD_DELIVERABLE_ITEMS_SUCCESS;

  constructor(public payload: { result: Array<DeliverableItem>, total_count?: number, page_size?: number, page_no?: number }) {
  }
}

export class LoadDeliverableItemsFailure implements Action {
  readonly type = CommonActionTypes.LOAD_DELIVERABLE_ITEMS_FAILURE;

  constructor(public payload: Error) {
  }
}

export class AddDeliverableItems implements Action {
  readonly type = CommonActionTypes.ADD_DELIVERABLE_ITEM;

  constructor(public payload: {
    deliverable_name: string, deliverable_item_parent_code: string, description: string,
    deliverable_item_date_code?: []
  }) {
  }
}

export class AddDeliverableItemsSuccess implements Action {
  readonly type = CommonActionTypes.ADD_DELIVERABLE_ITEMS_SUCCESS;

  constructor(public payload: { deliverableItem: DeliverableItem, deliverable_item_parent_code: string }) {
  }
}

export class AddDeliverableItemsFailure implements Action {
  readonly type = CommonActionTypes.ADD_DELIVERABLE_ITEMS_FAILURE;

  constructor(public payload: Error) {
  }
}

export class UpdateDeliverableItems implements Action {
  readonly type = CommonActionTypes.UPDATE_DELIVERABLE_ITEMS;

  constructor(public payload: {
    deliverable_code: string, deliverable_name: string, deliverable_item_parent_code: string,
    description: string, deliverable_item_date_code?: []
  }) {
  }
}

export class UpdateDeliverableItemsSuccess implements Action {
  readonly type = CommonActionTypes.UPDATE_DELIVERABLE_ITEMS_SUCCESS;

  constructor(public payload: { deliverableItem: DeliverableItem, deliverable_item_parent_code: string }) {
  }
}

export class UpdateDeliverableItemsFailure implements Action {
  readonly type = CommonActionTypes.UPDATE_DELIVERABLE_ITEMS_FAILURE;

  constructor(public payload: Error) {
  }
}

export class DeleteDeliverableItem implements Action {
  readonly type = CommonActionTypes.DELETE_DELIVERABLE_ITEM;

  constructor(public payload: { deliverable_item_code: string }) {
  }
}

export class DeleteDeliverableItemsSuccess implements Action {
  readonly type = CommonActionTypes.DELETE_DELIVERABLE_ITEMS_SUCCESS;

  constructor(public payload: { deliverable_item_code: string }) {
  }
}

export class DeleteDeliverableItemsFailure implements Action {
  readonly type = CommonActionTypes.DELETE_DELIVERABLE_ITEMS_FAILURE;

  constructor(public payload: Error) {
  }
}

// Line Items

export class LoadLineItems implements Action {
  readonly type = CommonActionTypes.LOAD_LINE_ITEM;

  constructor(public payload: { page_size?: number, page_no?: number }) {
  }
}

export class LoadLineItemsSuccess implements Action {
  readonly type = CommonActionTypes.LOAD_LINE_ITEM_SUCCESS;

  constructor(public payload: { result: Array<OrderItem>, total_count?: number, page_size?: number, page_no?: number }) {
  }
}

export class LoadLineItemsFailure implements Action {
  readonly type = CommonActionTypes.LOAD_LINE_ITEM_FAILURE;

  constructor(public payload: Error) {
  }
}

export class AddLineItem implements Action {
  readonly type = CommonActionTypes.ADD_LINE_ITEM;

  constructor(public payload: { order_item_name: string, order_item_parent_code: string, description: string }) {
  }
}

export class AddLineItemSuccess implements Action {
  readonly type = CommonActionTypes.ADD_LINE_ITEM_SUCCESS;

  constructor(public payload: { lineItem: OrderItem, order_item_parent_code: string }) {
  }
}

export class AddLineItemFailure implements Action {
  readonly type = CommonActionTypes.ADD_LINE_ITEM_FAILURE;

  constructor(public payload: Error) {
  }
}

export class UpdateLineItem implements Action {
  readonly type = CommonActionTypes.UPDATE_LINE_ITEM;

  constructor(public payload: { order_item_code: string, order_item_name: string, order_item_parent_code: string, description: string }) {
  }
}

export class UpdateLineItemSuccess implements Action {
  readonly type = CommonActionTypes.UPDATE_LINE_ITEM_SUCCESS;

  constructor(public payload: { lineItem: OrderItem, order_item_parent_code: string }) {
  }
}

export class UpdateLineItemFailure implements Action {
  readonly type = CommonActionTypes.UPDATE_LINE_ITEM_FAILURE;

  constructor(public payload: Error) {
  }
}

export class DeleteLineItem implements Action {
  readonly type = CommonActionTypes.DELETE_LINE_ITEM;

  constructor(public payload: { order_item_code: string }) {
  }
}

export class DeleteLineItemSuccess implements Action {
  readonly type = CommonActionTypes.DELETE_LINE_ITEM_SUCCESS;

  constructor(public payload: { line_item_code: string }) {
  }
}

export class DeleteLineItemFailure implements Action {
  readonly type = CommonActionTypes.DELETE_LINE_ITEM_FAILURE;

  constructor(public payload: Error) {
  }
}

// Deliverable Status
export class LoadDeliverableStatus implements Action {
  readonly type = CommonActionTypes.LOAD_DELIVERABLE_STATUS;

  constructor(public payload: { page_size?: number, page_no?: number }) {
  }
}

export class LoadDeliverableStatusSuccess implements Action {
  readonly type = CommonActionTypes.LOAD_DELIVERABLE_STATUS_SUCCESS;

  constructor(public payload: { result: Array<DeliverableStatus>, total_count?: number, page_size?: number, page_no?: number }) {
  }
}

export class LoadDeliverableStatusFailure implements Action {
  readonly type = CommonActionTypes.LOAD_DELIVERABLE_STATUS_FAILURE;

  constructor(public payload: Error) {
  }
}

export class AddDeliverableStatus implements Action {
  readonly type = CommonActionTypes.ADD_DELIVERABLE_STATUS;

  constructor(public payload: { deliverable_status_name: string, deliverable_status_parent_code: string, description: string }) {
  }
}

export class AddDeliverableStatusSuccess implements Action {
  readonly type = CommonActionTypes.ADD_DELIVERABLE_STATUS_SUCCESS;

  constructor(public payload: {
    deliverable_status_parent_code: string,
    deliverableStatus: DeliverableStatus
  }) {
  }
}

export class AddDeliverableStatusFailure implements Action {
  readonly type = CommonActionTypes.ADD_DELIVERABLE_STATUS_FAILURE;

  constructor(public payload: Error) {
  }
}

export class UpdateDeliverableStatus implements Action {
  readonly type = CommonActionTypes.UPDATE_DELIVERABLE_STATUS;

  constructor(public payload: {
    deliverable_status_code: string, deliverable_status_name: string, deliverable_status_parent_code: string,
    description: string
  }) {
  }
}

export class UpdateDeliverableStatusSuccess implements Action {
  readonly type = CommonActionTypes.UPDATE_DELIVERABLE_STATUS_SUCCESS;

  constructor(public payload: { deliverableStatus: DeliverableStatus, deliverable_status_parent_code: string }) {
  }
}

export class UpdateDeliverableStatusFailure implements Action {
  readonly type = CommonActionTypes.UPDATE_DELIVERABLE_STATUS_FAILURE;

  constructor(public payload: Error) {
  }
}

export class DeleteDeliverableStatus implements Action {
  readonly type = CommonActionTypes.DELETE_DELIVERABLE_STATUS;

  constructor(public payload: { deliverable_status_code: string }) {
  }
}

export class DeleteDeliverableStatusSuccess implements Action {
  readonly type = CommonActionTypes.DELETE_DELIVERABLE_STATUS_SUCCESS;

  constructor(public payload: { deliverable_status_code: string }) {
  }
}

export class DeleteDeliverableStatusFailure implements Action {
  readonly type = CommonActionTypes.DELETE_DELIVERABLE_STATUS_FAILURE;

  constructor(public payload: Error) {
  }
}

// Cost Center
export class LoadCostCenter implements Action {
  readonly type = CommonActionTypes.LOAD_COST_CENTER;

  constructor(public payload: { page_size?: number, page_no?: number }) {
  }
}

export class LoadCostCenterSuccess implements Action {
  readonly type = CommonActionTypes.LOAD_COST_CENTER_SUCCESS;

  constructor(public payload: { result: Array<CostCenter>, total_count?: number, page_size?: number, page_no?: number }) {
  }
}

export class LoadCostCenterFailure implements Action {
  readonly type = CommonActionTypes.LOAD_COST_CENTER_FAILURE;

  constructor(public payload: Error) {
  }
}

export class AddCostCenter implements Action {
  readonly type = CommonActionTypes.ADD_COST_CENTER;

  constructor(public payload: { cost_center_name: string }) {
  }
}

export class AddCostCenterSuccess implements Action {
  readonly type = CommonActionTypes.ADD_COST_CENTER_SUCCESS;

  constructor(public payload: { costCenter: CostCenter }) {
  }
}

export class AddCostCenterFailure implements Action {
  readonly type = CommonActionTypes.ADD_COST_CENTER_FAILURE;

  constructor(public payload: Error) {
  }
}

export class UpdateCostCenter implements Action {
  readonly type = CommonActionTypes.UPDATE_COST_CENTER;

  constructor(public payload: { cost_center_code: string, cost_center_name: string }) {
  }
}

export class UpdateCostCenterSuccess implements Action {
  readonly type = CommonActionTypes.UPDATE_COST_CENTER_SUCCESS;

  constructor(public payload: { costCenter: CostCenter }) {
  }
}

export class UpdateCostCenterFailure implements Action {
  readonly type = CommonActionTypes.UPDATE_COST_CENTER_FAILURE;

  constructor(public payload: Error) {
  }
}

export class DeleteCostCenter implements Action {
  readonly type = CommonActionTypes.DELETE_COST_CENTER;

  constructor(public payload: { cost_center_code: string }) {
  }
}

export class DeleteCostCenterSuccess implements Action {
  readonly type = CommonActionTypes.DELETE_COST_CENTER_SUCCESS;

  constructor(public payload: { cost_center_code: string }) {
  }
}

export class DeleteCostCenterFailure implements Action {
  readonly type = CommonActionTypes.DELETE_COST_CENTER_FAILURE;

  constructor(public payload: Error) {
  }
}

export class MetaDataResetError implements Action {
  readonly type = CommonActionTypes.RESET_ERROR;
}

// Subscription Stage
export class LoadSubscriptionStage implements Action {
  readonly type = CommonActionTypes.LOAD_SUBSCRIPTION_STAGE;

  constructor(public payload: { page_size?: number, page_no?: number }) {
  }
}

export class LoadSubscriptionStageSuccess implements Action {
  readonly type = CommonActionTypes.LOAD_SUBSCRIPTION_STAGE_SUCCESS;

  constructor(public payload: { data: any }) {
  }
}

export class LoadSubscriptionStageFailure implements Action {
  readonly type = CommonActionTypes.LOAD_SUBSCRIPTION_STAGE_FAILURE;

  constructor(public payload: Error) {
  }
}

// Currency
export class LoadCurrency implements Action {
  readonly type = CommonActionTypes.LOAD_CURRENCY;

  constructor(public payload: { page_size?: number, page_no?: number }) {
  }
}

export class LoadCurrencySuccess implements Action {
  readonly type = CommonActionTypes.LOAD_CURRENCY_SUCCESS;

  constructor(public payload: { data: any }) {
  }
}

export class LoadCurrencyFailure implements Action {
  readonly type = CommonActionTypes.LOAD_CURRENCY_FAILURE;

  constructor(public payload: Error) {
  }
}

// Project Source Status
export class LoadProjectSourceStatus implements Action {
  readonly type = CommonActionTypes.LOAD_PROJECT_SOURCE_STATUS;
}

export class LoadProjectSourceStatusSuccess implements Action {
  readonly type = CommonActionTypes.LOAD_PROJECT_SOURCE_STATUS_SUCCESS;

  constructor(public payload: Array<ProjectSourceStatus>) {
  }
}

export class LoadProjectSourceStatusFailure implements Action {
  readonly type = CommonActionTypes.LOAD_PROJECT_SOURCE_STATUS_FAILURE;

  constructor(public payload: Error) {
  }
}

// Article Type
export class LoadArticleType implements Action {
  readonly type = CommonActionTypes.LOAD_ARTICLE_TYPE;

  constructor(public payload: { page_size?: number, page_no?: number }) {
  }
}

export class LoadArticleTypeSuccess implements Action {
  readonly type = CommonActionTypes.LOAD_ARTICLE_TYPE_SUCCESS;

  constructor(public payload: { data: any }) {
  }
}

export class LoadArticleTypeFailure implements Action {
  readonly type = CommonActionTypes.LOAD_ARTICLE_TYPE_FAILURE;

  constructor(public payload: Error) {
  }
}

// Email Template
export class LoadEmailTemplate implements Action {
  readonly type = CommonActionTypes.LOAD_EMAIL_TEMPLATE;

  constructor(public payload: { page_size?: number, page_no?: number }) {
  }
}

export class LoadEmailTemplateSuccess implements Action {
  readonly type = CommonActionTypes.LOAD_EMAIL_TEMPLATE_SUCCESS;

  constructor(public payload: { result: Array<EmailTemplate>, total_count?: number, page_size?: number, page_no?: number }) {
  }
}

export class LoadEmailTemplateFailure implements Action {
  readonly type = CommonActionTypes.LOAD_EMAIL_TEMPLATE_FAILURE;

  constructor(public payload: Error) {
  }
}

export class AddEmailTemplate implements Action {
  readonly type = CommonActionTypes.ADD_EMAIL_TEMPLATE;

  constructor(public payload: {
    email_template_name: string, email_template_subject: string, email_template_content: string,
    is_html_yn: number, parent_email_template_code: string, email_category_code: string
  }) {
  }
}

export class AddEmailTemplateSuccess implements Action {
  readonly type = CommonActionTypes.ADD_EMAIL_TEMPLATE_SUCCESS;

  constructor(public payload: { emailTemplate: EmailTemplate, email_template_parent_code: string }) {
  }
}

export class AddEmailTemplateFailure implements Action {
  readonly type = CommonActionTypes.ADD_EMAIL_TEMPLATE_FAILURE;

  constructor(public payload: Error) {
  }
}

export class UpdateEmailTemplate implements Action {
  readonly type = CommonActionTypes.UPDATE_EMAIL_TEMPLATE;

  constructor(public payload: {
    email_template_code: string, email_template_name: string, email_template_subject: string, email_template_content: string,
    is_html_yn: number, parent_email_template_code: string, email_category_code: string
  }) {
  }
}

export class UpdateEmailTemplateSuccess implements Action {
  readonly type = CommonActionTypes.UPDATE_EMAIL_TEMPLATE_SUCCESS;

  constructor(public payload: { emailTemplate: EmailTemplate, parent_email_template_code: string }) {
  }
}

export class UpdateEmailTemplateFailure implements Action {
  readonly type = CommonActionTypes.UPDATE_EMAIL_TEMPLATE_FAILURE;

  constructor(public payload: Error) {
  }
}

export class DeleteEmailTemplate implements Action {
  readonly type = CommonActionTypes.DELETE_EMAIL_TEMPLATE;

  constructor(public payload: { email_template_code: string }) {
  }
}

export class DeleteEmailTemplateSuccess implements Action {
  readonly type = CommonActionTypes.DELETE_EMAIL_TEMPLATE_SUCCESS;

  constructor(public payload: { email_template_code: string }) {
  }
}

export class DeleteEmailTemplateFailure implements Action {
  readonly type = CommonActionTypes.DELETE_EMAIL_TEMPLATE_FAILURE;

  constructor(public payload: Error) {
  }
}

// Email Log
export class LoadEmailLog implements Action {
  readonly type = CommonActionTypes.LOAD_EMAIL_LOG;

  constructor(public payload: { page_size?: number, page_no?: number }) {
  }
}

export class LoadEmailLogSuccess implements Action {
  readonly type = CommonActionTypes.LOAD_EMAIL_LOG_SUCCESS;

  constructor(public payload: { result: Array<EmailLog>, total_count?: number, page_size?: number, page_no?: number }) {
  }
}

export class LoadEmailLogFailure implements Action {
  readonly type = CommonActionTypes.LOAD_EMAIL_LOG_FAILURE;

  constructor(public payload: Error) {
  }
}

export class FilterEmailLog implements Action {
  readonly type = CommonActionTypes.FILTER_EMAIL_LOG;

  constructor(public payload: { page_size?: number, page_no?: number }) {
  }
}

export class FilterEmailLogSuccess implements Action {
  readonly type = CommonActionTypes.FILTER_EMAIL_LOG_SUCCESS;

  constructor(public payload: { result: Array<EmailLog>, total_count?: number, page_size?: number, page_no?: number }) {
  }
}

export class FilterEmailLogFailure implements Action {
  readonly type = CommonActionTypes.FILTER_EMAIL_LOG_FAILURE;

  constructor(public payload: Error) {
  }
}

// Deliverable Item Date
export class LoadDeliverableItemDate implements Action {
  readonly type = CommonActionTypes.LOAD_DELIVERABLE_ITEM_DATE;
}

export class LoadDeliverableItemDateSuccess implements Action {
  readonly type = CommonActionTypes.LOAD_DELIVERABLE_ITEM_DATE_SUCCESS;

  constructor(public payload: { result: Array<DeliverableItemDate> }) {
  }
}

export class LoadDeliverableItemDateFailure implements Action {
  readonly type = CommonActionTypes.LOAD_DELIVERABLE_ITEM_DATE_FAILURE;

  constructor(public payload: Error) {
  }
}

export class AddDeliverableItemDate implements Action {
  readonly type = CommonActionTypes.ADD_DELIVERABLE_ITEM_DATE;

  constructor(public payload: { deliverable_item_date_name: string, filter_color: string, short_code: string, filter_text_color: string, rank_order: number }) {
  }
}

export class AddDeliverableItemDateSuccess implements Action {
  readonly type = CommonActionTypes.ADD_DELIVERABLE_ITEM_DATE_SUCCESS;

  constructor(public payload: { deliverableItemDate: DeliverableItemDate }) {
  }
}

export class AddDeliverableItemDateFailure implements Action {
  readonly type = CommonActionTypes.ADD_DELIVERABLE_ITEM_DATE_FAILURE;

  constructor(public payload: Error) {
  }
}

export class UpdateDeliverableItemDate implements Action {
  readonly type = CommonActionTypes.UPDATE_DELIVERABLE_ITEM_DATE;

  constructor(public payload: { deliverable_item_date_code: string, deliverable_item_date_name: string,
    filter_color: string, short_code: string, filter_text_color: string, rank_order: number }) {
  }
}

export class UpdateDeliverableItemDateSuccess implements Action {
  readonly type = CommonActionTypes.UPDATE_DELIVERABLE_ITEM_DATE_SUCCESS;

  constructor(public payload: { deliverableItemDate: DeliverableItemDate, deliverable_item_date_code: string }) {
  }
}

export class UpdateDeliverableItemDateFailure implements Action {
  readonly type = CommonActionTypes.UPDATE_DELIVERABLE_ITEM_DATE_FAILURE;

  constructor(public payload: Error) {
  }
}

export class LoadSocialMediaPlatform implements Action {
  readonly type = CommonActionTypes.LOAD_SOCIAL_MEDIA_PLATFORM;
}

export class LoadSocialMediaPlatformSuccess implements Action {
  readonly type = CommonActionTypes.LOAD_SOCIAL_MEDIA_PLATFORM_SUCCESS;

  constructor(public payload: { result: any[] }) {
  }
}

export class LoadSocialMediaPlatformFailure implements Action {
  readonly type = CommonActionTypes.LOAD_SOCIAL_MEDIA_PLATFORM_FAILURE;

  constructor(public payload: Error) {
  }
}

export class LoadRegions implements Action {
  readonly type = CommonActionTypes.LOAD_REGION;
}

export class LoadRegionsSuccess implements Action {
  readonly type = CommonActionTypes.LOAD_REGION_SUCCESS;

  constructor(public payload: any) {
  }
}

export class LoadRegionsFailure implements Action {
  readonly type = CommonActionTypes.LOAD_REGION_FAILURE;

  constructor(public payload: Error) {
  }
}

export class LoadOrderStatus implements Action {
  readonly type = CommonActionTypes.LOAD_ORDER_STATUS;
}

export class LoadOrderStatusSuccess implements Action {
  readonly type = CommonActionTypes.LOAD_ORDER_STATUS_SUCCESS;

  constructor(public payload: any) {
  }
}

export class LoadOrderStatusFailure implements Action {
  readonly type = CommonActionTypes.LOAD_ORDER_STATUS_FAILURE;

  constructor(public payload: Error) {
  }
}

// Cost Center
export class LoadCommunityService implements Action {
  readonly type = CommonActionTypes.LOAD_COMMUNITY_SERVICE;

  constructor(public payload: { page_size?: number, page_no?: number }) {
  }
}

export class LoadCommunityServiceSuccess implements Action {
  readonly type = CommonActionTypes.LOAD_COMMUNITY_SERVICE_SUCCESS;

  constructor(public payload: { result: Array<any>, total_count?: number, page_size?: number, page_no?: number }) {
  }
}

export class LoadCommunityServiceFailure implements Action {
  readonly type = CommonActionTypes.LOAD_COMMUNITY_SERVICE_FAILURE;

  constructor(public payload: Error) {
  }
}

// Cost Center
export class LoadCommunityType implements Action {
  readonly type = CommonActionTypes.LOAD_COMMUNITY_TYPE;

  constructor(public payload: { page_size?: number, page_no?: number }) {
  }
}

export class LoadCommunityTypeSuccess implements Action {
  readonly type = CommonActionTypes.LOAD_COMMUNITY_TYPE_SUCCESS;

  constructor(public payload: { result: Array<any>, total_count?: number, page_size?: number, page_no?: number }) {
  }
}

export class LoadCommunityTypeFailure implements Action {
  readonly type = CommonActionTypes.LOAD_COMMUNITY_TYPE_FAILURE;

  constructor(public payload: Error) {
  }
}

export class LoadProductType implements Action {
  readonly type = CommonActionTypes.LOAD_PRODUCT_TYPE;

  constructor(public payload: { page_size?: number, page_no?: number }) {
  }
}

export class LoadProductTypeSuccess implements Action {
  readonly type = CommonActionTypes.LOAD_PRODUCT_TYPE_SUCCESS;

  constructor(public payload: { result: Array<any>, total_count?: number, page_size?: number, page_no?: number }) {
  }
}

export class LoadProductTypeFailure implements Action {
  readonly type = CommonActionTypes.LOAD_PRODUCT_TYPE_FAILURE;

  constructor(public payload: Error) {
  }
}


export type CommonActions =
  LoadTags
  | LoadTagsSuccess
  | LoadTagsFailure
  | LoadUsers
  | LoadUsersSuccess
  | LoadUsersFailure
  | LoadCountries
  |
  LoadCountriesSuccess
  | LoadCountriesFailure
  | LoadDeliverableItems
  | LoadDeliverableItemsSuccess
  | LoadDeliverableItemsFailure
  |
  AddDeliverableItems
  | AddDeliverableItemsSuccess
  | AddDeliverableItemsFailure
  | UpdateDeliverableItems
  | UpdateDeliverableItemsSuccess
  |
  UpdateDeliverableItemsFailure
  | DeleteDeliverableItem
  | DeleteDeliverableItemsSuccess
  | DeleteDeliverableItemsFailure
  |
  MetaDataResetError
  | LoadLineItems
  | LoadLineItemsSuccess
  | LoadLineItemsFailure
  | AddLineItem
  | AddLineItemSuccess
  | AddLineItemFailure
  |
  UpdateLineItem
  | UpdateLineItemSuccess
  | UpdateLineItemFailure
  | DeleteLineItem
  | DeleteLineItemSuccess
  | DeleteLineItemFailure
  |
  LoadDeliverableStatus
  | LoadDeliverableStatusSuccess
  | LoadDeliverableStatusFailure
  | LoadCostCenter
  | LoadCostCenterSuccess
  |
  LoadCostCenterFailure
  | AddDeliverableStatus
  | AddDeliverableStatusSuccess
  | AddDeliverableStatusFailure
  | UpdateDeliverableStatus
  |
  UpdateDeliverableStatusSuccess
  | UpdateDeliverableStatusFailure
  | DeleteDeliverableStatus
  | DeleteDeliverableStatusSuccess
  |
  DeleteDeliverableStatusFailure
  | AddCostCenter
  | AddCostCenterSuccess
  | AddCostCenterFailure
  | UpdateCostCenter
  | UpdateCostCenterSuccess
  |
  UpdateCostCenterFailure
  | DeleteCostCenter
  | DeleteCostCenterSuccess
  | DeleteCostCenterFailure
  | LoadSubscriptionStage
  |
  LoadSubscriptionStageSuccess
  | LoadSubscriptionStageFailure
  | LoadCurrency
  | LoadCurrencySuccess
  | LoadCurrencyFailure
  |
  LoadProjectSourceStatus
  | LoadProjectSourceStatusSuccess
  | LoadProjectSourceStatusFailure
  | LoadArticleType
  | LoadArticleTypeSuccess
  |
  LoadArticleTypeFailure
  | LoadEmailTemplate
  | LoadEmailTemplateSuccess
  | LoadEmailTemplateFailure
  | AddEmailTemplate
  |
  AddEmailTemplateSuccess
  | AddEmailTemplateFailure
  | UpdateEmailTemplate
  | UpdateEmailTemplateSuccess
  | UpdateEmailTemplateFailure
  |
  DeleteEmailTemplate
  | DeleteEmailTemplateSuccess
  | DeleteEmailTemplateFailure
  | LoadEmailLog
  | LoadEmailLogSuccess
  | LoadEmailLogFailure
  |
  FilterEmailLog
  | FilterEmailLogSuccess
  | FilterEmailLogFailure
  | LoadDeliverableItemDate
  | LoadDeliverableItemDateSuccess
  | LoadDeliverableItemDateFailure
  | AddDeliverableItemDate
  | AddDeliverableItemDateSuccess
  | AddDeliverableItemDateFailure
  | UpdateDeliverableItemDate
  | UpdateDeliverableItemDateSuccess
  | UpdateDeliverableItemDateFailure |
  LoadSocialMediaPlatform | LoadSocialMediaPlatformSuccess | LoadSocialMediaPlatformFailure |
  LoadRegions | LoadRegionsSuccess | LoadRegionsFailure |
  LoadOrderStatus | LoadOrderStatusSuccess | LoadOrderStatusFailure |
  LoadCommunityService | LoadCommunityServiceSuccess | LoadCommunityServiceFailure |
  LoadCommunityType | LoadCommunityTypeSuccess | LoadCommunityTypeFailure |
  LoadProductType | LoadProductTypeSuccess | LoadProductTypeFailure;
