// Angular
import { Component, Input, OnInit } from '@angular/core';
// RxJS
import { Observable } from 'rxjs';
// NGRX
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/core';
import {User} from '../../../../../core/models/user.model';
import {CommonService} from '../../../../../core/services/common.service';
// State


@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnInit {
  // Public properties
  user$: Observable<User>;

  @Input() avatar = true;
  @Input() greeting = true;
  @Input() badge: boolean;
  @Input() icon: boolean;

  /**
   * Component constructor
   *
   * @param store: Store<AppState>
   */
  constructor(private store: Store<AppState>, private commonService: CommonService) {
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {
    this.user$ = this.store.pipe(select(store => store.auth.user));
    // this.user$.subscribe((data) => {
    //   this.commonService.getUserAvatar(data.user_code).subscribe((data) => {
    //
    //   })
    // })

  }

  /**
   * Log out
   */
  // logout() {
  //   this.store.dispatch(new Logout());
  // }
}
