<ejs-sidebar id="sidebar-menu" #sidebarInstance type='Over' [width]='width' [position]='position'
             style="visibility: hidden" [enableGestures]="false" [showBackdrop]=true>

  <div class="offcanvas-close mt-n1 pr-5" style="float:right; padding: 48px;">
    <a href="javascript:;" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_panel_close"
       (click)="closeSidebar()">
      <i class="ki ki-close icon-xs text-muted"></i>
    </a>
  </div>

  <div class='p-15'>
    <h2 *ngIf="!deliverableStatusInput">Add Deliverable Status</h2>
    <h2 *ngIf="deliverableStatusInput">Update Deliverable Status</h2>

    <form id="formId" [formGroup]="deliverableStatusForm" #formDir="ngForm" class="form-horizontal" novalidate=""
          style="margin-top: 16px;">


      <div class="form-group sf-outline-input mb-10" [class.hide-label]="deliverableStatusForm.controls.deliverable_status_parent_code.value == ''">
        <ejs-dropdowntree formControlName="deliverable_status_parent_code" id='deliverable_status_parent_code' allowMultiSelection='false'
                          [fields]='deliverableStatusFields' placeholder='Parent Deliverable Status' floatLabelType="Auto" cssClass="e-filled"
                          (blur)="deliverableStatusForm.controls.deliverable_status_parent_code.markAsTouched()"></ejs-dropdowntree>
        <div>Note: If a parent status isn't selected, the new deliverable status will be added as a parent</div>
      </div>


      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="deliverable_status_name" placeholder="Deliverable Status Name *" cssClass="e-filled"
                     formControlName="deliverable_status_name" floatLabelType="Auto"
                     (blur)="deliverableStatusForm.controls.deliverable_status_name.markAsTouched()"
        ></ejs-textbox>
        <div *ngIf="deliverableStatusForm.controls.deliverable_status_name.errors">
          <div
            *ngIf="deliverableStatusForm.controls.deliverable_status_name.errors.required && deliverableStatusForm.controls.deliverable_status_name.touched"
            class="e-error">
            Deliverable Status Name is required.
          </div>
        </div>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="description" [multiline]="true" placeholder="Description" cssClass="e-filled"
                     formControlName="description" floatLabelType="Auto"
                     (blur)="deliverableStatusForm.controls.description.markAsTouched()"
        ></ejs-textbox>
        <div *ngIf="deliverableStatusForm.controls.description.errors">
          <div *ngIf="deliverableStatusForm.controls.description.errors.maxlength && deliverableStatusForm.controls.description.touched"
               class="e-error">
            Description cannot be more than 200 characters
          </div>
        </div>
      </div>


      <div *ngIf="deliverableStatusForm.invalid" class="e-error">Please fill all fields</div>
      <div *ngIf="error$ | async as error" class="e-error">{{error.error.message}}</div>

      <div class="my-10 pb-20">
        <button id="resetbtn"
                class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5"
                type="reset"
                data-ripple="true">Clear
        </button>
        <button *ngIf="!deliverableStatusInput" id="addSubmit"
                class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit"
                (click)="onAddUpdateDeliverableStatus(deliverableStatusForm)" [disabled]="formSent" data-ripple="true"> Add
          Deliverable Status
        </button>
        <button *ngIf="deliverableStatusInput" id="updateSubmit"
                class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit"
                (click)="onAddUpdateDeliverableStatus(deliverableStatusForm, true)" [disabled]="formSent" data-ripple="true">
          Update Deliverable Status
        </button>
      </div>
    </form>
  </div>
</ejs-sidebar>
