import {Component, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {SidebarComponent} from '@syncfusion/ej2-angular-navigations';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';
import {ProjectType} from '../../../../../core/models/project-type.model';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../core';
import {trimValidator} from '../../../../../app-validators';
import {ProjectTypeAdd, ProjectTypeResetError, ProjectTypeUpdate} from '../../../../../core/store/actions/project-type.actions';

@Component({
  selector: 'app-project-types-form',
  templateUrl: './project-types.component.html',
  styleUrls: ['./project-types.component.scss']
})
export class ProjectTypesComponent implements OnInit {
  @ViewChild('sidebarInstance') sidebarInstance: SidebarComponent;
  @Input() parentProjectTypes: any;

  @Output() valueChange = new EventEmitter();
  projectTypeForm: FormGroup;

  public width = 'auto';
  public position = 'Right';

  formSent: boolean;
  errorMsg: any;
  error$: Observable<any>;
  success$: Observable<boolean>;
  public projectTypeFields: object = {value: 'project_type_code', text: 'project_type_name'};

  public _projectTypeInput$: BehaviorSubject<ProjectType | null> = new BehaviorSubject<ProjectType | null>(null);

  @Input() set projectTypeInput(mode: ProjectType | null) {
    this._projectTypeInput$.next(mode);
  }

  get projectTypeInput(): ProjectType | null {
    return this._projectTypeInput$.getValue();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.sidebarInstance.isOpen) {
      this.closeSidebar();
    }
  }

  constructor(private formBuilder: FormBuilder, private store: Store<AppState>) { }

  ngOnInit(): void {
    if (!this.projectTypeForm) {
      this.generateProjectTypeForm();
    }

    this.error$ = this.store.pipe(select(store => store.projectType.error));
    this.error$.subscribe((data: any) => {

      if (data) {
        this.errorMsg = data.error.message;
        this.formSent = false;
      }
    });

    this.success$ = this.store.pipe(select(store => store.projectType.success));
    this.success$.subscribe((data: any) => {

      if (data) {
        this.formSent = false;
        this.closeSidebar();
      }
    });
  }

  generateProjectTypeForm() {
    this.projectTypeForm = this.formBuilder.group({
      project_type_parent_code: new FormControl(''),
      project_type_name: new FormControl('', [Validators.required, Validators.compose([trimValidator])]),
    });
  }

  openSidebar(): void {
    this.sidebarInstance.show();
  }

  closeSidebar(): void {
    this.valueChange.emit('success');
    this.clearForm();
    this.sidebarInstance.hide();
  }

  clearForm() {
    this.store.dispatch(new ProjectTypeResetError());
    this.projectTypeForm.reset();
    this.projectTypeForm.controls.project_type_parent_code.setValue('');
  }

  onAddUpdateProjectType(form: FormGroup, isUpdate = false) {
    if (!form.invalid) {
      this.formSent = true;
      if (!this._projectTypeInput$.getValue()) {
        this.store.dispatch(new ProjectTypeAdd({
          project_type_name: form.value.project_type_name,
          parent_type_code: form.value.project_type_parent_code[0]
        }));
      } else {
        this.store.dispatch(new ProjectTypeUpdate({
          projectTypeName: form.value.project_type_name,
          project_type_code: this._projectTypeInput$.getValue().project_type_code,
          parent_type_code: form.value.project_type_parent_code ? form.value.project_type_parent_code[0] : null
        }));
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this._projectTypeInput$.getValue()) {
      this.projectTypeForm.setValue({
        project_type_name: this._projectTypeInput$.getValue().project_type_name,
        project_type_parent_code: this._projectTypeInput$.getValue().parent ? [this._projectTypeInput$.getValue().parent] : null
      });
    }

    if (changes.parentProjectTypes) {
      this.projectTypeFields = {
        dataSource: this.parentProjectTypes,
        value: 'project_type_code',
        text: 'project_type_name',
        child: 'children'
      };
    }

  }

}
