import {CompanyTypeActions, CompanyTypeActionTypes} from '../actions/company-type.actions';
import {log} from 'util';

export interface CompanyTypeState {
  companyType?: {
    result?: any,
    total_count?: number,
    page_no?: number,
    page_size?: number
  };
  error?: Error;
  loading: boolean;
  loaded: boolean;
  success?: boolean;
}

export const initialState: CompanyTypeState = {
  companyType: {
    result: [],
    total_count: 0,
    page_no: 0,
    page_size: 0
  },
  error: undefined,
  loading: false,
  loaded: false,
};

export const ReturnLoading = (State: CompanyTypeState) => {
  return {
    ...State,
    loading: true,
    loaded: false,
    error: undefined,
    success: undefined
  };
};

export function companyTypeReducer(state: CompanyTypeState = initialState, action: CompanyTypeActions): CompanyTypeState {
  switch (action.type) {

    case CompanyTypeActionTypes.LOAD_COMPANY_TYPE: {
      return ReturnLoading(state);
    }

    case CompanyTypeActionTypes.LOAD_COMPANY_TYPE_SUCCESS: {
      let companyTypeList = [...action.payload.result];

      // companyTypeList = childrenFunction(companyTypeList, action.payload.result, null);
      // companyTypeList = action.payload.result;

      // companyTypeList = companyTypeList.filter((thing, index, self) =>
      //   index === self.findIndex((t) => (
      //     t.company_type_code === thing.company_type_code && t.company_type_name === thing.company_type_name
      //   ))
      // );

      state = {
        ...state, companyType: {
          result: companyTypeList, page_no: action.payload.page_no, page_size: action.payload.page_size,
          total_count: action.payload.total_count
        }, loading: false, error: undefined, loaded: true,
      };

      return state;
    }

    case CompanyTypeActionTypes.LOAD_COMPANY_TYPE_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case CompanyTypeActionTypes.ADD_COMPANY_TYPE: {
      return ReturnLoading(state);
    }

    case CompanyTypeActionTypes.ADD_COMPANY_TYPE_SUCCESS: {
      let newCompanyType;
      if (action.payload.parentCode) {
        const parentCompanyType = {...find([...state.companyType.result], action.payload.parentCode)};
        const childrenArray = [...parentCompanyType.children];
        childrenArray.push(action.payload.companyType);
        parentCompanyType.children = childrenArray;
        const findParent = findAndReplace([...state.companyType.result], action.payload.parentCode, parentCompanyType);
        newCompanyType = findParent;
      } else {
        newCompanyType = [action.payload.companyType, ...state.companyType.result];
      }
      return {
        ...state, loading: false, companyType: {
          result: newCompanyType
        }, error: undefined, loaded: true,
        success: true
      };
    }

    case CompanyTypeActionTypes.ADD_COMPANY_TYPE_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }


    case CompanyTypeActionTypes.UPDATE_COMPANY_TYPE: {
      return ReturnLoading(state);
    }

    case CompanyTypeActionTypes.UPDATE_COMPANY_TYPE_SUCCESS: {
      const companyTypeList = findAndDelete([...state.companyType.result], action.payload.companyType.company_type_code);
      let newCompanyType;
      if (action.payload.parent_type_code) {
        const parentCompanyType = {...find(companyTypeList, action.payload.parent_type_code)};
        const childrenArray = [...parentCompanyType.children];
        childrenArray.push(action.payload.companyType);
        parentCompanyType.children = childrenArray;
        const findParent = findAndReplace(companyTypeList, action.payload.parent_type_code, parentCompanyType);
        newCompanyType = findParent;
      } else {
        newCompanyType = [action.payload.companyType, ...companyTypeList];
      }
      return {
        ...state, companyType: {result : newCompanyType}, error: undefined, loading: false, loaded: true,
        success: true
      };
    }

    case CompanyTypeActionTypes.UPDATE_COMPANY_TYPE_FAILURE:
      return {...state, error: action.payload, loading: false, loaded: false};


    case CompanyTypeActionTypes.DELETE_COMPANY_TYPE:
      return ReturnLoading(state);

    case CompanyTypeActionTypes.DELETE_COMPANY_TYPE_SUCCESS: {
      const companyTypeList = findAndDelete([...state.companyType.result], action.payload.companyTypeCode);
      return {
        ...state,
        companyType: {result: companyTypeList},
        loading: false,
        error: undefined,
        loaded: true
      };
    }

    case CompanyTypeActionTypes.DELETE_COMPANY_TYPE_FAILURE:
      return {...state, error: action.payload, loading: false, loaded: false};


    case CompanyTypeActionTypes.RESET_ERROR:
      return ReturnLoading(state);

    default:
      return state;
  }
}
//
// function childrenFunction(companyTypeList, apiResult, parent) {
//   for (const companyType of apiResult) {
//     companyTypeList.push({
//       company_type_code: companyType.company_type_code,
//       company_type_name: companyType.company_type_name,
//       parent
//     });
//     const childParent = {company_type_code: companyType.company_type_code, company_type_name: companyType.company_type_name};
//     if (companyType.children) {
//       childrenFunction(companyTypeList, companyType.children, childParent);
//     }
//   }
//
//   return companyTypeList;
// }

function find(array, id) {
  let result;
  array.some(o => result = o.company_type_code === id ? o : find(o.children || [], id));
  return result;
}

function findAndReplace(companyTypeList, findValue, replaceValue) {
  for (const companyType in companyTypeList) {
    if (companyTypeList[companyType].company_type_code === findValue) {
      companyTypeList[companyType] = replaceValue;
      return companyTypeList;
    }

    if (companyTypeList[companyType].children) {
      const children = findAndReplace([...companyTypeList[companyType].children], findValue, replaceValue);
      const companyTypeListChildCopy: any = {...companyTypeList[companyType]};
      companyTypeListChildCopy.children = children;
      companyTypeList[companyType] = companyTypeListChildCopy;
    }
  }
  return companyTypeList;
}

function findAndDelete(companyTypeList, findValue) {
  for (const companyType in companyTypeList) {
    if (companyTypeList[companyType].company_type_code === findValue) {
      delete companyTypeList[companyType];
      const companyTypeArray = companyTypeList.filter((el) => {
        return el != null;
      });
      return companyTypeArray;
    }

    if (companyTypeList[companyType].children) {
      const children = findAndDelete([...companyTypeList[companyType].children], findValue);
      const companyTypeListChildCopy: any = {...companyTypeList[companyType]};
      companyTypeListChildCopy.children = children;
      companyTypeList[companyType] = companyTypeListChildCopy;
    }
  }
  return companyTypeList;
}
