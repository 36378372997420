// Anglar
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// Layout Directives
// Services
import {
//   ContentAnimateDirective,
//   FirstLetterPipe,
//   GetObjectPipe,
//   HeaderDirective,
//   JoinPipe,
  MenuDirective,
  OffcanvasDirective,
//   SafePipe,
//   ScrollTopDirective,
//   SparklineChartDirective,
//   StickyDirective,
//   TabClickEventDirective,
//   TimeElapsedPipe,
//   ToggleDirective
} from './_base/layout';
import {CoordinateDirective} from './_base/layout/directives/coordinate.directive';

@NgModule({
  imports: [CommonModule],
    declarations: [
        OffcanvasDirective,
        MenuDirective,
        CoordinateDirective,
    ],
    exports: [

        OffcanvasDirective,
        MenuDirective,
        CoordinateDirective,

    ],
  providers: []
})
export class CoreModule {
}
