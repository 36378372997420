import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {SidebarComponent} from '@syncfusion/ej2-angular-navigations';
import {Form, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';
import {CompanyType} from '../../../../../core/models/company-types.model';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../core';
import {CompanyTypeAdd, CompanyTypeResetError, CompanyTypeUpdate} from '../../../../../core/store/actions/company-type.actions';
import {trimValidator} from '../../../../../app-validators';

@Component({
  selector: 'app-company-types-form',
  templateUrl: './company-types.component.html',
  styleUrls: ['./company-types.component.scss']
})
export class CompanyTypesComponent implements OnInit, OnChanges {
  @ViewChild('sidebarInstance') sidebarInstance: SidebarComponent;
  @Input() parentCompanyTypes: any;

  @Output() valueChange = new EventEmitter();
  companyTypeForm: FormGroup;

  public width = 'auto';
  public position = 'Right';

  formSent: boolean;
  errorMsg: any;
  error$: Observable<any>;
  success$: Observable<boolean>;
  public companyTypeFields: object = {value: 'company_type_code', text: 'company_type_name'};

  public _companyTypeInput$: BehaviorSubject<CompanyType | null> = new BehaviorSubject<CompanyType | null>(null);

  @Input() set companyTypeInput(mode: CompanyType | null) {
    this._companyTypeInput$.next(mode);
  }

  get companyTypeInput(): CompanyType | null {
    return this._companyTypeInput$.getValue();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.sidebarInstance.isOpen) {
      this.closeSidebar();
    }
  }

  constructor(private formBuilder: FormBuilder, private store: Store<AppState>) {
  }

  ngOnInit(): void {
    if (!this.companyTypeForm) {
      this.generateCompanyTypeForm();
    }

    this.error$ = this.store.pipe(select(store => store.companyType.error));
    this.error$.subscribe((data: any) => {

      if (data) {
        this.errorMsg = data.error.message;
        this.formSent = false;
      }
    });

    this.success$ = this.store.pipe(select(store => store.companyType.success));
    this.success$.subscribe((data: any) => {

      if (data) {
        this.formSent = false;
        this.closeSidebar();
      }
    });

  }

  generateCompanyTypeForm() {
    this.companyTypeForm = this.formBuilder.group({
      company_type_parent_code: new FormControl(''),
      company_type_name: new FormControl('', [Validators.required, Validators.compose([trimValidator])]),
    });
  }

  openSidebar(): void {
    this.sidebarInstance.show();
  }

  closeSidebar(): void {
    this.valueChange.emit('success');
    this.clearForm();
    this.sidebarInstance.hide();
  }

  clearForm() {
    this.companyTypeForm.reset();
    this.companyTypeForm.controls.company_type_parent_code.setValue('');
    this.store.dispatch(new CompanyTypeResetError());
  }

  onAddUpdateCompanyType(form: FormGroup, isUpdate = false) {
    if (!form.invalid) {
      this.formSent = true;
      if (!this._companyTypeInput$.getValue()) {
        this.store.dispatch(new CompanyTypeAdd({
          company_type_name: form.value.company_type_name,
          parent_type_code: form.value.company_type_parent_code[0]
        }));
      } else {
        this.store.dispatch(new CompanyTypeUpdate({
          companyTypeName: form.value.company_type_name,
          company_type_code: this._companyTypeInput$.getValue().company_type_code,
          parent_type_code: form.value.company_type_parent_code ? form.value.company_type_parent_code[0] : null
        }));
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this._companyTypeInput$.getValue()) {
      this.companyTypeForm.setValue({
        company_type_name: this._companyTypeInput$.getValue().company_type_name,
        company_type_parent_code: this._companyTypeInput$.getValue().parent ? [this._companyTypeInput$.getValue().parent] : null
      });
    }

    if (changes.parentCompanyTypes) {
      this.companyTypeFields = {
        dataSource: this.parentCompanyTypes,
        value: 'company_type_code',
        text: 'company_type_name',
        child: 'children'
      };
    }

  }

}
