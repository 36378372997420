import { Action } from '@ngrx/store';
import { Project } from '../../models/project.model';
import { Activity } from '../../models/activity.model';
import { ProjectSource } from '../../models/project-source.model';
import {ProjectRole} from '../../models/project-role.model';
import {TransactionHistory} from '../../models/transaction-history.model';
import {CompanyActionTypes} from './company.actions';
import {Company} from '../../models/company.model';
import {ProjectActionTypes} from './project.actions';

export enum ArticleActionTypes {

  LOAD = '[ARTICLE] Load Action',
  LOAD_SUCCESS = '[ARTICLE] Load Success Action',
  LOAD_FAILURE = '[ARTICLE] Load Failure Action',

  FILTER = '[ARTICLE] Filter Action',
  FILTER_SUCCESS = '[ARTICLE] Filter Success Action',
  FILTER_FAILURE = '[ARTICLE] Filter Failure Action',

  FILTER_RESET = '[ARTICLE] FILTER RESET',
  SET_SUCCESS_NULL = '[ARTICLE] Set Success Null',

}

export class ArticleLoad implements Action {
  readonly type = ArticleActionTypes.LOAD;
  constructor(public payload?: {page_size?: number, page_no?: number }) { }
}

export class ArticleLoadSuccess implements Action {
  readonly type = ArticleActionTypes.LOAD_SUCCESS;
  constructor(public payload: {
   result: [],
    total_count?: number,
    page_no?: number,
    page_size?: number
  }) { }
}

export class ArticleLoadFailure implements Action {
  readonly type = ArticleActionTypes.LOAD_FAILURE;
  constructor(public payload: Error) { }
}

export class ArticleFilter implements Action {
  readonly type = ArticleActionTypes.FILTER;
  constructor(public payload: { article_name?: string, reference_id?: string, article_type_code?: [], country?: [], tags?: string[],
    assigned_to?: string, proposed_post_date?: string, status?: string[], page_no?: number, page_size?: number, sort_by?: string,
    sort_order?: string, company_code?: string, article_contact_code?: string, company_code_source?: string, contact_code_article?: string }) { }
}

export class ArticleFilterSuccess implements Action {
  readonly type = ArticleActionTypes.FILTER_SUCCESS;
  constructor(public payload: {
    result: [],
    total_count?: number,
    page_no?: number,
    page_size?: number
  }) { }
}

export class ArticleFilterFailure implements Action {
  readonly type = ArticleActionTypes.FILTER_FAILURE;
  constructor(public payload: Error) { }
}

export class ArticleFilterReset implements Action {
  readonly type = ArticleActionTypes.FILTER_RESET;
}

export class ArticleSetSuccessNull implements Action {
  readonly type = ArticleActionTypes.SET_SUCCESS_NULL;
}




export type ArticleActions = ArticleLoad | ArticleLoadSuccess | ArticleLoadFailure | ArticleFilter | ArticleFilterSuccess |
  ArticleFilterFailure | ArticleFilterReset | ArticleSetSuccessNull;
