import { Pipe, PipeTransform } from '@angular/core';
import { faPhoneVolume, faStickyNote } from '@fortawesome/free-solid-svg-icons';


@Pipe({
  name: 'iconGenerate'
})
export class IconGeneratePipe implements PipeTransform {

  faphoneVolume = faPhoneVolume;
  faNote = faStickyNote;

  transform(value: unknown, ...args: string[]): unknown {
    let selectedCSS;

    switch (value) {
      case 'meeting':
        selectedCSS = args[0] === 'icon' ? this.faphoneVolume : 'Meeting';
        break;

      case 'note':
        selectedCSS = args[0] === 'icon' ? this.faNote : 'Notes';
        break;

      case 'status-changed':
        selectedCSS = args[0] === 'icon' ? this.faNote : 'Status Changed';
        break;

      case 'email-sent':
        selectedCSS = args[0] === 'icon' ? this.faNote : 'Email Sent';
        break;

      case 'assigned-to-changed':
        selectedCSS = args[0] === 'icon' ? this.faNote : 'Assigned User Changed';
        break;

      case 'post-date-changed':
        selectedCSS = args[0] === 'icon' ? this.faNote : 'Post Date Changed';
        break;

      case 'featured-date-changed':
        selectedCSS = args[0] === 'icon' ? this.faNote : 'Featured Date Changed';
        break;

      default:
        break;
    }
    return selectedCSS;
  }

}
