import {ActionReducer, INIT, MetaReducer} from '@ngrx/store';
import {AuthActionTypes} from '../auth/auth.actions';

export function logout(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    if ( action != null && action.type === AuthActionTypes.LOGOUT) {
      return reducer( undefined, {type: INIT});
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer[] = [ logout ];
